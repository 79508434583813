
function getCandidateStatus(candidate) {
    return candidate.status;
};

/**
 * If the candidate has a request from this company, return the request status, otherwise return the
 * candidate status.
 * @param candidate - The candidate object
 * @returns The status of the candidate or the request.
 */
function getCandidateOrRequestStatus(candidate) {
    const companyId = getCompanyId(candidate.job || job);
    const request = (candidate.company_requests || []).find((request) => request.company_id == companyId || request.company?.adminID == companyId);
    return request?.status || candidate.status;
};
/**
 * It returns true if the candidate's status is either approved or accepted
 * @param candidate - The candidate object
 * @returns A boolean value.
 */
function getCandidateOrRequestStatusIsApproved(candidate, job) {
    const status = getCandidateOrRequestStatus(candidate, job);
    return status === 'approved';
}
/**
 * It returns true if the candidate's status is approved, requested, pending, request, or if the
 * company request is approved
 * @param candidate - The candidate object
 */
function getCandidateCanBeInterviewed(candidate, job) {
    const status = candidate.status;
    return status === 'approved' || status === 'requested' || status === 'pending' || status === 'request' || getCompanyRequestIsApproved(candidate, job);
};
/**
 * If the candidate has a company request for the company, and the request is accepted, return true.
 * @param candidate - The candidate object
 * @returns A boolean value.
 */
function getCompanyRequestIsApproved(candidate, job) {
    const companyId = getCompanyId(candidate.job || job);
    const request = (candidate.company_requests || []).find((request) => request.company_id == companyId || request.company?.adminID == companyId);
    return request.status === 'approved';
};
// Still not 100% sure what company_id is supposed to be here. The data structure isn't clear
function getCompanyId(candidateJob) {
    return (candidateJob && candidateJob.subscriber_id || candidateJob.company_id) || (job && job.subscriber_id || job.company_id);
};

function parseHTMLText(htmlText = '') {
    if (htmlText?.trim()?.includes('<br>') && htmlText?.replace(/<br>/g, '')?.replace(/(?:\r\n|\r|\n)/g, '')?.replace(/\s/g, '')?.trim()?.length) {
        return htmlText;
    }
    return '';
}

const equals = (str1 = '', str2 = '') => str1.trim().toLowerCase() === str2.trim().toLowerCase();

const isEmpty = (obj = {}) => !Object.values(obj).map((o) => typeof o === 'number' || !!o).some(Boolean);

export {
    isEmpty,
    getCompanyId,
    getCandidateOrRequestStatus,
    getCandidateCanBeInterviewed,
    getCandidateOrRequestStatusIsApproved,
    getCompanyRequestIsApproved,
    parseHTMLText,
    getCandidateStatus,
    equals,
}