import { useMutation, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import { Link, navigate } from 'gatsby';
import moment from 'moment';
import fetch from 'node-fetch';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Arrow, TabTitle } from '../../components/common';
import JobsFilter from '../../components/Filter/JobsFilter';
import { EXPRESS_SERVER_URL } from '../../config';
import { ApolloContext } from '../../context/Apollo';
import CaretDownGray from '../../images/caret-down-gray.png';
import BriefcaseIcon from '../../images/experience.png';
import LocationPin from '../../images/location.svg';
import UserAvatar from '../../images/user-avatar.png';
import RecruiterIcon from '../../images/user-icon.png';
import { EMAIL_TEMPLATES } from '../../utils/constants';
import { formatSalary } from '../../utils/formatter';
import FilteredJobs from './filtered-jobs';
import JobImportModal from './job-import-modal';
import SampleCsvJobs from './sample-csv-jobs';

const UPDATE_JOB_STATUS = gql`
  mutation updateJobStatus($jobID: uuid, $status: String) {
    update_job(where: { id: { _eq: $jobID } }, _set: { status: $status }) {
      affected_rows
    }
  }
`;

const UPDATE_APPROVE_STATUS = gql`
  mutation updateJobStatus($jobID: uuid, $adminApproved: Boolean) {
    update_job(where: { id: { _eq: $jobID } }, _set: { adminApproved: $adminApproved }) {
      affected_rows
      returning {
        id
        adminApproved
        subscriber {
          id
          email
        }
      }
    }
  }
`;

const GET_SUBSCRIBER = gql`
  query getCompany($admin_id: String) {
    company(where: { adminID: { _eq: $admin_id } }) {
      id
      name
      logoURL
    }
  }
`;

const StatusSelect = ({ options, status, jobID }) => {
  const [value, setValue] = useState(status ? options.filter(({ value }) => value === status)[0] : options[0]);
  const textRef = useRef();
  const [width, setWidth] = useState();
  const [setJobStatus] = useMutation(UPDATE_JOB_STATUS);

  const colors = {
    pink: {
      text: 'rgb(255, 76, 138)',
      bg: 'rgba(255, 76, 138, 0.29)',
    },
    orange: {
      text: 'rgb(244, 144, 74)',
      bg: 'rgba(244, 144, 74, 0.29)',
    },
    blue: {
      text: 'rgb(0, 102, 255)',
      bg: 'rgba(0, 102, 255, 0.29)',
    },
  };

  useEffect(() => {
    const textWidth = window.getComputedStyle(textRef.current).width;
    const newWidth = parseInt(textWidth.slice(0, textWidth.length - 2)) + 60;
    setWidth(newWidth === 201 ? 231 : newWidth);
  }, [value]);

  return (
    <div className="relative">
      <div className="absolute right-0 z-10 invisible" ref={textRef} style={{ fontWeight: 600, fontSize: 12 }}>
        {value.value}
      </div>
      <Arrow
        className="absolute"
        direction="down"
        style={{
          right: 15,
          top: 10,
        }}
        color={colors[value.color]['text']}
      />
      <select
        className="px-md py-sm rounded relative cursor-pointer z-20"
        style={{
          WebkitAppearance: 'none',
          width,
          fontWeight: 600,
          fontSize: 12,
          color: colors[value.color]['text'],
          backgroundColor: colors[value.color]['bg'],
        }}
        value={value.value}
        onChange={({ target }) => {
          setValue(options.filter((option) => option.value === target.value)[0]);
          setJobStatus({
            variables: {
              jobID,
              status: target.value,
            },
          });
        }}
      >
        {options.map((option, index) => (
          <option key={index}>{option.value}</option>
        ))}
      </select>
    </div>
  );
};

const JobEntry = ({ job, fillPosition, closePosition, isAdmin, isSubscriber, currSubscriber, type, activeTab }) => {
  const {
    id,
    company,
    title,
    location,
    salaryMax,
    salaryMin,
    status,
    recruiter,
    recruiter_id: recruiterId,
    industry,
    subscriber_id: subscriberId,
    adminApproved,
    subscriber,
    created_at: createdAt,
  } = job;

  const statusOptions = [
    {
      value: 'Awaiting Scheduling',
      color: 'blue',
    },
    {
      value: 'Phone Interview Scheduled',
      color: 'pink',
    },
    {
      value: 'Pending Hire',
      color: 'orange',
    },
  ];

  const [jobStatus, setJobStatus] = useState(`${adminApproved ? 'approved' : 'requested'}`);
  const [disabled, setDisabled] = useState(false);

  const [setApproveStatus, { data: approveStatusResponse }] = useMutation(UPDATE_APPROVE_STATUS);

  useEffect(() => {
    if (jobStatus === 'approved') {
      setDisabled(true);
    }
  }, [jobStatus]);

  const options = {
    requested: {
      bg: '#FFD2D2',
      color: '#D8000C',
      label: 'Approve Job',
    },
    approved: {
      bg: '#6ACA63',
      color: 'white',
      label: 'Job Approved',
    },
  };

  const { apolloClient } = useContext(ApolloContext);

  const approveRequest = async () => {
    setApproveStatus({
      variables: {
        jobID: job.id,
        adminApproved: true,
      },
    });
    setJobStatus('approved');

    // await fetch(`${EXPRESS_SERVER_URL}/matchCandidatesWithNewJob`, {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({
    //     event: {
    //       data: {
    //         new: {
    //           id: job.id,
    //           requiredExperience: job.requiredExperience,
    //           salaryMin: job.salaryMin,
    //           salaryMax: job.salaryMax,
    //           location: job.location,
    //           requiredAttributes: job.requiredAttributes,
    //           closed: false,
    //           allowsRemote: job.allowsRemote,
    //         },
    //       },
    //     },
    //   }),
    // });
  };

  const sendNotificationToCandidates = async () => {
    const response = await apolloClient.query({
      query: gql`query getJobCandidates {
  job_candidate(where: {job_id: {_eq: "${job.id}"}}) {
    candidate_id
    job_id
  }
}`,
      variables: {},
      fetchPolicy: 'network-only',
    });

    for (const jobCandidate of response.data.job_candidate) {
      fetch(`${EXPRESS_SERVER_URL}/sendJobMatchNotification`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          event: {
            data: {
              new: jobCandidate,
            },
          },
        }),
      });
    }
  };

  useEffect(() => {
    if (approveStatusResponse) {
      let email = '';
      if (
        approveStatusResponse.update_job.returning[0] &&
        approveStatusResponse.update_job.returning[0].subscriber &&
        approveStatusResponse.update_job.returning[0].subscriber.email
      ) {
        email = approveStatusResponse.update_job.returning[0].subscriber.email;
      } else {
        email = 'cjtufano@getcherrypicker.com';
      }
      fetch(`${EXPRESS_SERVER_URL}/send-email`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          recipient: email,
          // subject: 'Great news! Your job posting has been approved!', //added in sendGrid
          template_id: EMAIL_TEMPLATES.TO_SUBSCRIBER_WHEN_JOB_POSTING_APPROVED_BY_ADMIN, //verified
          attributes: {
            job_title: title,
          },
        }),
      });

      sendNotificationToCandidates();
    }
  }, [approveStatusResponse]);

  const getElapsedTime = (postDate) => {
    const difference = moment(new Date()).utc() - moment(new Date(postDate)).utc();

    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerDay * 365;

    let timeElapsed = '';
    if (difference < msPerMinute) {
      timeElapsed = Math.round(difference / 1000) + ' seconds ago';
    } else if (difference < msPerHour) {
      timeElapsed = Math.round(difference / msPerMinute) + ' minutes ago';
    } else if (difference < msPerDay) {
      timeElapsed = Math.round(difference / msPerHour) + ' hours ago';
    } else if (difference < msPerMonth) {
      timeElapsed = Math.round(difference / msPerDay) + ' days ago';
    } else if (difference < msPerYear) {
      timeElapsed = Math.round(difference / msPerMonth) + ' months ago';
    } else {
      timeElapsed = (Math.round(difference / msPerMonth) / 10) + ' years ago';
    }
    return timeElapsed;
  };

  const onSubscriberClick = () => {
    if (!isSubscriber) {
      if (subscriberId) {
        navigate(`/subscribers/${subscriber ? subscriber.id : null}`, { state: { subscriber: subscriberId ? subscriber : null, activeTab: activeTab } });
      } else {
        navigate(`/companies/${company && !subscriberId ? company.id : null}`, {
          state: { company_id: company && !subscriberId ? company.id : subscriber ? subscriber.id : null, activeTab: activeTab },
        });
      }
    }
  };

  return (
    <tr>
      <td>
        <div className="lg:text-sm">
          {moment(new Date(createdAt)).format('MM/DD/YYYY')}
          <br />
          {getElapsedTime(createdAt)}
        </div>
      </td>

      <td>
        <img
          alt=""
          src={isSubscriber ? (currSubscriber.logoURL ? currSubscriber.logoURL : UserAvatar) : company && company.logoURL ? company.logoURL : UserAvatar}
          style={{ margin: 0, maxHeight: 40, maxWidth: 80 }}
        />
      </td>

      <td>
        <div className="flex flex-col" style={{ width: '300px' }}>
          <Link to={`/jobs/${job.id}`} state={{ jobID: job.id, type: type }} className="hover:underline py-sm lg:p-0 cursor-pointer lg:text-sm" style={{ fontWeight: 500 }}>
            {title}
          </Link>
          <div className={`lg:text-xs text-darkgray lg:text-left ${!isSubscriber ? 'cursor-pointer' : ''}`} onClick={onSubscriberClick}>
            {!isSubscriber ? (company && !subscriberId ? company.name : subscriber ? subscriber.name : 'N/A') : currSubscriber.name}
          </div>
          {!isSubscriber && job.subscriber_id ? (
            <div>
              <button
                disabled={disabled}
                onClick={() => approveRequest()}
                style={{
                  ...{ cursor: disabled ? 'default' : 'pointer', color: options[jobStatus].color, borderColor: 'rgb(86, 208, 0)' },
                  ...{ backgroundColor: options[jobStatus].bg, border: '1px solid', borderRadius: 5 },
                  ...{ height: 40, marginBottom: 2, paddingLeft: 10, paddingRight: 10, width: 130 },
                }}
                className={`text-xs flex justify-center items-center rounded`}
              >
                {`${options[jobStatus].label}`}
              </button>
            </div>
          ) : (
            ''
          )}
        </div>
      </td>

      {isAdmin && (
        <td>
          <div
            className="flex items-center text-darkgray font-medium cursor-pointer hover:underline"
            style={{ fontSize: 14 }}
            onClick={() => {
              navigate('/dashboard/candidates', { state: { industry } });
            }}
          >
            <img alt="" src={BriefcaseIcon} style={{ height: 20, margin: 0, marginLeft: 2, marginRight: 8 }} />
            {industry}
          </div>
        </td>
      )}

      <td>
        <div className={`text-center text-xs text-darkgray  mr-sm lg:mr-0 ${isAdmin ? '' : 'lg:mb-sm'}`} style={{ fontWeight: 500 }}>
          $ {`${formatSalary({ salary: salaryMin })} - ${formatSalary({ salary: salaryMax })}`}
        </div>
      </td>
      <td>
        {!isAdmin && (
          <div className="flex items-center">
            <br />
            <img alt="" src={LocationPin} style={{ height: 14, margin: 0, marginRight: 10 }} />
            {typeof location !== 'string' && <span className={`text-center text-xs text-darkgray  mr-sm lg:mr-0 ${isAdmin ? '' : 'lg:mb-sm'}`}>{location.city}</span>}
          </div>
        )}
      </td>

      {isAdmin && (
        <td>
          <div className="flex lg:flex-col items-start">
            <div className="text-xs text-darkgray flex items-center" style={{ fontWeight: 500 }}>
              <img alt="" src={LocationPin} style={{ height: 14, margin: 0, marginRight: 10 }} />
              {typeof location !== 'string' && location.city}
            </div>
          </div>
        </td>
      )}

      {isAdmin && (
        <td>
          <div
            onClick={() => navigate(`/recruiters/${recruiterId}`, { state: { recruiter: { id: recruiterId, ...recruiter }, from: '/dashboard/jobs' } })}
            className="font-medium cursor-pointer hover:underline text-darkgray flex items-center"
            style={{ fontSize: 14 }}
          >
            <img alt="" src={RecruiterIcon} style={{ marginBottom: 0, marginRight: 10, height: 16 }} />
            {recruiter.name}
          </div>
        </td>
      )}

      {!isAdmin && (
        <td>
          <div className={`flex justify-center`} style={{}}>
            {job.adminApproved || !isSubscriber ? (
              <StatusSelect options={statusOptions} status={status} jobID={id} />
            ) : (
              <div
                className={`bg-lightred text-red"} flex font-semibold text-xs px-md rounded hover:bg-lightred hover:text-red cursor-pointer`}
                style={{ paddingTop: 5, paddingBottom: 5 }}
              >
                Approval Pending
              </div>
            )}
          </div>
        </td>
      )}

      {!isAdmin && (
        <td className='flex flex-col'>
          {(job.adminApproved || !isSubscriber ? (
            <td>
              <div>
                <button
                  onClick={() => fillPosition(id, job.filled ? false : true)}
                  className={`${job.filled ? 'bg-lightgreen text-green' : 'hover:bg-lightgreen text-green'
                    }  border-green flex items-center justify-center px-md rounded border text-xs font-medium`}
                    style={{ height: 40, width: 160 }}
                >
                  {job.filled ? 'Unfill Position' : 'Fill Position'}
                </button>
              </div>
            </td>
          ) : (
            <div className='h-10'></div>
          ))}
          <div
            onClick={() => closePosition(job.id, job.closed ? false : true)}
            className={`bg-${job.closed ? 'lightred text-red' : 'white text-darkgray'
              } border-2 border-red flex font-semibold text-xs px-md rounded hover:bg-lightred hover:text-red cursor-pointer justify-center`}
            style={{ paddingTop: 5, paddingBottom: 5, width: 160 }}
          >
            {job.closed ? (
              'Closed'
            ) : (
              <>
                <span className="text-md mr-sm">X</span>
                Close Position
              </>
            )}
          </div>
        </td>
      )}
    </tr>
  );
};

export { JobEntry };

//This is almost exactly the same as TitleSort below. Not DRY. Use TableHeader component instead
function CreatedAtSort({ sortState, text }) {
  const [, setSortType] = sortState;
  const [direction, setDirection] = useState('asc');

  function setType() {
    if (direction === 'desc') {
      setDirection('asc');
      setSortType({ type: 'createdAt', direction: 'asc' });
    } else {
      setDirection('desc');
      setSortType({ type: 'createdAt', direction: 'desc' });
    }
  }

  return (
    <div className="flex justify-left">
      <div
        onClick={setType}
        className={`text-sm flex flex-row items-center font-medium cursor-pointer px-sm rounded`}
        style={{ color: 'black', fontWeight: '500', paddingLeft: 0 }}
      >
        {text}
        <img alt="" src={CaretDownGray} style={{ height: 5, margin: 0, transform: `rotate(${direction === 'desc' ? '0' : '180'}deg)`, marginLeft: 10 }} />
      </div>
    </div>
  );
}

function TitleSort({ sortState, text }) {
  const [, setSortType] = sortState;
  const [direction, setDirection] = useState('asc');

  function setType() {
    if (direction === 'desc') {
      setDirection('asc');
      setSortType({ type: 'title', direction: 'asc' });
    } else {
      setDirection('desc');
      setSortType({ type: 'title', direction: 'desc' });
    }
  }

  return (
    <div className="flex justify-left">
      <div
        onClick={setType}
        className={`text-sm flex flex-row items-center font-medium cursor-pointer px-sm rounded`}
        style={{ color: 'black', fontWeight: '500', paddingLeft: 0 }}
      >
        {text}
        <img alt="" src={CaretDownGray} style={{ height: 5, margin: 0, transform: `rotate(${direction === 'desc' ? '0' : '180'}deg)`, marginLeft: 10 }} />
      </div>
    </div>
  );
}

function IndustrySort({ sortState, text }) {
  const [, setSortType] = sortState;
  const [direction, setDirection] = useState('asc');

  function setType() {
    if (direction === 'desc') {
      setDirection('asc');
      setSortType({ type: 'industry', direction: 'asc' });
    } else {
      setDirection('desc');
      setSortType({ type: 'industry', direction: 'desc' });
    }
  }

  return (
    <div className="flex justify-start">
      <div
        onClick={setType}
        className={`text-sm flex flex-row items-center font-medium cursor-pointer px-sm rounded`}
        style={{ color: 'black', fontWeight: '500', paddingLeft: 10 }}
      >
        {text}
        <img alt="" src={CaretDownGray} style={{ height: 5, margin: 0, transform: `rotate(${direction === 'desc' ? '0' : '180'}deg)`, marginLeft: 10 }} />
      </div>
    </div>
  );
}

function SalarySort({ sortState, text }) {
  const [, setSortType] = sortState;
  const [direction, setDirection] = useState('asc');

  function setType() {
    if (direction === 'desc') {
      setDirection('asc');
      setSortType({ type: 'salaryMin', direction: 'asc' });
    } else {
      setDirection('desc');
      setSortType({ type: 'salaryMin', direction: 'desc' });
    }
  }

  return (
    <div className="flex justify-center">
      <div
        onClick={setType}
        className={`text-sm flex flex-row items-center font-medium cursor-pointer px-sm rounded`}
        style={{ color: 'black', fontWeight: '500', paddingLeft: 0 }}
      >
        {text}
        <img alt="" src={CaretDownGray} style={{ height: 5, margin: 0, transform: `rotate(${direction === 'desc' ? '0' : '180'}deg)`, marginLeft: 10 }} />
      </div>
    </div>
  );
}

function StatusSort({ sortState, text }) {
  const [, setSortType] = sortState;
  const [direction, setDirection] = useState('asc');

  function setType() {
    if (direction === 'desc') {
      setDirection('asc');
      setSortType({ type: 'status', direction: 'asc' });
    } else {
      setDirection('desc');
      setSortType({ type: 'status', direction: 'desc' });
    }
  }

  return (
    <div className="flex justify-center">
      <div
        onClick={setType}
        className={`text-sm flex flex-row items-center font-medium cursor-pointer px-sm rounded`}
        style={{ color: 'black', fontWeight: '500', paddingLeft: 0 }}
      >
        {text}
        <img alt="" src={CaretDownGray} style={{ height: 5, margin: 0, transform: `rotate(${direction === 'desc' ? '0' : '180'}deg)`, marginLeft: 10 }} />
      </div>
    </div>
  );
}

function RecruiterSort({ sortState, text }) {
  const [, setSortType] = sortState;
  const [direction, setDirection] = useState('asc');

  function setType() {
    if (direction === 'desc') {
      setDirection('asc');
      setSortType({ type: 'recruiter', direction: 'asc' });
    } else {
      setDirection('desc');
      setSortType({ type: 'recruiter', direction: 'desc' });
    }
  }

  return (
    <div className="flex justify-center">
      <div
        onClick={setType}
        className={`text-sm flex flex-row items-center font-medium cursor-pointer px-sm rounded`}
        style={{ color: 'black', fontWeight: '500', paddingLeft: 0 }}
      >
        {text}
        <img alt="" src={CaretDownGray} style={{ height: 5, margin: 0, transform: `rotate(${direction === 'desc' ? '0' : '180'}deg)`, marginLeft: 10 }} />
      </div>
    </div>
  );
}

export default function JobList({ activeTab, filterState, fillPosition, closePosition, loading, jobs, isAdmin, sortState, isSubscriber, path, type }) {
  const [importModal, setImportModal] = useState({ data: null, open: false });

  const [currSubscriber, setCurrSubscriber] = useState('');

  const { data: subscriberResponse } = useQuery(GET_SUBSCRIBER, {
    variables: { admin_id: firebase.auth().currentUser && firebase.auth().currentUser.uid },
  });

  useEffect(() => {
    if (subscriberResponse) {
      setCurrSubscriber(subscriberResponse.company[0]);
    }
  }, [subscriberResponse]);

  return (
    <React.Fragment>
      <div className="job-list-js flex flex-col items-center justify-between w-full" style={{ marginTop: 30, marginBottom: 12 }}>
        <TabTitle marginBottom={activeTab === '/jobs/search' ? 10 : 30}>
          {!activeTab ? 'Jobs' : activeTab === '/jobs' || activeTab === '/jobs/open-jobs' ? 'Open Jobs' : activeTab === '/jobs/completed-jobs' ? 'Completed Jobs' : 'All Jobs'}
          <div className="import_csv flex justify-between lg:mt-0 mt-sm">
            {!isSubscriber && <FilteredJobs jobs={jobs} />}
            {!isAdmin && !isSubscriber && <SampleCsvJobs />}
            {!isAdmin && !isSubscriber && (
              <a
                href="#"
                className={`bg-red text-white font-medium rounded flex items-center justify-center`}
                style={{ fontSize: 14, height: 40, width: 135, marginRight: 10 }}
                onClick={() => setImportModal({ data: null, open: true })}
              >
                <span style={{ fontSize: 22, marginRight: 8 }}>+</span>
                Import Jobs
              </a>
            )}
            {!isAdmin && (
              <Link
                className="bg-red text-white font-medium rounded flex items-center justify-center"
                style={{ fontSize: 14, height: 40, width: isSubscriber ? 200 : 112 }}
                to="/jobs/new-job"
              >
                <span style={{ fontSize: 22, marginRight: 8 }}>+</span> {isSubscriber ? 'Post a new job' : 'Add Job'}
              </Link>
            )}
          </div>
        </TabTitle>
        <JobsFilter filterState={filterState} showRecruiterFilter={isAdmin} />
      </div>

      <div className={`job-list-js flex-1 bg-white rounded shadow candidates-container candidate-blank-dash`} style={{ overflowX: 'auto', height: '700px' }}>
        <table style={{ width: '98%', marginLeft: '1%', marginRight: '1%' }}>
          <thead>
            <tr style={{ position: 'sticky', top: 0, zIndex: 21, background: 'white' }}>
              <th>
                <div className="text-sm justify-left" style={{ fontWeight: '500' }}>
                  <CreatedAtSort sortState={sortState} text="Created At" />
                </div>
              </th>
              <th></th>
              <th>
                <div className="text-sm justify-center" style={{ fontWeight: '500' }}>
                  <TitleSort sortState={sortState} text="Title" />

                  <div className={`lg:text-xs text-darkgray lg:text-left`}>Company Name</div>
                </div>
              </th>
              {isAdmin && (
                <th>
                  <div className="text-sm justify-center" style={{ fontWeight: '500' }}>
                    <IndustrySort sortState={sortState} text="Industry" />
                  </div>
                </th>
              )}
              <th>
                <div className="text-sm justify-center" style={{ fontWeight: '500' }}>
                  <SalarySort sortState={sortState} text="Salary" />
                </div>
              </th>
              <th>
                <div className="text-sm text-center" style={{ fontWeight: '500', textAlign: 'start' }}>
                  {!isAdmin && `Location`}
                </div>
              </th>
              {isAdmin && (
                <th>
                  <div className="text-sm justify-center" style={{ fontWeight: '500', textAlign: 'start' }}>
                    Location
                  </div>
                </th>
              )}
              {isAdmin && (
                <th>
                  <div className="text-sm justify-center" style={{ fontWeight: '500' }}>
                    <RecruiterSort sortState={sortState} text="Recruiter" />
                  </div>
                </th>
              )}
              {!isAdmin && (
                <th>
                  <div className="text-sm justify-center" style={{ fontWeight: '500', textAlign: 'center' }}>
                    <StatusSort sortState={sortState} text="Status" />
                  </div>
                </th>
              )}
              {!isAdmin && (
                <th colSpan="2">
                  <div className="text-sm justify-center" style={{ fontWeight: '500', textAlign: 'center' }}>
                    Actions
                  </div>
                </th>
              )}
            </tr>
          </thead>

          <tbody>
            {(jobs || []).map((job) => {
              return (
                <JobEntry
                  key={job.id}
                  fillPosition={fillPosition}
                  closePosition={closePosition}
                  job={job}
                  isAdmin={isAdmin}
                  isSubscriber={isSubscriber}
                  currSubscriber={currSubscriber}
                  type={type}
                  activeTab={activeTab}
                />
              );
            })}
          </tbody>
        </table>

        {loading && (
          <div className={`flex justify-center items-center flex-1 candidate-blank`} style={{ width: '100%' }}>
            <div className="bg-lightgray text-darkgray py-sm px-md" style={{ borderRadius: 43 }}>
              Loading
            </div>
          </div>
        )}

        {!loading && jobs && jobs.length === 0 && (
          <div className={`flex justify-center items-center flex-1 candidate-blank`} style={{ width: '100%' }}>
            <div className="bg-lightgray text-darkgray py-sm px-md" style={{ borderRadius: 43 }}>
              No Jobs Found
            </div>
          </div>
        )}
      </div>

      <br />

      <JobImportModal state={[importModal, setImportModal]} />
    </React.Fragment >
  );
}
