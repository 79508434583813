import { useMutation, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { GlobalFilterContext } from '../../components/layout/layout';
import TabNav from '../../components/layout/TabNav';
import UserRoleContext from '../../context/UserRole';
import '../dashboard/dashboard.css';
import JobList from './job-list';
import JobSearch from './job-search';
import { DEFAULT_PER_PAGE } from '../../utils/constants';
import CherryPagination from '../../components/common/Pagination/Pagination';
import moment from 'moment';

const ALL_RECRUITER_JOBS = gql`
  query getJobs($recruiterID: String) {
    job(where: { recruiter_id: { _eq: $recruiterID }, subscriber_id: { _is_null: true } }, order_by: { created_at: desc }) {
      id
      industry
      location
      notes
      company {
        id
        name
        logoURL
      }
      subscriber {
        id
        name
        logoURL
      }
      optionalAttributes
      requiredAttributes
      requiredExperience
      salaryMax
      salaryMin
      status
      title
      hiddenFromCandidates
      filled
      closed
      description
      compensation
      compensation_list
      subscriber_id
      adminApproved
      created_at
      allowsRemote
    }
  }
`;

const ALL_SUBSCRIBER_JOBS = gql`
  query getJobs($subscriber_id: uuid) {
    job(where: { subscriber_id: { _eq: $subscriber_id } }, order_by: { created_at: desc }) {
      id
      industry
      location
      notes
      company {
        id
        name
        logoURL
      }
      subscriber {
        id
        name
        logoURL
      }
      optionalAttributes
      requiredAttributes
      requiredExperience
      salaryMax
      salaryMin
      status
      title
      hiddenFromCandidates
      filled
      closed
      description
      compensation
      compensation_list
      subscriber_id
      adminApproved
      created_at
      allowsRemote
    }
  }
`;

const ALL_JOBS = gql`
  query getJobs {
    job(where: { adminApproved: { _eq: false }, subscriber_id: { _is_null: false } }, order_by: { created_at: desc }) {
      id
      industry
      location
      notes
      company {
        id
        name
        logoURL
      }
      subscriber {
        id
        name
        logoURL
      }
      optionalAttributes
      requiredAttributes
      requiredExperience
      salaryMax
      salaryMin
      status
      title
      hiddenFromCandidates
      filled
      description
      compensation
      compensation_list
      recruiter_id
      recruiter {
        id
        availability
        email
        industries
        name
        phone
        profilePhoto
        title
        company {
          id
          name
        }
        position
        candidates_aggregate {
          aggregate {
            count
          }
        }
      }
      subscriber_id
      adminApproved
      created_at
      allowsRemote
    }
  }
`;

const ALL_FILTERED_JOBS = gql`
query getJobs($whereCondition: job_bool_exp, $orderBy: [job_order_by!], $g_lat: float8, $g_long: float8, $g_radius: Int, $search_term: String, $perPage: Int, $offset: Int) {
    filter_jobs_aggregate(
      where: $whereCondition,
      args: {g_lat: $g_lat, g_long: $g_long, g_radius: $g_radius, search_term: $search_term},
      ) {
      aggregate {
        count
      }
    }

    filter_jobs(
      where: $whereCondition,
      args: {g_lat: $g_lat, g_long: $g_long, g_radius: $g_radius, search_term: $search_term},
      order_by: $orderBy, 
      limit: $perPage,
      offset: $offset
      ) {
      id
      industry
      location
      notes
      company {
        id
        name
        logoURL
      }
      subscriber {
        id
        name
        logoURL
      }
      optionalAttributes
      requiredAttributes
      requiredExperience
      salaryMax
      salaryMin
      status
      title
      hiddenFromCandidates
      filled
      closed
      description
      compensation
      compensation_list
      recruiter_id
      recruiter {
        id
        availability
        email
        industries
        name
        phone
        profilePhoto
        title
        company {
          id
          name
        }
        position
        candidates_aggregate {
          aggregate {
            count
          }
        }
      }
      subscriber_id
      adminApproved
      created_at
      allowsRemote
    }
  }
`;

const GET_COMPANY = gql`
  query getCompany($admin_id: String) {
    company(where: { adminID: { _eq: $admin_id } }) {
      id
      email
      name
      logoURL
      adminName
      totalCredit
      remainingCredit
      subscription_type
    }
  }
`;

const FILL_JOB = gql`
  mutation fillJob($id: uuid, $value: Boolean) {
    update_job(where: { id: { _eq: $id } }, _set: { filled: $value }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

const CLOSE_JOB = gql`
  mutation fillJob($id: uuid, $value: Boolean) {
    update_job(where: { id: { _eq: $id } }, _set: { closed: $value }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export default function JobQueue({ path, location, admin }) {
  const tabIndexes = {
    '/jobs/open-jobs': 0,
    '/jobs/all-jobs': 1,
    '/jobs/search': 2,
  };
  const [userRole] = useContext(UserRoleContext);
  const { globalFilter, setGlobalFilter } = useContext(GlobalFilterContext);
  const isAdmin = userRole === 'admin';
  const isSubscriber = userRole === 'company';
  const [jobs, setJobs] = useState([]);
  const [allFilteredJobs, setAllFilteredJobs] = useState([]);
  const [activeTab, setActiveTab] = useState('');
  const [allJobs, setAllJobs] = useState([]);
  const [filter, setFilter] = useState({});
  const [direction, setDirection] = useState('right');
  const [setJobFilled] = useMutation(FILL_JOB);
  const [setJobClosed] = useMutation(CLOSE_JOB);
  const [sortType, setSortType] = useState({ type: 'createdAt', direction: 'desc' });

  // Pagination
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [total, setTotal] = useState(0);

  // set currCompany
  const [currCompany, setCurrCompany] = useState('');

  function getOrderBy() {
    let _orderBy;

    if (sortType.type === 'createdAt') {
      _orderBy = {
        created_at: sortType.direction,
      };
    } else if (sortType.type === 'title') {
      _orderBy = {
        title: sortType.direction,
      };
    } else if (sortType.type === 'industry') {
      _orderBy = {
        industry: sortType.direction,
      };
    } else if (sortType.type === 'salaryMin') {
      _orderBy = {
        salaryMin: sortType.direction,
      };
    } else if (sortType.type === 'recruiter') {
      _orderBy = {
        recruiter: {
          name: sortType.direction,
        },
      };
    }

    return _orderBy;
  }

  function filterJobsQueryVar() {
    let variables;

    if (isAdmin) {
      variables = {
        whereCondition: { adminApproved: { _eq: false }, subscriber_id: { _is_null: false } },
      };
    } else if (isSubscriber) {
      if (!currCompany) {
        return;
      }

      variables = {
        whereCondition: { subscriber_id: { _eq: currCompany.id }, filled: { _eq: false }, closed: { _eq: false } },
        orderBy,
        perPage: perPage,
        offset: page * perPage,
      };
    } else {
      variables = {
        whereCondition: {
          recruiter_id: { _eq: firebase.auth().currentUser && firebase.auth().currentUser.uid },
          // subscriber_id: { _is_null: true },
        },
        orderBy,
        perPage: perPage,
        offset: page * perPage,
      };
    }

    if (filter.date) {
      if (filter.date.startDate && filter.date.endDate) {
        variables.whereCondition.created_at = {
          _gte: moment(new Date(filter.date.startDate)).startOf('day').toISOString(),
          _lte: moment(new Date(filter.date.endDate)).endOf('day').toISOString(),
        };
      } else if (filter.date.startDate) {
        variables.whereCondition.created_at = {
          _gte: moment(new Date(filter.date.startDate)).startOf('day').toISOString(),
        };
      } else if (filter.date.endDate) {
        variables.whereCondition.created_at = {
          _lte: moment(new Date(filter.date.endDate)).endOf('day').toISOString(),
        };
      }
    }

    if (filter.recruiter && filter.recruiter.length) {
      variables.whereCondition.recruiter_id = {
        _in: filter.recruiter.map((a) => a.id),
      };
    }

    if (filter.salary) {
      if (filter.salary.min) {
        variables.whereCondition.salaryMin = {
          _gte: parseInt(filter.salary.min),
        };
      }

      if (filter.salary.max) {
        variables.whereCondition.salaryMax = {
          _lte: parseInt(filter.salary.max),
        };
      }
    }

    if (filter.attributes && filter.attributes.length) {
      variables.whereCondition._or = [{ optionalAttributes: { _has_keys_any: filter.attributes } }, { requiredAttributes: { _has_keys_any: filter.attributes } }];
    }

    return variables
  }

  let orderBy = getOrderBy();
  const { data: companyResponse } = useQuery(GET_COMPANY, {
    variables: { admin_id: firebase.auth().currentUser && firebase.auth().currentUser.uid },
  });

  function handlePaginationChange(event) {
    setPage(event.page);
    setPerPage(event.perPage);
  }

  const { data, error, loading } = useQuery(
    ALL_FILTERED_JOBS,
    {
      variables: {
        whereCondition: { ...filterJobsQueryVar()?.whereCondition },
        g_lat: filter?.location?.lat ? filter?.location?.lat : null,
        g_long: filter?.location?.lng ? filter.location?.lng : null,
        g_radius: filter?.location?.radius ? filter.location.radius : process.env.GATSBY_DEFAULT_SEARCH_RADIUS || 50,
        search_term: filter?.title ? filter.title : null,
        orderBy,
        perPage: perPage,
        offset: page * perPage,
      },
      fetchPolicy: 'network-only'
    });
  /*
  const { data, error, loading } = useQuery(
    isAdmin ? ALL_JOBS : isSubscriber ? ALL_SUBSCRIBER_JOBS : ALL_RECRUITER_JOBS,
    isAdmin
      ? { fetchPolicy: 'network-only' }
      : isSubscriber
        ? { variables: { subscriber_id: currCompany.id }, fetchPolicy: 'network-only' }
        : { variables: { recruiterID: firebase.auth().currentUser && firebase.auth().currentUser.uid }, fetchPolicy: 'network-only' },
  );
  */

  useEffect(() => {
    if (companyResponse) {
      setCurrCompany(companyResponse.company[0]);
    }
  }, [companyResponse]);

  function fillPosition(jobID, value) {
    // setJobs
    setAllFilteredJobs(
      allFilteredJobs.map((job) => {
        if (job.id === jobID) {
          return { ...job, filled: value };
        } else {
          return job;
        }
      }),
    );

    setJobFilled({
      variables: {
        id: jobID,
        value,
      },
      refetchQueries: ['getJobs'],
    });
  }

  function closePosition(jobID, value) {
    setAllFilteredJobs(
      allFilteredJobs.map((job) => {
        if (job.id === jobID) {
          return { ...job, closed: value };
        } else {
          return job;
        }
      }),
    );

    setJobClosed({
      variables: {
        id: jobID,
        value,
      },
      refetchQueries: ['getJobs'],
    });
  }

  function isEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
  /*
  function calcDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // km
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    lat1 = toRad(lat1);
    lat2 = toRad(lat2);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d * 0.621371;
  }
  function toRad(Value) {
    return (Value * Math.PI) / 180;
  }

  function filterJobs() {
    if (isEmpty(filter)) {
      setJobs(sortJobs(allJobs));
    } else {
      const filteredJobs = allJobs.filter((job) => {
        for (const name in filter) {
          const value = filter[name];

          switch (name) {
            case 'search':
              for (const index in value) {
                let found = false;
                if (job.company && job.company.name.toLowerCase().includes(value[index].toLowerCase())) {
                  found = true;
                }
                if (job.title.toLowerCase().includes(value[index].toLowerCase())) {
                  found = true;
                }
                if (!found) {
                  return false;
                }
              }
              break;
            case 'experience':
              const { requiredExperience } = job;
              if (parseInt(value.min) < parseInt(requiredExperience[0]) || parseInt(value.max) > parseInt(requiredExperience[1])) {
                return false;
              }
              break;
            case 'salary':
              const { salaryMax, salaryMin } = job;

              if (parseInt(value.min) > salaryMax || parseInt(value.max) < salaryMin) {
                return false;
              }

              break;
            case 'attributes':
              let found = true;
              const attributes = value;
              for (const index in attributes) {
                if (!job.requiredAttributes.includes(attributes[index])) {
                  found = false;
                }
              }
              if (!found) {
                return false;
              }
              break;
            case 'location':
              // const locations = value;
              // const filtered_locations = locations.filter(location => (location.lat === job.location.lat && location.lng === job.location.lng));
              // if (filtered_locations.length === 0) {
              //   return false;
              // }
              let { city, lat, lng } = value;

              if (city && city.lat) {
                lat = city.lat;
              }

              if (city && city.lng) {
                lng = city.lng;
              }

              const radius = value.radius ? value.radius : process.env.GATSBY_DEFAULT_SEARCH_RADIUS || 50;
              const distance = calcDistance(lat, lng, job.location.lat, job.location.lng);
              if (distance > radius) {
                return false;
              }
              break;
            default:
              break;
          }
        }
        return true;
      });
      setJobs(sortJobs(filteredJobs));
    }
  }
  */

  useEffect(() => {
    if (data) {
      // const sortedJobs = sortJobs(data.job);
      // setAllJobs(sortedJobs);
      // setJobs(sortedJobs);
      setAllFilteredJobs(data?.filter_jobs);
      setTotal(data?.filter_jobs_aggregate?.aggregate?.count)
    }
  }, [data, error, filter, location, path, currCompany, page, perPage]);

  useEffect(() => {
    if (path === '/jobs') {
      navigate('/jobs/open-jobs');
    }
  }, []);

  useEffect(() => {
    // filterJobs();
    if (path === '/jobs/open-jobs' && JSON.stringify(globalFilter['open-jobs-filter']) !== JSON.stringify(filter)) {
      setGlobalFilter({ ...globalFilter, 'open-jobs-filter': filter });
    } else if (path === '/jobs/all-jobs' && JSON.stringify(globalFilter['all-jobs-filter']) !== JSON.stringify(filter)) {
      setGlobalFilter({ ...globalFilter, 'all-jobs-filter': filter });
    } else if (location.pathname === '/dashboard/job-queue' && JSON.stringify(globalFilter['admin-job-queue-filter']) !== JSON.stringify(filter)) {
      setGlobalFilter({ ...globalFilter, 'admin-job-queue-filter': filter });
    }
    setPage(0);
  }, [filter]);

  useEffect(() => {
    if (location && location.pathname.split('/').length > 2) {
      setActiveTab(location.pathname);

      if (location.state) {
        if (location.state.companyName) {
          setFilter({
            search: [location.state.companyName],
          });
        } else if (location.state.previousTab) {
          setDirection(tabIndexes[location.state.previousTab] > tabIndexes[location.pathname] ? 'left' : 'right');
        }
      }
    } else if (!admin) {
      navigate('/jobs/open-jobs');
    }
  }, [location]);

  useEffect(() => {
    if (path === '/jobs/open-jobs' && globalFilter['open-jobs-filter']) {
      setFilter(globalFilter['open-jobs-filter']);
    } else if (path === '/jobs/all-jobs' && globalFilter['all-jobs-filter']) {
      setFilter(globalFilter['all-jobs-filter']);
    }
  }, [path]);

  useEffect(() => {
    if (location && location.pathname === '/dashboard/job-queue' && JSON.stringify(globalFilter['admin-job-queue-filter'])) {
      setFilter(globalFilter['admin-job-queue-filter']);
    }
  }, []);

  // useEffect(() => {
  //   filterJobs();
  // }, [allJobs]);
  function sortJobs(jobsList) {
    if (jobsList) {
      if (sortType.type === 'alphabetical') {
        return [...jobsList].sort((a, b) => {
          const textA = isSubscriber || isAdmin ? a.title.toUpperCase() : a.company.name.toUpperCase();
          const textB = isSubscriber || isAdmin ? b.title.toUpperCase() : b.company.name.toUpperCase();
          return textA < textB ? (sortType.direction === 'desc' ? -1 : 1) : textA > textB ? (sortType.direction === 'desc' ? 1 : -1) : 0;
        });
      } else {
        return jobsList;
      }
    } else {
      return jobsList;
    }
  }

  // useEffect(() => {
  //   if (sortType && jobs) {
  //     setJobs(sortJobs(jobs));
  //   }
  // }, [sortType]);

  const renderJobTab = () => {
    switch (activeTab) {
      case '/jobs/open-jobs':
        return (
          <JobList
            activeTab={activeTab}
            filterState={[filter, setFilter]}
            sortState={[sortType, setSortType]}
            loading={loading}
            fillPosition={fillPosition}
            closePosition={closePosition}
            // jobs={jobs.filter((job) => !job.filled && !job.closed)}
            jobs={allFilteredJobs}
          />
        );
      case '/jobs/all-jobs':
        return (
          <JobList
            activeTab={activeTab}
            filterState={[filter, setFilter]}
            sortState={[sortType, setSortType]}
            fillPosition={fillPosition}
            closePosition={closePosition}
            loading={loading}
            // jobs={jobs}
            jobs={allFilteredJobs}
          />
        );
      // hidden menu item
      case '/jobs/search':
        return <JobSearch nav={location} />;
      default:
        break;
    }
  };

  return (
    activeTab && (
      <div className="job-queue-js flex flex-col container h-full">
        {!admin && <TabNav route={activeTab} routeIndex={activeTab === '/jobs/open-jobs' ? 0 : activeTab === '/jobs/all-jobs' ? 1 : 2} type="jobs" />}
        {!admin ? (
          <ReactCSSTransitionGroup
            className={`dash-slider relative`}
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}
            transitionName={{
              enter: `enter-${direction}`,
              enterActive: `enter-${direction}-active`,
              leave: `leave-${direction}`,
              leaveActive: `leave-${direction}-active`,
              appear: `appear-${direction}`,
              appearActive: `appear-${direction}-active`,
            }}
          >
            <div key={activeTab} className="dash-tab">
              <div className="absolute flex flex-col w-full" style={{ minHeight: 'calc(100vh - 156px)' }}>
                {renderJobTab()}
              </div>
            </div>
          </ReactCSSTransitionGroup>
        ) : !isSubscriber ? (
          <JobList
            activeTab={activeTab}
            isAdmin={true}
            sortState={[sortType, setSortType]}
            filterState={[filter, setFilter]}
            closePosition={closePosition}
            fillPosition={fillPosition}
            loading={loading}
            // jobs={jobs}
            jobs={allFilteredJobs}
            type={'job-queue'}
          />
        ) : (
          <JobList
            activeTab={activeTab}
            filterState={[filter, setFilter]}
            sortState={[sortType, setSortType]}
            loading={loading}
            fillPosition={fillPosition}
            closePosition={closePosition}
            jobs={allFilteredJobs}
            // jobs={jobs.filter((job) => !job.filled && !job.closed)}
            isSubscriber={true}
          />
        )}
        <div className="job-pagination jobs-js-ReactPaginate-container">
          <CherryPagination page={page} perPage={perPage} total={total} onChange={handlePaginationChange} />
        </div>
      </div>
    )
  );
}
