import React from 'react'
import CheckIcon from "../../../images/check-solid.svg";
import CloseIcon from "../../../images/times-solid.svg";
import EyeIcon from "../../../images/eye-solid.svg";

export default function ProfileChangeUI({ approveProfileChangeRequest, declineProfileChangeRequest, type }) {
  return (
    <div className='flex'>
      <img
        title='Preview'
        src={EyeIcon}
        style={{ width: 17, height: 17, marginBottom: 0, top: -20, left: 32 }}
      />
      <img
        className='ml-md'
        onClick={() => approveProfileChangeRequest(type)}
        src={CheckIcon}
        style={{ cursor: "pointer", position: "relative", top: 1, width: 15, height: 15, marginBottom: 0, marginRight: 10, }}
      />
      <img
        onClick={() => declineProfileChangeRequest(type)}
        src={CloseIcon}
        style={{ cursor: "pointer", position: "relative", top: 1, width: 15, height: 15, marginBottom: 0, marginRight: 10, }}
      />
    </div>
  )
}
