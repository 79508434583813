import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import React, { useContext, useEffect, useState } from 'react';
import SelectDropdown from './SelectDropdown';
import { UserRoleContext } from '../../context';
import firebase from 'firebase/app';

const ALL_AGENCY_RECRUITERS = gql`
  query getAllAgencyRecruiters(
    $adminID: String
  ) {
    recruiter(where: {company: {adminID: {_eq: $adminID}}}) {
      id
      name
      profilePhoto
    }
  }
`;

export default function AgencyRecruiterSelect({ update, value, error }) {
  const [selectedRecruiter, setSelectedRecruiter] = useState(value ? value : { name: '' });
  const adminID = firebase?.auth().currentUser ? firebase.auth().currentUser.uid : 'not-logged-in'

  const { data: recruiters } = useQuery(ALL_AGENCY_RECRUITERS, {
    variables: {
      adminID
    }
  });
  useEffect(() => {
    update(selectedRecruiter, 'recruiter');
  }, [selectedRecruiter]);

  return (
    <SelectDropdown
      value={selectedRecruiter.name}
      error={error}
      update={(val) => val && setSelectedRecruiter(val)}
      placeholder="Select Recruiter"
      list={recruiters && recruiters.recruiter}
    />
  );
}
