import React, { useEffect, useRef, useState } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import closeIcon from '../../images/close-gray.svg';
import firebase from 'firebase/app';
import { EXPRESS_SERVER_URL } from '../../config';
import { EMAIL_TEMPLATES } from '../../utils/constants';
import { formatSalary } from '../../utils/formatter';
import { toast, ToastContainer } from 'material-react-toastify';

const UPDATE_COMPANY_REQUEST = gql`
  mutation updateCompanyRequest($candidate_id: String, $company_id: uuid, $status: String, $hired_date: date, $start_date: timestamptz, $offerLetterUrl: String , $hired_position_title: String, $hired_salary: bigint ) {
    update_company_request(
      where: { candidate_id: { _eq: $candidate_id }, company_id: { _eq: $company_id } }
      _set: { status: $status, hired_date: $hired_date, start_date: $start_date, offerLetterUrl: $offerLetterUrl, hired_position_title: $hired_position_title, hired_salary: $hired_salary }
    ) {
      affected_rows
      returning {
        candidate_id
        company_id
        status
        credit_amount
        hired_date
        start_date
        offerLetterUrl
        hired_position_title
      }
    }
  }
`;

const SETTINGS = gql`
  query getSettings {
    settings {
      placement_fee_percentage
      guarantee_period
      recruiter_share
      cherrypicker_share
    }
  }
`;

const GET_COMPANY_REQUEST = gql`
  query getCompanyRequest($candidate_id: String, $company_id: uuid) {
    company_request(
      where: { candidate_id: { _eq: $candidate_id }, company_id: { _eq: $company_id } }
    ) {
        candidate {
            name
            recruiter {
              email
              name
              company {
                email
                is_agency
                name
              }
            }
          }
          company {
            name
            is_agency
            email
          }
    }
  }
`;
/**
 * Validation popup for filter search
 * @param {Object} props Modal props
 * @param {Boolean} props.open View Modal
 * @param {String} props.validationError Validation Message
 * @param {Function} props.onChange On Change
 * 
 * @returns 
 */
const ConfirmationModal = (props) => {
    const [fadeDelay, setFadeDelay] = useState(false);
    const [date, setDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [salaryHiredOn, setSalaryHiredOn] = useState(0);
    const [offerLetter, setOfferLetter] = useState('');
    const [positionTitle, setPositionTitle] = useState('');
    const [IsFormSubmitted, setIsFormSubmitted] = useState(false);
    const [requiredFields,] = useState({
        offerLetter: 'Offer Letter required',
        salaryHiredOn: 'Salary Hired field required',
        positionTitle: 'Position Title required',
    })

    const modalRef = useRef();

    useEffect(() => {
        if (props.open) {
            setFadeDelay(true);
        } else {
            setTimeout(() => {
                setFadeDelay(false);
            }, 300);
        }
    }, [props]);

    const handleChanges = (value) => {
        if (props.onChange) {
            props.onChange(value);
        }
    }

    const handleConfirmClick = (data) => {
        props?.handleHiredStatus(data);
        handleChanges(false)
    }

    const handleOfferLetterChange = async (e) => {
        const newOfferLetter = await uploadFile(e.target.files[0], 'offerLetter');
        setOfferLetter(newOfferLetter)
    }
    const uploadFile = async (file, path) => {
        const storageRef = firebase
            .storage()
            .ref()
            .child(`${path}/${file.name}`);
        const fileURL = await storageRef.put(file).then((snapshot) => snapshot.ref.getDownloadURL());
        return fileURL;
    };
    const handleOnconfirmClick = () => {
        if (!offerLetter || !salaryHiredOn || !positionTitle) {
            setIsFormSubmitted(true);
            return;
        }
        handleConfirmClick({ date, startDate, salaryHiredOn, offerLetter, positionTitle, offerLetter })
    }
    return (
        <div
            className={`fixed inset-0 items-center justify-center`}
            onClick={() => handleChanges(false)}
            style={{
                transition: 'opacity 0.3s ease-in-out',
                opacity: props.open ? 1 : 0,
                zIndex: 3000000,
                display: fadeDelay ? 'flex' : 'none',
                backgroundColor: 'rgba(34, 46, 66, 0.9)',
            }}>
            <div ref={modalRef} onClick={(e) => e.stopPropagation()} className="bg-white px-xl py-md rounded shadow flex flex-col" style={{ minHeight: 140, minWidth: '35%', backgroundColor: '#f1f4f8' }}>
                <img src={closeIcon} onClick={() => handleChanges(false)} style={{
                    width: 14,
                    height: 14,
                    marginBottom: 15,
                    cursor: 'pointer',
                    marginLeft: '100%',
                }} />
                <div className='font-semibold py-3 text-l'>Please enter the following information to initiate the hiring process.</div>
                <p style={{ minWidth: '50%' }}>
                    {/* {props.children} */}
                    <div className='flex flex-col gap-1 w-full mb-3'>
                        <span className='font-normal'>Transaction Date</span>
                        <DatePicker
                            className="text-base w-full shadow rounded relative p-sm text-xs placeholder-primary font-normal"
                            placeholderText={'Hired Date'}
                            selected={date}
                            disabled={true}
                            onChange={(date) => {
                                setDate(date);
                            }}
                            style={{ backgroundColor: 'rgb(184, 189, 194)' }}
                            maxDate={moment(new Date()).toDate()}
                        />
                    </div>
                    <div className='flex flex-col gap-1 w-full mb-3'>
                        <span className='font-normal'>Start Date</span>
                        <DatePicker
                            className="text-base bg-white w-full shadow rounded relative p-sm text-xs placeholder-primary font-normal"
                            placeholderText={'Start Date'}
                            selected={startDate}
                            onChange={(date) => {
                                setStartDate(date);
                            }}
                            // maxDate={moment(new Date()).toDate()}
                            minDate={date}
                        />
                    </div>
                    <div className='flex flex-col gap-1 w-full mb-3'>
                        <span className='font-normal'>Salary Hired</span>
                        <input
                            type='number'
                            className="text-base bg-white w-full shadow rounded relative p-sm text-xs placeholder-primary font-normal"
                            placeholder={'Salary Hired'}
                            onChange={(e) => { setSalaryHiredOn(e.target.value) }}
                        // value={salaryHiredOn}
                        />
                        {(!salaryHiredOn && IsFormSubmitted) ? <p className='text-red'>{requiredFields.salaryHiredOn}</p> : null}
                    </div>
                    <div className='flex flex-col gap-1 w-full mb-3'>
                        <span className='font-normal'>Position Title</span>
                        <input
                            type='text'
                            className="text-base bg-white w-full shadow rounded relative p-sm text-xs placeholder-primary font-normal"
                            placeholder={'Position Title'}
                            onChange={(e) => { setPositionTitle(e.target.value) }}
                            value={positionTitle}
                        />
                        {(!positionTitle && IsFormSubmitted) ? <p className='text-red'>{requiredFields.positionTitle}</p> : null}
                    </div>
                    <div className='flex flex-col gap-1 w-full mb-3'>
                        <span className='font-normal'>Upload Offer Letter</span>
                        <div className='flex rounded cursor-pointer'>
                            {/* <label style={{ cursor: 'pointer' }} className='text-gray-400 font-[18px] pl-1 w-full py-1 placeholder-primary font-normal'> {offerLetter ? offerLetter?.name : 'Upload Offer letter'} */}
                            <input
                                type='file'
                                className="text-base w-full shadow rounded relative p-sm text-xs placeholder-primary font-normal"
                                onChange={handleOfferLetterChange}
                                // value={offerLetter}
                                hidden={false}
                                accept=".pdf"
                            />
                            {/* </label> */}
                        </div>
                        {(!offerLetter && IsFormSubmitted) ? <p className='text-red'>{requiredFields.offerLetter}</p> : null}
                    </div>
                </p>
                <div className="flex flex-1 justify-center items-end" style={{ marginTop: 10, marginBottom: 10 }}>
                    {
                        props?.showCancel ? (
                            <button
                                className="rounded border mr-sm font-medium text-sm bg-lightgray text-darkgray"
                                style={{ width: '25%', height: 40, fontSize: 14 }}
                                onClick={() => handleChanges(false)}
                            >
                                Cancel
                            </button>
                        ) : null
                    }
                    <button
                        className="rounded bg-red ml-md font-medium  text-sm text-white"
                        style={{ width: '25%', height: 40, fontSize: 14 }}
                        // onClick={() => handleChanges(!!props?.confirm())}
                        onClick={handleOnconfirmClick}
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    )
}


export default function OpenToHireButton({ status, candidateID, companyID }) {
    const [date, setDate] = useState(new Date());
    const [updateCandidateRequest] = useMutation(UPDATE_COMPANY_REQUEST);
    const [openModal, setOpenModal] = useState(false);
    const [hiredStatus, setHiredStatus] = useState(status);

    const { data: feeConfigSettingsData } = useQuery(SETTINGS);

    const { data: companyRequestData } = useQuery(GET_COMPANY_REQUEST, {
        variables: {
            candidate_id: candidateID,
            company_id: companyID,
        },
    });
    const {
        candidate,
        company
    } = companyRequestData?.company_request?.[0] || {};
    const { cherrypicker_share, placement_fee_percentage, guarantee_period, recruiter_share } = feeConfigSettingsData?.settings?.[0] || {};
    const guaranteeDate = moment(moment().add(guarantee_period || 0, 'days').toDate()).format('MM/DD/YYYY');

    const options = {
        OpenToHire: { bg: 'white', color: 'rgb(86, 208, 0)', label: 'Hire Candidate' },
        hired: { bg: '#6ACA63', color: 'white', label: 'Hired' },
    };

    const handleOpenToHireClick = () => {
        setOpenModal(true);
    }

    const handleHiredStatus = async (data) => {
        const { date, startDate, salaryHiredOn, offerLetter, positionTitle } = data || {};
        const newStartDate = startDate && moment(new Date(startDate)).toISOString();
        const fee = Math.ceil(Number(salaryHiredOn) * (placement_fee_percentage / 100)); // Calculate the 15% fee
        const cherrypickerShare = Math.ceil(Number(fee) * (cherrypicker_share / 100));
        const recruiterShare = Math.ceil(Number(fee) * (recruiter_share / 100));
        try {
            updateCandidateRequest({
                variables: {
                    candidate_id: candidateID,
                    company_id: companyID,
                    status: 'hired',
                    hired_date: date,
                    start_date: newStartDate,
                    offerLetterUrl: offerLetter,
                    hired_position_title: positionTitle,
                    hired_salary: salaryHiredOn,
                },
            });
            toast.success(`Congratulations! You have successfully hired ${candidate?.name || ''}!`)
            setHiredStatus('hired');
            const isAgency = candidate?.recruiter?.company?.is_agency || false;
            const agency = candidate?.recruiter?.company || {};
            const transaction_date = moment(new Date(date)).format('MM/DD/YYYY');
            const start_date = moment(new Date(startDate)).format('MM/DD/YYYY');
            const recruiter_name = candidate?.recruiter?.name || ''; //
            const recruiter_email = candidate?.recruiter?.email || ''; //
            const agency_name = !isAgency ? 'Cherry picker' : agency?.name ? agency?.name : 'cherry picker';
            const agency_email = !isAgency ? 'admin@getcherrypicker.com' : agency?.email ? agency?.email : 'admin@cherrypicker.com';;
            const subscriber_name = company?.name || '';
            const subscriber_email = company?.email || '';
            const candidate_name = candidate?.name || '';  //
            const job_title = positionTitle;
            const salary_hired = formatSalary({ salary: `$${salaryHiredOn}` });
            const placement_percentage = `${placement_fee_percentage} %`;
            const total_fee = formatSalary({ salary: `$${fee}` });
            const cherrypicker_share = formatSalary({ salary: `$${cherrypickerShare}` });
            const recruiter_share = formatSalary({ salary: `$${recruiterShare}` });
            const payment_due_date = guaranteeDate;
            //TO ADMIN
            fetch(`${EXPRESS_SERVER_URL}/send-email`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    recipient: 'admin@getcherrypicker.com',
                    pdfURL: offerLetter,
                    template_id: EMAIL_TEMPLATES.TO_CP_ADMIN_WHEN_SUBSCRIBER_HIRE_CANDIDATE,
                    attributes: {
                        transaction_date,
                        start_date,
                        recruiter_name,
                        recruiter_email,
                        agency_name,
                        agency_email,
                        subscriber_name,
                        subscriber_email,
                        candidate_name,
                        job_title,
                        salary_hired,
                        placement_percentage,
                        total_fee,
                        cherrypicker_share,
                        recruiter_share,
                        payment_due_date
                    },
                }),
            });
            //TO RECRUITER  
            fetch(`${EXPRESS_SERVER_URL}/send-email`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    recipient: recruiter_email,
                    pdfURL: offerLetter,
                    template_id: EMAIL_TEMPLATES.TO_RECRUITER_AND_AGENCY_WHEN_SUBSCRIBER_HIRE_CANDIDATE,
                    attributes: {
                        transaction_date,
                        start_date,
                        recruiter_name,
                        recruiter_email,
                        agency_name,
                        agency_email,
                        subscriber_name,
                        candidate_name,
                        job_title,
                        salary_hired,
                        placement_percentage,
                        total_fee,
                        cherrypicker_share,
                        recruiter_share,
                        payment_due_date
                    },
                }),
            });
            // TO Agency
            fetch(`${EXPRESS_SERVER_URL}/send-email`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    recipient: agency_email,
                    pdfURL: offerLetter,
                    template_id: EMAIL_TEMPLATES.TO_RECRUITER_AND_AGENCY_WHEN_SUBSCRIBER_HIRE_CANDIDATE,
                    attributes: {
                        transaction_date,
                        start_date,
                        recruiter_name,
                        recruiter_email,
                        agency_name,
                        agency_email,
                        subscriber_name,
                        candidate_name,
                        job_title,
                        salary_hired,
                        placement_percentage,
                        total_fee,
                        cherrypicker_share,
                        recruiter_share,
                        payment_due_date
                    },
                }),
            });
            // To subscriber
            fetch(`${EXPRESS_SERVER_URL}/send-email`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    recipient: subscriber_email,
                    pdfURL: offerLetter,
                    template_id: EMAIL_TEMPLATES.TO_SUBSCRIBER_WHEN_SUBSCRIBER_HIRE_CANDIDATE,
                    attributes: {
                        transaction_date,
                        start_date,
                        subscriber_name,
                        candidate_name,
                        job_title,
                        salary_hired,
                        placement_percentage,
                        total_fee,
                        guarantee_period,
                        payment_due_date,
                        recruiter_name: recruiter_name,
                    },
                }),
            });
        } catch (error) {
            setHiredStatus('');
            // console.log({ error })
        }
    }

    const disabled = hiredStatus === 'hired' ? true : false;
    const IsHired = hiredStatus === 'hired' ? true : false;
    return (
        <div>
            {/* {
                hiredStatus === 'hired' ? <div style={{ color: '#e31c25' }}>Hired</div> : <div onClick={handleOpenToHireClick} style={{ cursor: 'pointer', width: '5.7rem' }}>
                    Open to Hire
                </div>
            } */}
            <button
                disabled={disabled}
                onClick={handleOpenToHireClick}
                style={{
                    cursor: disabled ? 'default' : 'pointer',
                    color: IsHired ? options['hired'].color : options['OpenToHire'].color,
                    borderColor: 'rgb(86, 208, 0)',
                    backgroundColor: IsHired ? options['hired'].bg : options['OpenToHire'].bg,
                    border: '1px solid',
                    borderRadius: 5,
                    height: 40,
                    minWidth: '130px',
                    marginBottom: 2,
                    paddingLeft: 2,
                    paddingRight: 2,
                }}
                className={`text-xs flex justify-center items-center rounded`}
            >
                {`${IsHired ? options['hired'].label : options['OpenToHire'].label}`}
            </button>
            <ConfirmationModal handleHiredStatus={handleHiredStatus} open={openModal} showCancel={true} onChange={(e) => setOpenModal(e)}>
                <p className='text-base text-lg'>Pick a date when candidate was hired.</p>
            </ConfirmationModal>
            <ToastContainer style={{ width: 'fit-content' }} theme="dark" position="bottom-center" autoClose={6000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div >
    )
}
