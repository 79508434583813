import React, { useState } from "react";
import { validateFile } from "./util";
import { BsFiletypeCsv } from "react-icons/bs";
import { SiMicrosoftexcel } from "react-icons/si";
import './style.css'

import { useDropzone } from "react-dropzone";
import { parse } from "papaparse";
import { toast, ToastContainer } from 'material-react-toastify';

function CSVUploader({ onChangeCSVData, uploadedFiles, setUploadedFiles }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [outputData, setOutputData] = useState([]);
  const handleFileUpload = async (uploadedFile) => {
    const file = uploadedFile[0];
    if (!file || !validateFile(file, setErrorMessage)) return;

    let data = [];
    const fileExtension = file.name.split(".").pop()?.toLowerCase();

    try {
      if (fileExtension === "csv") {
        const fileContent = await file.text();
        data = parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            onChangeCSVData(results.data);
          },
        });
      } else {
        // const workbook = XLSX.read(await file.arrayBuffer(), {
        //     type: "array",
        // });
      }
    } catch (error) {
      console.log(error);
      setErrorMessage("An error occurred during the upload");
    }
  };

  const handleUserClick = () => {
    console.log("clicked");
  };

  // const [uploadedFiles, setUploadedFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      const validFiles = acceptedFiles.filter(
        (file) =>
          file.type === "text/csv"
          // file.type === "application/vnd.ms-excel" ||
          // file.type ===
          //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );

      setUploadedFiles(validFiles);
      if (validFiles?.length) {
        handleFileUpload(validFiles);
      }
      const unsupportedFiles = acceptedFiles.filter(
        (file) => !validFiles.includes(file)
      );
      if (unsupportedFiles.length > 0) {
        toast.error(
          `Unsupported file types: ${unsupportedFiles
            .map((file) => file.name)
            .join(", ")}`
        );
      }
    },
    // "application/vnd.ms-excel": [".xls"],
    // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    //   ".xlsx",
    // ],
    accept: {
      "text/csv": [".csv"],
    },
  });

  return (
    <div>
      <div className="flex justify-center flex-col align-middle py-3 pb-5">
        <label htmlFor="file-upload">
          <div className="flex justify-center align-middle text-3xl gap-10 flex-1 px-5 file-uploader">
            <BsFiletypeCsv />
            {/* <SiMicrosoftexcel /> */}
          </div>
          <p>Drag and drop files here or click to browse.</p>
        </label>
      
        <div {...getRootProps()} className="wfp--dropzone">
          <input
            id="file-upload"
            accept=".csv, .xls, .xlsx"
            {...getInputProps()}
          />
        </div>
        <ul>
          {uploadedFiles.map((file) => (
            <li key={file.name}>{file.name}</li>
          ))}
        </ul>
        {/* {outputData?.length > 0 && <Table data={outputData}/>} */}
        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      </div>
      <ToastContainer style={{ width: 'fit-content' }} position="bottom-center" autoClose={3000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
    </div>
  );
}

export default CSVUploader;
