import React, { useEffect, useState } from 'react';
import LocationFilter from './LocationFilter';
import SearchTermsFilter from './SearchTermsFilter';

const ClearButton = ({ updateField }) => (
  <div
    onClick={updateField}
    className="absolute lowercase cursor-pointer bg-darkgray text-white font-medium px-sm"
    style={{ top: 0, marginLeft: 15, borderRadius: 45.39, lineHeight: '20px' }}
  >
    clear
  </div>
);

export default function CompanyFilter({ filterState, type }) {
  const [filterValue, update] = filterState;
  const sections = {
    search: filterValue.search ? filterValue.search : [],
    location: filterValue.location ? filterValue.location : { city: null, radius: process.env.GATSBY_DEFAULT_SEARCH_RADIUS || 50 },
  };

  const [options, setOptions] = useState(sections);

  const updateField = (value, name) => setOptions({ ...options, [name]: value });

  const clearFilterField = (label) => {
    let resetValue;
    switch (label) {
      case 'location':
        resetValue = { city: null, radius: process.env.GATSBY_DEFAULT_SEARCH_RADIUS || 50 };
        break;
      default:
        break;
    }
    updateField(resetValue, label);
  };
  const renderFilterField = (name) => {
    switch (name) {
      case 'search':
        return <SearchTermsFilter type={type} value={filterValue.search} update={(value) => updateField(value, name)} />;

      case 'location':
        return <LocationFilter value={options[name]} update={(value) => updateField(value, name)} />;
      default:
        return <div></div>;
    }
  };

  const renderClearButton = (label) => {
    const value = options[label];
    switch (label) {
      case 'search':
        return null;
      case 'location':
        if (!value.city) {
          return null;
        }
        break;
      default:
        break;
    }
    return <ClearButton updateField={() => clearFilterField(label)} />;
  };

  useEffect(() => { }, [filterValue]);
  useEffect(() => {
    let filter = {};
    const { search, location } = options;

    if (search.length > 0) {
      filter = { ...filter, search };
    }
    if (location.city) {
      filter = { ...filter, location };
    }

    update(filter);
  }, [options]);

  return (
    <div className="flex w-full flex-wrap" style={{ marginBottom: 30 }}>
      {Object.entries(sections).map(([label], index) => {
        return (
          <div key={index} className={`flex flex-col ${label}-filter`} style={{ flexGrow: 1 }}>
            <div
              className="text-darkgray flex items-center capitalize font-medium"
              style={{ fontSize: 12, height: 20, marginBottom: 15, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              <div className="flex-1 relative" style={{ height: 20 }}>
                {renderClearButton(label)}
              </div>
            </div>
            {renderFilterField(label)}
          </div>
        );
      })}
    </div>
  );
}
