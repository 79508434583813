/* eslint-disable max-len */
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Field, Form, Formik } from 'formik';
import { navigate } from 'gatsby';
import _ from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import * as Yup from 'yup';
import { EXPRESS_SERVER_URL } from '../../config';
import { UserRoleContext } from '../../context';
import { defaultCompanyLogo, EMAIL_TEMPLATES } from '../../utils/constants';
import { getLongId } from '../../utils/getId';
import { updateLoxoCandidateEmployment } from '../../utils/loxo.helper';
import { PhotoUpload } from '../fields';
import CandidateField from '../fields/CandidateField';
import InterestedCitiesField from '../fields/InterestedCitiesField';
import ResumeUpload from '../fields/ResumeUpload';
import SalaryRange from '../fields/SalaryRange';
import { getEducation, getEmployment, getSkills, parseResume } from './CandidateFormUtils';

const UPDATE_CANDIDATE = gql`
  mutation updateCandidate($candidateID: String, $changedFields: candidate_set_input) {
    update_candidate(_set: $changedFields, where: { id: { _eq: $candidateID } }) {
      returning {
        id
        name
        email
        phone
        resumeFileName
        resumeURL
        linkedin
        referralName
        location
        facebook
        instagram
        twitter
        experience
        bio
        employment
        education
        certifications
        attributes
        preferredSkills
        notes
        salaryMax
        salaryMin
        locationPreferences
        interestedCity
      }
    }
  }
`;

const LocationCheckbox = ({ value, name, update }) => {
  return (
    <div className="flex items-center" style={{ fontSize: 14, marginRight: 45 }}>
      <div
        onClick={() => update(!value)}
        className={`${value ? 'bg-green' : ''} border border-green cursor-pointer`}
        style={{
          width: 14,
          height: 14,
          marginRight: 10,
        }}
      />
      <div className="cursor-pointer font-medium">
        {name === 'currentLocation' && 'Current Location'}
        {name === 'willingToRelocate' && 'Willing to Relocate'}
        {name === 'remote' && 'Remote'}
      </div>
    </div>
  );
};

export default function EditCandidateForm({ candidate, isApprovedCandidate }) {
  const [profilePhoto, setProfilePhoto] = useState(candidate.profilePictureURL);
  const [resume, setResume] = useState(candidate.resume);
  const [newParsedResume, setNewParsedResume] = useState();
  const [newResumeLoading, setNewResumeLoading] = useState(false);
  const [salary, setSalary] = useState([candidate.salaryMin || 0, candidate.salaryMax || 0]);
  const [hasError, setHasError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userRole] = useContext(UserRoleContext);
  const isAdmin = userRole === 'admin';

  const customErrors = {};
  const locationPreferencesInititalState = Object.fromEntries(
    Object.entries(candidate.locationPreferences ? candidate.locationPreferences : { remote: false, currentLocation: false, willingToRelocate: false }).filter(
      ([label]) => label !== 'options' && label !== 'preferredLocation',
    ),
  );
  const [locationPreferences, setLocationPreferences] = useState(locationPreferencesInititalState);
  const [updateCandidate, { data }] = useMutation(UPDATE_CANDIDATE);
  const [interestedCity, setInterestedCity] = useState(candidate.interestedCity);

  const placeholders = {
    name: 'Name',
    email: 'Email',
    linkedin: 'Linkedin Handle',
    referralName: 'Referral Name',
    recruiterName: 'Recruiter Name',
    phone: 'Phone Number',
    facebook: 'Facebook Profile Handle',
    instagram: 'Instagram Profile Handle',
    twitter: 'Twitter Profile Handle',
    bio: 'Short Bio',
    address: 'Address',
    experience: 'Years of Experience',
  };

  const titledFields = {
    // bio: 'Short Bio',
    // location: 'Location',
    // experience: 'Years of Experience',
    employment: 'Employment',
    education: 'Education',
    certifications: 'Certifications or Licenses',
    attributes: `Candidate's Attributes`,
    preferredSkills: 'Job Preferences',
  };

  const optionalFields = ['facebook', 'instagram', 'referralName', 'twitter'];

  let initialValues = {
    name: candidate.name,
    email: candidate.email,
    phone: candidate.phone ? candidate.phone : '',
    referralName: candidate.referralName ? candidate.referralName : '',
  };

  if (isAdmin) {
    initialValues = { ...initialValues, recruiter: candidate.recruiter };
  }

  initialValues = {
    ...initialValues,
    linkedin: candidate.linkedin ? candidate.linkedin : '',
    facebook: candidate.facebook ? candidate.facebook : '',
    instagram: candidate.instagram ? candidate.instagram : '',
    twitter: candidate.twitter ? candidate.twitter : '',
    bio: candidate.bio ? candidate.bio : '',
    location: candidate.location ? candidate.location : '',
    experience: candidate.experience ? candidate.experience : '',
    employment:
      candidate.employment && candidate.employment.length > 0
        ? candidate.employment.map((entry) => (entry.id ? entry : { id: getLongId(), ...entry }))
        : [{ id: getLongId(), companyLogo: '', companyLocation: '', title: '', companyName: '', description: '', tenure: { startDate: '', endDate: '' }, skills: [] }],
    education:
      candidate.education && candidate.education.length > 0
        ? candidate.education.map((entry) => (entry.id ? entry : { id: getLongId(), ...entry }))
        : [{ school: '', degree: '', tenure: { startDate: '', endDate: '' } }],
    certifications: candidate.certifications ? candidate.certifications : [],
    attributes: candidate.attributes ? candidate.attributes : [],
    preferredSkills: candidate.preferredSkills ? candidate.preferredSkills : [],
  };

  const candidateSchema = Yup.object().shape({
    name: Yup.string().required('Candidate name is required'),
    // phone: Yup.mixed()
    //   .test({
    //     name: "Phone",
    //     message: "Please enter a valid phone number",
    //     test: value => value && value.length === 14,
    //   })
    //   .required("Candidate phone number is required"),
    email: Yup.string().required('Candidate email is required'),
    // bio: Yup.string().required("A short bio is required"),
    location: Yup.mixed().test({
      name: 'Location',
      message: 'Please select a location',
      test: (location) => (location && location.city ? true : false),
    }),
    attributes: Yup.mixed().test({
      name: 'Attributes',
      message: 'At least one attribute for the candidate is required',
      test: (value) => (value.length === 0 ? false : true),
    }),
    experience: Yup.string().required('Experience is required'),
  });

  const adminSchema = Yup.object().shape({
    name: Yup.string().required('Candidate name is required'),
    // phone: Yup.mixed()
    //   .test({
    //     name: "Phone",
    //     message: "Please enter a valid phone number",
    //     test: value => value && value.length === 14,
    //   })
    //   .required("Candidate phone number is required"),
    email: Yup.string().required('Candidate email is required'),
    // bio: Yup.string().required("A short bio is required"),
    location: Yup.mixed().test({
      name: 'Location',
      message: 'Please select a location',
      test: (location) => (location && location.city ? true : false),
    }),
    attributes: Yup.mixed().test({
      name: 'Attributes',
      message: 'At least one attribute for the candidate is required',
      test: (value) => (value.length === 0 ? false : true),
    }),
    experience: Yup.string().required('Experience is required'),
  });

  const uploadFile = async (file, path) => {
    const storageRef = firebase
      .storage()
      .ref()
      .child(`${path}/${file.name}`);
    const fileURL = await storageRef.put(file).then((snapshot) => snapshot.ref.getDownloadURL());
    return fileURL;
  };

  async function uploadImage(file, location) {
    const storageRef = firebase
      .storage()
      .ref()
      .child(`images/${location}/${file.name}`);
    const logoURL = await storageRef.put(file).then((snapshot) => snapshot.ref.getDownloadURL());
    return logoURL;
  }

  const saveCandidate = async (submission) => {
    setHasError(false);
    // const employmentEntries = Object.entries(submission.employment.length > 0 ? submission.employment[0] : {}).filter(([label, val]) => {
    //   if (label === 'companyLogo') {
    //     return false;
    //   } else {
    //     return !val;
    //   }
    // });
    // const educationEntries = Object.values(submission.education.length > 0 ? submission.education[0] : {}).filter((val) => val === '');
    // const emptyLocationPreferences = Object.values(locationPreferences).filter((val) => !val);

    // if (
    //   employmentEntries.length > 0 ||
    //   educationEntries.length > 0 ||
    //   emptyLocationPreferences.length === 3 ||
    //   (locationPreferences.willingToRelocate && !interestedCity)
    // ) {
    //   setCustomErrors({
    //     employment:
    //       employmentEntries.length > 0 &&
    //       "Please completely fill out at least one employment entry",
    //     education:
    //       educationEntries.length > 0 &&
    //       "Please completely fill out at least one education entry",
    //     locationPreferences:
    //       emptyLocationPreferences.length === 3
    //         ? "Please choose a location preference"
    //         : locationPreferences.willingToRelocate &&
    //         !interestedCity &&
    //         "Please choose a city the candidate is interested in",
    //   });
    // } else {
    setLoading(true);
    const changedFields = Object.entries(submission)
      .filter(([label, value]) => {
        switch (label) {
          case 'recruiterName':
            return false;
          case 'education':
            return JSON.stringify(value) === JSON.stringify(candidate.education) ? false : true;
          case 'recruiter':
            return false;
          case 'employment':
            return JSON.stringify(value) === JSON.stringify(candidate.employment) ? false : true;
          default:
            return candidate[label] === value ? false : true;
        }
      })
      .map(async ([label, value]) => {
        if (label === 'employment') {
          const updatedEmployment = value.map(async (employment) => {
            if (typeof employment.companyLogo !== 'string') {
              return {
                ...employment,
                companyLogo: employment.companyLogo && employment.companyLogo !== '' ? await uploadFile(employment.companyLogo, 'images/companylogos') : defaultCompanyLogo,
              };
            } else {
              return employment;
            }
          });
          const employment = await Promise.all(updatedEmployment);
          return [label, employment];
        } else {
          return [label, value];
        }
      });
    Promise.all(changedFields).then(async (updatedFields) => {
      let extraFields = [];

      // check for changes to location preferences
      if (candidate.locationPreferences !== locationPreferences) {
        extraFields = [['locationPreferences', locationPreferences]];
      }

      // check for uploaded resume
      if (resume) {
        const newResume = await uploadFile(resume, 'resumes');
        extraFields = [...extraFields, ['resumeURL', newResume], ['resumeFileName', resume.name]];
      }

      // check for uploaded profile picture
      if (profilePhoto && profilePhoto.name) {
        const profilePictureURL = await uploadImage(profilePhoto, 'avatars');
        updatedFields = [...updatedFields, ['profilePictureURL', profilePictureURL]];
      }
      // Check TODO
      if (isApprovedCandidate) {
        updatedFields = [...updatedFields, ['created_at', new Date().toISOString() ]];
      }
      // check for changes in salary
      if (candidate.salaryMin !== salary[0] || candidate.salaryMax !== salary[1]) {
        extraFields = [...extraFields, ['salaryMin', salary[0]], ['salaryMax', salary[1]]];
      }

      // check for changes to interested city
      if (candidate.interestedCity !== interestedCity) {
        extraFields = [...extraFields, ['interestedCity', interestedCity]];
      }

      // check if employment was deleted
      if (submission.employment && candidate.employment) {
        const experience = submission.employment.reduce((totalExperience, { tenure: { startDate, endDate, current } }) => {
          const start = moment(startDate);
          const end = current ? moment() : moment(endDate);
          return totalExperience + end.diff(start, 'months');
        }, 0);

        if (experience && !Object.fromEntries(updatedFields).experience) {
          extraFields = [...extraFields, ['experience', Math.floor(experience / 12)]];
        }

        submission.employment.sort((a, b) => {
          const x = a.tenure.current;
          const y = b.tenure.current;
          return (x === y)? 0 : x? -1 : 1;
        });
      }

      if (submission.recruiter && submission.recruiter.id !== candidate.recruiter_id) {
        extraFields = [...extraFields, ['recruiter_id', submission.recruiter.id]];
      }

      // updating email into firebase auth if not already present
      const user = await fetch(`${EXPRESS_SERVER_URL}/getUserFirebaseAccount`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: submission.email,
        }),
      }).then((response) => response.json());

      if (user.statusCode === 500) {
        await fetch(`${EXPRESS_SERVER_URL}/createUserFirebaseAccount`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            email: submission.email,
            name: submission.name,
          }),
        }).then((response) => response.json());
      }

      updateCandidate({
        variables: {
          candidateID: candidate.id,
          changedFields: Object.fromEntries([...updatedFields, ...extraFields, ['approved', true]]),
        },
        refetchQueries: ['getCandidatesByRecruiter'],
      });

      // Update candidate details in Loxo is candidate is from Loxo
      const { data: importerData } = await axios.post(`${EXPRESS_SERVER_URL}/checkImporter`, {
        recruiter_id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
        account_type: 'loxo',
      });

      const auth = importerData && importerData[0] && importerData[0].authorization ? importerData[0].authorization : null;

      if (auth && candidate.loxo_id) {
        const currentEmployment = submission.employment.filter((a) => a.tenure.current)[0];
        let title = '';
        let company = '';

        if (currentEmployment) {
          title = currentEmployment.title;
          company = currentEmployment.companyName;
        }

        const payload = {
          person: {
            name: submission.name,
            description: submission.bio,
            city: submission.location.city,
            title,
            company,
            email: submission.email,
            phone: submission.phone,
            linkedin_url: submission.linkedin,
            all_raw_tags: submission.attributes,
            compensation: salary[0],
          },
        };

        await updateLoxoCandidateEmployment({ auth, candidateId: candidate.loxo_id, payload });
        // await axios.post(`${EXPRESS_SERVER_URL}/sync-cp-loxo`, {
        //   auth,
        //   candidateId: candidate.id,
        // })
      }
    });
    // }
  };

  const parseNewResume = async (resume) => {
    setNewResumeLoading(true);
    const { ResumeParserData: newResume } = await parseResume(resume);
    const newEmployment = getEmployment(newResume);
    const newEducation = getEducation(newResume);
    const newAttributes = getSkills(newResume);

    setNewParsedResume({
      bio: resume.Summary !== '' ? resume.Summary : resume.ExecutiveSummary !== '' ? resume.ExecutiveSummary : candidate.bio,
      employment: newEmployment.length > 0 && newEmployment,
      education: newEducation.length > 0 && newEducation,
      attributes: _.uniq([...candidate.attributes, ...newAttributes]),
    });
    setNewResumeLoading(false);
  };

  useEffect(() => {
    if (data) {
      if (!candidate.approved) {
        const email = data.update_candidate.returning[0].email;
        const candidate_name = data.update_candidate.returning[0].name;
        fetch(`${EXPRESS_SERVER_URL}/send-email`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            recipient: email,
            // subject: 'Your Cherrypicker Profile Has Been Approved', // added in sendGrid
            template_id: EMAIL_TEMPLATES.TO_CANDIDATE_WHEN_QUEUE_PROFILE_APPROVED_BY_RECRUITER, //verified
            attributes: {
              // no_content: true
              candidate_name,
            },
          }),
        });
      }
      navigate(`/candidates/${candidate.id}`, { state: { candidate } });
      setLoading(false);
    }
  }, [data]);

  return (
    <div className="EditCandidateForm container flex flex-col lg:flex-row w-full pb-lg relative">
      <div className="flex flex-col flex-1  mb-lg lg:mb-0 mr-0 lg:mr-xl">
        <div className="font-semibold" style={{ fontSize: 16, marginBottom: 28 }}>
          Profile Preview
        </div>
        <div className="flex bg-white relative flex-wrap rounded shadow p-lg lg:p-xl w-full">
          <PhotoUpload value={candidate ? candidate.profilePictureURL : ''} placeholder={'Profile Photo'} update={setProfilePhoto} />
          <ResumeUpload value={resume} update={setResume} parseResume={parseNewResume} loading={newResumeLoading} />
          <Formik initialValues={initialValues} onSubmit={saveCandidate} validationSchema={isAdmin ? adminSchema : candidateSchema} validateOnBlur>
            {({ values, setFieldValue }) => (
              <Form className="w-full mb-0">
                {Object.entries(values).map(([label], index) => (
                  <Field key={index} name={label} className="flex">
                    {({ field: { value }, form: { errors, touched } }) => {
                      return (
                        <CandidateField
                          value={value}
                          newParsedResume={newParsedResume}
                          name={label}
                          update={(value) => setFieldValue(label, value)}
                          setFieldValue={setFieldValue}
                          placeholder={placeholders[label]}
                          optional={optionalFields.includes(label)}
                          title={titledFields[label]}
                          error={(touched[label] && errors[label]) || customErrors[label]}
                        />
                      );
                    }}
                  </Field>
                ))}
                <div>
                  <div className="relative">
                    {/* <div
                      className="text-darkblue w-full flex items-center font-medium flex justify-between"
                      style={{
                        fontSize: 18,
                        marginTop: 40,
                        fontWeight: 400,
                        marginBottom: 30,
                      }}>
                      <div>Salary Range</div>
                      <div className="flex-1 border-b ml-md" />
                      <div
                        className="font-medium"
                        style={{
                          fontSize: 12,
                          color: "#c4cad3",
                          marginLeft: 20,
                        }}>
                        OPTIONAL
                      </div>
                    </div> */}
                    <SalaryRange hideTitle={false} value={salary} update={setSalary} isCandidate={true} />
                  </div>
                  <div
                    className="text-darkblue w-full flex items-center font-medium flex justify-between"
                    style={{ fontSize: 18, marginTop: 40, fontWeight: 400, marginBottom: 30 }}
                  >
                    <div>Location Preference</div>
                    <div className="flex-1 border-b ml-md" />
                  </div>
                  <div className="flex flex-wrap">
                    {customErrors.locationPreferences && <div className="text-red font-medium text-sm mb-md w-full text-center">{customErrors.locationPreferences}</div>}
                    {Object.entries(locationPreferencesInititalState).map(([label], index) => {
                      return (
                        <LocationCheckbox
                          key={index}
                          value={locationPreferences[label]}
                          update={(bool) => setLocationPreferences({ ...locationPreferences, [label]: bool })}
                          name={label}
                        />
                      );
                    })}
                  </div>
                  {locationPreferences.willingToRelocate && (
                    <InterestedCitiesField value={interestedCity} placeholder="City/State you are interested in" update={setInterestedCity} />
                  )}
                </div>
                {(hasError || Object.values(customErrors).length > 0) && (
                  <div className="font-medium text-red text-sm text-center relative" style={{ top: 25 }}>
                    Please completely fill out all required fields
                  </div>
                )}
                <div className="flex relative justify-center" style={{ height: 55, marginBottom: 32, marginTop: 60 }}>
                  <button
                    onClick={() => window.history.back()}
                    type="button"
                    className="rounded border mr-sm font-medium text-sm bg-lightgray text-darkgray"
                    style={{ width: 137, fontSize: 14 }}
                  >
                    Cancel
                  </button>
                  <button                                                 
                    type="submit"
                    onClick={() => {
                      setHasError(true);
                    }}
                    className="rounded font-medium text-sm bg-red text-white"
                    style={{ width: 157, fontSize: 14, boxShadow: '0 14px 10px 0 rgba(255, 0, 0, 0.18)' }}
                  >
                    {loading ? <Loader className="flex justify-center" type="TailSpin" color="#FFFFFF" height={20} width={20} /> : 'Save & Continue'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="flex flex-col flex-1">
        <div className="font-semibold" style={{ fontSize: 16, marginBottom: 28 }}>
          Resume Preview
        </div>
        {(candidate.resumeURL || resume) && (
          <iframe title="Resume" style={{ width: '572px', maxWidth: '100%', height: 800 }} src={resume ? URL.createObjectURL(resume) : candidate.resumeURL} />
        )}
      </div>
    </div>
  );
}
