import firebase from 'firebase/app';
import { navigate } from 'gatsby';
import React from 'react';
import Arrow from '../../components/common/Arrow';
import { EXPRESS_SERVER_URL } from '../../config';
// import CreateAccountForm from '../../forms/gateway/CreateAccountForm';
// import CreateAgenciesForm from '../../forms/gateway/CreateAgenciesForm';
import CreateAgencyForm from '../../forms/agency-form/CreateAgencyForm';

export default function NewAgencies({ location }) {
  async function uploadImage(file, location) {
    const storageRef = firebase
      .storage()
      .ref()
      .child(`images/${location}/${file.name}`);
    const logoURL = await storageRef.put(file).then((snapshot) => snapshot.ref.getDownloadURL());
    return logoURL;
  }

  async function createAgencies(values) {
    let profilePhoto;
    if (values.profilePhoto && values.profilePhoto !== '') {
      profilePhoto = await uploadImage(values.profilePhoto, 'profilephotos');
    }
    fetch(`${EXPRESS_SERVER_URL}/adminCreateRecruiter`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...values, profilePhoto }),
    })
      .then(() => navigate('/dashboard/recruiters'))
      .catch(console.log);
    fetch(`${EXPRESS_SERVER_URL}/recruiterAccountEmail`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ password: values.password, email: values.email }),
    });
  }

  return (
    <div className="new-recruiter-js w-full h-full relative">
      <div
        className="cursor-pointer absolute text-darkgray flex items-center capitalize"
        style={{ fontSize: 12, left: 0, top: 30, zIndex: 30 }}
        onClick={() => (location.state && location.state.from ? navigate(location.state.from) : navigate('/dashboard/agencies'))}
      >
        <Arrow color="darkgray" style={{ height: 15, marginRight: 10 }} />
        Agencies
      </div>
      <CreateAgencyForm subscriber={location.state.recruiter} />
    </div>
  );
}
