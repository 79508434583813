import firebase from 'firebase/app';
import { navigate } from 'gatsby';
import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import * as Yup from 'yup';
import RichTextArea from '../../forms/fields/RichTextArea';
import { IndustrySelect, PhotoUpload, TextInput } from '../fields';
import CompanySize from '../fields/CompanySize';
import Form from '../Form';

export default function NewAgencyForm({ action, globalFormData, isEdit, loading }) {
    const [newAccount] = globalFormData;

    const [errors, setErrors] = useState({});

    const passwordErrorMessage = 'Password must be at least 8 characters, have one capital letter, and one special character';

    // Defining the form elements
    const adminFields = {
        name: {
            placeholder: 'Agency Name',
            component: TextInput,
            value: newAccount ? newAccount.name : '',
            validation: Yup.string().required(`Please enter the company's name`),
        },
        email: {
            placeholder: 'email',
            type: 'email',
            component: TextInput,
            value: newAccount ? newAccount.email : '',
            validation: Yup.string()
                .email('Invalid email')
                .required('No email provided'),
        },
        password: {
            placeholder: 'password',
            type: 'password',
            component: TextInput,
            value: newAccount ? newAccount.password : '',
            validation: Yup.string()
                .min(8, passwordErrorMessage)
                .matches(/(?=.*[A-Z])/, passwordErrorMessage)
                .matches(/\W|_/, passwordErrorMessage)
                .required(passwordErrorMessage),
        },
        confirm_password: {
            placeholder: 'confirm password',
            type: 'password',
            component: TextInput,
            value: newAccount ? newAccount.password : '',
            validation: Yup.string()
                .min(8, passwordErrorMessage)
                .matches(/(?=.*[A-Z])/, passwordErrorMessage)
                .matches(/\W|_/, passwordErrorMessage)
                .required(passwordErrorMessage)
                .oneOf([Yup.ref('password'), null], 'Passwords must match'),
        },
        industry: {
            value: newAccount ? newAccount.industry : [],
            component: IndustrySelect,
            validation: Yup.mixed().test({
                name: 'Industries',
                message: 'Please select at least one industry',
                test: (value) => (value && value.length === 0 ? false : true),
            }),
        },
        websiteURL: {
            placeholder: 'website',
            component: TextInput,
            value: newAccount ? newAccount.websiteURL : '',
            validation: Yup.string().required('Please enter the company website url'),
        },
        adminName: {
            placeholder: 'Agency Manager',
            component: TextInput,
            value: newAccount ? newAccount.adminName : '',
            validation: Yup.string().required(`Please enter Agency manager's name`),
        },
        bio: {
            placeholder: 'bio',
            component: RichTextArea,
            value: newAccount ? newAccount.bio : '',
        },
        logoURL: {
            placeholder: 'Agency Logo',
            component: PhotoUpload,
            value: newAccount ? newAccount.logoURL : '',
        },
        size: {
            component: CompanySize,
            value: newAccount ? newAccount.size : '',
            validation: Yup.string().required(`Please enter the company's size`),
        },
    };

    const adminFieldsEdit = {
        name: {
            placeholder: 'Agency Name',
            component: TextInput,
            value: newAccount ? newAccount.name : '',
            validation: Yup.string().required(`Please enter the company's name`),
        },
        email: {
            placeholder: 'email',
            type: 'email',
            component: TextInput,
            value: newAccount ? newAccount.email : '',
            validation: Yup.string()
                .email('Invalid email')
                .required('No email provided'),
        },
        websiteURL: {
            placeholder: 'website',
            component: TextInput,
            value: newAccount ? newAccount.websiteURL : '',
            validation: Yup.string().required('Please enter the company website url'),
        },
        adminName: {
            placeholder: 'Hiring Manager',
            component: TextInput,
            value: newAccount ? newAccount.adminName : '',
            validation: Yup.string().required(`Please enter hiring manager's name`),
        },
        bio: {
            placeholder: 'bio',
            component: RichTextArea,
            value: newAccount ? newAccount.bio : '',
        },
        logoURL: {
            placeholder: 'Agency Logo',
            component: PhotoUpload,
            value: newAccount ? newAccount.logoURL : '',
        },
        size: {
            component: CompanySize,
            value: newAccount ? newAccount.size : '',
            validation: Yup.string().required(`Please enter the company's size`),
        },
    };

    function onSubmit(values) {
        if (isEdit) {
            if (newAccount.email !== values.email) {
                firebase
                    .auth()
                    .fetchSignInMethodsForEmail(values.email)
                    .then((signInMethods) => {
                        if (signInMethods.length === 0) {
                            action(values);
                        } else {
                            setErrors({ email: 'An account with that email already exists. Please choose another.' });
                        }
                    })
                    .catch();
            } else {
                action(values);
            }
        } else {
            firebase
                .auth()
                .fetchSignInMethodsForEmail(values.email)
                .then((signInMethods) => {
                    if (signInMethods.length === 0) {
                        action(values);
                    } else {
                        setErrors({ email: 'An account with that email already exists. Please choose another.' });
                    }
                })
                .catch();
        }
    }

    return (
        <div className="NewSubscriberForm-js py-lg w-full h-full relative container flex flex-col items-center">
            <div className="font-main text-darkblue" style={{ fontWeight: 500, fontSize: 21, paddingBottom: 20 }}>
                {isEdit ? 'Edit Agency' : 'Create Agency'}
            </div>
            <div className="w-full bg-white rounded shadow flex-1 flex flex-col" style={{ paddingRight: 30, paddingLeft: 30 }}>
                <Form fields={!isEdit ? adminFields : adminFieldsEdit} onSubmit={onSubmit} customErrors={errors} setCustomErrors={setErrors}>
                    <div className="flex w-full mt-lg">
                        <button
                            onClick={() => (isEdit ? navigate(`/agencies/${newAccount.id}/`, { state: { company_id: newAccount.id } }) : navigate(`/dashboard/agencies`))}
                            type="button"
                            className="flex-1 p-md mr-sm cursor-pointer bg-lightgray font-main text-darkgray rounded text-sm"
                        >
                            Cancel
                        </button>
                        <button type="submit" className="flex-1 p-md ml-sm cursor-pointer bg-red font-main text-white rounded flex justify-center text-xs">
                            {loading ? <Loader type="TailSpin" color="#FFFFFF" height={20} width={20} /> : 'Save & Continue'}
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    );
}
