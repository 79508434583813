import firebase from 'firebase/app';
import { navigate } from 'gatsby';
import React, { useContext } from 'react';
import Arrow from '../../components/common/Arrow';
import { EXPRESS_SERVER_URL } from '../../config';
import CreateAccountForm from '../../forms/gateway/CreateAccountForm';
import { UserRoleContext } from '../../context';
import CreateAgenciesForm from '../../forms/gateway/CreateRecruiterForm';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

const GET_COMPANY_ID = gql`
  query getCompanyID($admin_id: String) {
    company(where: { adminID: { _eq: $admin_id } }) {
      id
      remainingCredit
      subscription_type
    }
  }
`;

export default function NewRecruiter({ location }) {
  let [userRole] = useContext(UserRoleContext);

  async function uploadImage(file, location) {
    const storageRef = firebase
      .storage()
      .ref()
      .child(`images/${location}/${file.name}`);
    const logoURL = await storageRef.put(file).then((snapshot) => snapshot.ref.getDownloadURL());
    return logoURL;
  }

  const { data: company } = useQuery(GET_COMPANY_ID, {
    variables: { admin_id: firebase.auth().currentUser ? firebase.auth().currentUser.uid : 'not-logged-in' },
  });
  async function createRecruiter(values) {
    let profilePhoto;
    const newValues = { ...values }
    if (userRole === 'agency') {
      newValues['company'] = company.company[0]
    }
    if (values.profilePhoto && values.profilePhoto !== '') {
      profilePhoto = await uploadImage(values.profilePhoto, 'profilephotos');
    }
    // console.log('Creating recruiter', EXPRESS_SERVER_URL);
    fetch(`${EXPRESS_SERVER_URL}/adminCreateRecruiter`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...newValues, profilePhoto }),
    })
      .then(() => navigate('/dashboard/recruiters'))
      .catch(console.log);
    fetch(`${EXPRESS_SERVER_URL}/recruiterAccountEmail`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ password: values.password, email: values.email }),
    });
  }

  return (
    <div className="new-recruiter-js w-full h-full relative">
      <div
        className="cursor-pointer absolute text-darkgray flex items-center capitalize"
        style={{ fontSize: 12, left: 0, top: 30, zIndex: 30 }}
        onClick={() => (location.state && location.state.from ? navigate(location.state.from) : navigate('/dashboard/recruiters'))}
      >
        <Arrow color="darkgray" style={{ height: 15, marginRight: 10 }} />
        Recruiters
      </div>
      {userRole === 'agency' ? <CreateAgenciesForm onSubmit={createRecruiter} /> : <CreateAccountForm onSubmit={createRecruiter} />}
    </div>
  );
}
