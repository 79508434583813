import React from 'react';
import { CSVDownloader } from 'react-papaparse';
import { formatSalary } from '../../utils/formatter';

export default function FilteredJobs({ jobs }) {
  const csvData = (jobs || []).map((job) => {
    return {
      Title: job.title,
      Description: job.description ? job.description.replace(/<[^>]+>/g, '') : 'N/A',
      Company: job.company && job.company.name,
      'Min Salary ($)': formatSalary({ salary: job.salaryMin }),
      'Max Salary ($)': formatSalary({ salary: job.salaryMax }),
      Compensation: job.compensation_list.join(', '),
      Location: job.location && job.location.city,
      Industry: job.industry,
      'Min Experience': job.requiredExperience[0],
      'Max Experience': job.requiredExperience[1],
      Notes: job.notes && job.notes.value,
      'Optional Attributes': job.optionalAttributes && job.optionalAttributes.map((attr) => attr),
      'Required Attributes': job.requiredAttributes && job.requiredAttributes.map((attr) => attr),
      'Allow Remote ?': job.allowsRemote ? 'Yes' : 'No',
      'Hide Company from Candidates ?': job.hiddenFromCandidates ? 'Yes' : 'No',
      'Filled ?': job.filled ? 'Yes' : 'No',
    };
  });

  return (
    <CSVDownloader
      data={csvData}
      type="anchor"
      filename={'jobs'}
      className={`bg-red text-white font-medium rounded flex items-center justify-center`}
      style={{ fontSize: 14, height: 40, width: 150, marginRight: 10 }}
    >
      <span style={{ fontSize: 22, marginRight: 8 }}></span> Export Jobs
    </CSVDownloader>
  );
}
