import { useMutation, useQuery, useSubscription } from '@apollo/react-hooks';
import styled from '@emotion/styled';
import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { gql } from 'apollo-boost';
import axios from 'axios';
import firebase from 'firebase/app';
import { navigate } from 'gatsby';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Arrow } from '../../components/common';
import { EXPRESS_SERVER_URL } from '../../config';
import { TextInput } from '../../forms/fields';
import Form from '../../forms/Form';
import StripeSecure from '../../images/stripe-logo.png';
import CreditQuantity from './CreditQuantity';
import PaymentSuccessModal from './ui/PaymentSuccessModal';
import Row from './ui/Row';
import SubmitButton from './ui/SubmitButton';

/*css for plus/minus quantity section*/
const Controls = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

/*css for card element section*/
const CardElementContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;

  & .StripeElement {
    width: 100%;
    padding: 15px;
  }
`;

/*graphql query to get the payment history*/
const PAYMENT_HISTORY = gql`
  subscription getPaymentHistory($admin_id: String) {
    payment_history(where: { company: { adminID: { _eq: $admin_id } } }, order_by: { date: desc }) {
      id
      company_id
      credits
      date
      type
      candidate {
        id
        name
        profilePictureURL
      }
    }
  }
`;

/*graphql query to create payment history and update the total credits for the company*/
const CREATE_PAYMENT_HISTORY = gql`
  mutation CreatePayment($credits: numeric, $date: String, $type: smallint, $company_id: uuid, $totalCredit: numeric, $remainingCredit: numeric, $credit_amount: numeric) {
    insert_payment_history(objects: { credits: $credits, date: $date, type: $type, company_id: $company_id, credit_amount: $credit_amount }) {
      returning {
        id
        credits
        date
        type
        company_id
      }
    }
    update_company(where: { id: { _eq: $company_id } }, _set: { totalCredit: $totalCredit, remainingCredit: $remainingCredit }) {
      affected_rows
      returning {
        id
        name
        totalCredit
        remainingCredit
        subscription_type
      }
    }
  }
`;

const GET_COMPANY = gql`
  query getCompany($admin_id: String) {
    company(where: { adminID: { _eq: $admin_id } }) {
      id
      email
      name
      logoURL
      adminName
      totalCredit
      remainingCredit
      subscription_type
    }
  }
`;

const SETTINGS = gql`
  query getSettings {
    settings {
      id
      credit_rate
    }
  }
`;

function CheckoutForm({ numCredits, settingData }) {
  //set initial state of payment success modal
  const [modal, setModal] = useState({ data: null, open: false });

  const [isProcessing, setProcessingTo] = useState(false);
  //set current date
  const [date, setDate] = useState(new Date());
  const [error, setError] = useState('');

  //set company
  const [company, setCompany] = useState();

  const { data: companyResponse } = useQuery(GET_COMPANY, {
    variables: { admin_id: firebase.auth().currentUser && firebase.auth().currentUser.uid },
  });

  useEffect(() => {
    if (companyResponse) {
      setCompany(companyResponse.company[0]);
    }
  }, [companyResponse]);

  const stripe = useStripe();
  const elements = useElements();

  /*customer form fields*/
  const customerFields = {
    name: {
      component: TextInput,
      value: company ? company.name : '',
      validation: Yup.string().required('Please enter your name'),
    },
    email: {
      placeholder: 'email',
      type: 'email',
      component: TextInput,
      value: '',
      validation: Yup.string()
        .email('Invalid email')
        .required('Please provide your email'),
    },
    address: {
      component: TextInput,
      value: '',
      validation: Yup.string().required('Please enter your address'),
    },
    city: {
      component: TextInput,
      value: '',
      validation: Yup.string().required('Please enter city'),
    },
    state: {
      component: TextInput,
      value: '',
      validation: Yup.string().required('Please enter state'),
    },
    zip: {
      component: TextInput,
      value: '',
      validation: Yup.string().required('Please enter zip'),
    },
  };

  /*debit/credit card options used to set the orientation*/
  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#fff',
        '::placeholder': {
          color: '#87bbfd',
        },
      },
      invalid: {
        color: '#FFC7EE',
        iconColor: '#FFC7EE',
      },
    },
    hidePostalCode: true,
  };

  const handleFormSubmit = async (billing) => {
    if (!stripe || !elements) {
      return;
    }

    setError('');

    //blling customer detail
    const billingDetails = {
      name: billing.name,
      email: billing.email,
      address: {
        line1: billing.address,
        city: billing.city,
        state: billing.state,
        postal_code: billing.zip,
      },
    };

    setProcessingTo(true);

    const cardElement = elements.getElement(CardElement);

    //creating the payment method using stripe API
    const paymentMethodReq = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: billingDetails,
    });

    //calling the backend API to generate payment intent
    const { data: clientSecret } = await axios.post(`${EXPRESS_SERVER_URL}/createPaymentIntent`, {
      amount: Math.round(numCredits * (settingData ? settingData.credit_rate : 1)).toFixed(2) * 100,
      payment_method: paymentMethodReq.paymentMethod.id,
      company_name: company.name,
      credits: numCredits,
    });

    //confirming the payment using the client secret returned by the payment intent
    const confirmCardPayment = await stripe.confirmCardPayment(clientSecret, {
      payment_method: paymentMethodReq.paymentMethod.id,
    });

    //updating the payment into the database along with the data returned by the stripe
    if (confirmCardPayment.error) {
      //setting the errors here
      setError(confirmCardPayment.error.message);
      setProcessingTo(false);
    } else {
      const dateString = Moment(date).format('YYYY-MM-DD');
      const paymentHistory = {
        credits: numCredits,
        date: dateString,
        type: 1,
        company_id: company.id,
        remainingCredit: parseInt(numCredits) + parseInt(company.remainingCredit),
        totalCredit: parseInt(numCredits) + parseInt(company.totalCredit),
        credit_amount: parseInt(numCredits) * (settingData ? settingData.credit_rate : 1),
      };
      createPaymentHistory({
        variables: paymentHistory,
      });
    }
  };

  /*mutation to create new payment history and update the credits in company*/
  const [createPaymentHistory, { data: paymentResponseData }] = useMutation(CREATE_PAYMENT_HISTORY);

  useEffect(() => {
    if (paymentResponseData) {
      setModal({
        data: {
          purchased_credit: paymentResponseData.insert_payment_history.returning[0].credits,
        },
        open: true,
      });
      setProcessingTo(false);
    }
  }, [paymentResponseData]);

  return (
    <div>
      <Form fields={customerFields} onSubmit={handleFormSubmit}>
        {error && <div style={{ color: 'red', fontWeight: 'bold', marginTop: 20 }}>{error ? error : ''}</div>}
        <Row>
          <CardElementContainer>
            <CardElement options={cardElementOptions} />
          </CardElementContainer>
        </Row>
        <img src={StripeSecure} style={{ marginTop: '-20' }} />
        <Row>
          <SubmitButton type="submit" disabled={isProcessing}>
            {isProcessing ? 'Processing...' : `Pay $${(Math.round(numCredits * (settingData ? settingData.credit_rate : 1) * 100) / 100).toFixed(2)}`}
          </SubmitButton>
        </Row>
        <div>
          Email{' '}
          <a style={{ color: 'blue' }} target="_blank" href="mailto:support@getcherrypicker.com?subject=To Purchase More Credits">
            support@getcherrypicker.com
          </a>{' '}
          to purchase credits through alternate methods
        </div>
      </Form>
      <PaymentSuccessModal state={[modal, setModal]} />
    </div>
  );
}

export default function Checkout() {
  const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK);

  const [numCredits, setNumCredits] = useState(1);
  const [settingData, setSettingData] = useState();

  //getting cost per credit from settings
  const { data: setting_data } = useQuery(SETTINGS);
  useEffect(() => {
    if (setting_data) {
      setSettingData(setting_data.settings[0]);
    }
  }, [setting_data]);

  /*add/remove the credits from plus/minus button*/
  const addCredit = () => setNumCredits((num) => Math.min(10000, num + 1));
  const remCredit = () => setNumCredits((num) => Math.max(1, num - 1));
  const handleSetNumCredit = (credit) => {
    if(credit < 1){
      setNumCredits(1);
    } else {
      setNumCredits(() => Math.min(10000, credit))
    }
  }
  //subscription to get the payment history
  const { data: payments, loading } = useSubscription(PAYMENT_HISTORY, {
    variables: {
      admin_id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
    },
  });
  return (
    <div className="checkout-js w-full h-full pb-lg">
      <div className="flex pb-sm pt-md">
        <div className="cursor-pointer text-darkgray flex items-center capitalize hover:underline" style={{ fontSize: 12 }} onClick={() => navigate('/dashboard/find-candidate')}>
          <Arrow color="darkgray" style={{ height: 15, marginRight: 10 }} />
          {'Dashboard'}
        </div>
      </div>
      <div className="flex flex-col lg:flex-row">
        <div className="flex flex-col w-full" style={{ maxWidth: 530 }}>
          <div className="flex py-sm mb-sm justify-between items-center">
            <div className="text-darkblue font-medium" style={{ fontSize: 21 }}>
              Checkout
            </div>
          </div>
          <div className="bg-white p-lg rounded  flex flex-col shadow" style={{ minHeight: 370, marginTop: 10 }}>
            <Controls>
              <div>
                <b>Rate: </b>
                {'$' + (settingData ? settingData.credit_rate : 1)}/credit
              </div>
            </Controls>
            <Controls>
              <CreditQuantity onAdd={addCredit} onChangeCredit={handleSetNumCredit} onRemove={remCredit} quantity={numCredits} />
            </Controls>
            <Elements stripe={stripePromise}>
              <CheckoutForm numCredits={numCredits} settingData={settingData} />
            </Elements>
          </div>
        </div>
        <div className="flex flex-col flex-1 lg:ml-lg w-full lg:w-auto">
          <div className="flex py-sm mb-sm items-center justify-between">
            <div className="text-darkblue font-medium" style={{ fontSize: 21 }}>
              History
            </div>
            <div style={{ height: 40 }} />
          </div>
          <div className="bg-white rounded shadow flex-1" style={{ minHeight: 530, maxHeight: 'calc(100vh - 210px)', overflow: 'auto' }}>
            {payments && payments.payment_history.length > 0 ? (
              payments.payment_history.map((payment) => (
                <div style={{ padding: 30, paddingBottom: 0 }}>
                  <div className="flex border-b" style={{ paddingBottom: 20 }}>
                    <div
                      className="flex flex-col"
                      style={{
                        paddingLeft: 15,
                        flexGrow: 1,
                        minWidth: 225,
                        maxWidth: 225,
                      }}
                    >
                      <div
                        className="font-medium text-darkgray cursor-pointer hover:underline"
                        style={{
                          fontSize: 16,
                          whiteSpace: 'nowrap',
                          paddingRight: 10,
                        }}
                      >
                        {payment.type == 1 ? 'Purchased' : payment.type == 3 ? 'Admin added' : payment.type == 4 ? 'Admin removed' : 'Used'} {payment.credits}{' '}
                        {payment.credits > 1 ? 'credits ' : 'credit '} 
                        {/* {payment.type == 2 ? 'for ' + (payment.candidate && payment.candidate.name ? payment.candidate.name : '') : ''}{' '} */}
                        on {Moment(payment.date).format('MM/DD/YYYY')}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="w-full h-full flex text-sm items-center py-xl lg:py-0 text-center justify-center text-darkgray font-medium">No history available</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
