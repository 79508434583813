import React from 'react';
import CandidateDashboard from '../candidate-dashboard';
import firebase from 'firebase/app';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { prop } from 'ramda';


const GET_COMPANY_ID = gql`
  query getCompanyID($admin_id: String) {
    company(where: { adminID: { _eq: $admin_id } }) {
      id
      remainingCredit
      subscription_type
    }
  }
`;

const AgencyCandidates = (props) => {
  const adminID = firebase.auth().currentUser ? firebase.auth().currentUser.uid : 'not-logged-in'
  return <CandidateDashboard
    ROLE={props.ROLE}
    filterBySearch={false}
    context="admin-dashboard"
    searchPlaceholder="Job title"
    BASE_FILTER={`where: { is_enriched: { _eq: true }, recruiter: {company: {adminID: {_eq: "${adminID}"}}}}`}
    canSort={true}
    // ref={dashboardRef}
    showSavedCandidates={true}
    includeSalaryMetrics={true}
  />
}

export default AgencyCandidates;
