import React, { useEffect, useState } from "react";
import CreateCompanyForm from "../../forms/company/CreateCompanyForm";
import CreateAgencyForm from "../../forms/agency-form/CreateAgencyForm";

export default function EditCompany({ location }) {

  return (
    <CreateAgencyForm
      agency={location.state.agency}
    />
  );
}
