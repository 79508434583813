import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { TabTitle } from '../../components/common';
import { EXPRESS_SERVER_URL } from '../../config';
import axios from 'axios';
import firebase from 'firebase/app';
import Loader from 'react-loader-spinner';
import { navigate } from 'gatsby';
import { ToastContainer, toast } from 'material-react-toastify';

export default function LoxoCandidateBulkImport() {
    const [importing, setImporting] = useState(false);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState(new Date());
    const handleStartDateChange = (date) => {
        setStartDate(date);
    }

    const handleEndDateChange = (date) => {
        setEndDate(date);
    }

    const uploadAllCandidate = async () => {
        if (!startDate || !endDate) {
            toast.warning('Start and End Date Required');
            return;
        }
        setImporting(true);
        try {
            const recruiter_id = firebase.auth().currentUser && firebase.auth().currentUser.uid

            const { data: importerData } = await axios.post(`${EXPRESS_SERVER_URL}/checkImporter`, {
                recruiter_id,
                account_type: 'loxo',
            });
            try {
                const res = await axios.post(`${EXPRESS_SERVER_URL}/import-bulk-candidate`, {
                    "recruiter_id": recruiter_id,
                    "startDate": moment(startDate).format('YYYY-MM-DD'),
                    "endDate": moment(endDate).format('YYYY-MM-DD')
                }, {
                    headers: {
                        Accept: 'application/json',
                        Authorization: importerData && importerData[0] && importerData[0].authorization ? importerData[0].authorization : null,
                    },
                })
                if (res?.data?.status === 'success') {
                    toast.success('Bulk Candidate Imported');
                }
            } catch (error) {
                toast.error('Error In Bulk Import');
            }
        } catch (error) {
            toast.error('Error In Bulk Import');
        } finally {
            setImporting(false);
            setStartDate('');
            setEndDate(new Date());
        }
    }
    return <div className={`flex-1 candidates-container candidate-blank-dash`}>
        <div>
            <div className="relative flex items-center" style={{ marginTop: 20, zIndex: 200 }}>
                <TabTitle>Import Bulk candidates</TabTitle>
                <button onClick={() => navigate('/candidate-imports/loxo')} style={{ color: 'color: #303132' }} class="bg-transparent hover:bg-blue-500 text-blue-500 font-semibold hover:text-red py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                    Back
                </button>
            </div>
            <div className={`mt-16 flex justify-center items-center gap-4`}>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => handleStartDateChange(date)}
                    maxDate={moment(new Date()).toDate()}
                    class="SearchTermsFilter-js pl-1 bg-white w-full shadow rounded p-sm text-xs placeholder-primary font-normal"
                    placeholderText={'Start date'}
                    style={{ width: 300 }}
                />
                <DatePicker
                    selected={endDate}
                    onChange={(date) => handleEndDateChange(date)}
                    maxDate={moment(new Date()).toDate()}
                    class="SearchTermsFilter-js pl-1 bg-white w-full shadow rounded p-sm text-xs placeholder-primary font-normal"
                    placeholderText={'End date'}
                    style={{ width: 300 }}
                />
                <a
                    onClick={() => uploadAllCandidate()}
                    className={`bg-red text-white font-medium rounded flex items-center justify-center`}
                    style={{ fontSize: 14, height: 40, width: 200, marginRight: 10, cursor: 'pointer' }}
                >
                    {importing ? <Loader type="TailSpin" className="flex justify-center" color="#fff" height={20} width={20} /> : 'Import Bulk Candidates'}
                </a>
            </div>
        </div>
        <ToastContainer position="bottom-center" autoClose={3000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

    </div >
}