import React, { useState, useEffect, useContext } from "react";
import Loader from "react-loader-spinner";
import SelectDropdown from "../../../forms/fields/SelectDropdown";
import RecruiterSelect from "../../../forms/fields/RecruiterSelect";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { UserRoleContext } from "../../../context";

const ASSIGN_RECRUITER = gql`
  mutation assignRecruiter($candidateID: String, $recruiterID: String) {
    update_candidate(
      where: { id: { _eq: $candidateID } }
      _set: { recruiter_id: $recruiterID }
    ) {
      affected_rows
    }
  }
`;

export default function MatchCandidate({ candidateID, triggerState }) {
  const [userRole] = useContext(UserRoleContext);
  const [open, toggleOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState();
  const [matchWithRecruiter, { data }] = useMutation(ASSIGN_RECRUITER);

  function assignRecruiter() {
    if (inputValue) {
      setLoading(true);
      matchWithRecruiter({
        variables: {
          candidateID,
          recruiterID: inputValue.id,
        },
        refetchQueries: ["getCandidate"],
      });
    }
  }

  useEffect(() => {
    if (data) {
      toggleOpen(false);
      setLoading(false);
    }
  }, [data]);

  return (
    <div className="flex">
      <button
        onClick={() => toggleOpen(true)}
        className={userRole === 'agency' ? "mark-dander-btn" : "danger-button"}
        style={{ fontSize: 14 }}>
        Match with new recruiter
      </button>
      <div
        onClick={() => toggleOpen(false)}
        className={`fixed inset-0 ${open ? "flex" : "hidden"
          } items-center justify-center`}
        style={{ backgroundColor: "rgba(34, 46, 66, 0.9)", zIndex: 20000 }}>
        <div
          onClick={e => e.stopPropagation()}
          className="bg-white p-lg rounded shadow flex flex-col">
          <div
            className="text-darkblue font-medium border-b flex justify-between pb-sm"
            style={{ width: 380 }}>
            Match with new recruiter
            <div
              onClick={() => toggleOpen(false)}
              className="text-darkgray hover:text-darkblue cursor-pointer">
              x
            </div>
          </div>
          <div className="text-darkblue text-sm text-center py-lg">
            <RecruiterSelect value={inputValue} update={setInputValue} />
          </div>
          <div className="flex justify-center pt-lg">
            <button
              onClick={() => toggleOpen(false)}
              type="button"
              className="rounded border mr-sm font-medium text-sm bg-lightgray text-darkgray"
              style={{ width: 137, height: 55, fontSize: 14 }}>
              Cancel
            </button>
            <button
              onClick={assignRecruiter}
              className="rounded bg-red ml-md font-medium flex justify-center items-center text-sm text-white"
              style={{
                width: 180,
                height: 55,
                fontSize: 14,
                boxShadow: "0 14px 10px 0 rgba(255, 0, 0, 0.18)",
              }}>
              {loading ? (
                <Loader
                  type="TailSpin"
                  color="#FFFFFF"
                  height={20}
                  width={20}
                />
              ) : (
                "Save changes"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
