import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import React, { useEffect, useState } from 'react';

const DELETE_COMPANY_REQUEST = gql`
  mutation removeCompanyRequest($candidate_id: String, $company_id: uuid) {
    delete_company_request(where: { candidate_id: { _eq: $candidate_id }, company_id: { _eq: $company_id } }) {
      affected_rows
      returning {
        candidate_id
        company_id
        status
        candidate {
          status
        }
      }
    }
  }
`;

const RescindButton = ({ candidateID, companyID, status: originalStatus, updateStatus }) => {
  const [status] = useState(`${originalStatus}`);

  const [deleteCompanyRequest, { data }] = useMutation(DELETE_COMPANY_REQUEST);

  const cancelRequest = () => {
    deleteCompanyRequest({ variables: { candidate_id: candidateID, company_id: companyID } });
  };

  useEffect(() => {
    const _company_request = data?.delete_company_request?.returning?.length && data?.delete_company_request?.returning[0];
    if (_company_request && updateStatus) {
      updateStatus(_company_request?.candidate?.status, _company_request)
    }
  }, [data]);

  const options = {
    request: { bg: '#FFFFFF', color: 'rgb(86, 208, 0)', label: 'Request an Introduction' },
    requested: { bg: '#FFD2D2', color: '#D8000C', label: 'Cancel request' },
  };

  // This was to resolve a crashing bug if the status wasn't present in the options. It may have introduced a new bug?
  const selectedStatus = options[status] || options.requested;

  return (
    <button
      onClick={() => cancelRequest()}
      style={{
        cursor: 'pointer',
        color: selectedStatus.color,
        borderColor: 'rgb(86, 208, 0)',
        backgroundColor: selectedStatus.bg,
        border: '1px solid',
        borderRadius: 5,
        height: 40,
        marginBottom: 2,
        paddingLeft: 10,
        paddingRight: 10,
        width: 130,
      }}
      className={`text-xs flex justify-center items-center rounded`}
    >
      {`${selectedStatus.label}`}
    </button>
  );
};

export default RescindButton;
