// Firebase
import 'firebase/auth';
import 'firebase/firestore';
import firebase from 'firebase/app';
// GraphQL Hasura
import { ApolloContext } from '../../../context/Apollo';
import { gql } from 'apollo-boost';
// React
import React, { useContext, useEffect, useState, useCallback, useRef, useImperativeHandle, forwardRef } from 'react';
// Custom Components
import CandidatesTable from '../../../components/common/CandidatesTable';
import CherryPagination from '../../../components/common/Pagination/Pagination';
import DeleteUsersButton from '../../../components/common/DeleteUsersButton';
import AddToProject from './add-to-project';
import Filter from '../../../components/Filter/Filter';
import FilterModal from './filter-modal';
import DatePicker from 'react-datepicker';
import moment from 'moment';
// Libraries
import _ from 'lodash';
// Helpers
import { DEFAULT_PER_PAGE, QUERY_FILTERS, QUERY_PARAMS, REQUEST_STATUSES as STATUSES, USER_ROLES } from '../../../utils/constants';
import { filterCandidates } from '../../../utils/filter-candidates.helper';
import { getCandidateQuery } from '../../../queries/candidate';
// Context
import { GlobalFilterContext } from '../../../components/layout/layout';
// Asserts
import DollarSign from '../../../images/dollarsign.svg';
// Styles
import './candidate-dashboard.css';
import { isEmpty } from '../../../utils/candidate-helper';
import { UserRoleContext } from '../../../context';

/**
 * 
 * @param {Object} props React Props
 * @param {String} props.BASE_FILTER Candidate Base Filter
 * @param {Boolean} [props.canSort] Apply Candidate filters by Search and Reset buttons
 * @param {String} props.ROLE User Role
 * @param {String} props.context Table Type
 * @param {Boolean} [props.showFavoriteCandidates] Show interested candidates
 * @param {Boolean} [props.showInterestedCandidates] Show interested candidates
 * @param {Boolean} [props.showSavedCandidates] Show Candidates meta details/saved candidates
 * @param {Boolean} [props.showRequestedCandidates] Show Candidates requested candidates
 * @param {Boolean} [props.searchPlaceholder] Search input placeholder
 * @param {Boolean} [props.filterBySearch] Apply Candidate filters by Search and Reset buttons
 * @param {Boolean} [props.includeSalaryMetrics] Display/Fetch salary metrics
 * @returns {React.FunctionComponent}
 */
const CandidateDashboard = forwardRef((props, ref) => {
  // Pagination
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [total, setTotal] = useState(0);
  const [admin_id] = useState(firebase.auth().currentUser && firebase.auth().currentUser.uid);

  // Candidates query
  const BASE_FILTER = props.BASE_FILTER;
  const { apolloClient } = useContext(ApolloContext);
  const [queryFilters, setQueryFilter] = useState({ ...QUERY_FILTERS });
  const [query, setQuery] = useState(getCandidateQuery(BASE_FILTER, !!props.showSavedCandidates, !!props.includeSalaryMetrics));
  const [data, setData] = useState(null);
  const [salaryMetrics, setSalaryMetrics] = useState({
    minSalary: 0,
    avgSalary: 0,
    maxSalary: 0
  });
  const [loading, setLoading] = useState(false);
  const [queryHit, setQueryHit] = useState(false);

  // Filters
  const [candidates, setCandidates] = useState([]);
  const { globalFilter, setGlobalFilter } = useContext(GlobalFilterContext);
  const [filter, setFilter] = useState({});
  const [sort, setSort] = useState({});
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: new Date() });
  // Actions
  const [selectedCandidates, setSelectedCandidates] = useState([]);

  // Modals
  const [openModal, setOpenModal] = useState(false);
  const [validationError, setValidationError] = useState('');

  const [userRole] = useContext(UserRoleContext);

  // Refs
  const tableRef = useRef();
  useImperativeHandle(ref, () => ({
    getQuery: () => ({
      variables: {
        admin_id,
        order_by: sort,
        with_meta: !!props.showSavedCandidates,
        ...queryFilters,
      },
      query
    })
  }));

  /**
   * Fetch candidates
   * @param {Object} query_filters Candidate filters
   * @param {Number} perPage Page count
   * @param {Number} offset Page offset
   * @param {Object} order_by Candidate Sort
   */
  const fetchCandidates = async (
    query_filters = queryFilters,
    _perPage = perPage,
    offset = (page * perPage),
    order_by = sort,
    _filter = filter
  ) => {

    const variables = {
      admin_id,
      perPage: _perPage,
      offset: offset,
      order_by,
      with_meta: ['agency', 'recruiter'].includes(userRole) ? true : !!props.showSavedCandidates,
      jobTitleFilter: [{
        tenure: {
          current: true
        }
      }],
      ...query_filters
    };
    if(props?.context === 'find-candidates' && userRole === 'company'){
      variables['order_by'] = [order_by, {is_enriched: 'desc_nulls_last'}, {device_token: 'asc_nulls_last'}];
    }
    if(props.showSavedCandidates && !Object.keys(order_by).length){
      variables['order_by'] = {company_requests_aggregate: {max: {unlocked_date: 'desc'}}}
    }

    if(props.ROLE === 'Recruiter' && props.context === 'admin-dashboard' && !Object.keys(order_by).length){
      variables['order_by'] = {created_at: 'desc'}
    }
    
    setGlobalFilter({
      ...globalFilter,
      [props.context || 'default-context']: {
        variables,
        filter: _filter,
        page: offset / _perPage,
        perPage: _perPage,
        sort: order_by,
        total,
      }
    });

    setQueryHit(true);

    const response = await apolloClient.query({
      query: gql`${query}`,
      variables,
      fetchPolicy: 'network-only',
    });

    setData(response.data);
    setLoading(response.loading);
  }

  // Set current user config
  useEffect(() => {
    if (!props.filterBySearch && !isValidateContext) {
      fetchCandidates();
    }
  }, [admin_id]);

  useEffect(() => {
    if(props.showSavedCandidates && props.ROLE !== USER_ROLES.ADMIN){
      fetchCandidates();
    }
  }, [BASE_FILTER])

  // Set global context

  useEffect(() => {
    if (isValidateContext) {
      const opts = globalFilter[props.context];
      setTotal(opts.total);
      setPage(opts.page);
      setSort(opts.sort);
      setPerPage(opts.perPage);
      setFilter(opts.filter);
      // if (props.filterBySearch) {
      setTimeout(async () => {
        if (props.filterBySearch) {
          await updateCandidates(opts.filter, opts.perPage, opts.page * opts.perPage, opts.sort);
        }
      }, 500);
      // }
    }
  }, []);

  // Init Candidates
  useEffect(() => {
    if (data?.filter_candidates) {
      const _candidates = data?.filter_candidates?.map((aCandidates) => {
        const _candidate = { ...aCandidates };
        const _candidate_meta = data?.candidates_meta?.find((cm) => cm?.id === _candidate?.id);
        const _candidate_job_title = data?.job_titles?.find((cm) => cm?.id === _candidate?.id);
        if (_candidate_job_title && !props.showSavedCandidates) {
          const currentEmployment = aCandidates?.employment?.sort((a, b) => {
            const x = a.tenure.current;
            const y = b.tenure.current;
            return (x === y)? 0 : x? -1 : 1;
          })?.[0];
          const employment = {
            tenure: {
              current: true,
            },
            title: currentEmployment?.title || _candidate_job_title.employment,
            companyName: currentEmployment?.companyName || 'Company Name'
          };
          _candidate.employment = [employment];
          _candidate.name = aCandidates?.company_requests?.[0]?.candidate?.name || '';
          _candidate.profilePictureURL = aCandidates?.company_requests?.[0]?.candidate?.profilePictureURL || '';
        }
        // Patch meta details
        if (_candidate_meta) {
          _candidate.name = _candidate_meta?.name;
          _candidate.employment = _candidate_meta?.employment;
          _candidate.profilePictureURL = _candidate_meta?.profilePictureURL;
        }
        _candidate.isFavorite = aCandidates?.fav_candidates?.some((fc) => fc.candidate_id === aCandidates.id && fc?.company_firebase_id === admin_id);
        const candidate_status = aCandidates?.company_requests.find((cr) => cr?.company?.adminID === admin_id);
        return {
          candidate: _candidate,
          status: candidate_status?.status || '',
          isDirectRequest: !!aCandidates?.company_requests?.isDirectRequest
        };
      });
      setCandidates(_candidates);
      setSalaryMetrics({
        minSalary: data?.salary_metrics?.aggregate?.min?.salaryMin || 0,
        avgSalary: Math.ceil(data?.salary_metrics?.aggregate?.avg?.salaryMin || 0),
        maxSalary: data?.salary_metrics?.aggregate?.max?.salaryMin || 0
      });
      setTotal(data?.filter_candidates_aggregate?.aggregate?.count || 0);
    }
  }, [data?.filter_candidates]);

  const onDateRangeChange = async (date) => {
    const rangeDate = { ...dateRange, [date['type']]: date['date'] };
    const { startDate, endDate } = rangeDate || {};
    const start_date = startDate !== null ? moment(startDate).format('YYYY-MM-DD') : null;
    const end_date = endDate ? moment(endDate).format('YYYY-MM-DD') : null;
    let BASE_FILTER;
    if (start_date || end_date) {
      if (start_date && end_date) {
        BASE_FILTER = `where: {company_requests: {_and: {status: {_in: ["${STATUSES.approved}", "${STATUSES.hired}"]}, company: {adminID: {_eq: $admin_id}}, unlocked_date: {_lte: "${end_date}", _gte: "${start_date}"} }}}`
      }
      else if (start_date) {
        BASE_FILTER = `where: {company_requests: {_and: {status: {_in: ["${STATUSES.approved}", "${STATUSES.hired}"]}, company: {adminID: {_eq: $admin_id}}, unlocked_date: {_gte: "${start_date}"} }}}`
      } else {
        BASE_FILTER = `where: {company_requests: {_and: {status: {_in: ["${STATUSES.approved}", "${STATUSES.hired}"]}, company: {adminID: {_eq: $admin_id}}, unlocked_date: {_lte: "${end_date}"} }}}`
      }
    } else {
      BASE_FILTER = `where: {company_requests: {_and: {status: {_in: ["${STATUSES.approved}", "${STATUSES.hired}"]}, company: {adminID: {_eq: $admin_id}}, unlocked_date: {_lte: "${end_date}"} }}}`
    }
    props.onUnlockedDateChange(BASE_FILTER);
    setQuery(getCandidateQuery(BASE_FILTER, !!props.showSavedCandidates, !!props.includeSalaryMetrics));
    setDateRange(prevState => {
      return { ...prevState, [date['type']]: date['date'] };
    });
  };
  /**
   * Update/Rerender candidate upon changes
   * @desc Filter candidate based on context
   * @param {*} _filter Candidate filters
   * @param {Number} offset Page offset
   * @param {Object} _sort Sort key: values
   */
  const updateCandidates = async (_filter = filter, _prePage = perPage, offset = (page * perPage), _sort = sort) => {
    const filters = await filterCandidates(_filter, props.showSavedCandidates);
    const qfilters = { ...QUERY_FILTERS, ...filters };
    setQueryFilter(qfilters);
    setQuery(getCandidateQuery(BASE_FILTER, !!props.showSavedCandidates, !!props.includeSalaryMetrics));
    await fetchCandidates(qfilters, _prePage, offset, _sort, _filter);
  };

  // Debounce changes upon filter change, useCallback to improve performance
  const debounceUpdates = useCallback(
    _.debounce(async (_filter, _perPage = perPage, offset = (page * perPage), _sort) => {
      await updateCandidates(_filter, _perPage, offset, _sort);
    }, 1000),
    []
  );

  // Reset changes upon filter change, !Avoid resetting/updating the data if search by external button is used.
  useEffect(() => {
    if (!props.filterBySearch) {
      setLoading(true);
      setPage(0);
      debounceUpdates(filter, perPage, (page * perPage), sort);
    } else {
      filterCandidates(filter, props.showSavedCandidates).then((filters) => {
        setQueryFilter({ ...QUERY_FILTERS, ...filters });
      });
    }
  }, [filter]);

  /**
   * Handle pagination change event
   * @param {Object} event pagination change event
   * @param {Number} event.page page
   * @param {Number} event.perPage perPage
   * @param {Number} event.offset offset
   */
  async function handlePaginationChange(event) {
    setLoading(true);
    const offset = event.page * event.perPage;
    setPage(event.page);
    setPerPage(event.perPage);
    await updateCandidates(filter, event.perPage, offset);
  }

  /**
   * Filter candidates list based on candidate favorite flag
   * @param {Object} candidate Candidate
   * @param {String} candidate.id Candidate id
   * @param {Boolean} candidate.isFavorite Candidate favorite flag
   */
  const handleCandidateUpdates = (candidate) => {
    // TODO: Check if we want to make API call to refresh data, cuz data is spliced.
    const favoriteCandidates = candidates && Array.isArray(candidates) ? _.cloneDeep(candidates) : [];
    const fCandidateIndex = favoriteCandidates.findIndex((fCandidate) => fCandidate.candidate.id === candidate.id && !candidate.isFavorite);
    if (fCandidateIndex > -1) {
      favoriteCandidates.splice(fCandidateIndex, 1);
      setCandidates(favoriteCandidates);
    }
  };

  const handleFavStatusChange = (candidate) => {
    const favoriteCandidates = candidates && Array.isArray(candidates) ? _.cloneDeep(candidates) : [];
    if (props.showFavoriteCandidates) {
      const fCandidateIndex = favoriteCandidates.filter((fCandidate) => fCandidate.candidate.id !== candidate.id);
      setCandidates(fCandidateIndex)
    } else {
      const fCandidateIndex = favoriteCandidates.map((fCandidate) => {
        if (fCandidate.candidate.id === candidate.id) {
          fCandidate.candidate.isFavorite = candidate.isFavorite;
          return fCandidate
        }
        return fCandidate
      });
      setCandidates(fCandidateIndex)
    }
  }

  const handleCandidateStatusChange = (candidate, status) => {
    const candidateAction = candidates && Array.isArray(candidates) ? _.cloneDeep(candidates) : [];
    const fCandidate = candidateAction.map((fCandidate) => {
      if (fCandidate.candidate.id === candidate.id) {
        fCandidate.status = status;
        return fCandidate
      }
      return fCandidate
    });
    setCandidates(fCandidate)
  }

  const handleCandidateCancelStatus = (candidate) => {
    const aCandidate = candidates && Array.isArray(candidates) ? _.cloneDeep(candidates) : [];
    const fCandidateIndex = aCandidate.filter((fCandidate) => fCandidate.candidate.id !== candidate.id);
    setCandidates(fCandidateIndex);
  };

  const excludeApprovedCandidate = (id) => {
    const candidateAction = candidates && Array.isArray(candidates) ? _.cloneDeep(candidates) : [];
    const excludeApprovedCandidate = candidateAction?.filter(fCandidate => fCandidate.candidate.id !== id);
    setCandidates(excludeApprovedCandidate);
  }
  /**
   * Order/Sort Candidates
   * @param {String} key Column key
   * @param {String} preOrder Column Order
   */
  const sortBy = async (key, preOrder) => {
    let candiadteSort = { ...sort };
    if (!(key in candiadteSort)) {
      candiadteSort = {};
    }
    if (preOrder === 'desc' && candiadteSort[key] && candiadteSort[key] === 'desc') {
      delete candiadteSort[key];
    }
    if (preOrder === 'asc') {
      if(key === 'company_requests_aggregate'){
        candiadteSort[key] = {max: {unlocked_date: 'desc'}};
      } else {
        candiadteSort[key] = 'desc';
      }
    } else if (preOrder === 'desc') {
      if(key === 'company_requests_aggregate'){
        candiadteSort[key] = {max: {unlocked_date: 'asc'}};
      } else {
        candiadteSort[key] = 'asc';
      }
    }
    if (!preOrder) {
      if(key === 'company_requests_aggregate'){
        candiadteSort[key] = {max: {unlocked_date: 'asc'}};
      } else {
      candiadteSort[key] = 'asc';
      }
    }
    setSort({ ...candiadteSort });
    await fetchCandidates(undefined, undefined, undefined, candiadteSort);
  }

  /**
   * Apply filter based on candidates
   * @param {Object} _filter Candidate filter
   * @returns {Boolean} reset validate
   */
  const validateFilter = (_filter) => {
    let message = (<span>Please select at least one attribute or provide a job title to begin a candidate search query.</span>);
    // Check if filter is empty
    if (_.isEmpty(_filter)) {
      setValidationError(message);
      return false;
    }
    // Check if location is selected for radius
    if (Object.keys(_filter).includes(QUERY_PARAMS.LOCATION) &&
      _filter?.location?.city &&
      !Object.keys(_filter).includes(QUERY_PARAMS.LOCATION)) {
      setValidationError(<span><b>Please note:</b>To edit the Radius field, kindly specify the desired Location</span>);
      return false;
    }
    // Check if filter includes search and attribute keys/opts
    if (userRole !== 'recruiter' && !Object.keys(_filter).some((f) => [QUERY_PARAMS.SEARCH, QUERY_PARAMS.ATTRIBUTES].includes(f))) {
      setValidationError(message);
      return false;
    }
    /*
    // Check if location perference is selected with location
    if (Object.keys(_filter).includes(QUERY_PARAMS.LOCATION_PREFERENCES) &&
      _filter?.locationPreferences?.willingToRelocate &&
      !Object.keys(_filter).includes(QUERY_PARAMS.LOCATION)) {
      setValidationError(<span><b>Please note:</b> A location is required when searching for candidates who are willing to relocate.</span>);
      return false;
    }
    */
    return true;
  }

  /**
   * Reset candidate page, perPage & candidates
   */
  const reset = () => {
    setLoading(false);
    setCandidates([]);
    setTotal(0);
    setPage(0);
    setQueryHit(false);
  }

  /**
   * Filter Candidates on click of search button
   * @param {Object} f filters
   * @param {Boolean} revert Revert/Reset filters
   */
  const handleFilterChange = async (f, revert) => {
    setLoading(true);
    const valid = validateFilter(filter || f);
    if (!valid && !revert) {
      setOpenModal(true);
      reset();
      return;
    }
    if (_.isEmpty(filter || f)) {
      reset();
    } else {
      await fetchCandidates();
    }
    setPage(0);
  }

  /**
   * Sort candidate on click
   * @param {String} key Column key
   * @param {String} preOrder Column order
   */
  const handleSort = async (key, preOrder) => {
    const valid = validateFilter(filter);
    if (valid || !props.filterBySearch) {
      setLoading(true);
      sortBy(key, preOrder)
    }
  }

  /**
   * Format salary
   * @param {Number} salary candidate salary
   * @returns {String} Formatted Salary
   */
  const formatSalary = (salary) => salary?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0;

  /**
   * Salary Insights JSX
   * @returns {React.FunctionComponent} Salary Insight JSX
   */
  const SalaryInsights = () => (
    <div className='salary-insight-container'>
      {/* Min Salary */}
      <div className='salary-min salary-wrapper'>
        <label>Min Salary: </label>
        <img alt="$" src={DollarSign} />
        <span id='min-salary'>{formatSalary(salaryMetrics?.minSalary || 0)}</span>
      </div>
      {/* Avg Salary */}
      <div className='salary-avg salary-wrapper'>
        <label>Avg Salary: </label>
        <img alt="$" src={DollarSign} />
        <span id='avg-salary'>{formatSalary(salaryMetrics?.avgSalary || 0)}</span>
      </div>
      {/* Max Salary */}
      <div className='salary-max salary-wrapper'>
        <label>Max Salary: </label>
        <img alt="$" src={DollarSign} />
        <span id='max-salary'>{formatSalary(salaryMetrics?.maxSalary || 0)}</span>
      </div>
    </div>
  );

  const Actions = () => (
    props.ROLE !== USER_ROLES.ADMIN ? (
      <div className="flex justify-end" style={{ marginBottom: 10 }}>
        <AddToProject selectedCandidates={selectedCandidates} clearCandidates={() => setSelectedCandidates([])} />
      </div>
    ) : (
      <DeleteUsersButton selectedCandidates={selectedCandidates} afterDelete={() => fetchCandidates()} />
    )
  )

  const isValidateContext = props.context && !_.isEmpty(globalFilter) && globalFilter[props.context] && !_.isEmpty(globalFilter[props.context]);

  return (
    <div className="flex flex-col w-full flex-1 h-full">
      {/* Candidate filters */}
      <Filter
        type="candidate"
        isCompany={props.ROLE !== USER_ROLES.ADMIN}
        filterState={[filter, setFilter]}
        searchPlaceholder={props.searchPlaceholder}
        candidateCount={total}
        tableContext={isValidateContext && props.context}
        onEnter={handleFilterChange}
        onChange={props.filterBySearch && handleFilterChange} />
      {/* Admin and Recuriter Actions */}
      {
        [USER_ROLES.ADMIN, USER_ROLES.AGENCY, USER_ROLES.RECRUITER].includes(props.ROLE) ? <Actions /> : ''
      }
      {/* Salary Insights */}
      {
        candidates?.length && !!props.includeSalaryMetrics ? (
          <SalaryInsights />
        ) : null
      }
      {props.showSavedCandidates && userRole === 'company' && <div>
        <div className="h-full flex flex-col" style={{ width: '100%', height: 60, display: '-webkit-inline-box' }}>
          <div style={{ marginRight: 20 }}>
            <span>Start Date: </span>
            <DatePicker selected={dateRange.startDate} name= 'startDate' onChange={(e) => onDateRangeChange({type: 'startDate', date: e})} maxDate={moment(new Date()).toDate()} />
          </div>
          <div>
            <span>End Date: </span>
            <DatePicker selected={dateRange.endDate} name='endDate' onChange={(e) => onDateRangeChange({type: 'endDate', date: e})} minDate={dateRange.startDate} maxDate={moment(new Date()).toDate()} />
          </div>
        </div>
      </div>}
      {/* Candidate Table */}
      <CandidatesTable
        ref={tableRef}
        candidateCount={total}
        candidates={candidates}
        selectedCandidates={selectedCandidates}
        isLoading={loading}
        filter={filter}
        sort={sort}
        ROLE={props.ROLE}
        showMessage={!queryHit}
        colSpan={props.ROLE === USER_ROLES.ADMIN || !props.filterBySearch ? 9 : 7}
        isInterestedCandidates={props.showInterestedCandidates}
        isFavoriteCandidates={props.showFavoriteCandidates}
        isSavedCandidates={props.showSavedCandidates}
        isRequestedCandidates={props.showRequestedCandidates}
        filterBySearch={props.filterBySearch}
        onSortChange={handleSort}
        onCandidateSelect={setSelectedCandidates}
        onChange={props.showFavoriteCandidates && handleCandidateUpdates}
        handleFavStatusChange={handleFavStatusChange}
        handleCandidateStatusChange={handleCandidateStatusChange}
        excludeApprovedCandidate={excludeApprovedCandidate}
        handleCandidateCancelStatus={props.showRequestedCandidates && handleCandidateCancelStatus}
      />
      {/* Pagination */}
      {total && total > DEFAULT_PER_PAGE ? (
        <div className="candidate-pagination">
          <CherryPagination page={page} perPage={perPage} total={total} onChange={handlePaginationChange} />
        </div>
      ) : null}
      {/* Filter Validator Modal */}
      <FilterModal open={openModal} onChange={(e) => setOpenModal(e)}>
        {validationError}
      </FilterModal>
    </div>
  );
});

export default CandidateDashboard;
