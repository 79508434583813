import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import favIcon from '../../images/favorite.png';
import unfavIcon from '../../images/unfavorite.png'
import React, { useEffect } from 'react';
import firebase from 'firebase';
import { useState } from 'react';
import _ from 'lodash';

const UPDATE_FAVORITE = gql`
  mutation insertFavCand($candidate_id: String, $company_id: String) {
    insert_favorite_candidates(objects: { candidate_id: $candidate_id, company_firebase_id: $company_id }) {
      affected_rows
      returning {
        candidate_id
        company_firebase_id
      }
    }
  }
`;

const DELETE_FAVORITE = gql`
  mutation deleteFavCand($candidate_id: String, $company_id: String) {
    delete_favorite_candidates(where: { candidate_id: { _eq: $candidate_id }, company_firebase_id: { _eq: $company_id } }) {
      affected_rows
      returning {
        candidate_id
        company_firebase_id
      }
    }
  }
`;

const REMOVE_COMPANY_REQUEST = gql`
  mutation deleteCompanyRequest($candidate_id: String, $company_id: uuid) {
    delete_company_request(where: { candidate_id: { _eq: $candidate_id }, company_id: { _eq: $company_id } }) {
      affected_rows
    }
  }
`;

export default function CandidateFav({ modal, handleFavStatusChange, handleOnFavStatusChange, onChange, candidate }) {
    const companyId = firebase.auth().currentUser && firebase.auth().currentUser.uid;

    const [favStatus, setFavStatus] = useState(candidate?.isFavorite || false);
    const [updateFavorite] = useMutation(UPDATE_FAVORITE);
    const [deleteFavorite] = useMutation(DELETE_FAVORITE);

    useEffect(() => {
        setFavStatus(candidate?.isFavorite);
    }, [candidate])

    const favUnfavCand = (isfav) => {
        if (!isfav) {
            const insertdata = {
                company_id: companyId,
                candidate_id: candidate.id,
            };
            updateFavorite({
                variables: insertdata,
            });
            setFavStatus(true);
        } else {
            const deletedata = {
                company_id: companyId,
                candidate_id: candidate.id,
            };
            deleteFavorite({
                variables: deletedata,
            });
            setFavStatus(false);
        }
        const updates = _.cloneDeep(candidate);
        // Temporary key to mark candidate favorite's
        updates.isFavorite = !isfav;
        // handleFavStatusChange(updates)
        modal && handleOnFavStatusChange(!isfav);
        if (onChange) {
            onChange(updates);
        } else if (!modal) {
            handleFavStatusChange(updates);
        }
    };

    return <div className='flex justify-center items-center pr-0'>
        {/* <div style={{ fontSize: '19px', paddingRight: '10px', color: 'green' }}>{modal && (favStatus ? 'Unfavourite this Candidate' : 'Favourite this Candidate')}</div> */}
        <div className="h-full flex justify-center items-center">
            <img alt="" src={favStatus ? favIcon : unfavIcon} onClick={() => favUnfavCand(favStatus)} style={{ width: 20, cursor: 'pointer', height: 20 }} className="mb-0" />
        </div>
    </div>
}