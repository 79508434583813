import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { DEFAULT_EMAIL_INVITE_BODY } from '../../../utils/constants';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import RichTextArea from '../../../forms/fields/RichTextArea';
import { TextInput } from '../../../forms/fields';
import { gql } from 'apollo-boost';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { EXPRESS_SERVER_URL } from '../../../config';
import { toast, ToastContainer } from 'material-react-toastify';

const INSERT_INVITATION_TEMPLATE = gql`
  mutation createInvitationTemplate($id: uuid, $recruiter_id: String, $body: String, $subject: String) {
    insert_recruiter_invitation_templates(objects: {id: $id, recruiter_id: $recruiter_id, body: $body, subject: $subject }) {
      affected_rows
      returning {
        recruiter_id
      }
    }
  }
`;

const UPDATE_INVITATION_TEMPLATE = gql`
  mutation updateCompany($id: uuid, $body: String, $subject: String) {
    update_recruiter_invitation_templates(where: { id: { _eq: $id } }, _set: { body: $body, subject: $subject }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

function RecruiterEmailTemplate({ currentUser, templateContent, setTemplateContent, updateCurrentUser }) {
    const [inviteEmailSubject, setInviteEmailSubject] = useState(templateContent?.subject || '');
    const [inviteEmailBody, setInviteEmailBody] = useState(templateContent?.body || '');
    const [showRichTextArea, setShowTextRichArea] = useState(true);
    const [currentTemplateId, setCurrentTemplateId] = useState(templateContent?.id || '');
    const [originalContent, setOriginalContent] = useState({
        body: '',
        subject: ''
    })

    const [createInvitationTemplate, { data: invitationTemplateResponse, loading: loadingRequest }] = useMutation(INSERT_INVITATION_TEMPLATE);
    const [updateInvitationTemplate, { loading: lg, data: submissionResponse }] = useMutation(UPDATE_INVITATION_TEMPLATE);

    useEffect(() => {
        if (currentUser?.invitationTemplate?.length) {
            const { id, body, subject } = currentUser?.invitationTemplate?.[0];
            setInviteEmailBody(templateContent?.body);
            setInviteEmailSubject(templateContent?.subject);
            setCurrentTemplateId(id);
            setOriginalContent({
                body: body,
                subject: subject
            })
        }
    }, [templateContent, currentUser, currentUser?.body]);

    const handleChangeCancel = () => {
        const { subject, body } = originalContent || {};
        setInviteEmailSubject(subject);
        setInviteEmailBody(body);
        setTemplateContent(prevState => {
            return { ...prevState, subject, body }
        });
        toast.success('Changes are reverted');
    }

    const setDefaultTemplate = async () => {
        setShowTextRichArea(false);
        if (currentUser?.invitationTemplate?.length) {
            try {
                await updateInvitationTemplate({ variables: { id: currentTemplateId, body: DEFAULT_EMAIL_INVITE_BODY, subject: '{{candidate_name}}, Inviting you to CherryPicker' } });
                setInviteEmailSubject('{{candidate_name}}, Inviting you to CherryPicker');
                setInviteEmailBody(DEFAULT_EMAIL_INVITE_BODY);
                setTemplateContent({ id: currentTemplateId, body: DEFAULT_EMAIL_INVITE_BODY, subject: '{{candidate_name}}, Inviting you to CherryPicker' })
                toast.success('Default template restored, please refresh the page.');
            } catch (error) {
                setShowTextRichArea(true)
                console.log({ error })
                toast.error('Something went wrong, please try after some time');
            }
        }
        setShowTextRichArea(true)
    }

    const handleSaveInviteTemplate = async () => {
        try {
            // return;
            if (currentUser?.invitationTemplate?.length) {
                const res = await updateInvitationTemplate({ variables: { id: currentTemplateId, body: inviteEmailBody, subject: inviteEmailSubject } });
                setTemplateContent({ id: currentTemplateId, body: inviteEmailBody, subject: inviteEmailSubject })
                toast.success('Template Updated')
            } else {
                const { data } = await axios.get(`${EXPRESS_SERVER_URL}/cp-uuid`);
                const res = await createInvitationTemplate({ variables: { id: data?.uuid, recruiter_id: currentUser?.id, subject: inviteEmailSubject, body: inviteEmailBody } });
                setTemplateContent({ id: data?.uuid, body: inviteEmailBody, subject: inviteEmailSubject })
                toast.success('Template Updated')
            }
        } catch (error) {
            console.log({ error })
            toast.error('Something went wrong, please try after some time');
        }
    }
    return (
        <div className="bg-white p-lg rounded flex flex-col" style={{ minHeight: 370 }}>
            <DialogTitle>
                <p style={{ fontFamily: 'montserrat', margin: 0 }}>Candidate Invitation Template</p>
            </DialogTitle>

            <DialogContent dividers>
                <p style={{ paddingBottom: 0, margin: 0, fontFamily: 'montserrat' }}>Subject:</p>
                <TextInput error={!inviteEmailSubject} value={inviteEmailSubject} update={(value) => setInviteEmailSubject(value)} />

                <br />

                <p style={{ paddingBottom: 0, margin: 0, fontFamily: 'montserrat' }}>Content:</p>

                {showRichTextArea ? <div className="w-full">
                    <RichTextArea value={inviteEmailBody} error={!inviteEmailBody} update={(value) => setInviteEmailBody(value)} height={400} />
                </div> : null}
            </DialogContent>

            <DialogActions>
                <div className='flex justify-between w-full'>
                    <button
                        className={`bg-red text-white font-medium rounded flex items-center justify-center`}
                        style={{ fontSize: 14, height: 40, width: 200, marginLeft: 10, fontFamily: 'montserrat' }}
                        onClick={setDefaultTemplate}
                    >
                        Restore Default Template
                    </button>
                    <div className='flex gap-1'>
                        <button
                            className={`bg-lightgray text-black font-medium rounded flex items-center justify-center`}
                            style={{ fontSize: 14, height: 40, width: 100, marginLeft: 10, fontFamily: 'montserrat' }}
                            onClick={handleChangeCancel}
                        >
                            Cancel
                        </button>

                        <button
                            className={`bg-red text-white font-medium rounded flex items-center justify-center`}
                            style={{ fontSize: 14, height: 40, width: 100, marginLeft: 10, fontFamily: 'montserrat' }}
                            onClick={handleSaveInviteTemplate}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </DialogActions>
            <ToastContainer style={{ width: 'fit-content' }} theme="dark" position="bottom-center" autoClose={3000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
    )
}

export default RecruiterEmailTemplate;