import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import { navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import UserRoleContext from '../../context/UserRole';
import EditIcon from '../../images/edit.svg';
import Logo from '../../images/header-logo.svg';
import CreditCost from '../settings/credit-cost';
import Integrations from '../settings/integrations/integrations';
import EmailNotifications from './email-notifications';
import EditProfile from './profile/edit-profile';
import ViewProfile from './profile/view-profile';
import ChangePassword from './security/change-password';
import FeeConfig from './fee-config/fee-config';
import './settings.css';
import Onboarding from './onboarding/onboarding';
import axios from 'axios';
import { EXPRESS_SERVER_URL } from '../../config';
import { useStripe } from '@stripe/react-stripe-js';
import { toast } from 'material-react-toastify';
import { useSnackbar } from 'react-simple-snackbar';
import TransactionDashboard from './transaction-dashboard.js/transaction-dashboard';
import RecruiterEmailTemplate from './recruiter-email-template/RecruiterEmailTemplate';
import { DEFAULT_EMAIL_INVITE_BODY } from '../../utils/constants';

const RECRUITER_PROFILE = gql`
  query getRecruiterProfile($recruiter_id: String) {
    recruiter(where: { id: { _eq: $recruiter_id } }) {
      id
      availability
      company_id
      company {
        id
        name
      }
      phone
      position
      profilePhoto
      title
      industries
      name
      email
      recruiter_invitation_templates {
        subject
        body
        id
        recruiter_id
      }
    }
  }
`;

const COMPANY_PROFILE = gql`
  query getCompanyProfile($admin_id: String) {
    company(where: { adminID: { _eq: $admin_id } }) {
      id
      name
      logoURL
      email
      adminName
      websiteURL
      size
      bio
      links
      totalCredit
      remainingCredit
      subscription_type
    }
  }
`;

const SETTINGS = gql`
  query getSettings {
    settings {
      id
      credit_rate
      daily_candidate_request_limit
      weekly_candidate_request_limit
      monthly_candidate_request_limit
    }
  }
`;

const ONBOARDING = gql`
  query getStripeAgencyOnboarding($agency_id: uuid) {
    stripe_agency_onboarding(where: {agency_id: {_eq: $agency_id}}) {
      account_id
    }
  }
`;

const FEE_PAYMENT_LIST = gql`
  query getFeePaymentList($subscriber_id: uuid) {
    fee_payment(where: {subscriber_id: {_eq: $subscriber_id}}) {
      invoice_id
      invoice_generation_on
      invoice_amount
      invoice_paid_on
      invoice_payment_link
      invoice_sent_on
      invoice_url
      subscriber_id
      id
      candidate_id
      agency_id
    }
  }
`;


export default function Settings() {
  const [userRole, , , , , , isOnBoarded] = useContext(UserRoleContext);
  const [templateContent, setTemplateContent] = useState({
    subject: '{{candidate_name}}, Inviting you to CherryPicker',
    body: DEFAULT_EMAIL_INVITE_BODY,
    id: '',
  })
  const isAdmin = userRole === 'admin';
  const isRecruiter = userRole === 'recruiter';
  const isSubscriber = userRole === 'company';
  const isAgency = userRole === 'agency';
  const [openSnackbar] = useSnackbar();
  let navItems = ['Profile', 'Security', 'Notifications', 'Integrations', 'CreditCost'];
  if (isAgency) {
    navItems.push('Onboarding')
  }
  if (isAdmin) {
    navItems.push('FeeConfig');
  };
  if(isRecruiter) {
    navItems.push('Candidate Invitation Templates');
  }
  const [nav, setNav] = useState('Profile');

  const viewOtherSetting = (item) => {
    if (item === 'terms' || item === 'privacy') {
      navigate('https://www.getcherrypicker.com/terms');
    } else {
      setNav(item);
    }
  };

  const [currentUser, updateCurrentUser] = useState();

  const { data } = useQuery(
    isRecruiter ? RECRUITER_PROFILE : COMPANY_PROFILE,
    isRecruiter
      ? { variables: { recruiter_id: firebase.auth().currentUser && firebase.auth().currentUser.uid } }
      : { variables: { admin_id: firebase.auth().currentUser && firebase.auth().currentUser.uid } },
  );

  useEffect(() => {
    if (data && isRecruiter) {
      // updateCurrentUser(data && data.recruiter && data.recruiter[0]);
      updateCurrentUser({
        id: data && data.recruiter ? data.recruiter[0].id : null,
        name: data && data.recruiter ? data.recruiter[0].name : '',
        title: data && data.recruiter ? data.recruiter[0].title : '',
        industries: data && data.recruiter ? data.recruiter[0].industries : [],
        company_id: data && data.recruiter ? data.recruiter[0].company_id : '',
        email: data && data.recruiter ? data.recruiter[0].email : '',
        phone: data && data.recruiter ? data.recruiter[0].phone : '',
        profilePhoto: data && data.recruiter ? data.recruiter[0].profilePhoto : null,
        availability: data && data.recruiter ? data.recruiter[0].availability : {},
        invitationTemplate: data && data.recruiter ? data.recruiter[0].recruiter_invitation_templates : [],
      });
      if(data.recruiter[0].recruiter_invitation_templates?.length){
        setTemplateContent(prevState => {
          const { body, subject, id } = data.recruiter[0].recruiter_invitation_templates?.[0] || {};
          return { ...prevState, body, subject, id }
        })
      }
   
    } else if (data && isSubscriber) {
      updateCurrentUser({
        adminName: data.company[0].adminName,
        bio: data.company[0].bio,
        email: data.company[0].email,
        id: data.company[0].id,
        logoURL: data.company[0].logoURL,
        name: data.company[0].name,
        remainingCredit: data.company[0].remainingCredit,
        size: data.company[0].size,
        totalCredit: data.company[0].totalCredit,
        websiteURL: data.company[0].websiteURL,
        adminID: data.company[0] ? data.company[0].adminID : null,
      });
    } else if (isAdmin) {
      updateCurrentUser({
        name: 'Administrator',
        email: 'admin@getcherrypicker.com',
        profilePhoto: Logo,
      });
    } else if (isAgency) {
      updateCurrentUser({
        id: data && data.company ? data.company[0].id : '',
        name: data && data.company ? data.company[0].name : '',
        email: data && data.company ? data.company[0].email : '',
        profilePhoto: data && data.company ? data.company[0].profilePhoto : Logo,
      });
    }
  }, [data]);

  useEffect(() => {
    document.title = 'Settings | Cherry Picker';
  }, []);

  const [settings, setSettings] = useState({});
  const [stripeID, setStripeID] = useState('');
  const [feePaymentListData, setFeePaymentListData] = useState([]);

  const { data: settingData, refetch } = useQuery(SETTINGS);
  const { data: onboardingData } = useQuery(ONBOARDING, {
    variables: { agency_id: currentUser?.id }
  });

  const { data: feePaymentList } = useQuery(FEE_PAYMENT_LIST, {
    variables: { subscriber_id: currentUser?.id }
  });

  useEffect(() => {
    if (settingData && settingData.settings && settingData.settings[0].credit_rate) {
      setSettings(settingData.settings[0]);
    }
  }, [settingData]);

  useEffect(() => {
    if (onboardingData && onboardingData.stripe_agency_onboarding && onboardingData.stripe_agency_onboarding[0]?.account_id) {
      setStripeID(onboardingData.stripe_agency_onboarding[0]?.account_id);
    }
  }, [onboardingData]);

  useEffect(() => {
    if (feePaymentList && feePaymentList.fee_payment) {
      setFeePaymentListData(feePaymentList.fee_payment);
    }
  }, [feePaymentList]);

  const handlePaymentDashboardClick = async () => {
    try {
      const { data } = await axios.post(`${EXPRESS_SERVER_URL}/stripe-login-link`, {
        id: stripeID
      })
      window.open(data?.url, '_blank', 'noopener,noreferrer');
    } catch (error) {
      console.log({ error })
      openSnackbar(error?.response?.data?.message)
    }

  }

  const handleTransactionDashBoardClick = async () => {
    try {
      const { data: subscriberStipeID } = await axios.post(`${EXPRESS_SERVER_URL}/stripe-accountId`, {
        email: currentUser?.email
      })

      if (!subscriberStipeID?.id) {
        return openSnackbar('Failed to find your account')
      }
      const { data: dashboardURL } = await axios.post(`${EXPRESS_SERVER_URL}/billing-portal`, {
        customerID: subscriberStipeID?.id
      })
      window.open(dashboardURL?.url, '_blank', 'noopener,noreferrer');

    } catch (error) {
      openSnackbar('Fail to access transaction dashboard')
    }
  };

  return (
    <div className="flex flex-col lg:flex-row">
      <div className="flex flex-col flex-shrink bg-white shadow" style={{ height: 'calc(100vh - 150px)', marginTop: 50, width: 300, justifyContent: 'space-between' }}>
        <div className="p-lg rounded flex flex-col" style={{}}>
          {navItems &&
            navItems.map((item, index) =>
              isAdmin && (item === 'Profile' || item === 'CreditCost') ? (
                <div key={index} style={{ padding: 15 }}>
                  <div className="flex border-b" style={{ paddingBottom: 10, fontSize: 15 }} onClick={() => viewOtherSetting(item)}>
                    <div className={`flex flex-col font-medium cursor-pointer ${item === nav ? 'text-darkblue' : 'text-darkgray'}`}>
                      {item === 'terms' ? 'Terms and Conditions' : item === 'privacy' ? 'Privacy Policy' : item === 'CreditCost' ? 'Cost per credit' : item}
                    </div>
                  </div>
                </div>
              ) : isRecruiter && item !== 'CreditCost' ? (
                <div style={{ padding: 15 }}>
                  <div className="flex border-b" style={{ paddingBottom: 10, fontSize: 15 }} onClick={() => viewOtherSetting(item)}>
                    <div className={`flex flex-col font-medium cursor-pointer ${item === nav ? 'text-darkblue' : 'text-darkgray'}`}>
                      {item === 'terms' ? 'Terms and Conditions' : item === 'privacy' ? 'Privacy Policy' : item === 'credit_cost' ? 'Cost per credit' : item}
                    </div>
                  </div>
                  {item === 'Profile' && (
                    <img
                      alt=""
                      onClick={() => viewOtherSetting('editProfile')}
                      className="cursor-pointer"
                      src={EditIcon}
                      style={{ width: 25, marginTop: -35, marginLeft: 195, position: 'absolute' }}
                    />
                  )}
                </div>
              ) : item === 'CreditCost' || item === 'Integrations' || item === 'Notifications' ? (
                ''
              ) : (
                <div key={index} style={{ padding: 15 }}>
                  <div className="flex border-b" style={{ paddingBottom: 10, fontSize: 15 }} onClick={() => viewOtherSetting(item)}>
                    <div className={`flex flex-col font-medium cursor-pointer ${item === nav ? 'text-darkblue' : 'text-darkgray'}`}>
                      {item === 'terms' ? 'Terms and Conditions' : item === 'FeeConfig' ? 'Fee Config' : item === 'privacy' ? 'Privacy Policy' : item === 'credit_cost' ? 'Cost per credit' : item}
                    </div>
                  </div>
                  {item === 'Profile' && (
                    <img
                      alt=""
                      onClick={() => viewOtherSetting('editProfile')}
                      className="cursor-pointer"
                      src={EditIcon}
                      style={{ width: 25, marginTop: -35, marginLeft: 195, position: 'absolute' }}
                    />
                  )}
                </div>
              ),
            )}
          {/* {isSubscriber && (
            <div onClick={handleTransactionDashBoardClick} style={{ padding: 15 }}>
              <div className="flex border-b" style={{ paddingBottom: 10, fontSize: 15 }}>
                <div className={`flex flex-col font-medium cursor-pointer ${nav === 'terms' ? 'text-darkblue' : 'text-darkgray'}`}>Transaction Dashboard</div>
              </div>
            </div>
          )} */}
          {!isAdmin && (
            <div style={{ padding: 15 }}>
              <a href={`https://www.getcherrypicker.com/terms`} target="_blank" rel="noreferrer">
                <div className="flex border-b" style={{ paddingBottom: 10, fontSize: 15 }}>
                  <div className={`flex flex-col font-medium cursor-pointer ${nav === 'terms' ? 'text-darkblue' : 'text-darkgray'}`}>Terms and Conditions</div>
                </div>
              </a>
            </div>
          )}
          {isAgency ? <div style={{ padding: 15 }} onClick={handlePaymentDashboardClick}>
            <div className="flex border-b" style={{ paddingBottom: 10, fontSize: 15 }}>
              <div className={`flex flex-col font-medium cursor-pointer ${nav === 'privacy' ? 'text-darkblue' : 'text-darkgray'}`}>Payment Dashboard</div>
            </div>
          </div> : null}
          {!isAdmin && (
            <div style={{ padding: 15 }}>
              <a href={`https://www.getcherrypicker.com/privacypolicy`} target="_blank" rel="noreferrer">
                <div className="flex border-b" style={{ paddingBottom: 10, fontSize: 15 }}>
                  <div className={`flex flex-col font-medium cursor-pointer ${nav === 'privacy' ? 'text-darkblue' : 'text-darkgray'}`}>Privacy Policy</div>
                </div>
              </a>
            </div>
          )}
        </div>
        <div className="p-lg rounded flex flex-col" style={{}}>
          <div style={{ padding: 15 }}>
            <div className="flex border-b" style={{ paddingBottom: 10, fontSize: 15 }}>
              <div
                className="flex flex-col font-medium text-darkgray cursor-pointer"
                onClick={async () => {
                  await firebase.auth().signOut();
                  navigate('/');
                }}
              >
                Logout
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-1 lg:ml-lg w-full lg:w-auto" style={{ marginTop: 50 }}>
        <div className="bg-white rounded shadow flex-1" style={{ height: 700, width: '100%' }}>
          {nav === 'Profile' ? (
            <ViewProfile currentUser={currentUser} isAdmin={isAdmin} isSubscriber={isSubscriber} />
          ) :
            nav === 'Security' ? (
              <ChangePassword navigate={setNav} />
            ) : nav === 'Onboarding' ? (
              <Onboarding isOnBoarded={!isOnBoarded} currentUser={currentUser} isAgency={isAgency} />
            ) : nav === 'Candidate Invitation Templates' ? (
              <RecruiterEmailTemplate currentUser={currentUser} updateCurrentUser={updateCurrentUser} setTemplateContent={setTemplateContent} templateContent={templateContent} />
            ) : nav === 'Notifications' ? (
              <EmailNotifications navigate={setNav} />
            ) : nav === 'Integrations' ? (
              <Integrations />
            ) : nav === 'CreditCost' ? (
              <CreditCost navigate={setNav} settings={settings} refetch={refetch} />
            ) : nav === 'editProfile' ? (
              <EditProfile navigate={setNav} currentUser={currentUser} />
            ) : nav === 'FeeConfig' ? (
              <FeeConfig navigate={setNav} />
            ) : (
              ''
            )}
        </div>
      </div>
    </div>
  );
}
