import React, { useEffect, useState, useContext } from 'react';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import { useMutation, useSubscription } from '@apollo/react-hooks';
import { ConfirmModalContext } from '../../../context';
import LoxoModalEdit from './loxo-modal-edit';
import { BACKGROUND_SYNC_FREQUENCIES } from '../../../utils/constants';

const GET_IMPORTER_ACCOUNT = gql`
  subscription getImporters($recruiter_id: String, $account_type: String) {
    importers(where: { recruiter_id: { _eq: $recruiter_id }, account_type: { _eq: $account_type } }) {
      recruiter_id
      username
      password
      authorization
      account_type
      id
      is_connected
      sync_frequency
    }
  }
`;

const UPDATE_IMPORTER_ACCOUNT = gql`
  mutation updateImporter($id: uuid, $changes: importers_set_input) {
    update_importers(where: { id: { _eq: $id } }, _set: $changes) {
      affected_rows
      returning {
        recruiter_id
        username
        password
        account_type
        id
        sync_frequency
      }
    }
  }
`;

function ImporterEntry({ type, state }) {
  const [modal, setModal] = state;

  const [account, setAccount] = useState();
  const { data } = useSubscription(GET_IMPORTER_ACCOUNT, {
    variables: {
      recruiter_id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
      account_type: type,
    },
  });

  useEffect(() => {
    if (data && data.importers[0]) {
      setAccount(data.importers[0]);
    }
  }, [data]);

  const [confirmModal, setConfirmModal] = useContext(ConfirmModalContext);

  const editLoxoAccount = () => {
    setModal({
      open: true,
      data: account,
    });
  };

  const onClickConnectBtn = () => {
    setConfirmModal({
      open: true,
      title: `Confirm Account ${account && account.is_connected ? 'Disconnent' : 'Connect'}`,
      message: `Are you sure you want to ${account && account.is_connected ? 'disconnent' : 'connent'} this account ?`,
      buttonText: `${account && account.is_connected ? 'Disconnect' : 'Connect'}`,
      action: connectImporter,
    });
  };
  function connectImporter() {
    updateImporter({
      variables: {
        id: account.id,
        changes: {
          is_connected: !account.is_connected,
        },
      },
    });
  }
  const [updateImporter, { data: connect_response }] = useMutation(UPDATE_IMPORTER_ACCOUNT);

  useEffect(() => {
    if (connect_response) {
      setConfirmModal({
        loading: false,
        open: false,
      });
    }
  }, [connect_response]);

  return (
    <div className="flex px-lg">
      <div className="flex border-b w-full items-center" style={{ height: 100 }}>
        <div className="flex flex-col" style={{ flexGrow: 1, minWidth: 'min-content', marginLeft: 30 }}>
          <div className="text-sm text-darkblue font-medium">
            {type.toUpperCase()}
            {account && account.is_connected ? (
              <>
                <br />
                Background sync frequency: {BACKGROUND_SYNC_FREQUENCIES[account.sync_frequency]}
              </>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="flex text-xs text-darkgray font-medium" style={{ width: 'auto', minWidth: 'min-content', flexShrink: 1 }}>
          <div
            onClick={type == 'loxo' ? editLoxoAccount : ''}
            className="rounded py-sm px-md bg-lightgray text-darkgray hover:bg-red hover:text-white font-medium text-sm mr-md cursor-pointer flex items-center"
          >
            <div className="mr-sm">Edit</div>
          </div>
        </div>
        <div className="flex text-xs text-darkgray font-medium" style={{ width: 'auto', minWidth: 'min-content', flexShrink: 1 }}>
          <div
            onClick={account && type == 'loxo' ? onClickConnectBtn : ''}
            className={`rounded py-sm px-md font-medium text-sm mr-md flex items-center ${
              account && account.is_connected ? 'bg-lightred text-red hover:bg-red hover:text-white' : 'bg-lightgreen text-green hover:bg-disabled hover:text-white'
            }`}
            style={{ cursor: account ? 'pointer' : 'not-allowed' }}
          >
            <div className="mr-sm">{account && account.is_connected ? 'Disconnect' : 'Connect'}</div>
          </div>
        </div>
      </div>
      <LoxoModalEdit state={[modal, setModal]} />
    </div>
  );
}

export default function Integrations() {
  const [formModal, setFormModal] = useState({ data: null, open: false });
  const integrationTypes = [
    'loxo',
    // 'bullhorn'
  ];
  return (
    <div className="flex-1 flex flex-col pb-lg">
      <div className="flex-1 bg-white rounded shadow relative">
        <div className="absolute inset-0">{integrationTypes && integrationTypes.map((type) => <ImporterEntry type={type} state={[formModal, setFormModal]} />)}</div>
      </div>
    </div>
  );
}
