import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import { EXPRESS_SERVER_URL } from '../../config';
import { ApolloContext } from '../../context/Apollo';
import NewAgencyForm from './NewAgencyForm';

// graphql query to create new company
const CREATE_AGENCY = gql`
  mutation createAgency(
    $name: String
    $websiteURL: String
    $bio: String
    $size: String
    $logoURL: String
    $email: String
    $adminID: String
    $recruiter_id: String
    $adminName: String
    $is_agency: Boolean
  ) {
    insert_company(
      objects: {
        name: $name
        websiteURL: $websiteURL
        bio: $bio
        size: $size
        logoURL: $logoURL
        email: $email
        adminID: $adminID
        recruiter_id: $recruiter_id
        adminName: $adminName
        is_agency: $is_agency
      }
    ) {
      returning {
        id
        name
        websiteURL
        bio
        size
        logoURL
        email
        adminID
        adminName
        is_agency
      }
    }
  }
`;

// graphql query to update the company detail
const UPDATE_AGENCY = gql`
  mutation updateAgency($id: uuid, $changedFields: company_set_input) {
    update_company(where: { id: { _eq: $id }, is_agency: { _eq: true } }, _set: $changedFields) {
      affected_rows
      returning {
        id
        name
        websiteURL
        bio
        size
        logoURL
        is_agency
        adminName
      }
    }
  }
`;

export default function CreateAgencyForm({ agency }) {

  const accountState = useState(agency);
  const { apolloClient } = useContext(ApolloContext);
  const [openSnackbar] = useSnackbar();

  // mutation to create new company
  const [createAgency, { loading: createLoading, data: createResponseData }] = useMutation(CREATE_AGENCY);

  // mutation to update existing company
  const [updateAgency, { loading: updateLoading, data: updateResponseData }] = useMutation(UPDATE_AGENCY);

  const [loading, setLoading] = useState(updateLoading || createLoading ? true : false);

  // function to upload the image into firebase storage
  async function uploadImage(file, location) {
    const storageRef = firebase
      .storage()
      .ref()
      .child(`images/${location}/${file.name}`);
    const logoURL = await storageRef.put(file).then((snapshot) => snapshot.ref.getDownloadURL());
    return logoURL;
  }

  async function saveAgencyCreate(agency) {
    setLoading(true);
    const user = await fetch(`${EXPRESS_SERVER_URL}/createUserFirebaseAccount`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: agency.email, password: agency.password, name: agency.name }),
    }).then((response) => response.json());

    const { name, email, websiteURL, bio, size, logoURL, adminName } = agency;
    const newAgency = {
      name,
      email,
      websiteURL,
      bio,
      size,
      logoURL: !logoURL ? null : typeof logoURL === 'string' ? logoURL : await uploadImage(logoURL, 'newcompanylogos'),
      adminID: user.uid,
      adminName,
      is_agency: true
    };

    // If error in creating agency, then delete from firebase
    try {
      await apolloClient.mutate({
        mutation: CREATE_AGENCY,
        variables: newAgency,
      });
      setLoading(false);

      navigate(`/dashboard/agency`);
    } catch (e) {
      setLoading(false);
      openSnackbar(e.message);

      await fetch(`${EXPRESS_SERVER_URL}/remove-user-firebase-account`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId: user.uid }),
      }).then((response) => response.json());
    }
  }

  async function saveAgencyEdit(updatedAgency) {
    setLoading(true);

    const { name, email, websiteURL, bio, size, logoURL, adminName } = updatedAgency;

    const editedAgency = {
      name,
      email,
      websiteURL,
      bio,
      size,
      logoURL: !logoURL ? null : typeof logoURL === 'string' ? logoURL : await uploadImage(logoURL, 'newcompanylogos'),
      adminName,
      is_agency: true
    };

    const user = await fetch(`${EXPRESS_SERVER_URL}/getUserFirebaseAccount`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: agency.email }),
    }).then((response) => response.json());

    if (user.statusCode === 200) {
      await fetch(`${EXPRESS_SERVER_URL}/updateUserEmailFirebase`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ uid: user.data.uid, email: email }),
      }).then((response) => response.json());
    } else {
      await fetch(`${EXPRESS_SERVER_URL}/createUserFirebaseAccount`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email: email, password: 'Password1!', name: name }),
      }).then((response) => response.json());
    }

    updateAgency({ variables: { id: agency.id, changedFields: editedAgency } });

    setLoading(false);
  }

  // navigate to the companies list after creating new company
  useEffect(() => {
    if (createResponseData) {
      navigate(`/dashboard/agencies`);
    }
  }, [createResponseData]);

  // navigate to the company detail with the updated data
  useEffect(() => {
    if (updateResponseData) {
      navigate(`/agencies/${updateResponseData.update_company.returning[0].id}/`,
        {
          state: {
            company_id: updateResponseData.update_company.returning[0].id
          }
        });
    }
  }, [updateResponseData]);

  return <NewAgencyForm action={agency ? saveAgencyEdit : saveAgencyCreate} globalFormData={accountState} isEdit={agency ? true : false} loading={loading} />;
}
