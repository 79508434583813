import React from 'react';
import CandidateExperience from './CandidateExperience';
import CandidateSkills from './CandidateSkills';
import EducationField from './EducationField';
import EmploymentField from './EmploymentField';
import ErrorMessage from './ErrorMessage';
import LocationField from './LocationField';
import PhoneInput from './PhoneInput';
import RecruiterSelect from './RecruiterSelect';
import SocialInput from './SocialInput';
import TextInput from './TextInput';

export default function CandidateField ({ value, name, placeholder, optional, title, update, error, newParsedResume, setFieldValue }) {
  const renderField = (name) => {
    switch (name) {
      case 'phone':
        return <PhoneInput value={value} update={update} error={error} />;
      case 'recruiter':
        return <RecruiterSelect value={value} update={update} />;
      case 'location':
        return <LocationField placeholder="City/State" value={value} update={update} error={error} />;

      case 'experience':
        return <CandidateExperience placeholder={placeholder} value={value} update={update} />;

      case 'employment':
        return <EmploymentField value={value} update={update} error={error} setFieldValue={setFieldValue} newResumeValue={newParsedResume && newParsedResume.employment} />;

      case 'education':
        return <EducationField value={value} update={update} error={error} newResumeValue={newParsedResume && newParsedResume.education} />;

      case 'certifications':
        return <CandidateSkills type="certifications" value={value} update={update} placeholder="Certification or License" />;

      case 'attributes':
        return <CandidateSkills type="skills" value={value} update={update} placeholder="Select Attributes" newResumeValue={newParsedResume && newParsedResume.attributes} />;

      case 'preferredSkills':
        return <CandidateSkills type="skills" value={value} update={update} placeholder="Skill Tags" />;

      case 'linkedin':
        return <SocialInput label={name} value={value} update={update} placeholder={placeholder} optional={optional} error={error} />;
      case 'facebook':
        return <SocialInput label={name} value={value} update={update} placeholder={placeholder} optional={optional} error={error} />;

      case 'instagram':
        return <SocialInput label={name} value={value} update={update} placeholder={placeholder} optional={optional} error={error} />;

      case 'twitter':
        return <SocialInput label={name} value={value} update={update} placeholder={placeholder} optional={optional} error={error} />;
      default:
        return (
          <TextInput
            value={value}
            update={update}
            placeholder={placeholder}
            optional={optional}
            error={error}
            newResumeValue={newParsedResume && name === 'bio' && newParsedResume.bio}
          />
        );
    }
  };

  return (
    <div className="flex items-center flex-col">
      {title && (
        <div className="text-darkblue w-full flex items-center font-medium flex justify-between" style={{ fontSize: 18, marginTop: 40, fontWeight: 400 }}>
          <div>
            {title}
            {optional && <span className="uppercase text-xs text-darkgray">Optional</span>}
          </div>
          <div className="flex-1 border-b ml-md" />
        </div>
      )}
      {renderField(name)}
      {error && name !== 'employment' && name !== 'education' && <ErrorMessage error={error} />}
    </div>
  );
}
