import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import AttributesFilter from './AttributesFilter';
import './date.css';
import LocationFilter from './LocationFilter';
import RecruiterFilter from './RecruiterFilter';
import SalaryFilter from './SalaryFilter';
import SearchTermsFilter from './SearchTermsFilter';
import AddToProject from '../../views/candidates/candidate-dashboard/add-to-project';

const ClearButton = ({ updateField }) => (
  <div
    onClick={updateField}
    className="absolute lowercase cursor-pointer bg-darkgray text-white font-medium px-sm"
    style={{ top: 0, marginLeft: 15, borderRadius: 45.39, lineHeight: '20px' }}
  >
    clear
  </div>
);

export default function JobsFilter({ filterState, showRecruiterFilter }) {
  const [filterValue, update] = filterState;

  const sections = {
    title: filterValue.title ? filterValue.title : '',
    salary: filterValue.salary || { min: null, max: null },
    location: filterValue.location || { city: null, radius: process.env.GATSBY_DEFAULT_SEARCH_RADIUS || 50 },
    attributes: filterValue.attributes ? filterValue.attributes : [],
    date: filterValue.date || { startDate: null, endDate: null },
  };

  if (showRecruiterFilter) {
    sections.recruiter = filterValue.recruiter ? filterValue.recruiter : [];
  }

  const [options, setOptions] = useState(sections);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const updateField = (value, name) => setOptions({ ...options, [name]: value });

  const clearFilterField = (label) => {
    let resetValue;
    switch (label) {
      case 'date':
        resetValue = { startDate: '', endDate: '' };
        break;

      case 'title':
        resetValue = '';
        break;

      case 'recruiter':
        resetValue = [];
        break;

      case 'salary':
        resetValue = { min: '', max: '' };
        break;

      case 'location':
        resetValue = { city: '', radius: process.env.GATSBY_DEFAULT_SEARCH_RADIUS || 50 };
        break;

      case 'attributes':
        resetValue = [];
        break;

      default:
        break;
    }

    updateField(resetValue, label);
  };

  const renderFilterField = (name) => {
    switch (name) {
      case 'date':
        return (
          <div className="h-full flex flex-col" style={{ width: '100%', height: 60, display: '-webkit-inline-box' }}>
            <div style={{ marginRight: 20 }}>
              <DatePicker
                className="bg-white w-full shadow rounded relative p-sm text-xs placeholder-primary font-normal"
                placeholderText="Start Date"
                selected={startDate}
                onChange={(date) => {
                  setStartDate(date);

                  let newOptions = {};

                  if (options.date) {
                    newOptions = { ...options, date: { startDate: date ? moment(date).format('YYYY-MM-DD') : '', endDate: options.date.endDate } };
                  } else {
                    newOptions = { ...options, date: { startDate: date } };
                  }

                  setOptions(newOptions);
                }}
                maxDate={moment(new Date()).toDate()}
              />
            </div>
            <div>
              <DatePicker
                className="bg-white w-full shadow rounded relative p-sm text-xs placeholder-primary font-normal"
                placeholderText="End Date"
                selected={endDate}
                onChange={(date) => {
                  setEndDate(date);

                  let newOptions = {};

                  if (options.date) {
                    newOptions = { ...options, date: { endDate: date ? moment(date).format('YYYY-MM-DD') : '', startDate: options.date.startDate } };
                  } else {
                    newOptions = { ...options, date: { endDate: date } };
                  }

                  setOptions(newOptions);
                }}
                minDate={startDate}
                maxDate={moment(new Date()).toDate()}
              />
            </div>
          </div>
        );

      case 'title':
        return (
          <SearchTermsFilter
            type={'jobtitle'}
            value={filterValue.title}
            update={(value) => updateField(value, name)}
            placeholder="Search for job title"
            style={{
              width: 300,
              paddingRight: 0,
            }}
          />
        );

      case 'recruiter':
        return <RecruiterFilter recruiters={options[name]} update={(value) => updateField(value, name)} />;

      case 'salary':
        return <SalaryFilter value={options[name]} update={(value) => updateField(value, name)} />;

      case 'location':
        return <LocationFilter value={options[name]} update={(value) => updateField(value, name)} type={'job-search'} />;

      case 'attributes':
        return <AttributesFilter attributes={options[name]} update={(value) => updateField(value, name)} style={{ width: 300 }} />;

      default:
        return <div></div>;
    }
  };

  const renderClearButton = (label) => {
    const value = options[label];
    switch (label) {
      case 'title':
        if (!value) {
          return null;
        }

        break;
      case 'date':
        if (!value.date) {
          return null;
        }

        break;
      case 'recruiter':
        if (!value.length) {
          return null;
        }

        break;
      case 'salary':
        if (!(value.min || value.max)) {
          return null;
        }

        break;
      case 'location':
        if (!value.city) {
          return null;
        }

        break;
      case 'attributes':
        if (value.length === 0) {
          return null;
        }

        break;
      default:
        break;
    }

    return <ClearButton updateField={() => clearFilterField(label)} />;
  };

  useEffect(() => { }, [filterValue]);

  useEffect(() => {
    let filter = {};
    const { title, recruiter, date, salary, location, attributes } = options;

    if (title.length > 0) {
      filter = { ...filter, title };
    }

    if (recruiter && recruiter.length > 0) {
      filter = { ...filter, recruiter };
    }

    if (date) {
      filter = { ...filter, date };
    }

    if (salary) {
      filter = { ...filter, salary };
    }

    if (location) {
      filter = { ...filter, location };
    }

    if (attributes.length > 0) {
      filter = { ...filter, attributes };
    }

    update(filter);
  }, [options]);

  return (
    <div className="flex w-full flex-wrap" style={{ marginBottom: 30 }}>
      {Object.entries(sections).map(([label], index) => {
        return (
          <div key={index} className="flex flex-col" style={{ marginTop: 15, marginRight: 15 }}>
            <div
              className="text-darkgray flex items-center capitalize font-medium"
              style={{ fontSize: 12, height: 20, marginBottom: 15, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {label}
              <div className="flex-1 relative" style={{ height: 20 }}>
                {renderClearButton(label)}
              </div>
            </div>
            {renderFilterField(label)}
          </div>
        );
      })}
      <div className={`w-full flex justify-end filter-attributes-container`}>
        {(options.recruiter || []).length > 0 ? <div style={{ marginTop: 16, marginRight: 10, fontWeight: 600 }}>Recruiters</div> : ''}
        <div className={`flex flex-wrap filter-attributes`} style={{ marginTop: 6, top: 40 }}>
          {(options.recruiter || []).map((term, index) => (
            <div
              key={index}
              className="border flex text-darkgray border-darkgray flex items-center px-md"
              style={{ height: 28, fontSize: 12, borderRadius: 28, marginRight: 10, marginTop: 10, whiteSpace: 'nowrap' }}
            >
              {term.name}
              <div
                onClick={() =>
                  updateField(
                    (options.recruiter || []).filter((val) => val.id !== term.id),
                    'recruiter',
                  )
                }
                className="ml-sm text-sm relative cursor-pointer"
                style={{ left: 5, width: 10, height: 10 }}
              >
                <div className="bg-darkgray absolute" style={{ height: 10, width: 1.5, transform: 'rotate(45deg)', borderRadius: 1.5 }} />
                <div className="bg-darkgray absolute" style={{ height: 10, width: 1.5, transform: 'rotate(-45deg)', borderRadius: 1.5 }} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={`w-full flex justify-end filter-attributes-container`}>
        {(options.attributes || []).length > 0 ? <div style={{ marginTop: 16, marginRight: 10, fontWeight: 600 }}>Attributes</div> : ''}
        <div className={`flex flex-wrap filter-attributes`} style={{ marginTop: 6, top: 40 }}>
          {(options.attributes || []).map((term, index) => (
            <div
              key={index}
              className="border flex text-darkgray border-darkgray flex items-center px-md"
              style={{ height: 28, fontSize: 12, borderRadius: 28, marginRight: 10, marginTop: 10, whiteSpace: 'nowrap' }}
            >
              {term}
              <div
                onClick={() =>
                  updateField(
                    (options.attributes || []).filter((val) => val !== term),
                    'attributes',
                  )
                }
                className="ml-sm text-sm relative cursor-pointer"
                style={{ left: 5, width: 10, height: 10 }}
              >
                <div className="bg-darkgray absolute" style={{ height: 10, width: 1.5, transform: 'rotate(45deg)', borderRadius: 1.5 }} />
                <div className="bg-darkgray absolute" style={{ height: 10, width: 1.5, transform: 'rotate(-45deg)', borderRadius: 1.5 }} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
