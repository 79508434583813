import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import { EXPRESS_SERVER_URL } from '../../config';
import { ConfirmModalContext } from '../../context';
import UserAvatar from '../../images/user-avatar.png';
import { SUBSCRIPTION_TYPES } from '../../utils/constants';
import TrashIcon from '../jobs/TrashIcon';
import { useSnackbar } from 'react-simple-snackbar';
import ProfileImage from '../candidates/profile/profile-image';

// graphql query to delete company on the basis of id
const DELETE_COMPANY = gql`
  mutation removeCompany($id: uuid) {
    delete_payment_history(where: { company_id: { _eq: $id } }) {
      affected_rows
    }
    delete_company_request(where: { company_id: { _eq: $id } }) {
      affected_rows
    }
    delete_company(where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        email
        name
        adminName
        candidate_requests_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

const UPDATE_SUBSCRIPTION_TYPE = gql`
  mutation updateSubscriptionType($companyId: uuid, $subscriptionType: String) {
    update_company(where: { id: { _eq: $companyId } }, _set: { subscription_type: $subscriptionType }) {
      affected_rows
    }
  }
`;

export default function SubscriberEntry({ subscriber }) {
  const [, setModal] = useContext(ConfirmModalContext);
  const [deleteCompany, { data }] = useMutation(DELETE_COMPANY);
  const [subscriptionType, setSubscriptionType] = useState(subscriber.subscription_type);
  const [updateSubscriptionType] = useMutation(UPDATE_SUBSCRIPTION_TYPE);
  const [openSnackbar] = useSnackbar();

  function removeCompany() {
    fetch(`${EXPRESS_SERVER_URL}/remove-user-firebase-account-by-ids`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ subscriberIds: [subscriber.adminID] }),
    })
      .then((res) => res.json())
      .then(() => {
        deleteCompany({ variables: { id: subscriber.id }, refetchQueries: ['getAllCompanies'] });
      });
  }

  const onClickTrashCan = () => {
    setModal({
      open: true,
      title: `Confirm Subscriber Removal`,
      message: `Are you sure you want to remove the subscriber ${subscriber.name}?`,
      buttonText: 'Remove Subscriber',
      action: removeCompany,
    });
  };

  useEffect(() => {
    if (data) {
      setModal({ loading: false, open: false });
    }
  }, [data]);

  return (
    // <div className="px-md lg:px-xl">
    //   <div className="flex flex-col lg:flex-row items-center border-b py-md lg:py-lg">
    //     <div
    //       className="mb-sm lg:mb-0"
    //       style={{ paddingLeft: 0, paddingRight: 0 }}>
    //       <div
    //         className="hover:underline lg:p-0 cursor-pointer lg:text-sm"
    //         style={{ fontWeight: 500, width: 200, overflow: 'hidden' }}
    //         onClick={() =>
    //           navigate(`/subscribers/${subscriber.id}/`, {
    //             state: { subscriber },
    //           })
    //         }>
    //         {subscriber.name}
    //       </div>
    //     </div>
    //     {["email", "adminName", "count"].map((label, i) => (
    //       <div
    //         className={`flex-1 font-medium text-darkgray ${
    //           i === 2 ? "mb-md lg:mb-0" : i === 0 ? "text-left" : ""
    //         } ${label === "adminName" ? "text-left" : ""}`}
    //         style={{ fontSize: 14 }}>
    //         {label === "count"
    //           ? `${subscriber.candidate_requests_aggregate.aggregate.count} Candidate Requests Sent`
    //           : subscriber[label]}
    //       </div>
    //     ))}
    //     <div
    //         className={`text-xs flex font-medium text-darkblue mb-md lg:mb-0 lg:mr-md`} style={{ fontSize: 14 }}>
    //           {subscriber.remainingCredit}/{subscriber.totalCredit} Credits
    //     </div>
    //     <TrashIcon onClick={onClickTrashCan} />
    //   </div>
    // </div>
    <div className="px-md lg:px-xl">
      <div className="flex flex-col lg:flex-row items-center border-b py-md">
        <div className="flex w-full lg:w-auto" style={{ paddingLeft: 0, paddingRight: 0 }}>
          <ProfileImage
            width={49}
            height={49}
            approved={true}
            isDisabled={subscriber?.is_disabled}
            src={subscriber?.logoURL || UserAvatar}
          />
          <div className="flex flex-col">
            <div
              className="hover:underline lg:p-0 cursor-pointer lg:text-sm"
              style={{ fontWeight: 500, maxWidth: 400 }}
              onClick={() => navigate(`/subscribers/${subscriber.id}/`, { state: { subscriber } })}
            >
              {subscriber.name}
            </div>
            <div className="flex items-center font-medium" style={{ fontSize: 14, maxWidth: 402 }}>
              <div className="text-darkblue mr-sm" style={{ whiteSpace: 'nowrap' }}>
                {subscriber.email}
              </div>
              <div className="mr-sm text-darkgray">{subscriber.email && subscriber.adminName && '|'}</div>
              <div className="text-darkgray" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                {subscriber.adminName}
              </div>
            </div>
          </div>
        </div>
        <div className="hidden flex-col text-xs text-darkgray font-medium flex-1">
          {/* <div>
                        {recruiter.phone}
                    </div>
                    <div>
                        {recruiter.email}
                    </div> */}
        </div>

        <div className="hidden lg:flex justify-end mr-lg" style={{ flexGrow: 1 }}>
          <div className="flex items-center rounded px-md font-medium mr-sm" style={{ height: 30, fontSize: 12 }}>
            Subscription Type:&nbsp;&nbsp;&nbsp;
            <select
              className={`text-sm rounded border border-darkgray`}
              style={{ height: 30, borderColor: '#c4cad3', width: 100 }}
              onChange={async (e) => {
                if (subscriptionType !== e.target.value) {
                  openSnackbar('Loading...');

                  setSubscriptionType(e.target.value);

                  // Update subscription type in database
                  await updateSubscriptionType({
                    variables: {
                      companyId: subscriber.id,
                      subscriptionType: e.target.value,
                    },
                  });

                  openSnackbar('Subscription type updated.');
                }
              }}
              value={subscriptionType}
            >
              {Object.keys(SUBSCRIPTION_TYPES).map((a) => {
                return <option value={a}>{SUBSCRIPTION_TYPES[a]}</option>;
              })}
            </select>
          </div>

          <div
            className="bg-mediumgray text-darkgray flex items-center rounded px-md font-medium mr-sm"
            style={{ height: 30, fontSize: 12, borderRadius: 15, width: 220, justifyContent: 'center' }}
          >
            {`${subscriber.candidate_requests_aggregate.aggregate.count} Candidate Requests Sent`}
          </div>
        </div>
        <div className={`text-xs flex font-medium text-darkblue mb-md lg:mb-0 lg:mr-md`} style={{ fontSize: 14, width: 150 }}>
          {subscriber.remainingCredit}/{subscriber.totalCredit} Credits
        </div>
        <TrashIcon onClick={onClickTrashCan} />
      </div>
    </div>
  );
}
