import { useSubscription } from '@apollo/react-hooks';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { gql } from 'apollo-boost';
import firebase from 'firebase';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { TabTitle } from '../../components/common';

const GET_SYNC_LOGS = gql`
  subscription getSyncLogs($recruiter_id: String, $start_date_time: String, $end_date_time: String) {
    sync_logs(where: { recruiter_id: { _eq: $recruiter_id }, start_date_time: { _gte: $start_date_time, _lte: $end_date_time } }, order_by: { start_date_time: desc }) {
      account_type
      start_date_time
      end_date_time
      current_status
      candidates_count
      success_count
      failed_count
      id
      failed_candidate_ids
    }
  }
`;

function SingleSyncLog({ activity }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <tr style={{ fontSize: 14 }}>
      <th style={{ textAlign: 'left' }}>
        <div className="font-medium">{activity.account_type}</div>
      </th>
      <th style={{ textAlign: 'left' }}>
        <div className="font-medium">
          {moment(activity.start_date_time).format('MMM DD YYYY')}
          <br />
          {moment(activity.start_date_time).format('HH:mm:ss A')}
        </div>
      </th>
      <th style={{ textAlign: 'left' }}>
        <div className="font-medium">
          {activity.end_date_time ? (
            <>
              {moment(activity.end_date_time).format('MMM DD YYYY')}
              <br />
              {moment(activity.end_date_time).format('HH:mm:ss A')}
            </>
          ) : (
            '-'
          )}
        </div>
      </th>
      <th style={{ textAlign: 'left' }}>
        <div className="font-medium">{activity.current_status}</div>
      </th>
      <th style={{ textAlign: 'left' }}>
        <div className="font-medium">{activity.candidates_count}</div>
      </th>
      <th style={{ textAlign: 'left' }}>
        <div className="font-medium">{activity.success_count}</div>
      </th>
      <th style={{ textAlign: 'left' }}>
        <div className="font-medium">{activity.failed_count}</div>
      </th>

      <th style={{ textAlign: 'center' }}>
        {activity.failed_candidate_ids.length ? (
          <div
            onClick={handleOpen}
            className={`bg-red text-white font-medium rounded flex items-center justify-center`}
            style={{ fontSize: 14, height: 40, width: 250, marginRight: 10, cursor: 'pointer' }}
          >
            View Failed candidates
          </div>
        ) : (
          'No failed candidates'
        )}
      </th>

      <Dialog open={open} onClose={handleClose} scroll="paper" fullWidth maxWidth={'lg'}>
        <DialogContent dividers style={{ fontFamily: 'montserrat' }}>
          <table>
            <thead>
              <tr>
                <th>No.</th>
                <th>Id</th>
                <th>Name</th>
                <th>Email</th>
                <th>Reason</th>
              </tr>
            </thead>

            <tbody>
              {activity.failed_candidate_ids.map((candidate, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{candidate.id}</td>
                    <td>{candidate.name}</td>
                    <td>{candidate.email}</td>
                    <td>{candidate.reason}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </DialogContent>

        <DialogActions>
          <button
            className={`bg-lightgray text-black font-medium rounded flex items-center justify-center`}
            style={{ fontSize: 14, height: 40, width: 100, marginLeft: 10, fontFamily: 'montserrat' }}
            onClick={handleClose}
          >
            Close
          </button>
        </DialogActions>
      </Dialog>
    </tr>
  );
}

export default function SyncLogs() {
  const [activityData, setActivityData] = useState([]);
  const [startDate, setStartDate] = useState(
    moment()
      .subtract({ days: 7 })
      .startOf('day')
      .toDate(),
  );
  const [endDate, setEndDate] = useState(
    moment()
      .endOf('day')
      .toDate(),
  );

  const { data: getSyncLogsData, loading } = useSubscription(GET_SYNC_LOGS, {
    variables: {
      recruiter_id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
      start_date_time: moment(startDate)
        .startOf('day')
        .toISOString(),
      end_date_time: moment(endDate)
        .endOf('day')
        .toISOString(),
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (getSyncLogsData) {
      setActivityData(getSyncLogsData.sync_logs);
    }
  }, [getSyncLogsData]);

  return (
    <>
      <div className="relative flex items-center">
        <TabTitle>Sync Logs</TabTitle>
      </div>

      <br />

      <div className="h-full flex flex-col" style={{ width: '100%', height: 60, display: '-webkit-inline-box' }}>
        <div style={{ marginRight: 20 }}>
          <span>Start Date: </span>
          <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} maxDate={moment(new Date()).toDate()} />
        </div>
        <div>
          <span>End Date: </span>
          <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} minDate={startDate} maxDate={moment(new Date()).toDate()} />
        </div>
      </div>

      <div className={`flex-1 bg-white rounded shadow`} style={{ overflowX: 'auto', height: '700px', minHeight: 500, maxHeight: 'calc(100vh - 200px)', marginBottom: 100 }}>
        <table style={{ width: '98%', marginLeft: '1%', marginRight: '1%' }}>
          <thead style={{ fontSize: 14, fontWeight: 'bold' }}>
            <tr style={{ position: 'sticky', top: 0, background: 'white' }}>
              <th style={{ textAlign: 'left' }}>Account type</th>
              <th style={{ textAlign: 'left' }}>Started at</th>
              <th style={{ textAlign: 'left' }}>Ended at</th>
              <th style={{ textAlign: 'left' }}>Status</th>
              <th style={{ textAlign: 'left' }}>Total candidates count</th>
              <th style={{ textAlign: 'left' }}>Success count</th>
              <th style={{ textAlign: 'left' }}>Failed count</th>
              <th style={{ textAlign: 'center' }}>Failed candidates</th>
            </tr>
          </thead>

          <tbody>
            {(loading ? [] : activityData || []).map((activity) => {
              return <SingleSyncLog activity={activity} />;
            })}
          </tbody>
        </table>

        {loading && (
          <div className={`flex justify-center items-center flex-1`} style={{ width: '100%' }}>
            <div className="bg-lightgray text-darkgray py-sm px-md" style={{ borderRadius: 43 }}>
              Loading
            </div>
          </div>
        )}

        {!loading && activityData && activityData.length === 0 && (
          <div className={`flex justify-center items-center flex-1`} style={{ width: '100%' }}>
            <div className="bg-lightgray text-darkgray py-sm px-md" style={{ borderRadius: 43 }}>
              No Records found
            </div>
          </div>
        )}
      </div>
    </>
  );
}
