import { useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Link } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import CaretDownGray from '../../images/caret-down-gray.png';
import CaretDown from '../../images/caret-down.png';
import RecruiterEntry from './recruiter-entry';
import CherryPagination from '../../components/common/Pagination/Pagination';
import { SearchBox, TabTitle } from '../../components/common';
import { DEFAULT_PER_PAGE } from '../../utils/constants';
import { UserRoleContext } from '../../context';
import firebase from 'firebase/app';

const ALL_RECRUITERS = gql`
  subscription getAllRecruiters(
    $perPage: Int
    $offset: Int
    $is_like: String
    $order_by: [recruiter_order_by!]
  ) {
    recruiter(
      order_by: $order_by
      where: {
        _or: [{ name: { _ilike: $is_like } }, { email: { _ilike: $is_like } }]
      }
      limit: $perPage
      offset: $offset
    ) {
      availability
      email
      id
      industries
      name
      phone
      profilePhoto
      title
      is_disabled
      company {
        id
        name
      }
      position
      candidates_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

const ALL_AGENCY_RECRUITERS = gql`
  subscription getAllAgencyRecruiters(
    $perPage: Int
    $offset: Int
    $is_like: String
    $adminID: String
    $order_by: [recruiter_order_by!]
  ) {
    recruiter(
      order_by: $order_by
      where: {company: {adminID: {_eq: $adminID}}, _or: [{email: {_is_null: false}}, {name: {_ilike: $is_like}}, {email: {_ilike: $is_like}}]}
      limit: $perPage
      offset: $offset
    ) {
      availability
      email
      id
      industries
      name
      phone
      permissions
      profilePhoto
      title
      is_disabled
      company {
        id
        name
      }
      position
      candidates_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

const ALL_AGENCY_RECRUITERS_TOTAL = gql`
  subscription getAllRecruiters($is_like: String, $adminID: String) {
    recruiter_aggregate(
      where: {company: {adminID: {_eq: $adminID}}, _or: [{email: {_is_null: false}}, {name: {_ilike: $is_like}}, {email: {_ilike: $is_like}}]}
    ) {
      aggregate {
        count
      }
    }
  }
`;

const ALL_RECRUITERS_TOTAL = gql`
  subscription getAllRecruiters($is_like: String) {
    recruiter_aggregate(
      where: {
        _or: [{ name: { _ilike: $is_like } }, { email: { _ilike: $is_like } }]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

function AlphabeticalSort({ sortState }) {
  const [sortType, setSortType] = sortState;
  const [direction, setDirection] = useState('desc');

  function setType() {
    if (direction === 'desc') {
      setDirection('asc');
      setSortType({ name: 'asc' });
    } else {
      setDirection('desc');
      setSortType({ name: 'desc' });
    }
  }

  return (
    <div className="flex mr-md">
      <div
        onClick={setType}
        className={`text-sm ${sortType.name === 'desc' ? 'bg-darkblue text-white' : 'text-darkgray border border-darkgray'
          }   flex flex-row items-center font-medium cursor-pointer px-sm rounded`}
      >
        A - Z
        <img
          alt=""
          src={sortType.name === 'desc' ? CaretDown : CaretDownGray}
          style={{ height: 5, margin: 0, transform: `rotate(${direction === 'desc' ? '0' : '180'}deg)`, marginLeft: 10 }}
        />
      </div>
    </div>
  );
}

export default function Recruiter() {
  // Pagination
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [total, setTotal] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [sortType, setSortType] = useState({ name: 'desc' });
  const adminID = firebase?.auth().currentUser ? firebase.auth().currentUser.uid : 'not-logged-in'
  const [userRole] = useContext(UserRoleContext);

  const variables = {
    is_like: `%${searchInput}%`,
    order_by: sortType,
    perPage: perPage,
    offset: page * perPage,
  }

  const countVariable = {
    is_like: `%${searchInput}%`
  }
  let ACTIVE_RECRUITER = ALL_RECRUITERS;
  let ACTIVE_RECRUITER_COUNT = ALL_RECRUITERS_TOTAL;
  if (userRole === 'agency') {
    variables.adminID = adminID;
    countVariable.adminID = adminID;
    ACTIVE_RECRUITER = ALL_AGENCY_RECRUITERS;
    ACTIVE_RECRUITER_COUNT = ALL_AGENCY_RECRUITERS_TOTAL
  }
  // Aggregate of the recruiters that is use to show the page count
  const { data: recruitersTotal } = useSubscription(ACTIVE_RECRUITER_COUNT, {
    variables: countVariable,
  });

  useEffect(() => {
    if (recruitersTotal) {
      setTotal(recruitersTotal?.recruiter_aggregate?.aggregate.count || 0);
    }
  }, [recruitersTotal]);

  const [recruiters, setRecruiters] = useState();

  const { data: recruitersQuery, loading, error } = useSubscription(ACTIVE_RECRUITER, {
    variables,
  });

  useEffect(() => {
    if (recruitersQuery) {
      setRecruiters(recruitersQuery.recruiter);
    }
  }, [recruitersQuery]);

  useEffect(() => {
    reset()
  }, [searchInput])

  function reset() {
    setPage(0);
    setPerPage(DEFAULT_PER_PAGE);
  }

  function handlePaginationChange(event) {
    setPage(event.page);
    setPerPage(event.perPage);
  }

  return (
    <div className="flex flex-col h-full pb-lg">
      <div className="mb-md lg:m-0 capitalize flex items-center flex-row justify-between w-full" style={{ fontSize: 21, fontWeight: 500, marginTop: 30, marginBottom: 30 }}>
        <TabTitle>Recruiters</TabTitle>
        <Link className="bg-red text-white font-medium rounded flex items-center justify-center" style={{ fontSize: 14, height: 40, width: 160 }} to="/recruiters/new-recruiter">
          <span style={{ fontSize: 22, marginRight: 8 }}>+</span> Add Recruiter
        </Link>
      </div>
      <div className="mb-4">
        <SearchBox value={searchInput} placeholder="Search by name or email" update={setSearchInput} />
      </div>
      <div className="flex mb-sm">
        <div className="text-sm mr-sm">Sort:</div>
        <AlphabeticalSort sortState={[sortType, setSortType]} />
      </div>
      <div className="bg-white rounded shadow flex-1 mb-lg w-full" style={{ maxWidth: 1340, height: '700px', overflow: 'auto' }}>
        {
          loading ? (
            <div className={`flex justify-center items-center flex-1 candidate-blank`} style={{ width: '100%' }}>
              <div className="bg-lightgray text-darkgray py-sm px-md" style={{ borderRadius: 43 }}>
                Loading...
              </div>
            </div>
          ) : (
            recruiters && recruiters.map((recruiter, index) => <RecruiterEntry key={index} recruiter={recruiter} />)
          )
        }
      </div>
      <div className="recruiters-pagination">
        <CherryPagination page={page} perPage={perPage} total={total} onChange={handlePaginationChange} />
      </div>
    </div>
  );
}
