import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import axios from 'axios';
import firebase from 'firebase/app';
import { navigate } from 'gatsby';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { CSVReader } from 'react-papaparse';
import { EXPRESS_SERVER_URL } from '../../config';
import { generateInviteCode } from '../../utils/getId';
import { generateInviteUrl } from '../../utils/google.helper';
const buttonRef = React.createRef();

const CREATE_CANDIDATE = gql`
  mutation CreateCandidate(
    $id: String
    $name: String
    $email: String
    $phone: String
    $referralName: String
    $recruiter_id: String
    $linkedin: String
    $facebook: String
    $instagram: String
    $twitter: String
    $bio: String
    $salaryMin: Int
    $salaryMax: Int
    $experience: Int
    $approved: Boolean
    $location: jsonb
    $locationPreferences: jsonb
    $attributes: jsonb
    $education: jsonb
    $interestedCity: jsonb
    $preferredSkills: jsonb
    $employment: jsonb
    $invite_code: String
    $invite_deeplink: String
    $invite_code_used_on: String
    $created_at: timestamptz
    $is_enriched: Boolean
  ) {
    insert_candidate(
      objects: {
        id: $id
        name: $name
        email: $email
        phone: $phone
        referralName: $referralName
        recruiter_id: $recruiter_id
        linkedin: $linkedin
        facebook: $facebook
        instagram: $instagram
        twitter: $twitter
        bio: $bio
        salaryMax: $salaryMax
        salaryMin: $salaryMin
        experience: $experience
        approved: $approved
        location: $location
        locationPreferences: $locationPreferences
        attributes: $attributes
        education: $education
        interestedCity: $interestedCity
        preferredSkills: $preferredSkills
        employment: $employment
        invite_code: $invite_code
        invite_deeplink: $invite_deeplink
        invite_code_used_on: $invite_code_used_on
        created_at: $created_at
        is_enriched: $is_enriched
      }
    ) {
      returning {
        id
        name
      }
    }
  }
`;

export default function CandidateImportModal({ state }) {
  const [modal, setModal] = state;
  const [, setFadeDelay] = useState(false);
  const modalRef = useRef();
  const [dataLoading, setDataLoading] = useState(false);

  const [addCandidate, { loading }] = useMutation(CREATE_CANDIDATE);

  useEffect(() => {
    if (modal.open) {
      setFadeDelay(true);
    } else {
      setTimeout(() => {
        setFadeDelay(false);
      }, 300);
    }
  }, [modal]);

  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  const handleOnFileLoad = async (data) => {
    const recruiterID = firebase.auth().currentUser.uid;

    if (data.length > 0) {
      setDataLoading(true);
      for (let i = 1; i < data.length; i++) {
        if (data[i].data[0] && data[i].data[1] && data[i].data[2] && data[i].data[5] && data[i].data[10] && data[i].data[15]) {
          // firebase
          //     .auth()
          //     .fetchSignInMethodsForEmail(data[i].data[1])
          //     .then(signInMethods => {
          //         if (signInMethods.length === 0) {
          //             var exist_in_firebase = false;
          //         } else {
          //             var exist_in_firebase = true;
          //         }
          //     })
          //     .catch();

          const { data: candidateData } = await axios.post(`${EXPRESS_SERVER_URL}/checkCandidate`, {
            candidate_email: data[i].data[1],
            recruiter_id: recruiterID,
          });

          if (candidateData.length == 0) {
            const res = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${data[i].data[5]}&key=${process.env.GATSBY_GOOGLE_PLACES_KEY}`);
            const result = res.data.results[0].geometry.location;

            data[i].data[15] &&
              (await data[i].data[15].split(',').map((attribute) => {
                axios.post(`${EXPRESS_SERVER_URL}/checkAndAddSkill`, {
                  attribute: attribute.trim(),
                });
              }));

            let totalExperience = 0;
            const employmentArr = [
              {
                id:
                  Math.random()
                    .toString(36)
                    .substring(2, 15) +
                  Math.random()
                    .toString(36)
                    .substring(2, 15),
                companyLogo: '',
                companyLocation: data[i].data[11] ? (data[i].data[11].split(',').length > 0 ? data[i].data[11].split(',')[2] : '') : '',
                title: data[i].data[11] ? (data[i].data[11].split(',').length > 0 ? data[i].data[11].split(',')[1] : '') : '',
                companyName: data[i].data[11] ? (data[i].data[11].split(',').length > 0 ? data[i].data[11].split(',')[0] : '') : '',
                description: '',
                tenure: {
                  current:
                    data[i].data[11]
                      .split(',')[3]
                      .split('-')[1]
                      .toLowerCase() == 'present'
                      ? true
                      : false,
                  startDate: data[i].data[11].split(',')[3]
                    ? data[i].data[11].split(',')[3].split('-').length > 0
                      ? new Date(data[i].data[11].split(',')[3].split('-')[0])
                      : ''
                    : '',
                  endDate:
                    data[i].data[11]
                      .split(',')[3]
                      .split('-')[1]
                      .toLowerCase() == 'present'
                      ? new Date()
                      : data[i].data[11].split(',')[3]
                        ? data[i].data[11].split(',')[3].split('-').length > 0
                          ? new Date(data[i].data[11].split(',')[3].split('-')[1])
                          : ''
                        : '',
                },
                skills: [],
              },
            ];

            const start = moment(new Date(data[i].data[11].split(',')[3].split('-')[0]));
            const end =
              data[i].data[11]
                .split(',')[3]
                .split('-')[1]
                .toLowerCase() == 'present'
                ? moment(new Date())
                : moment(new Date(data[i].data[11].split(',')[3].split('-')[1]));
            totalExperience = parseInt(totalExperience) + parseInt(end.diff(start, 'months'));

            data[i].data[12] &&
              (await data[i].data[12].split(';').map((employ) => {
                const empObj = {
                  id:
                    Math.random()
                      .toString(36)
                      .substring(2, 15) +
                    Math.random()
                      .toString(36)
                      .substring(2, 15),
                  companyLogo: '',
                  companyLocation: employ ? (employ.split(',').length > 0 ? employ.split(',')[2] : '') : '',
                  title: employ ? (employ.split(',').length > 0 ? employ.split(',')[1] : '') : '',
                  companyName: employ ? (employ.split(',').length > 0 ? employ.split(',')[0] : '') : '',
                  description: '',
                  tenure: {
                    current: false,
                    startDate: employ.split(',')[3] ? (employ.split(',')[3].split('-').length > 0 ? new Date(employ.split(',')[3].split('-')[0]) : '') : '',
                    endDate:
                      employ
                        .split(',')[3]
                        .split('-')[1]
                        .toLowerCase() == 'present'
                        ? new Date()
                        : employ.split(',')[3]
                          ? employ.split(',')[3].split('-').length > 0
                            ? new Date(employ.split(',')[3].split('-')[1])
                            : ''
                          : '',
                  },
                  skills: [],
                };
                employmentArr.push(empObj);

                const start1 = moment(new Date(employ.split(',')[3].split('-')[0]));
                const end1 =
                  employ
                    .split(',')[3]
                    .split('-')[1]
                    .toLowerCase() == 'present'
                    ? moment(new Date())
                    : moment(new Date(employ.split(',')[3].split('-')[1]));

                totalExperience = parseInt(totalExperience) + parseInt(end1.diff(start1, 'months'));
              }));

            const educationArr = [];
            data[i].data[13] &&
              (await data[i].data[13].split(';').map((edu) => {
                const eduObj = {
                  id:
                    Math.random()
                      .toString(36)
                      .substring(2, 15) +
                    Math.random()
                      .toString(36)
                      .substring(2, 15),
                  degree: edu ? edu.split(',')[1] : '',
                  school: edu ? edu.split(',')[0] : '',
                  tenure: {
                    current: edu.split(',')[2].split('-')[1] == 'present' ? true : false,
                    startDate: edu.split(',')[2] ? (edu.split(',')[2].split('-').length > 0 ? new Date(edu.split(',')[2].split('-')[0]) : '') : '',
                    endDate:
                      edu.split(',')[2].split('-')[1] == 'present'
                        ? new Date()
                        : edu.split(',')[2]
                          ? edu.split(',')[2].split('-').length > 0
                            ? new Date(edu.split(',')[2].split('-')[1])
                            : ''
                          : '',
                  },
                };
                educationArr.push(eduObj);
              }));

            const intrCityArr = [];
            if (data[i].data[18]) {
              const countCity = data[i].data[18].split(',').length;
              for (let j = 0; j < countCity; j++) {
                const resp = await axios.get(
                  `https://maps.googleapis.com/maps/api/geocode/json?address=${data[i].data[18].split(',')[j]}&key=${process.env.GATSBY_GOOGLE_PLACES_KEY}`,
                );
                const result1 = resp.data.results[0].geometry.location;

                intrCityArr.push({
                  ...result1,
                  city: data[i].data[18].split(',')[j],
                });
              }
            }

            const inviteCode = generateInviteCode();
            const inviteDeepLink = await generateInviteUrl({ inviteCode });

            const candidateObject = {
              id: Math.random()
                .toString(28)
                .substring(2),
              name: data[i].data[0],
              email: data[i].data[1],
              phone: data[i].data[2],
              linkedin: data[i].data[3],
              referralName: data[i].data[4],
              location: { ...result, city: data[i].data[5] },
              salaryMin: data[i].data[6] || 0,
              salaryMax: 1000,
              experience: totalExperience ? Math.floor(totalExperience / 12) : 0,
              facebook: data[i].data[7],
              instagram: data[i].data[8],
              twitter: data[i].data[9],
              bio: (data[i].data[10] || '').replace(/\n/g, '<br />'),

              employment: employmentArr ? employmentArr : '',
              education: educationArr ? educationArr : '',

              locationPreferences: {
                remote: data[i].data[16],
                currentLocation: false,
                willingToRelocate: data[i].data[17],
              },
              preferredSkills: data[i].data[14] ? data[i].data[14].split(',') : [],
              attributes: data[i].data[15] ? data[i].data[15].split(',') : [],
              interestedCity: intrCityArr,

              recruiter_id: recruiterID,
              approved: true,
              invite_code: inviteCode,
              is_enriched: true,
              invite_deeplink: inviteDeepLink,
              invite_code_used_on: null,
              created_at: new Date().toISOString(),
            };

            // Default candidates will have remote preselected
            if (candidateObject?.locationPreferences) {
              candidateObject.locationPreferences.remote = true;
            }
            // If location preference is null or not defined, Set remote as true and other to false
            if (!candidateObject?.locationPreferences) {
              candidateObject.locationPreferences = {
                remote: true,
                currentLocation: false,
                willingToRelocate: false,
              }
            }

            addCandidate({
              variables: candidateObject,
            });
          }
        }
      }
      if (buttonRef.current) {
        buttonRef.current.removeFile(data);
      }

      if (!loading) {
        setDataLoading(false);
        setModal({
          open: false,
        });
        navigate('/dashboard/candidates-in-process');
      }
    }
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  return (
    <React.Fragment>
      <div
        onClick={() => setModal({ data: null, open: true })}
        className={`candidate-import-modal-js fixed inset-0 ${modal.open ? 'flex' : 'hidden'} items-center justify-center font-main`}
        style={{ backgroundColor: 'rgba(34, 46, 66, 0.9)', zIndex: 20000 }}
        data-keyboard="false"
      >
        <div ref={modalRef} onClick={(e) => e.stopPropagation()} className="bg-white px-xl py-md rounded shadow flex flex-col" style={{ minHeight: 200, minWidth: 450 }}>
          <div className="text-darkblue font-medium border-b flex justify-between pb-sm" style={{ maxWidth: 'calc(100vw - 30px)' }}>
            Import Candidates
            <div onClick={() => setModal({ data: null, open: false })} className="text-darkgray hover:text-darkblue cursor-pointer">
              x
            </div>
          </div>
          <div className="text-darkblue text-sm text-center py-lg">
            <CSVReader ref={buttonRef} onFileLoad={handleOnFileLoad} onError={handleOnError} noClick noDrag>
              {({ file }) => (
                <aside
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: 10,
                  }}
                >
                  <button
                    type="button"
                    onClick={handleOpenDialog}
                    style={{
                      borderRadius: 0,
                      marginLeft: 0,
                      marginRight: 0,
                      width: '40%',
                      paddingLeft: 0,
                      paddingRight: 0,
                      background: '#366992',
                      color: '#FFF',
                    }}
                  >
                    {dataLoading ? 'loading...' : 'Select to upload CSV file'}
                  </button>
                  <div
                    style={{
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderColor: '#ccc',
                      height: 45,
                      lineHeight: 2.5,
                      paddingLeft: 13,
                      paddingTop: 3,
                      width: '60%',
                    }}
                  >
                    {file && file.name}
                  </div>
                </aside>
              )}
            </CSVReader>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
