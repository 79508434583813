import React, { useState } from 'react'
import { TabTitle } from '../../components/common'
import CsvUploader from './CsvUploader'
import CsvTable from './tables/CsvTable';
import axios from 'axios'
import { EXPRESS_SERVER_URL } from '../../config';
import firebase, { auth } from 'firebase/app';
import { toast, ToastContainer } from 'material-react-toastify';
import TalentFinderCheckboxes from './TalentFinderCheckboxes';

function CsvSheet() {
  const [sheetData, setSheetData] = useState([]);
  const [linkedinData, setLinkedInData] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [configData, setConfigData] = useState({
    contact_number: false,
    personal_email: false,
  })
  const requiredReaders = ['first_name','last_name', 'emails', 'tags', 'contact_numbers', 'company_domain', 'linkedin_url']
  const onChangeCSVData = (data) => {
    const headers = Object.keys(data[0]);
    const isMatchedWithHeaders = headers?.every(elem => requiredReaders.includes(elem));
    if(!isMatchedWithHeaders){
      toast.error('Required readers is missing, please download sample csv file')
      return;
    }
    setSheetData(data);
  };

  const handleCsvSearchClick = async () => {
    try {
      const recruiter_id = firebase.auth().currentUser && firebase.auth().currentUser.uid

      const { data: importerData } = await axios.post(`${EXPRESS_SERVER_URL}/checkImporter`, {
        recruiter_id,
        account_type: 'loxo',
      });

      const { data: talentFinderRes } = await axios.post(`${EXPRESS_SERVER_URL}/talent-finder`, {
        users: sheetData,
        auth: importerData && importerData[0] && importerData[0].authorization ? importerData[0].authorization : null,
        config: configData,
      })

      if (talentFinderRes?.status === 'success') {
        toast.success(talentFinderRes?.message);
        setSheetData([]);
        setUploadedFiles([]);
      }
    } catch (error) {
      toast.error('error in finding talent')
    }
  };

  const downloadCSVFile = async () => {
    try {
      const data = [
        {first_name: 'John', last_name: 'Peter', company_domain: 'https://www.example.com', contact_numbers: '12124567890', emails: 'sample@gmail.com', tags: "tag1##tag2", linkedin_url: 'https://www.linkedin.com/in/person'},
      ];
      const header = Object.keys(data[0]).join(',');
      // const rows = data.map(obj => Object.values(obj).join(',')).join('\n');    
      const rows = data.map(obj => {
        const emails = obj.emails.split('##').join(', ');
        const tags = obj.tags.split('##').join(', ');
        return `${obj.first_name},${obj.last_name},${obj.company_domain},${obj.contact_numbers},"${emails}","${tags}",${obj.linkedin_url}`;
      }).join('\n');  
      const csvContent =
      "data:text/csv;charset=utf-8," + `${header}\n${rows}`;
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "sampleData.csv");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
       console.log({error})
    }
  }

  return (
    <div className="mt-4">
      <div className="flex justify-between flex-row align-middle py-3 w-full">
        <div className='w-1/3 flex'>
          <TabTitle showBackButton={true}>{"Search New Talent"}</TabTitle>
        </div>
        <a onClick={downloadCSVFile} className='font-medium rounded flex items-center justify-center cursor-pointer w-400 underline underline-offset-8 text-red'>Download Sample CSV File</a>
        {sheetData?.length ? (
          <span onClick={handleCsvSearchClick} className="bg-red enrich-add-btn text-white hover:text-white font-medium rounded flex items-center justify-center cursor-pointer w-300 h-40">
            Search Selected Profile
          </span>
        ) : null}
      </div>
      <TalentFinderCheckboxes setConfigData={setConfigData}/>
      <CsvUploader onChangeCSVData={onChangeCSVData} uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} />
      <div>
        {sheetData?.length ? <CsvTable sheetData={sheetData || []} /> : null}
      </div>
      <ToastContainer style={{ width: 'fit-content' }} theme="dark" position="bottom-center" autoClose={3000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  )
}

export default CsvSheet;