import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import _ from 'lodash';
import firebase from 'firebase/app';
import { navigate } from 'gatsby';
import { find, isEmpty, isNil, not, propOr } from 'ramda';
import React, { useEffect, useState } from 'react';
import { RequestInterviewButton } from '../../../components/common';
import RescindButton from '../../../components/common/RescindButton';
import UnblockButton from '../../../components/common/UnblockButton';
import DollarSign from '../../../images/dollarsign.svg';
import BriefcaseIcon from '../../../images/experience.png';
import EyeIcon from '../../../images/eye-solid.svg';
import ChatIcon from '../../../images/chat.png';
import favIcon from '../../../images/favorite.png';
import LocationPin from '../../../images/location.svg';
import unfavIcon from '../../../images/unfavorite.png';
import UserAvatar from '../../../images/user-avatar.png';
import { formatSalary } from '../../../utils/formatter';
import Loader from 'react-loader-spinner';
import RemoveConfirmModal from '../../jobs/remove-confirm-modal';
import ProfileImage from '../profile/profile-image';
import moment from 'moment';
import OpenToHire from '../../../components/common/OpenToHire';
import CandidateFav from '../candidate-fav';
import CandidateAction from '../candidate-action';

const setCandidate = (candidate) => ({
  id: propOr('no-id', 'id')(candidate),
  profilePicture: propOr(UserAvatar, 'profilePicture')(candidate),
  name: propOr('Candidate', 'name')(candidate),
  employment: propOr([], 'employment')(candidate),
  location: propOr({ city: 'No city provided' }, 'location')(candidate),
  experience: propOr(0, 'experience')(candidate),
  salaryMax: propOr(0, 'salaryMax')(candidate),
  salaryMin: propOr(0, 'salaryMin')(candidate),
});

const Name = ({ candidate, name, blur, status, isRequestedCandidates, isInterestedCandidates, state }) => {
  const [setDetailModal] = state;

  const getCandidateDetail = (candidate) => {
    setDetailModal({ open: true, data: { candidate, status } });
  };

  return (
    <div style={{ display: 'inline-flex' }}>
      <div
        className="lg:p-0 cursor-pointer "
        onClick={() => navigate(`/candidates/${candidate.id}`, { state: { candidate, status, isRequestedCandidates, isInterestedCandidates } })}
        style={{ fontWeight: 500, color: blur && 'transparent', textShadow: blur && '0 0 7px black' }}
      >
        {blur ? 'Candidate Name' : name}
      </div>
      {/* <div className="flex items-center my-0">
        <img alt="" src={EyeIcon} title='Preview' onClick={() => getCandidateDetail(candidate)} style={{ width: 20, height: 20, cursor: `pointer`, marginLeft: 10, marginBottom: 0 }} />
      </div> */}
    </div>
  );
};

const CurrentEmployer = ({ employment, blur }) => {
  const currentEmployer = find((em) => em.tenure.current, employment);
  const title = propOr('', 'title')(currentEmployer);
  const companyName = propOr('', 'companyName')(currentEmployer);
  return (
    <>
      {not(isNil(currentEmployer)) && (
        <div className="items-center font-medium" style={{ fontSize: 14, maxWidth: '90%' }}>
          {not(isEmpty(title)) && <div className="text-darkblue mr-sm">{title}</div>}
          {not(isEmpty(companyName)) && (
            <div className="text-darkgray" style={{ fontSize: 14, filter: blur && 'blur(3px)' }}>
              {blur ? 'Company Name' : companyName}
            </div>
          )}
        </div>
      )}
    </>
  );
};

const Details = ({ candidate, name, mutualInterest, employment, blur, status, isRequestedCandidates, isInterestedCandidates, state }) => {
  const [setDetailModal] = state;

  return (
    <div style={{ width: '250px' }} className="flex flex-col">
      <Name
        name={name}
        blur={blur}
        candidate={candidate}
        status={status}
        isRequestedCandidates={isRequestedCandidates}
        isInterestedCandidates={isInterestedCandidates}
        state={[setDetailModal]}
      />
      <CurrentEmployer employment={employment} blur={blur} />
    </div>
  );
};

const UPDATE_FAVORITE = gql`
  mutation insertFavCand($candidate_id: String, $company_id: String) {
    insert_favorite_candidates(objects: { candidate_id: $candidate_id, company_firebase_id: $company_id }) {
      affected_rows
      returning {
        candidate_id
        company_firebase_id
      }
    }
  }
`;

const DELETE_FAVORITE = gql`
  mutation deleteFavCand($candidate_id: String, $company_id: String) {
    delete_favorite_candidates(where: { candidate_id: { _eq: $candidate_id }, company_firebase_id: { _eq: $company_id } }) {
      affected_rows
      returning {
        candidate_id
        company_firebase_id
      }
    }
  }
`;

const REMOVE_COMPANY_REQUEST = gql`
  mutation deleteCompanyRequest($candidate_id: String, $company_id: uuid) {
    delete_company_request(where: { candidate_id: { _eq: $candidate_id }, company_id: { _eq: $company_id } }) {
      affected_rows
    }
  }
`;

const CandidateListItem = ({
  candidate: { candidate: _candidate, status: _status, isFavorite, isDirectRequest },
  companyID,
  remainingCredit,
  isRequestedCandidates,
  isInterestedCandidates,
  subscriptionType,
  isSavedCandidates,
  isFavoriteCandidates,
  state,
  onChange,
  handleFavStatusChange,
  handleCandidateStatusChange,
  excludeApprovedCandidate,
  handleCandidateCancelStatus
}) => {
  const companyId = firebase.auth().currentUser && firebase.auth().currentUser.uid;

  const [candidate, setCandidateState] = useState(_candidate);
  const [status, setStatus] = useState(_status);

  const _candidateIsInterested = (c = candidate) => isInterestedCandidates !== undefined ? isInterestedCandidates : getCandidateIs(c, 'interested');
  const _candidateIsRequested = (c = candidate) => isRequestedCandidates !== undefined ? isRequestedCandidates : getCandidateIs(c, ['requested', 'request']);

  const [candidateIsInterested, setCandidateIsInterested] = useState(_candidateIsInterested());
  const [candidateIsRequested, setCandidateIsRequested] = useState(_candidateIsRequested());

  const { id, name, employment, location, experience, salaryMin } = setCandidate(candidate);
  const mutualInterest = ['approved', 'hired'].includes(status);

  const [setDetailModal] = state;


  const [favStatus, setFavStatus] = useState(isFavorite);
  const [loading, setLoading] = useState(false);

  const [updateFavorite] = useMutation(UPDATE_FAVORITE);

  const [deleteFavorite] = useMutation(DELETE_FAVORITE);

  useEffect(() => {
    setFavStatus(isFavorite || candidate.isFavorite);
    setCandidateIsInterested(_candidateIsInterested());
    setCandidateIsRequested(_candidateIsRequested());
  }, [candidate]);

  useEffect(() => {
    isInterestedCandidates = _candidateIsInterested();
    isRequestedCandidates = _candidateIsRequested();
  }, [candidate, status])

  const favUnfavCand = (isfav) => {
    if (!isfav) {
      const insertdata = {
        company_id: companyId,
        candidate_id: candidate.id,
      };
      updateFavorite({
        variables: insertdata,
      });
      setFavStatus(true);
    } else {
      const deletedata = {
        company_id: companyId,
        candidate_id: candidate.id,
      };
      deleteFavorite({
        variables: deletedata,
      });
      setFavStatus(false);
    }
    if (onChange) {
      const updates = _.cloneDeep(candidate);
      // Temporary key to mark candidate favorite's
      updates.isFavorite = !isfav;
      onChange(updates);
    }
  };

  const [open, setOpen] = React.useState(false);
  const [removeCompanyRequest] = useMutation(REMOVE_COMPANY_REQUEST);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function getCandidateStatus(candidate) {
    return candidate.status;
  }

  /**
   * If the candidate has a request from this company, return the request status, otherwise return the
   * candidate status.
   * @param candidate - The candidate object
   * @returns The status of the candidate or the request.
   */
  function getCandidateOrRequestStatus(candidate) {
    const request = (candidate.company_requests || []).find((request) => request.company_id == companyId || request.company?.adminID == companyId);
    return request?.status || candidate.status;
  }
  /**
   * It returns true if the candidate's status is either approved or accepted
   * @param candidate - The candidate object
   * @returns A boolean value.
   */
  function getCandidateOrRequestStatusIsApproved(candidate) {
    const status = getCandidateOrRequestStatus(candidate);
    return status === 'approved';
  }
  /**
   * It returns true if the candidate's status is approved, requested, pending, request, or if the
   * company request is approved
   * @param candidate - The candidate object
   */
  function getCandidateCanBeInterviewed(candidate) {
    const status = candidate.status;
    return status === 'approved' || status === 'requested' || status === 'pending' || status === 'request' || getCompanyRequestIsApproved(candidate);
  }

  /**
   * If the candidate has a company request for the company, and the request is accepted, return true.
   * @param candidate - The candidate object
   * @returns A boolean value.
   */
  function getCompanyRequestIsApproved(candidate) {
    const request = (candidate.company_requests || []).find((request) => request.company_id == companyId || request.company?.adminID == companyId);
    return request.status === 'approved';
  }

  /**
   * If the candidate's status is in the array of statuses, return true, otherwise return false.
   * @param candidate - The candidate object
   * @param status - The status you want to check for.
   */
  function getCandidateIs(candidate, status) {
    const candidateStatus = getCandidateOrRequestStatus(candidate);
    return status instanceof Array ? status.indexOf(candidateStatus) > -1 : candidateStatus === status;
  }

  /**
   * Update Candidate status
   * @param {string} newStatus updated status
   */
  function updateStatus(newStatus, updates) {
    const request = (candidate.company_requests || []).findIndex((request) => request.company_id == companyId || request.company?.adminID == companyId);
    const cand = { ...candidate, status: newStatus };
    if (request > -1 && cand?.company_requests?.length) {
      cand.company_requests[request] = updates?.status;
    }
    setCandidateState(cand);
    setStatus(newStatus);
    setCandidateIsInterested(_candidateIsInterested(cand));
    setCandidateIsRequested(_candidateIsRequested(cand));
  }

  const getCandidateDetail = (candidate, _status) => {
    setDetailModal({ open: true, data: { candidate, status }, actionStatus: _status });
  };

  const getUnlockedDate = (candidate) => {
    const companyRequest = candidate.find(elem => elem.unlocked_date);
    return moment(new Date(companyRequest?.unlocked_date)).format('MM/DD/YYYY') || 'N/A';
  }
  const unlockedDate = getUnlockedDate(candidate?.company_requests);

  return (
    <>
      <td>
        <div className="w-12 pl-4">
          <ProfileImage
            width={49}
            height={49}
            approved={mutualInterest}
            isDisabled={candidate?.is_disabled}
            src={!mutualInterest ? UserAvatar : candidate.profilePictureURL ? candidate.profilePictureURL : UserAvatar}
          />
        </div>
      </td>
      <td>
        <div style={{ width: '260px' }} className='flex justify-between items-center'>
          <Details
            candidate={candidate}
            name={name}
            employment={employment}
            blur={!mutualInterest}
            status={status}
            isRequestedCandidates={candidateIsInterested}
            isInterestedCandidates={candidateIsInterested}
            state={[setDetailModal]}
            mutualInterest={mutualInterest}
          />
          <div className='flex flex-col items-center justify-center gap-y-3'>
            <div className="flex items-center my-0">
              <img alt=""
                title='Preview'
                src={EyeIcon} onClick={() => getCandidateDetail(candidate, _status)} style={{ width: 20, height: 20, cursor: `pointer`, marginLeft: 0, marginBottom: 0 }} />
            </div>
            {mutualInterest ? <div
              onClick={() => navigate('/chat', { state: { candidate: { id: candidate.id, name, profilePicture: candidate?.profilePictureURL, messages: [] } } })}
              className="flex items-center my-0">
              <img
                title='Chat'
                alt=""
                src={ChatIcon} onClick={() => navigate('/chat', { state: { candidate: { id: candidate.id, name, profilePicture: candidate?.profilePictureURL, messages: [] } } })}
                style={{ width: 20, height: 17, cursor: `pointer`, marginLeft: 0, marginBottom: 0 }}
              />
            </div> : null}
          </div>
        </div >
      </td >
      <td className="px-0">
        <div className="w-32  ">
          <div className={`text-darkgray flex items-center  `} style={{ fontWeight: 500 }}>
            <img alt="" src={BriefcaseIcon} className="mx-2 w-4 my-0" />
            {`${experience} years`}
          </div>
        </div>
      </td>
      <td className="px-0">
        <div className="w-40 ">
          <div className=" text-darkgray flex items-center mr-sm lg:mr-0 justify-center" style={{ fontWeight: 500, marginRight: 19 }}>
            {salaryMin ? <img alt="" src={DollarSign} style={{ height: 15, margin: 0, marginLeft: 2, marginRight: 8 }} /> : ''}
            <span>{salaryMin ? `${formatSalary({ salary: salaryMin })} +` : 'Negotiable'}</span>
          </div>
        </div>
      </td>
      <td className="px-0">
        <div style={{ width: '200px' }}>
          <div className={`  flex justify-start items-center mr-sm lg:mr-0 `} style={{ color: 'hsla(0, 0%, 0%, 0.8)' }}>
            <img alt="" src={LocationPin} className="mx-2 my-0" width={10} />
            {location.city}
          </div>
        </div>
      </td>
      {!isSavedCandidates ? <td>
        <CandidateFav handleFavStatusChange={handleFavStatusChange} candidate={candidate} onChange={onchange} />
      </td> : ''}
      {/* Chat */}
      {/* <td style={{ paddingLeft: 0, paddingRight: 0 }}>
        {
          mutualInterest ?
            (<div
              onClick={() => navigate('/chat', { state: { candidate: { id: candidate.id, name, profilePicture: candidate?.profilePictureURL, messages: [] } } })}
              className="text-green cursor-pointer text-center"
              style={{ fontSize: 14 }}
            >
              Chat
            </div>) : null

        }
      </td> */}
      {!isSavedCandidates ? <td>
        <CandidateAction
          remainingCredit={remainingCredit}
          status={_status}
          handleCandidateStatusChange={handleCandidateStatusChange}
          excludeApprovedCandidate={excludeApprovedCandidate}
          candidate={candidate}
          companyID={companyID}
          subscriptionType={subscriptionType}
          isInterestedCandidates={isInterestedCandidates}
          isRequestedCandidates={isRequestedCandidates}
          handleCandidateCancelStatus={handleCandidateCancelStatus}
        />
      </td> : ''}
      {isSavedCandidates ? <td className="px-0">
        <div className="w-30">
          <div className=" text-darkgray flex items-center mr-sm lg:mr-0 justify-center" style={{ fontWeight: 500, width: '6rem' }}>
            <OpenToHire candidateID={id} status={status} companyID={companyID} />
          </div>
        </div>
      </td> : ''}

      {isSavedCandidates ? <td className="px-0">
        <div className="w-40 ">
          <div className=" text-darkgray flex items-center mr-sm lg:mr-0 justify-center" style={{ fontWeight: 500 }}>
            {unlockedDate}
          </div>
        </div>
      </td> : ''}
    </>
  );
};

export default CandidateListItem;
