// checkbox icons: https://fontawesome.com/license

import { useMutation, useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import firebase from "firebase/app";
import "firebase/auth";
import { navigate } from "gatsby";
import _ from "lodash";
import moment, { fn } from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Arrow, TabTitle } from "../../components/common";
import CherryPagination from "../../components/common/Pagination/Pagination";
import { GlobalFilterContext } from "../../components/layout/layout";
import { ApolloContext } from "../../context/Apollo";
import CheckboxEmpty from "../../images/checkbox-empty.svg";
import CheckboxSelected from "../../images/checkbox-selected.svg";
import UserAvatar from "../../images/user-avatar.png";
import { DEFAULT_PER_PAGE, NOTIFIACTION_STACK } from "../../utils/constants";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { DateRange } from "../../components/common/daterange/DateRange";
import InfiniteScroll from "react-infinite-scroll-component";
import "./Notification.css";

const READ_NOTIFICATION = gql`
  mutation readNotification($id: uuid) {
    update_notification(where: { id: { _eq: $id } }, _set: { read: true }) {
      affected_rows
    }
  }
`;

const UNREAD_NOTIFICATION = gql`
  mutation unreadNotification($id: uuid) {
    update_notification(where: { id: { _eq: $id } }, _set: { read: false }) {
      affected_rows
    }
  }
`;

const RECRUITER_NOTIFICATION_PROJECTION = `
  id
  created_at
  candidate {
    id
    profilePictureURL
    name
    email
    phone
    resumeFileName
    resumeURL
    linkedin
    referralName
    recruiter {
      id
      name
    }
    location
    facebook
    instagram
    twitter
    experience
    bio
    employment
    education
    certifications
    attributes
    preferredSkills
    notes
    salaryMax
    salaryMin
    locationPreferences
  }
  type
  read
  payload
`;

const RECRUITER_NOTIFICATIONS = gql`
  query recruiterNotifications(
    $recruiter_id: String
    $start_date: timestamptz
    $end_date: timestamptz
    $type: String
    $offset: Int
    $limit: Int
  ) {
    notification_aggregate(
      where: {
        recruiter_id: { _eq: $recruiter_id }
        type: { _eq: $type }
        created_at: { _gte: $start_date, _lte: $end_date }
      }
    ) {
      aggregate {
        count
      }
    }

    notification(
      where: {
        recruiter_id: { _eq: $recruiter_id }
        type: { _eq: $type }
        created_at: { _gte: $start_date, _lte: $end_date }
      }
      order_by: { read: asc, created_at: desc }
      offset: $offset
      limit: $limit
    ) {
      ${RECRUITER_NOTIFICATION_PROJECTION}
    }
  }
`;

const READ_ALL_NOTIFICATIONS = gql`
  mutation readAllNotifications($id: String) {
    update_notification(where: { recruiter_id: { _eq: $id } }, _set: { read: true }) {
      affected_rows
    }
  }
`;

const GET_COMPANY_IDS_FROM_JOB_IDS = gql`
  query getCompanyIdsFromJobIds($jobIds: [uuid!]) {
    job(where: { id: { _in: $jobIds } }) {
      id
      company_id
    }
  }
`;

const GET_ALL_RECRUITER_COMPANY_IDS = gql`
  query getCompanyIds($adminId: String) {
    companies(where: { adminID: { _eq: $adminId } }) {
      id
    }
  }
`;

const notificationTypes = {
  all: "All Notifications",
  profile_change_request: "Profile Edit",
  interview: "Interviews",
  "job-interest": "Job Interest",
  "company-interest": "Company",
  chat: "Chat",
  reassignment: "New Match",
};

function NotificationFilterSelect({ value, update }) {
  return (
    <div className="relative">
      <select
        value={value}
        onChange={(e) => update(e.target.value)}
        className="rounded font-medium p-sm border border-darkgray cursor-pointer"
        style={{
          fontSize: 14,
          WebkitAppearance: "none",
          backgroundColor: "#D0D2D5",
          color: "#686868",
          paddingRight: 30,
        }}
      >
        {React.Children.toArray(
          Object.entries(notificationTypes).map(([type, label]) => {
            return <option value={type}>{label}</option>;
          })
        )}
      </select>
      <Arrow
        color="#686868"
        className="absolute"
        style={{ zIndex: 21, right: 12, top: 13 }}
        direction="down"
      />
    </div>
  );
}

const getNotificationTitle = (notify, payload) => {
  let message = "";
  let candidateName = notify?.candidateName;
  let companyName = notify?.companyName || "";
  let jobTitle = payload?.payload?.title || "";
  let type = notify.type;

  switch (type) {
    case "profile_change_request":
      message = `${candidateName} requested to change their profile.`;
      break;
    case "interview":
      message = `${candidateName} scheduled an interview`;
      break;
    case "job-interest":
      message = `${candidateName} is interested in the ${jobTitle} job`;
      if (companyName) {
        message += ` at ${companyName}`;
      }
      break;
    case "company-interest":
      message = `A company is interested in more information about ${candidateName}`;
      if (companyName) {
        message = `${companyName} is interested in more information about ${candidateName}`;
      }
      break;
    case "chat":
      message = `${candidateName} sent you a new message`;
      break;
    case "reassignment":
      message = `You've been matched with ${candidateName}!`;
      break;
    default:
      message = "";
      break;
  }
  return message;
};

export const Notifications = () => {
  const { apolloClient } = useContext(ApolloContext);

  // Pagination
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [total, setTotal] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const [notifications, setNotifications] = useState([]);
  const [userCompanyIds, setUserCompanyIds] = useState([]);
  const [selectedNotifications, setSelectedNotifications] = useState([]);
  const [setNotificationRead] = useMutation(READ_NOTIFICATION);
  const [setNotificationUnread] = useMutation(UNREAD_NOTIFICATION);
  const [readAllNotifications] = useMutation(READ_ALL_NOTIFICATIONS);
  const { globalFilter, setGlobalFilter } = useContext(GlobalFilterContext);
  const [notificationType, setNotificationType] = useState('all');
  const [loading, setLoading] = useState(false);
  const [respNotifications, setRespNotifications] = useState([]);

  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });

  async function fetchCompanyIds() {
    try {
      const response = await apolloClient.query({
        query: GET_ALL_RECRUITER_COMPANY_IDS,
        variables: {
          adminId:
            firebase.auth().currentUser && firebase.auth().currentUser.uid,
        },
      });

      const companies = response.data.companies.map((a) => a.id);
      setUserCompanyIds(companies);
      return Promise.resolve(companies);
    } catch (e) {
      console.error("ERROR_FETCH_COMPANIES", e);
      return Promise.reject(e);
    }
  }

  function resetNotifications() {
    setHasMore(true);
    setRespNotifications([]);
    setNotifications([]);
    setTotal(0);
    setPage(0);
  }

  // useEffect(() => {
  //   resetNotifications();
  //   fetchCompanyIds()
  //     .then(() => {
  //       fetchNotifications();
  //     })
  //     .catch((error) => {
  //       console.error("ERROR_INITIALIZE_NOTIFICATIONS", error);
  //     });
  // }, []);

  useEffect(() => {
    fetchCompanyIds().then(() => {
      resetNotifications()
      fetchNotifications(0);
    }).catch((error) => {
      console.error("ERROR_INITIALIZE_NOTIFICATIONS", error);
    });
  }, [dateRange, notificationType]);

  async function mergeNotifications(notification, count) {
    // Group notifications
    let groupedNotifications = [];
    try {
      if (notification?.length) {
        const jobIds = notification
          .filter(
            (notify) => notify?.type === "job-interest" && notify?.payload?.id
          )
          .map((notify) => notify?.payload?.id);

        const jobRes = await apolloClient.query({
          query: GET_COMPANY_IDS_FROM_JOB_IDS,
          variables: { jobIds: jobIds },
        });

        const groupCriteria = ["profile_change_request", "chat"];

        groupedNotifications = notification.reduce((acc, nf) => {
          const notify = nf;
          const content = {
            id: notify?.id,
            candidateId: notify?.candidate?.id,
            candidateName: notify?.candidate?.name,
            candidate: notify?.candidate,
            profileImage: notify.candidate.profilePictureURL
              ? notify.candidate.profilePictureURL
              : UserAvatar,
            receivedAt: notify?.created_at,
            candidate_id: notify?.candidate?.id,
            type: notify?.type,
            title: "",
            read: notify?.read,
            stack: NOTIFIACTION_STACK.SINGLE,
            data: [],
            queue: [],
            count: 0,
            companyId: notify?.payload?.company?.id || "",
            companyName: notify?.payload?.company?.name || "",
            jobOrCompanyId: notify?.payload?.id,
          };

          if (
            jobRes?.data?.job?.length &&
            !content.companyId &&
            content.type === "job-interest"
          ) {
            const companyFromJob = jobRes?.data?.job.find(
              (j) => j.id === notify?.payload?.id
            );
            if (companyFromJob?.company_id) {
              content.companyId = companyFromJob?.company_id || "";
            }
          }

          if (notify.type === "company-interest") {
            content.companyId = notify?.payload?.id;
          }

          content.title = getNotificationTitle(content, notify);

          if (groupCriteria.includes(notify.type) && !notify.read) {
            const idx = acc.findIndex(
              (n) =>
                n.type === content.type &&
                n.candidate_id === content.candidate_id
            );
            if (idx > -1 && acc[idx]?.data && Array.isArray(acc[idx].data)) {
              content.data.push(notify);
              const stack = [...acc[idx].data, notify].sort((a, b) =>
                a.created_at < b.created_at
                  ? -1
                  : a.created_at > b.created_at
                    ? 1
                    : 0
              );
              const current = _.cloneDeep(acc[idx]);
              const queue = [current, content, ...current.queue];
              acc[idx].queue = queue.sort((a, b) =>
                a.receivedAt < b.receivedAt
                  ? -1
                  : a.receivedAt > b.receivedAt
                    ? 1
                    : 0
              );
              acc[idx].stack = NOTIFIACTION_STACK.GROUPED;
              acc[idx].data = stack;
              acc[idx].count = queue.length;
              acc[idx].id = queue[0].id;
              acc[idx].receivedAt = queue[0].created_at;
              acc[idx].title = queue[0].title;
            } else {
              content.data.push(notify);
              content.count += 1;
              acc.push(content);
            }
          } else {
            content.data.push(notify);
            content.count += 1;
            acc.push(content);
          }
          return acc;
        }, []);

        // Check if there are more items to fetch
        if (respNotifications?.length === count) {
          setHasMore(false);
        }

        // Increment the page number
        setPage(page + 1);
      }
    } catch (error) {
      console.error("ERROR_SET_NOTIFICATIONS", error);
    }

    setRespNotifications(prev => ([...prev, ...notification]));
    setNotifications(prev => ([...prev, ...groupedNotifications]));
  }

  const fetchNotifications = async (_offset) => {
    try {
      const variables = {
        recruiter_id:
          firebase.auth().currentUser && firebase.auth().currentUser.uid,
        type: notificationType === "all" ? null : notificationType,
        limit: perPage,
        start_date: null,
        end_date: null,
        offset: (_offset ?? page) * perPage,
      };


      if (dateRange.startDate && dateRange.endDate) {
        variables.start_date = moment(new Date(dateRange.startDate)).startOf('day').toISOString();
        variables.end_date = moment(new Date(dateRange.endDate)).endOf('day').toISOString();
      }

      setLoading(true);

      const response = await apolloClient.query({
        query: RECRUITER_NOTIFICATIONS,
        variables,
        fetchPolicy: "no-cache",
      });

      if (response?.data) {
        try {
          const notificationsResponse = response?.data;
          const {
            notification = [],
            notification_aggregate: { aggregate: { count = 0 } } = {
              aggregate: { count: 0 },
            },
          } = notificationsResponse;
          setTotal(count || 0);
          mergeNotifications(notification || [], count);
          setLoading(false);
        } catch (error) {
          setRespNotifications([]);
          setNotifications([]);
          setTotal(0);
        }
      } else {
        console.error("ERROR_FETCH_NOTIFICATIONS", "NO_DATA");
      }
    } catch (error) {
      console.error("ERROR_FETCH_NOTIFICATIONS", error);
    }
  };

  async function unReadNotification({ id }) {
    await setNotificationUnread({
      variables: { id },
      refetchQueries: ["recruiterNotifications"],
    });
  }

  async function readSingleNotification({ id }) {
   await setNotificationRead({
      variables: { id },
      refetchQueries: ["recruiterNotifications"],
    });
    
  }

  async function unReadNotifications() {
    for (const notification of selectedNotifications) {
      await unReadNotification(notification)
    }
    const selectedNotificationsIds = selectedNotifications?.map(elem => { return elem.id }) || []
    const readNotification = notifications?.map(notificationItem => {
      if (selectedNotificationsIds?.includes(notificationItem.id)) {
        if ((notificationItem?.queue || [])?.length) {
          return notificationItem?.queue?.map(elem => { return { ...elem, read: false } })
        }
        return { ...notificationItem, read: false }
      }
      return notificationItem;
    });
    setNotifications(readNotification);
    setSelectedNotifications([]);
  }

  async function readNotifications() {
    for (const notification of selectedNotifications) {
      await readSingleNotification(notification)
    }
    const selectedNotificationsIds = selectedNotifications?.map(elem => { return elem.id }) || []
    const readNotification = notifications?.map(notificationItem => {
      if (selectedNotificationsIds?.includes(notificationItem.id)) {
        if ((notificationItem?.queue || [])?.length) {
          return notificationItem?.queue?.map(elem => { return { ...elem, read: true } })
        }
        return { ...notificationItem, read: true }
      }
      return notificationItem;
    });
    setNotifications(readNotification);
    setSelectedNotifications([]);
  }

  function checkIfChecked(notification) {
    return selectedNotifications.includes(notification);
  }

  function toggleCheckBox(notification) {
    const alreadyChecked = checkIfChecked(notification);

    if (alreadyChecked) {
      const newSelectedArray = selectedNotifications.filter(
        (n) => n !== notification
      );
      setSelectedNotifications(newSelectedArray);
    } else {
      const newSelectedArray = [...selectedNotifications, notification];
      setSelectedNotifications(newSelectedArray);
    }
  }

  function handlePaginationChange(event) {
    setPage(event.page);
    setPerPage(event.perPage);
  }

  useEffect(() => {
    if (notificationType) {
      setGlobalFilter({ ...globalFilter, notificationType });
    }
  }, [notificationType]);


  const SingleNotificationBlock = (nProps) => {
    return nProps.notifications.map((notification, index) => {
      const checkboxSelected = checkIfChecked(notification);
      return (
        <SingleNotification
          key={index}
          notification={notification}
          userCompanyIds={userCompanyIds}
          toggleCheckBox={toggleCheckBox}
          checkboxSelected={checkboxSelected}
          setNotificationRead={setNotificationRead}
        />
      );
    });
  };

  const hasRecords = notifications && notifications.length;

  const Loading = () => (
    <div
      className={`flex justify-center items-center flex-1`}
      style={{ width: "100%", marginTop: 15, marginBottom: 15 }}
    >
      <div
        className="bg-lightgray text-darkgray py-sm px-md"
        style={{ borderRadius: 43 }}
      >
        Loading
      </div>
    </div>
  )

  return (
    <>
      <br />
      <div className="relative md:flex items-center justify-between">
        <TabTitle>Notifications</TabTitle>
        <div className="flex flex-shrink-0">
          <NotificationFilterSelect
            value={notificationType}
            update={setNotificationType}
          />

          <div className="hidden lg:flex justify-end items-center ml-3">
            {selectedNotifications.length === 0 && (
              <div
                onClick={() => {
                  readAllNotifications({
                    variables: { id: firebase.auth().currentUser.uid },
                  });
                  const readAll = notifications?.map(elem => {
                    if(!elem.read){
                      return {...elem, read: true};
                    }
                    return elem;
                  })
                  setNotifications(readAll);
                }}
                className="text-darkgray font-medium hover:underline cursor-pointer"
                style={{ fontSize: 14 }}
              >
                Read All Notifications
              </div>
            )}
            {selectedNotifications.length > 0 && (
              <div
                onClick={() => readNotifications()}
                className="text-darkgray font-medium hover:underline cursor-pointer"
                style={{ fontSize: 14, marginRight: 15 }}
              >
                Mark as Read
              </div>
            )}
            {selectedNotifications.length > 0 && (
              <div
                onClick={() => unReadNotifications()}
                className="text-darkgray font-medium hover:underline cursor-pointer"
                style={{ fontSize: 14 }}
              >
                Mark as Unread
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Date range */}
      <DateRange dates={dateRange} onChange={($event) => setDateRange($event)} />

      <div
        className={`flex-1 bg-white rounded shadow`}
        style={{ marginBottom: 50 }}
      >
        <InfiniteScroll
          hasMore={hasMore}
          next={fetchNotifications}
          height={700}
          loader={<Loading />}
          dataLength={notifications?.length || 0}
        >
          {
            loading ? (
              <Loading />
            ) : (
              hasRecords ? (
                notifications
                  .filter(
                    (notification) =>
                      notification.stack === NOTIFIACTION_STACK.GROUPED
                  )
                  .map((notification, index) => (
                    <GroupedNotifications
                      key={index}
                      notification={notification}
                      userCompanyIds={userCompanyIds}
                    >
                      <SingleNotificationBlock notifications={notification.queue} />
                    </GroupedNotifications>
                  ))
              ) : (
                <div
                  className={`flex justify-center items-center flex-1`}
                  style={{ width: "100%" }}
                >
                  <div
                    className="bg-lightgray text-darkgray py-sm px-md"
                    style={{ borderRadius: 43 }}
                  >
                    No Records found
                  </div>
                </div>
              )
            )
          }
          <SingleNotificationBlock
            notifications={notifications.filter(
              (notification) => notification.stack === NOTIFIACTION_STACK.SINGLE
            )}
          />
        </InfiniteScroll>
      </div>
      {/* <div className="notification-pagination">
        <CherryPagination
          page={page}
          perPage={perPage}
          total={total}
          onChange={handlePaginationChange}
        />
      </div> */}
    </>
  );
}

function SingleNotification({
  notification,
  userCompanyIds,
  toggleCheckBox,
  checkboxSelected,
  setNotificationRead,
}) {
  const { receivedAt, type, title, profileImage, candidate } = notification;

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    );
  };

  const dateString = isToday(new Date(receivedAt))
    ? moment(new Date(receivedAt)).format("h:mm a")
    : moment(new Date(receivedAt)).format("MMM. D");

  function readNotification() {
    const {
      id,
      type,
      candidate,
      companyId,
      candidateId,
      candidateName,
      profileImage,
      jobOrCompanyId,
    } = notification;

    setNotificationRead({
      variables: { id },
      refetchQueries: ["recruiterNotifications"],
    });

    switch (type) {
      case "job-interest":
        if (userCompanyIds.includes(companyId)) {
          navigate(`/jobs/${jobOrCompanyId}`, {
            state: { jobID: jobOrCompanyId, from: "/notifications" },
          });
        } else {
          navigate(`/candidates/${candidateId}`, { state: { candidate } });
        }
        break;
      case "chat":
        navigate("/chat", {
          state: {
            candidate: {
              id: candidateId,
              name: candidateName,
              profilePicture: profileImage,
              messages: [],
            },
          },
        });
        break;
      case "company-interest":
        if (userCompanyIds.includes(companyId)) {
          navigate(`/companies/${companyId}`, {
            state: { company_id: companyId },
          });
        } else {
          navigate(`/candidates/${candidateId}`, { state: { candidate } });
        }

        break;
      default:
        navigate(`/candidates/${candidateId}`, { state: { candidate } });
    }
  }

  return (
    <div
      style={{ fontSize: 14 }}
      className="hover:bg-lightgray cursor-pointer notification-item"
    >
      <div style={{ textAlign: "left", width: 167 }} onClick={readNotification}>
        <div
          className="text-darkgray border border-darkgray font-medium rounded text-center"
          style={{
            fontSize: 14,
            whiteSpace: "nowrap",
            paddingTop: 5,
            paddingBottom: 5,
            width: 110,
            borderRadius: 20,
          }}
        >
          {notificationTypes[type]}
        </div>
      </div>

      <div style={{ textAlign: "left", width: 116 }} onClick={readNotification}>
        <div className="font-medium">{dateString}</div>
      </div>

      <div style={{ textAlign: "left", width: 116 }} onClick={readNotification}>
        <div
          className="w-8 h-8 lg:h-12 lg:w-12"
          style={{
            backgroundImage: `url(${profileImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: 48,
            margin: 0,
          }}
        />
      </div>

      <div
        style={{ textAlign: "left", width: "62.5%" }}
        onClick={readNotification}
      >
        <div
          className={`${notification.read ? "font-medium" : "font-bold"
            } px-md flex items-center`}
          style={{ fontSize: 14 }}
        >
          {title}
        </div>
      </div>

      <div style={{ textAlign: "left", width: 40 }}>
        <div
          role="button"
          className="cursor-pointer px-sm relative right-0  "
          onClick={() => toggleCheckBox(notification)}
        >
          <img
            alt=""
            src={checkboxSelected ? CheckboxSelected : CheckboxEmpty}
            style={{ height: 25, margin: 0 }}
          />
        </div>
      </div>
    </div>
  );
}

const GroupedNotifications = ({ notification, children }) => {
  const { receivedAt, type, title, profileImage, candidate, count } =
    notification;

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    );
  };

  const dateString = isToday(new Date(receivedAt))
    ? moment(receivedAt).format("h:mm a")
    : moment(receivedAt).format("MMM. D");

  return (
    <Accordion style={{ boxShadow: "none" }}>
      <AccordionSummary
        style={{ padding: 0, margin: 0 }}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className="hover:bg-lightgray cursor-pointer notification-item">
          <div style={{ textAlign: "left", width: 167 }}>
            <div
              className="text-darkgray border border-darkgray font-medium rounded text-center"
              style={{
                fontSize: 14,
                whiteSpace: "nowrap",
                paddingTop: 5,
                paddingBottom: 5,
                width: 110,
                borderRadius: 20,
              }}
            >
              {notificationTypes[type]}
            </div>
          </div>

          <div style={{ textAlign: "left", width: 116 }}>
            <div className="font-medium">{dateString}</div>
          </div>

          <div style={{ textAlign: "left", width: 116 }}>
            <div
              className="w-8 h-8 lg:h-12 lg:w-12"
              style={{
                backgroundImage: `url(${profileImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: 48,
                margin: 0,
              }}
            />
          </div>

          <div style={{ textAlign: "left", width: 818 }}>
            <div
              className={`${notification.read ? "font-medium" : "font-bold"
                } px-md flex items-center`}
              style={{ fontSize: 14 }}
            >
              {title}
            </div>
          </div>
          <div style={{ textAlign: "left" }}>
            <div
              style={{
                borderRadius: "50%",
                fontWeight: "bolder",
                width: 25,
                height: 25,
                background: "#E31C25",
                color: "#fff",
                textAlign: "center",
              }}
            >
              {count}
            </div>
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ margin: 0, display: "block" }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default Notifications