import PropTypes from 'prop-types'
import { navigate } from 'gatsby';
import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import RichTextArea from '../../forms/fields/RichTextArea';
import { IndustrySelect, LocationField, PhotoUpload, TextInput } from '../fields';
import Autosuggest from 'react-autosuggest';
import CompanySize from '../fields/CompanySize';
import axios from 'axios';
import _ from 'lodash';
import './CompanyForm.css'
import { EXPRESS_SERVER_URL } from '../../config';
import FieldTitle from '../fields/FieldTitle';

/**
 * 
 * @param {Object} props Component Props
 * @param {Object} props.parentState Company parentState
 * @param {Boolean} props.canEdit Form Editable State
 * @param {Boolean} props.isLoading Form Loading State
 * @param {Function} props.onSubmit Form onSubmit
 * @returns 
 */
const CompanyForm = (props) => {
  const [newAccount, setNewAccount] = props?.parentState || useState({});
  const [inputProps, setInputProps] = useState({
    placeholder: 'Company Name',
    value: newAccount?.name || '',
    type: "search",
  });

  const [companies, setCompanies] = useState([]);
  const [dirty, setDirty] = useState(false);

  const handleOnSelect = (event, { newValue = '' }) => {
    // the item selected
    const value = newValue;
    const changes = newAccount ? { ...newAccount } : {};
    if (!changes) {
      changes.name = value;
    }
    setNewAccount({ ...changes, name: value || '' });
    setInputProps({ ...inputProps, value: value || '' });
  }

  const onChange = (key, value) => {
    setNewAccount({ ...newAccount, [key]: value, name: inputProps?.value || '' });
  }

  const fetchCompanies = async (query = '') => {
    try {
      const data = await axios.get(`${EXPRESS_SERVER_URL}/companies/autocomplete`, {
        headers: { Accept: 'application/json' },
        params: {
          query
        }
      });
      if (data?.data?.entities?.length) {
        const identifiers = data.data.entities.map((e) => ({ id: e.identifier.uuid, name: e.identifier.value, description: e.short_description }));
        setCompanies(identifiers);
      } else {
        setCompanies([]);
      }
    } catch (e) {
      return {};
    }
  }

  const _debounce = _.debounce(async (value) => {
    await fetchCompanies(value);
  }, 200);

  const handleOnSearch = async ({ value = '' }) => {
    _debounce(value);
  }

  const handleOnClear = () => {
    setNewAccount({ ...newAccount, name: '' });
  }

  const selectSuggestion = async (event, { suggestion = '' }) => {
    const changes = { ...newAccount, name: suggestion?.name || inputProps?.value || '', description: suggestion?.description || newAccount?.description || '' };
    try {
      const data = await axios.get(`${EXPRESS_SERVER_URL}/companies/crunchbase/${suggestion?.id}`, {
        headers: { Accept: 'application/json' },
      });
      if (data?.data?.properties) {
        const { properties } = data?.data;
        let location = '';
        if (properties?.location_identifiers?.length) {
          properties?.location_identifiers?.filter((loc) => loc.location_type !== "continent").forEach((loc) => {
            location += `${loc?.value}, `
          });
          changes.location = location.substring(0, location.length - 2);
        }

        if (properties?.website_url || properties?.website) {
          changes.websiteURL = properties?.website_url || properties?.website;
        }

        if (properties?.image_url) {
          changes.logoURL = properties?.image_url;
        }
        setNewAccount(changes);
        setInputProps({ ...inputProps, value: suggestion?.name || inputProps?.value });
        // const identifiers = data.entities.map((e) => ({ id: e.identifier.uuid, name: e.identifier.value, description: e.short_description }));
        // setCompanies(identifiers);
      }
    } catch (e) {
      setNewAccount(changes);
      setInputProps({ ...inputProps, value: suggestion?.name || inputProps?.value });
      console.log('ERROR_FETCH_DETAILS', e);
      return {};
    }
    // the item selected
  }

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = suggestion => suggestion.name;

  // Use your imagination to render suggestions.
  const renderSuggestion = suggestion => suggestion.name;

  const companyNameProps = {
    inputProps: {
      ...inputProps,
      onChange: handleOnSelect,
    },
    suggestions: companies,
    onSuggestionsFetchRequested: handleOnSearch,
    onSuggestionsClearRequested: handleOnClear,
    getSuggestionValue: getSuggestionValue,
    onSuggestionSelected: selectSuggestion,
    renderSuggestion: renderSuggestion,
  };

  const websiteProps = {
    value: newAccount?.websiteURL || '',
    placeholder: 'website',
    update: (val) => onChange('websiteURL', val || '')
  }

  const descriptionProps = {
    value: newAccount?.description || '',
    placeholder: 'description',
    update: (val) => onChange('description', val || '')
  }

  const industriesProps = {
    value: newAccount?.industries || [],
    update: (val) => onChange('industries', val || [])
  }

  const locationProps = {
    placeholder: 'City, State',
    value: newAccount?.location != null ? newAccount.location : '',
    update: (val) => onChange('location', val || '')
  }

  const sizeProps = {
    value: newAccount?.size || '',
    update: (val) => onChange('size', val || '')
  }

  const logoProps = {
    placeholder: 'Company Logo',
    value: newAccount?.logoURL || '',
    update: (val) => onChange('logoURL', val || '')
  };

  const onCancel = () => {
    props.canEdit
      ? navigate(`/companies/${newAccount.id}/`, {
        state: {
          company_id: newAccount.id,
        },
      })
      : navigate(`/dashboard/companies`)
  }

  const Actions = () => (
    <div className="flex w-full mt-lg">
      <button
        onClick={onCancel}
        type="button"
        className="flex-1 p-md mr-sm cursor-pointer bg-lightgray font-main text-darkgray rounded text-sm"
      >
        Cancel
      </button>
      <button type="submit" className="flex-1 p-md ml-sm cursor-pointer bg-red font-main text-white rounded flex justify-center text-xs">
        {props.isLoading ? <Loader type="TailSpin" color="#FFFFFF" height={20} width={20} /> : 'Save & Continue'}
      </button>
    </div>
  )

  const handleSubmit = (event) => {
    event?.preventDefault();
    setDirty(true);
    if (newAccount?.name && newAccount.websiteURL && newAccount.location && newAccount.size && newAccount?.industries && Array.isArray(newAccount?.industries) && newAccount.industries?.length) {
      props?.onSubmit(newAccount);
    }
  }

  const ValidationText = (childProps) => {

    if (!dirty) {
      return null;
    }

    if (childProps?.accountkey && childProps?.accountkey in newAccount && newAccount[childProps.accountkey]) {
      return null;
    }

    return (
      <p className='text-error'>{childProps?.text}</p>
    )
  };

  return (
    <div className='company-form-container'>
      <div className="font-main text-darkblue" style={{ fontWeight: 500, fontSize: 21, marginTop: 10, textAlign: 'center' }}>
        {props.canEdit ? 'Edit Company' : 'Create Company'}
      </div>
      <div className="flex p-md mb-md"></div>
      <form onSubmit={handleSubmit}>
        {/* company name */}
        <FieldTitle title="Company Name" />
        <Autosuggest {...companyNameProps} />
        <ValidationText key="name" accountkey="name" text="Please enter the company's name" />
        {/* Web site */}
        <TextInput {...websiteProps} />
        <ValidationText key="websiteURL" accountkey="websiteURL" text="Please enter the company website url" />
        {/* description */}
        <FieldTitle title="Company Description" />
        <RichTextArea {...descriptionProps} />
        {/* logoURL */}
        <PhotoUpload key={newAccount?.logoURL || 'logo'} placeholder='Company Logo' value={newAccount?.logoURL || ''} update={(val) => onChange('logoURL', val || '')} />
        {/* industry */}
        {/* <FieldTitle title="Industry" /> */}
        <IndustrySelect {...industriesProps} />
        <ValidationText key="industries" accountkey="industries" text="Please enter the industries" />
        {/* location */}
        <LocationField {...locationProps} />
        <ValidationText key="location" accountkey="location" text="Please enter the company's location" />
        {/* size */}
        <CompanySize {...sizeProps} />
        <ValidationText key="size" accountkey="size" text="Please enter the company's size" />
        {/* actions */}
        <Actions />
      </form>
    </div>
  )
}

// CompanyForm.propTypes = {
//   parentState: PropTypes.any,
//   canEdit: PropTypes.bool,
//   isLoading: PropTypes.bool,
//   onSubmit: PropTypes.func
// }

export default CompanyForm
