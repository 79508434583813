import { useQuery, useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import parse from 'html-react-parser';
import { head, isEmpty, propOr } from 'ramda';
import React, { useContext, useEffect, useRef, useState } from 'react';
import FieldTitle from '../../components/common/FieldTitle';
import { UserRoleContext } from '../../context';
import useCompanyCheck from '../../hooks/useCompanyCheck';
import EyeIcon from '../../images/eye-solid.svg';
import LinkIcon from '../../images/link.png';
import UserAvatar from '../../images/user-avatar.png';
import { bioLorem } from '../../utils/constants';
import { formatSalary } from '../../utils/formatter';
import CandidateEmployment from '../candidates/candidate-details/CandidateEmployment';
import CandidateSocial from '../candidates/candidate-details/CandidateSocial';
import EducationEntry from '../candidates/candidate-details/education-entry';
import { ViewResumeButton } from './view-resume-button';
import { prepareUrl } from '../../utils/formatter';
import ProfileImage from './profile/profile-image';

const GET_CANDIDATE = gql`
  subscription getCandidateDetails($id: String) {
    candidate(where: { id: { _eq: $id } }) {
      id
      profilePictureURL
      name
      email
      phone
      resumeFileName
      resumeURL
      linkedin
      referralName
      recruiter {
        id
        name
      }
      location
      salaryMax
      salaryMin
      facebook
      instagram
      twitter
      experience
      invite_code
      loxo_id
      recruiter_id
      bio
      employment
      education
      certifications
      attributes
      preferredSkills
      notes
      locationPreferences
      interestedCity
      is_login
      device_token
      approved
      is_disabled
    }
  }
`;

const GET_COMPANY_REQUESTS = gql`
  query companyRequests($admin_id: String) {
    company_request(where: { company: { adminID: { _eq: $admin_id } } }) {
      status
      candidate_id
    }
  }
`;

function CandidateDetailsHeader(props) {
  const { changes, recruiterUser, companyUser, mutualInterest, candidate, approvedChanges } = props;

  const profileImageSrc = changes.profilePictureURL
    ? changes.profilePictureURL
    : approvedChanges.profilePictureURL
      ? approvedChanges.profilePictureURL
      : candidate.profilePictureURL
        ? candidate.profilePictureURL
        : UserAvatar

  return (
    <div className="flex w-full items-center mb-xl">
      {recruiterUser && (
        <ProfileImage isDisabled={candidate?.is_disabled} approved={true} src={profileImageSrc} />
      )}
      {companyUser && (
        <ProfileImage isDisabled={candidate?.is_disabled} approved={mutualInterest} src={mutualInterest ? candidate.profilePictureURL : UserAvatar} />
      )}
      <div className="flex flex-col font-medium text-darkblue">
        <div className="flex items-center">
          <div className="text-darkblue mr-md">{changes.name ? changes.name : candidate.name}</div>
        </div>

        {companyUser && !mutualInterest && (
          <div className="flex" style={{ color: 'transparent', textShadow: '0 0 7px black' }}>
            Candidate Name
          </div>
        )}
        {(recruiterUser || (companyUser && mutualInterest)) && (
          <div className="flex items-center">
            <div className="text-darkgray mr-md">{approvedChanges.email ? approvedChanges.email : changes.email ? changes.email : candidate.email}</div>
          </div>
        )}
        {companyUser && !mutualInterest && (
          <div className="text-darkgray" style={{ color: 'transparent', textShadow: '0 0 7px black' }}>
            candidate@email.com
          </div>
        )}

        {(recruiterUser || (companyUser && mutualInterest)) && (
          <ViewResumeButton resumeUrl={candidate.resumeURL} candidateLoxoId={candidate.loxo_id} recruiterId={candidate.recruiter_id} />
          // <div className="flex">
          //   <div
          //     onClick={() => window.open(candidate.resumeURL)}
          //     className="bg-lightgray flex shadow cursor-pointer rounded hover:underline items-center text-darkgray"
          //     style={{ paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10, marginTop: 5, fontSize: 12 }}
          //   >
          //     <img alt="" src={LinkIcon} style={{ height: 13, width: 13, marginBottom: 0, marginRight: 10 }} />
          //     View Resume
          //   </div>
          // </div>
        )}
      </div>
    </div>
  );
}
export { CandidateDetailsHeader };

export default function RecruiterCandidateDetail({ state }) {
  const [modal, setModal] = state;
  const [fadeDelay, setFadeDelay] = useState(false);
  const modalRef = useRef();

  useEffect(() => {
    if (modal.open) {
      document.querySelector('html').style.overflow = 'hidden';
      setFadeDelay(true);
    } else {
      setTimeout(() => {
        document.querySelector('html').style.overflow = 'auto';
        setFadeDelay(false);
      }, 300);
    }
  }, [modal]);

  const [candidate, setCandidate] = useState();
  const [userRole] = useContext(UserRoleContext);
  const isAdmin = userRole === 'admin';
  const isAgency = userRole === 'agency';
  const { data } = useSubscription(GET_CANDIDATE, {
    variables: { id: modal.data && modal.data.candidate ? modal.data.candidate.id : null },
    fetchPolicy: 'no-cache',
  });

  const [changes] = useState({});
  const [approvedChanges] = useState({});

  const { isCompany, loading } = useCompanyCheck();
  const companyUser = !loading && isCompany;
  const recruiterUser = !loading && !isCompany;
  const { data: requests } = useQuery(GET_COMPANY_REQUESTS, {
    variables: { admin_id: firebase.auth().currentUser && firebase.auth().currentUser.uid },
  });

  const request = requests && candidate && requests.company_request.filter((req) => req.candidate_id === candidate.id);
  const status = request && !isEmpty(request) && propOr('pending', 'status')(head(request));

  const mutualInterest = status && status === 'approved' || isAgency;

  useEffect(() => {
    if (data) {
      // Some fields returning stringified JSON on update... Temporary fix
      const jsonFields = ['location', 'employment', 'education', 'attributes', 'locationPreferences', 'certifications', 'preferredSkills'];
      const newCandidate = Object.entries(data?.candidate[0] || {}).map(([label, value]) => {
        if (jsonFields.includes(label) && typeof value === 'string' && value !== '') {
          return [label, JSON.parse(value)];
        } else {
          return [label, value];
        }
      });
      setCandidate(Object.fromEntries(newCandidate));
    }
  }, [data]);

  const renderValue = (label, value) => {
    switch (label) {
      case 'recruiter':
        return value && value.name;
      case 'location':
        return value && value.city;
      case 'experience':
        return value === 1 ? `${value} year` : `${value} years`;
      case 'resume':
        /* return value.fileName */
        return null;
      case 'linkedin':
        return value ? (
          <a className="hover:underline" href={value.includes('linkedin.com/in') ? value : `https://www.linkedin.com/in/${value}/`} target="_blank" rel="noreferrer">
            {value.includes('linkedin.com/in') ? value : `@${value}`}
          </a>
        ) : (
          ''
        );
      default:
        return value;
    }
  };

  return (
    <React.Fragment>
      <div
        className={`fixed items-center justify-end`}
        key={Date.now()}
        style={{
          transition: 'opacity 0.3s ease-in-out',
          opacity: modal.open ? 1 : 0,
          zIndex: 3000000,
          display: fadeDelay ? 'flex' : 'none',
          backgroundColor: 'none',
          top: 0,
          bottom: 0,
          right: 0,
          width: 745,
        }}
      >
        <div ref={modalRef} className="bg-white px-xl py-md rounded shadow flex flex-col" style={{ height: '100%', width: 745, overflowY: 'scroll', overflowX: 'hidden' }}>
          <div style={{ display: 'inline-flex' }} className="text-lg font-medium pb-sm border-b mb-lg">
            <div>Candidate Detail</div>
            <div style={{ marginLeft: 450, cursor: 'pointer' }} onClick={() => setModal({ ...modal, open: false, data: null, reset: true })}>
              x
            </div>
          </div>
          <div className="flex flex-col w-full py-lg container">
            <div className="flex flex-col items-center flex-1 lg:items-start lg:flex-row ">
              <div className="bg-white rounded flex-1 mb-lg flex text-sm w-full">
                <div className="flex flex-wrap relative" style={{ maxWidth: '100%' }}>
                  {candidate && (
                    <CandidateDetailsHeader
                      approvedChanges={approvedChanges}
                      changes={changes}
                      candidate={candidate}
                      recruiterUser={recruiterUser}
                      companyUser={companyUser}
                      mutualInterest={mutualInterest}
                    />
                  )}
                  {candidate &&
                    Object.entries(candidate).map(([label, value], index) => {
                      const isChanged = changes[label] ? true : false;
                      switch (label) {
                        case 'id':
                          return null;
                        case 'notes':
                          return null;
                        case '__typename':
                          return null;

                        case 'profilePictureURL':
                          return null;
                        case 'resumeFileName':
                          return null;
                        case 'name':
                          return null;
                        case 'email':
                          return null;
                        case 'salaryMax':
                          return null;
                        case 'interestedCity':
                          if (!value && changes && !changes.interestedCity && !approvedChanges.interestedCity) {
                            return null;
                          }

                          let interestedCities = isAdmin
                            ? value
                            : changes && changes.interestedCity
                              ? changes.interestedCity
                              : approvedChanges.interestedCity
                                ? approvedChanges.interestedCity
                                : value;

                          interestedCities = interestedCities ? (Array.isArray(interestedCities) ? interestedCities : [interestedCities]).map(({ city }) => city) : [];

                          return (
                            <div key={index} className="w-full relative">
                              <div className="flex">
                                <div className="flex-1">
                                  <FieldTitle title="Interested Cities" />
                                </div>
                              </div>
                              <div className="flex flex-wrap">
                                {interestedCities.map((skill) => (
                                  <div
                                    className={`${changes && changes.interestedCity && (!value || !value.map(({ city }) => city).includes(skill)) && !approvedChanges[label]
                                      ? 'bg-green text-white'
                                      : 'bg-lightgray text-darkgray'
                                      } mt-md shadow px-md flex items-center mr-sm font-medium`}
                                    style={{ fontSize: 12, height: 28, borderRadius: 28 }}
                                  >
                                    {skill}
                                  </div>
                                ))}
                              </div>
                            </div>
                          );
                        case 'locationPreferences':
                          if (!value) {
                            return null;
                          }
                          const locationPreferences = Object.entries(
                            !isAdmin && approvedChanges.locationPreferences
                              ? approvedChanges.locationPreferences
                              : changes && changes.locationPreferences
                                ? changes.locationPreferences
                                : value,
                          )
                            .filter(([_, bool]) => bool)
                            .map(([preference]) => preference);
                          return (
                            <div key={index} className="w-full relative">
                              <div className="flex">
                                <div className="flex-1">
                                  <FieldTitle title="Location Preferences" />
                                </div>
                              </div>
                              <div className="flex flex-wrap">
                                {locationPreferences.map((skill) => {
                                  return (
                                    <div
                                      className={`${changes && changes.locationPreferences && value[skill] !== changes.locationPreferences[skill] && !approvedChanges[label]
                                        ? 'bg-green text-white'
                                        : 'bg-lightgray text-darkgray'
                                        } mt-md shadow px-md flex items-center mr-sm font-medium`}
                                      style={{ fontSize: 12, height: 28, borderRadius: 28 }}
                                    >
                                      {skill === 'remote' ? 'Remote' : skill === 'willingToRelocate' ? 'Willing To Relocate' : 'Current Location'}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        case 'resumeURL':
                          return null;
                        case 'salaryMin':
                          const salaryMin = changes && changes.salarymin ? changes.salarymin : approvedChanges.salaryMin ? approvedChanges.salaryMin : candidate.salaryMin;
                          return (
                            <div key={index} className=" flex flex-col font-medium w-1/2">
                              <div className="flex items-center capitalize text-darkgray pb-md">
                                Salary
                                {!isAdmin && changes && (changes.salarymax || changes.salarymin) && (
                                  <div className="flex ml-md">
                                    <img alt=""
                                      title='Preview'
                                      src={EyeIcon} style={{ width: 17, height: 17, marginBottom: 0, marginRight: 10 }} />
                                  </div>
                                )}
                              </div>
                              <div className="text-darkblue pb-xl pr-lg">{salaryMin ? `$ ${formatSalary({ salary: salaryMin })} +` : `Negotiable`}</div>
                            </div>
                          );
                        case 'facebook':
                          return <CandidateSocial key={index} value={{ facebook: candidate.facebook, instagram: candidate.instagram, twitter: candidate.twitter }} />;
                        case 'instagram':
                          return null;
                        case 'twitter':
                          return null;
                        case 'is_login':
                          return null;
                        case 'bio':
                          return (
                            value && (
                              <div key={index} className="w-full">
                                <div className="flex">
                                  <div className="flex-1">
                                    <FieldTitle title="Short Bio" />
                                  </div>
                                </div>
                                <div
                                  className="text-darkgray mt-sm"
                                  style={{ fontSize: 14, color: companyUser && !mutualInterest && 'transparent', textShadow: companyUser && !mutualInterest && '0 0 7px black' }}
                                >
                                  {recruiterUser && !isAdmin && (approvedChanges.bio ? approvedChanges.bio : changes.bio ? parse(changes.bio) : parse(value))}
                                  {isAdmin && value}
                                  {companyUser && mutualInterest && value}
                                  {companyUser && !mutualInterest && bioLorem}
                                </div>
                              </div>
                            )
                          );
                        case 'employment':
                          return (
                            <CandidateEmployment
                              key={index}
                              isAdmin={isAdmin}
                              changes={changes && changes.employment}
                              value={typeof value === 'string' ? JSON.parse(value) : value}
                            />
                          );

                        case 'education':
                          return value && value.length > 0 ? (
                            <div key={index} className="w-full">
                              <div className="flex">
                                <div className="flex-1">
                                  <FieldTitle title="Education" />
                                </div>
                              </div>
                              <div className="">
                                {(!isAdmin && changes && changes.education ? changes.education : approvedChanges.education ? approvedChanges.education : value).map((entry) => (
                                  <EducationEntry entry={entry} />
                                ))}
                              </div>
                            </div>
                          ) : null;

                        case 'certifications':
                          return (
                            value &&
                            value.length > 0 && (
                              <div key={index} className="w-full">
                                <FieldTitle title="Certifications & Licences" />
                                <div className="flex flex-wrap">
                                  {value.map((skill) => (
                                    <div
                                      className="bg-lightgray mt-md shadow px-md text-darkgray flex items-center mr-sm font-medium"
                                      style={{ fontSize: 12, height: 28, borderRadius: 28, color: '#9499a2' }}
                                    >
                                      {skill}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )
                          );

                        case 'attributes':
                          return (
                            <div key={index} className="w-full relative">
                              <div className="flex">
                                <div className="flex-1">
                                  <FieldTitle title="Candidate Attributes" />
                                </div>
                              </div>
                              <div className="flex flex-wrap">
                                {(!isAdmin && changes && changes.attributes ? changes.attributes : approvedChanges[label] ? approvedChanges[label] : value || []).map(
                                  (skill, index) => (
                                    <div
                                      key={index}
                                      className={`${changes && changes.attributes && !value.includes(skill) && !approvedChanges[label] ? 'bg-green text-white' : 'bg-lightgray text-darkgray'
                                        } mt-md shadow px-md flex items-center mr-sm font-medium`}
                                      style={{ fontSize: 12, height: 28, borderRadius: 28 }}
                                    >
                                      {skill}
                                    </div>
                                  ),
                                )}
                              </div>
                            </div>
                          );

                        case 'preferredSkills':
                          return (
                            <div key={index} className="w-full relative pb-10">
                              <div className="flex">
                                <div className="flex-1">
                                  <FieldTitle title="Job Preferences" />
                                </div>
                              </div>
                              <div className="flex flex-wrap">
                                {(!isAdmin && changes && changes.preferredSkills ? changes.preferredSkills : approvedChanges[label] ? approvedChanges[label] : value).map(
                                  (skill) => (
                                    <div
                                      className={`${changes && changes.preferredSkills && !value.includes(skill) && !approvedChanges[label]
                                        ? 'bg-green text-white'
                                        : 'bg-lightgray text-darkgray'
                                        } mt-md shadow px-md flex items-center mr-sm font-medium`}
                                      style={{ fontSize: 12, height: 28, borderRadius: 28 }}
                                    >
                                      {skill}
                                    </div>
                                  ),
                                )}
                              </div>
                            </div>
                          );
                        default:
                          return (
                            <div key={index} className="flex flex-col font-medium w-1/2">
                              <div className="flex items-center capitalize text-darkgray pb-md">
                                {label === 'recruiter'
                                  ? 'Recruiter Name'
                                  : label === 'referralName'
                                    ? 'Referral Name'
                                    : label === 'invite_code'
                                      ? 'Invite Code'
                                      : label === 'loxo_id'
                                        ? 'Loxo ID'
                                        : label === 'recruiter_id'
                                          ? 'Recruiter ID'
                                          : label === 'resumeFileName'
                                            ? 'Resume'
                                            : label === 'salaryMin'
                                              ? 'Salary'
                                              : label === 'approved'
                                                ? ''
                                                : label}
                                {isChanged && !approvedChanges[label] && (
                                  <div className="flex ml-md">
                                    <img alt=""
                                      title='Preview'
                                      src={EyeIcon} style={{ width: 17, height: 17, marginBottom: 0, marginRight: 10 }} />
                                  </div>
                                )}
                              </div>
                              <div
                                style={{
                                  color: companyUser && !mutualInterest && (label === 'linkedin' || label === 'phone') && 'transparent',
                                  textShadow: companyUser && !mutualInterest && (label === 'linkedin' || label === 'phone') && '0 0 7px black',
                                }}
                                className={`text-darkblue pb-xl pr-lg ${label === 'resumeFileName' ? 'hover:underline cursor-pointer' : ''}`}
                              >
                                {renderValue(
                                  label,
                                  approvedChanges[label]
                                    ? approvedChanges[label]
                                    : changes[label]
                                      ? changes[label]
                                      : companyUser && !mutualInterest && label === 'linkedin'
                                        ? 'linkedin'
                                        : companyUser && !mutualInterest && label === 'phone'
                                          ? '(555) 555-5555'
                                          : label == 'linkedin'
                                            ? prepareUrl(value)
                                            : value,
                                )}
                              </div>
                            </div>
                          );
                      }
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {modal.open && (
        <div
          onClick={() => setModal({ ...modal, open: false, data: null, reset: true })}
          style={{ position: 'fixed', top: 0, right: 0, height: '100%', width: '100%', background: 'black', opacity: '0.5', zIndex: 1000001 }}
        ></div>
      )}
    </React.Fragment>
  );
}
