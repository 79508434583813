import { useMutation, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { EXPRESS_SERVER_URL, WEBSITE_URL } from '../../config';
import { ApolloContext } from '../../context/Apollo';
import ApprovalConfirmModal from './../../views/jobs/approval-confirm-modal';
import ZeroCreditModal from './ZeroCreditModal';
import { useSnackbar } from 'react-simple-snackbar';
import { EMAIL_TEMPLATES } from '../../utils/constants';

const COMPANY_REQUEST = gql`
  mutation createCompanyRequest($candidate_id: String, $company_id: uuid, $status: String) {
    insert_company_request(objects: { candidate_id: $candidate_id, company_id: $company_id, status: $status }) {
      affected_rows
      returning {
        candidate_id
        company_id
        candidate {
          name
          email
          recruiter {
            email
            name
            company {
              name
            }
          }
        }
      }
    }
  }
`;

const COMPANY_REQUEST_HISTORY = gql`
  mutation createCompanyRequestHistory($obj: [company_request_history_insert_input!]!) {
    insert_company_request_history(objects: $obj) {
      affected_rows
    }
  }
`;

const GET_COMPANY_ID = gql`
  query getCompanyID($admin_id: String) {
    company(where: { adminID: { _eq: $admin_id } }) {
      id
      name
      totalCredit
      remainingCredit
      subscription_type
    }
  }
`;

const GET_SETTINGS = gql`
  query getSettings {
    settings {
      credit_rate
      daily_candidate_request_limit
      monthly_candidate_request_limit
      weekly_candidate_request_limit
      id
    }
  }
`;

const GET_REQUESTS_USED = gql`
  query getDailyRequestsUsed($companyId: uuid, $startDate: String, $endDate: String) {
    company_request_history_aggregate(where: { company_id: { _eq: $companyId }, created_at: { _gte: $startDate, _lte: $endDate } }) {
      aggregate {
        count
      }
    }
  }
`;

const UnblockButton = ({ candidateID, status, candidate, candidateIsInterested, excludeApprovedCandidate, changeStatus}) => {
  const [remainingCredit, setRemainingCredit] = useState();
  const [subscriptionType, setSubscriptionType] = useState();
  const [subscriber, setSubscriber] = useState();
  const [approvalModal, setApprovalModal] = useState({ data: null, open: false });
  const [zeroModal, setZeroModal] = useState({ data: null, open: false });

  const [createCandidateRequest, { data: sendRequestResponse, loading: loadingRequest }] = useMutation(COMPANY_REQUEST);
  const [createCandidateRequestHistory] = useMutation(COMPANY_REQUEST_HISTORY);
  const { apolloClient } = useContext(ApolloContext);
  const [openSnackbar] = useSnackbar();

  const { data: companyResponse } = useQuery(GET_COMPANY_ID, {
    variables: {
      admin_id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
    },
  });

  useEffect(() => {
    if (companyResponse) {
      setRemainingCredit(companyResponse.company && companyResponse.company[0] && companyResponse.company[0].remainingCredit);
      setSubscriptionType(companyResponse.company && companyResponse.company[0] && companyResponse.company[0].subscription_type);
      setSubscriber(companyResponse.company && companyResponse.company[0]);
    }
  }, [companyResponse]);

  // options array containing status with color and label of the button shown
  const options = {
    pending: { bg: 'white', color: 'rgb(86, 208, 0)', label: 'Unlock Candidate' },
    request: { bg: 'white', color: 'rgb(86, 208, 0)', label: 'Unlock Candidate' },
    accepted: { bg: 'white', color: 'rgb(86, 208, 0)', label: 'Unlock Candidate' },
    requested: { bg: '#fed8b1', color: '#FF8C00', label: 'Introduction Requested' },
    approved: { bg: '#6ACA63', color: 'white', label: 'Profile Unlocked' },
    hired: { bg: '#6ACA63', color: 'white', label: 'Profile Unlocked' },
    loading: { bg: 'white', color: 'rgb(86, 208, 0)', label: 'Loading...' },
    declined: { bg: '#FFD2D2', color: '#D8000C', label: 'Request Declined' },
    interested: { bg: 'white', color: 'rgb(86, 208, 0)', label: 'Unlock Candidate' },
    denied: { bg: '#FFD2D2', color: '#D8000C', label: 'Request Denied' },
  };

  const sendRequest = async () => {
    if (status === 'accepted' || status === 'interested') {
      // condition when status is accepted from the candidate app
      if (remainingCredit > 0 || subscriptionType == 'PAID') {
        setApprovalModal({ open: true, data: { subscriber, candidateID, status, candidate }, isFresh: true });
      } else {
        setZeroModal({ open: true });
      }
    } else {
      // Check if can send request
      // Check only if account is free. If it is paid, then requests are always allowed
      if (subscriber.subscription_type === 'FREE') {
        const response = await apolloClient.query({
          query: GET_SETTINGS,
          variables: {},
          fetchPolicy: 'network-only',
        });

        const settings = response.data.settings[0];

        const dailyRequestSentResponse = await apolloClient.query({
          query: GET_REQUESTS_USED,
          variables: {
            companyId: subscriber.id,
            startDate: moment()
              .startOf('day')
              .format('YYYY-MM-DD'),
            endDate: moment()
              .endOf('day')
              .format('YYYY-MM-DD'),
          },
          fetchPolicy: 'network-only',
        });

        const dailyRequestSent = dailyRequestSentResponse.data.company_request_history_aggregate.aggregate.count;

        if (dailyRequestSent >= settings.daily_candidate_request_limit) {
          openSnackbar('Cannot send request. You have reached your daily limit.');
          return;
        }

        const weeklyRequestSentResponse = await apolloClient.query({
          query: GET_REQUESTS_USED,
          variables: {
            companyId: subscriber.id,
            startDate: moment()
              .startOf('week')
              .format('YYYY-MM-DD'),
            endDate: moment()
              .endOf('week')
              .format('YYYY-MM-DD'),
          },
          fetchPolicy: 'network-only',
        });

        const weeklyRequestSent = weeklyRequestSentResponse.data.company_request_history_aggregate.aggregate.count;

        if (weeklyRequestSent >= settings.weekly_candidate_request_limit) {
          openSnackbar('Cannot send request. You have reached your weekly limit.');
          return;
        }

        const monthlyRequestSentResponse = await apolloClient.query({
          query: GET_REQUESTS_USED,
          variables: {
            companyId: subscriber.id,
            startDate: moment()
              .startOf('month')
              .format('YYYY-MM-DD'),
            endDate: moment()
              .endOf('month')
              .format('YYYY-MM-DD'),
          },
          fetchPolicy: 'network-only',
        });

        const monthlyRequestSent = monthlyRequestSentResponse.data.company_request_history_aggregate.aggregate.count;

        if (monthlyRequestSent >= settings.monthly_candidate_request_limit) {
          openSnackbar('Cannot send request. You have reached your monthly limit.');
          return;
        }
      }

      // condition when subscriber want to see the profile of candidate
      createCandidateRequest({ variables: { candidate_id: candidateID, company_id: subscriber.id, status: 'requested' } });

      createCandidateRequestHistory({
        variables: { obj: { candidate_id: candidateID, company_id: subscriber.id, created_at: moment().format('YYYY-MM-DD'), status: 'requested' } },
      });
    }
  };

  useEffect(() => {
    if (sendRequestResponse) {
      let email = '';
      let candidate_name = '';
      let recruiter_name = '';
      if (
        sendRequestResponse.insert_company_request.returning[0] &&
        sendRequestResponse.insert_company_request.returning[0].candidate &&
        sendRequestResponse.insert_company_request.returning[0].candidate.recruiter &&
        sendRequestResponse.insert_company_request.returning[0].candidate.recruiter.email
      ) {
        email = sendRequestResponse.insert_company_request.returning[0].candidate.recruiter.email;
        candidate_name = sendRequestResponse.insert_company_request.returning[0].candidate.name;
        recruiter_name = sendRequestResponse.insert_company_request.returning[0].candidate.recruiter.name;
      } else {
        email = 'cjtufano@getcherrypicker.com';
      }
      let company_name = 'NA';
      if (companyResponse?.company && Array.isArray(companyResponse?.company) && companyResponse?.company?.length && companyResponse?.company[0]?.name) {
        company_name = companyResponse?.company[0]?.name;
      }
      fetch(`${EXPRESS_SERVER_URL}/send-email`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          recipient: email,
          // subject: 'Subscriber interested in your candidate profile', //added in sendGrid
          template_id: EMAIL_TEMPLATES.TO_RECRUITER_WHEN_COMPANY_UNLOCK_CANDIDATE_PROFILE, //candidate unlock
          attributes: {
            recruiter_name,
            candidate_name,
            company_name,
            website_url: WEBSITE_URL,
          },
        }),
      });

      if (
        sendRequestResponse.insert_company_request.returning[0] &&
        sendRequestResponse.insert_company_request.returning[0].candidate &&
        sendRequestResponse.insert_company_request.returning[0].candidate.name &&
        sendRequestResponse.insert_company_request.returning[0].candidate.email
      ) {

        const candidate_details = sendRequestResponse?.insert_company_request?.returning[0]?.candidate;
        candidate_name = candidate_details?.name || 'N/A';
        const candidate_email = candidate_details?.email || 'N/A';
        const recruiter_company = companyResponse?.company?.name || 'N/A';
        fetch(`${EXPRESS_SERVER_URL}/send-email`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            recipient: candidate_email,
            // subject: 'Great news, a company has unlocked your Cherrypicker profile!', //added in sendGrid
            template_id: EMAIL_TEMPLATES.TO_CANDIDATE_WHEN_PROFILE_UNLOCKED_BY_SUBSCRIBER,
            attributes: {
              recruiter_company,
              candidate_name,
            },
          }),
        });
      }
    }
  }, [sendRequestResponse]);

  return (
    <div>
      <button
        onClick={() => sendRequest()}
        disabled={status === 'request' || status === 'accepted' || status === 'pending' || status === 'interested' ? false : true}
        style={{
          cursor: status === 'request' || status === 'accepted' || status === 'pending' || status === 'interested' ? 'pointer' : 'default',
          color: options[status] && options[status].color ? options[status].color : '',
          borderColor: 'rgb(86, 208, 0)',
          backgroundColor: options[status] && options[status].bg ? options[status].bg : '',
          border: '1px solid',
          borderRadius: 5,
          height: 40,
          width: '100%',
          minWidth: 200,
          marginBottom: 2,
          paddingLeft: 5,
          paddingRight: 5,
          marginTop: 25,
        }}
        className={`text-xs flex justify-center items-center rounded`}
      >
        {loadingRequest ? <Loader type="TailSpin" color="#000000" height={20} width={20} /> : `${options[status] && options[status].label ? options[status].label : ''}`}
      </button>
      <ApprovalConfirmModal  
        excludeApprovedCandidate={excludeApprovedCandidate}
        changeStatus={changeStatus} 
        state={[approvalModal, setApprovalModal]} 
        candidateId={candidate?.id}
      />
      <ZeroCreditModal state={[zeroModal, setZeroModal]} />
    </div>
  );
};

export default UnblockButton;
