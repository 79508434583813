import React, { useState, useRef, useEffect, useContext } from 'react'
import ProfileAvatar from '../../images/user-avatar.png'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'
import Moment from 'moment'
import Loader from 'react-loader-spinner'
import FileIcon from '../../images/file.png'
import AttachmentIcon from '../../images/attachment.png'
import XIcon from '../../images/x-white.png'
import attributes from '../../data/attributes'
import certifications from '../../data/certifications'
import industries from '../../data/industries'
import Linkify from 'react-linkify';
// Context
import { UserRoleContext } from '../../context';
import './chat.css'
import { USER_ROLES } from '../../utils/constants'
import { equals } from '../../utils/candidate-helper'
import axios from 'axios'
import { EXPRESS_SERVER_URL } from '../../config'

const SET_READ_MESSAGES = gql`
mutation readCandidateMessages($candidate_id: String) {
    update_chat_message(where: {candidate_id: {_eq: $candidate_id}, from: {_eq: "candidate"}}, _set: {read: true}) {
      affected_rows
    }
  }
`

const ADD_SKILLS = gql`
mutation addSkills($skills: [skill_insert_input!]!) {
    insert_skill(objects: $skills) {
      affected_rows
    }
  }
`

const RECRUITER_CHAT_MUTATION = `
    mutation sendMessage($candidate_id: String, $recruiter_id: String, $message: String, $type: String, $fileName: String) {
        insert_chat_message(objects: {candidate_id: $candidate_id, from: "recruiter", recruiter_id: $recruiter_id, message: $message, type: $type, fileName: $fileName, read: false}) {
            returning {
                message
                read
                from
                created_at
                candidate {
                    id
                    name
                    profilePictureURL
                }
            }
        }
    }
`

const SUBSCRIBER_CHAT_MUTATION = `
    mutation sendSubscriberMessage($candidate_id: String, $company_id: uuid, $message: String, $type: String, $fileName: String) {
        insert_chat_message(objects: {candidate_id: $candidate_id, from: "subscriber", company_id: $company_id, message: $message, type: $type, fileName: $fileName, read: false}) {
            returning {
                message
                read
                from
                created_at
                candidate {
                    id
                    name
                    profilePictureURL
                }
            }
        }
    }
`

export default function ChatWindow({ candidateState, queryLoading, id, isSubscriber }) {
    // context
    const [chatMutation, setChatMutation] = useState(RECRUITER_CHAT_MUTATION);
    const [candidate, setCandidate] = candidateState
    const [loading, setLoading] = useState(false)
    const [sendChatMessage, { data: messageSent, loading: sendingMessage }] = useMutation(gql`${chatMutation}`);
    const [attachment, setAttachment] = useState()
    const [attachmentLoading, setAttachmentLoading] = useState(false)
    const attachmentRef = useRef();
    const [readMessages] = useMutation(SET_READ_MESSAGES)
    const [inputValue, setInputValue] = useState()
    const scrollRef = useRef()

    const isSameDate = (someDate, today = new Date()) => {
        return someDate.getDate() == today.getDate() &&
            someDate.getMonth() == today.getMonth() &&
            someDate.getFullYear() == today.getFullYear()
    }

   async function sendMessage(message) {
        setLoading(true);
        const payload = {
            candidate_id: candidate.id,
            recruiter_id: id,
            message
        };
        if (isSubscriber) {
            delete payload.recruiter_id;
            payload.company_id = id;
        }
        sendChatMessage({
            variables: {
                ...payload
            }
        })
        if(isSubscriber){
           await axios.post(`${EXPRESS_SERVER_URL}/sendCandidateNotificationFromSubscriber`, {
            ...payload 
           }) 
        }
    }

    useEffect(() => {
        setChatMutation(isSubscriber ? SUBSCRIBER_CHAT_MUTATION : RECRUITER_CHAT_MUTATION);
    }, [])

    useEffect(() => {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight
        readMessages({ variables: { candidate_id: candidate.id } })
        setLoading(false)
    }, [candidate])

    useEffect(() => {
        readMessages({ variables: { candidate_id: candidate.id } })
    }, [])

    const uploadFile = async (file) => {
        const storageRef = firebase
            .storage()
            .ref()
            .child(`chat/${id}-${Moment().format('MM-DD-YYYY').toString()}/${file.name}`);
        const fileURL = await storageRef
            .put(file)
            .then(snapshot => snapshot.ref.getDownloadURL());
        return fileURL;
    };

    async function sendAttachment() {
        setAttachmentLoading(true)
        const attachmentURL = await uploadFile(attachment)
        const payload = {
            candidate_id: candidate.id,
            recruiter_id: id,
            message: attachmentURL,
            type: 'file',
            fileName: attachment.name
        };

        if (isSubscriber) {
            delete payload.recruiter_id;
            payload.company_id = id;
        }
        sendChatMessage({
            variables: {
                ...payload
            }
        })
        setAttachmentLoading(false)
        setAttachment()
    }


    return (
        <div className='flex-1 flex flex-col'>
            <div ref={scrollRef} className='flex flex-col flex-1 pb-lg' style={{ maxHeight: 'calc(100vh - 287px)', overflow: 'auto' }}>
                {queryLoading ?
                    <div className='w-full h-full flex items-center justify-center'>
                        <Loader
                            type="Oval"
                            color="#E31C25"
                            height={40}
                            width={40}
                        />
                    </div>
                    : candidate.messages.map(({ message, from, date, type, fileName }, idx) => {
                        const isCandidate = from === 'candidate'
                        const messageTime = isSameDate(Moment(date).toDate()) ? Moment(date).format('h:mm a') : Moment(date).format('MMM. D')

                        return (
                            <div className={`${isCandidate ? 'justify-start' : 'justify-end'} flex flex-row p-md`} style={{ paddingBottom: 0 }} key={idx}>
                                {isCandidate &&
                                    <div className='flex items-center' style={{ height: 39 }}>
                                        <div
                                            style={{
                                                height: 30,
                                                width: 30,
                                                borderRadius: 15,
                                                marginRight: 16,
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                                backgroundImage: candidate.profilePictureURL ? `url(${candidate.profilePictureURL})` : `url(${ProfileAvatar}`
                                            }} />
                                    </div>}
                                <div className='flex flex-col' style={{ maxWidth: '50%' }}>
                                    <div className={`text-sm ${isCandidate ? 'bg-mediumgray text-darkblue' : 'bg-blue text-white'} p-sm rounded chat-message`} style={{ whiteSpace: 'pre-line' }}>
                                        {type === 'file' ?
                                            <div>
                                                <a target='_blank' href={message} download={fileName} className='text-underline flex items-center'>
                                                    <img src={FileIcon} style={{ height: 20, marginBottom: 0, marginRight: 10 }} />
                                                    {fileName}
                                                </a>
                                            </div> :
                                            <Linkify properties={{ target: '_blank' }}>
                                                {message}
                                            </Linkify>}
                                    </div>
                                    <div className='text-xs text-darkgray font-medium'>
                                        {messageTime}
                                    </div>
                                </div>
                            </div>)
                    })}
            </div>
            {attachment &&
                <div className='flex bg-mediumgray px-md pt-md items-center'>
                    <div className='flex flex-1'>
                        <div className='flex bg-darkblue rounded items-center'>
                            <div
                                onClick={() => window.open(URL.createObjectURL(attachment))}
                                className='hover:underline cursor-pointer text-white px-sm flex text-sm items-center' style={{ paddingTop: 5, paddingBottom: 5, fontSize: 12 }}>
                                <img src={FileIcon} style={{ height: 20, marginBottom: 0, marginRight: 10 }} />
                                {attachment.name}
                            </div>
                            <div
                                className='cursor-pointer'
                                onClick={() => setAttachment()}
                            >
                                <img

                                    src={XIcon}
                                    style={{ height: 12, margin: 0, marginRight: 10 }} />
                            </div>
                        </div>
                    </div>
                    {attachmentLoading ?
                        <Loader
                            type="ThreeDots"
                            color="#009EE0"
                            height={20}
                            width={20}
                        /> :
                        <div
                            onClick={sendAttachment}
                            className='text-sm cursor-pointer hover:bg-darkgray hover:text-white px-md rounded' style={{ paddingTop: 3, paddingBottom: 3 }}>
                            Send Attachment
                        </div>}
                </div>}
            <div className='bg-mediumgray p-md relative'>
                <textarea
                    value={inputValue}
                    placeholder='Send a message...'
                    onKeyDown={(e) => {
                        if (e.keyCode === 13 && !e.shiftKey) {
                            e.preventDefault()
                            setInputValue('')
                            sendMessage(e.target.value)
                        }
                    }}
                    onChange={e => {
                        setInputValue(e.target.value)
                    }}
                    className='border border-lightgray rounded w-full text-sm text-darkblue p-sm bg-white'

                />
                {(loading || sendingMessage) &&
                    <div className='absolute' style={{ top: 25, right: 40 }}>
                        <Loader
                            type="ThreeDots"
                            color="#009EE0"
                            height={20}
                            width={20}
                        />
                    </div>}
                <div
                    onClick={() => attachmentRef.current.click()}
                    className='absolute cursor-pointer' style={{ top: 25, right: 30 }}>
                    <img src={AttachmentIcon} style={{ height: 20 }} />
                </div>
                <input
                    onChange={({ target: { files } }) => {
                        setAttachment(files[0])
                    }}
                    ref={attachmentRef} type='file' hidden={true} />
            </div>
        </div>)
}
