import { useMutation, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import Moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import Loader from 'react-loader-spinner';
import UserAvatar from '../../images/user-avatar.png';
import { Arrow } from '../common';
import './CalendarStyles.css';

const GET_JOBS = gql`
  query getInterviewJobs($recruiterID: String) {
    job(where: { recruiter_id: { _eq: $recruiterID } }) {
      id
      company {
        name
        logoURL
      }
      title
    }
  }
`;

const INTERVIEW_AVAILABILITY = gql`
  query getInterviewAvailability($id: String) {
    recruiter(where: { id: { _eq: $id } }) {
      interviews {
        time
        date
      }
      availability
    }
  }
`;

const SCHEDULE_INTERVIEW = gql`
  mutation addJobInterview($interview: [job_interview_insert_input!]!, $candidate_id: String, $status: String) {
    insert_job_interview(objects: $interview) {
      returning {
        id
        type
        time
        date
        job_id
        job {
          company {
            name
          }
          title
        }
        candidate_id
        candidate {
          name
          profilePictureURL
        }
      }
    }
    update_candidate(where: { id: { _eq: $candidate_id } }, _set: { status: $status }) {
      affected_rows
      returning {
        status
      }
    }
  }
`;

function JobSelect({ error, modalRef, selectedJobState }) {
  const placeholder = 'Select a job';
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { data: jobs } = useQuery(GET_JOBS, {
    variables: {
      recruiterID: firebase.auth().currentUser && firebase.auth().currentUser.uid,
    },
  });
  const [selectedJob, setSelectedJob] = selectedJobState;
  const [companies, setCompanies] = useState([]);

  function hideDropdown() {
    setDropdownVisible(false);
  }

  useEffect(() => {
    if (dropdownVisible) {
      modalRef.current.addEventListener('click', hideDropdown);
    } else {
      return modalRef.current.removeEventListener('click', hideDropdown);
    }
  }, [dropdownVisible]);

  useEffect(() => {
    if (jobs) {
      const companiesObject = jobs.job.reduce((allCompanies, job) => {
        if (allCompanies[job.company && job.company.name]) {
          return {
            ...allCompanies,
            [job.company && job.company.name]: {
              ...allCompanies[job.company && job.company.name],
              jobs: [...allCompanies[job.company && job.company.name].jobs, { id: job.id, title: job.title }],
            },
          };
        } else {
          return {
            ...allCompanies,
            [job.company && job.company.name]: {
              logoURL: job.company && job.company.logoURL,
              jobs: [{ id: job.id, title: job.title }],
            },
          };
        }
      }, {});
      setCompanies(
        Object.entries(companiesObject).map(([name, value]) => ({
          name,
          ...value,
        })),
      );
    }
  }, [jobs]);

  return (
    <div className="relative w-full">
      <div
        onClick={() => setDropdownVisible(true)}
        className="border rounded w-full relative flex text-sm cursor-pointer items-center px-md gray"
        style={{
          height: 55,
          zIndex: dropdownVisible ? 501 : 499,
          borderColor: error ? '#E31C25' : '#c4cad3',
          color: '#c4cad3',
          paddingRight: 40,
          maxWidth: 341,
        }}
      >
        {selectedJob ? (
          <div
            className="text-darkblue font-medium mr-sm"
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {selectedJob.title} - {selectedJob.company.name}
          </div>
        ) : (
          'Select a job'
        )}
      </div>
      {jobs && dropdownVisible && (
        <div
          className="absolute bg-white w-full border-r border-l overflow-auto shadow"
          style={{
            top: 'calc(100% - 10px)',
            zIndex: 500,
            paddingTop: 10,
            maxHeight: 350,
          }}
        >
          {companies.map((company) => (
            <div className="flex flex-col">
              <div className="flex items-center p-sm">
                {/* <img src={company.logoURL} style={{maxHeight:20, maxWidth:20, marginBottom: 0, marginRight: 10}}/> */}
                <div className="text-darkblue font-medium relative" style={{ top: 2, fontSize: 16, lineHeight: '16px' }}>
                  {company.name}
                </div>
              </div>
              <div className="text-darkgray" style={{ fontSize: 14 }}>
                {company.jobs.map((job) => (
                  <div
                    onMouseDown={() => setSelectedJob({ ...job, company })}
                    className="hover:bg-lightgray cursor-pointer"
                    style={{
                      paddingLeft: 20,
                      paddingTop: 3,
                      paddingBottom: 3,
                    }}
                  >
                    {job.title}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="absolute right-0 top-0 bottom-0 flex items-center pr-lg">
        <Arrow color="#c4cad3" direction="down" />
      </div>
    </div>
  );
}

const StatusSelect = ({ options, typeState }) => {
  const [type, setType] = typeState;

  const colors = {
    pink: {
      text: 'rgb(255, 76, 138)',
      bg: 'rgba(255, 76, 138, 0.29)',
    },
    orange: {
      text: 'rgb(244, 144, 74)',
      bg: 'rgba(244, 144, 74, 0.29)',
    },
    blue: {
      text: 'rgb(0, 102, 255)',
      bg: 'rgba(0, 102, 255, 0.29)',
    },
  };

  function getColors(type, colorType) {
    switch (type) {
      case 'in-person':
        return colors.orange[colorType];
      case 'phone':
        return colors.blue[colorType];
      case 'second-interview':
        return colors.pink[colorType];
      case 'final-interview':
        return colors.blue[colorType];
    }
  }

  return (
    <div className="relative">
      <div className="absolute right-0 z-10 invisible" style={{ fontWeight: 600, fontSize: 12 }}>
        {`${type} Interview`}
      </div>
      <Arrow
        className="absolute"
        direction="down"
        style={{
          right: 15,
          top: 10,
        }}
        color={type === 'phone' ? colors.blue.text : colors.orange.text}
      />
      <select
        className="px-md rounded relative cursor-pointer z-20"
        style={{
          WebkitAppearance: 'none',
          width: 181,
          height: 33,
          fontWeight: 600,
          fontSize: 12,
          color: getColors(type, 'text'),
          backgroundColor: getColors(type, 'bg'),
        }}
        value={type}
        onChange={({ target }) => {
          setType(target.value);
        }}
      >
        <option value="phone">Phone Interview</option>
        <option value="in-person">In-Person Interview</option>
        <option value="second-interview">Second Interview</option>
        <option value="final-interview">Final Interview</option>
      </select>
    </div>
  );
};

export default function InterviewModal({ state }) {
  const [modal, setModal] = state;
  const [fadeDelay, setFadeDelay] = useState(false);
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState({ hour: '9:00', meridian: 'AM' });
  const [jobError, setJobError] = useState();
  const [interviews, setInterviews] = useState([]);
  const [availability, setAvailability] = useState();
  const { data } = useQuery(INTERVIEW_AVAILABILITY, {
    variables: {
      id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
    },
  });
  const [createJobInterview, { loading, data: submissionResponse }] = useMutation(SCHEDULE_INTERVIEW);
  const [selectedJob, setSelectedJob] = useState();
  const [type, setType] = useState();
  const modalRef = useRef();

  useEffect(() => {
    if (selectedJob) {
      setJobError(false);
    }
  }, [selectedJob]);

  useEffect(() => {
    if (data) {
      const {
        recruiter: [recruiter],
      } = data;
      if (recruiter) {
        setAvailability(recruiter.availability);
        setInterviews(
          recruiter.interviews.reduce((allInterviews, interview) => {
            if (allInterviews[interview.date]) {
              return {
                ...allInterviews,
                [interview.date]: [...allInterviews[interview.date], interview.time],
              };
            } else {
              return { ...allInterviews, [interview.date]: [interview.time] };
            }
          }, {}),
        );
        setTime({
          hour: recruiter.availability.schedule && recruiter.availability.schedule.from.split(' ')[0],
          meridian: recruiter.availability.schedule && recruiter.availability.schedule.from.split(' ')[1],
        });
      }
    }
  }, [data]);

  useEffect(() => {
    if (modal.open) {
      setFadeDelay(true);
    } else {
      setTimeout(() => {
        setFadeDelay(false);
      }, 300);
    }
    if (modal.data) {
      setType(modal.data.type);
      if (modal.data.job) {
        setSelectedJob(modal.data.job);
      }
    }
  }, [modal]);

  useEffect(() => {
    if (submissionResponse) {
      setModal({
        ...modal,
        open: false,
        newStatus: submissionResponse.update_candidate.returning[0].status,
      });
    }
  }, [submissionResponse]);

  const statusOptions = [
    {
      value: 'Phone Interview',
      color: 'blue',
    },
    {
      value: 'In-Person Interview',
      color: 'orange',
    },
  ];

  function getInterviewType(label) {
    switch (label) {
      case 'in-person':
        return 'In-Person Interview Scheduled';
      case 'phone':
        return 'Phone Interview Scheduled';
      case 'second-interview':
        return 'Second Interview Scheduled';
      case 'final-interview':
        return 'Final Interview Scheduled';
    }
  }

  function scheduleInterview() {
    const dateString = Moment(date).format('YYYY-MM-DD');
    if (selectedJob) {
      createJobInterview({
        variables: {
          interview: {
            candidate_id: modal.data.candidate.id,
            job_id: selectedJob.id,
            date: dateString,
            time: `${time.hour} ${time.meridian}`,
            type,
          },
          candidate_id: modal.data.candidate.id,
          status: getInterviewType(type),
        },
        refetchQueries: ['findAllRecruiterInterviews', 'getCandidatesByRecruiter'],
      });
    } else {
      setJobError(true);
    }
  }

  return (
    <div
      onClick={() => setModal({ ...modal, open: !modal.open, reset: true })}
      className={`fixed inset-0 items-center justify-center`}
      style={{
        transition: 'opacity 0.3s ease-in-out',
        opacity: modal.open ? 1 : 0,
        zIndex: 3000000,
        display: fadeDelay ? 'flex' : 'none',
        backgroundColor: 'rgba(34, 46, 66, 0.9)',
      }}
    >
      <div ref={modalRef} onClick={(e) => e.stopPropagation()} className="bg-white px-xl py-md rounded shadow flex flex-col" style={{ minHeight: 489 }}>
        <div className="text-lg font-medium pb-sm border-b mb-lg">Schedule Interview</div>
        {modal.data && (
          <div className="flex items-center justify-center mb-md">
            <div
              style={{
                marginRight: 10,
                height: 36,
                width: 36,
                borderRadius: 40,
                backgroundImage: `url(${modal.data.candidate.profilePicture ? modal.data.candidate.profilePicture : UserAvatar})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
              }}
            />
            <div className="font-medium" style={{ fontSize: 16 }}>
              {modal.data.candidate.name}
            </div>
          </div>
        )}
        {jobError && <div className="font-medium text-red text-sm text-center mb-sm">Please select a job</div>}
        <JobSelect modalRef={modalRef} selectedJobState={[selectedJob, setSelectedJob]} />
        <div className="flex flex-col items-center mb-lg mt-lg">
          <Calendar
            value={date}
            onChange={setDate}
            formatShortWeekday={(locale, date) => Moment(date).format('dd')}
            tileDisabled={(tile) => {
              return Moment(tile.date)
                .add(1, 'days')
                .isBefore(Moment());
            }}
          />
        </div>
        <div className="flex justify-between">
          <div style={{ alignSelf: 'flex-start', marginRight: 15 }}>
            <StatusSelect options={statusOptions} typeState={[type, setType]} />
          </div>
          <div>
            <select
              value={time.hour}
              onChange={(e) => setTime({ ...time, hour: e.target.value })}
              className="border rounded px-md font-medium"
              style={{
                WebkitAppearance: 'none',
                backgroundColor: 'transparent',
                height: 33,
                borderColor: '#c4cad3',
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                fontSize: 14,
              }}
            >
              {Array(12)
                .fill('')
                .map((_, i) => {
                  const formattedDate = Moment(date).format('MM-DD-YYYY');
                  let isToday = false;
                  if (interviews[formattedDate]) {
                    isToday = true;
                  }
                  return (
                    <React.Fragment key={i}>
                      <option disabled={isToday && interviews[formattedDate].includes(`${i + 1}:00 ${time.meridian}`)}>{`${i + 1}:00`}</option>
                      <option disabled={isToday && interviews[formattedDate].includes(`${i + 1}:30 ${time.meridian}`)}>{`${i + 1}:30`}</option>
                    </React.Fragment>
                  );
                })}
            </select>
            <select
              value={time.meridian}
              onChange={(e) => setTime({ ...time, meridian: e.target.value })}
              className="border rounded px-md bg-lightgray text-darkblue font-medium"
              style={{
                WebkitAppearance: 'none',
                height: 33,
                borderColor: '#c4cad3',
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderLeft: 0,
                fontSize: 14,
              }}
            >
              <option>AM</option>
              <option>PM</option>
            </select>
          </div>
        </div>

        <div className="flex flex-1 justify-between items-end" style={{ marginTop: 40, marginBottom: 20 }}>
          <button
            onClick={() => setModal({ ...modal, open: false, reset: true })}
            type="button"
            className="rounded border mr-sm font-medium text-sm bg-lightgray text-darkgray"
            style={{ width: 137, height: 55, fontSize: 14 }}
          >
            Cancel
          </button>
          <button
            onClick={() => scheduleInterview()}
            className="rounded bg-red ml-md font-medium  text-sm text-white"
            style={{
              width: 180,
              height: 55,
              fontSize: 14,
              boxShadow: '0 14px 10px 0 rgba(255, 0, 0, 0.18)',
            }}
          >
            {loading ? <Loader type="TailSpin" className="flex justify-center" color="#FFFFFF" height={20} width={20} /> : 'Schedule Interview'}
          </button>
        </div>
      </div>
    </div>
  );
}
