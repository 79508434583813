import { navigate } from '@reach/router';
import { Field, Form, Formik } from 'formik';
import React, { useContext } from 'react';
import Loader from 'react-loader-spinner';
import * as Yup from 'yup';
import UserRoleContext from '../../context/UserRole';
import { FormField } from '../fields';
import FieldTitle from '../fields/FieldTitle';

export default function JobForm ({ job, onSubmit, loading, isAdmin }) {
  const [userRole] = useContext(UserRoleContext);
  const isSubscriber = userRole === 'company';

  const placeholders = {
    title: 'Job Title',
    description: 'Job Description',
    compensation_list: 'Compensation',
    location: 'Location',
  };

  const jobSchema = Yup.object().shape({
    title: Yup.string().required('A job title is required'),
    description: Yup.string().required('A job description is required'),
    company:
      !isSubscriber &&
      Yup.mixed().test({
        name: 'Empty Company',
        message: 'Please select or add a company for the job',
        test: (value) => {
          return value ? true : false;
        },
      }),
    compensation_list: Yup.mixed().test({
      name: 'Compensation',
      message: 'Please select compensation attributes',
      test: (value) => (value.length === 0 ? false : true),
    }),
    location: Yup.string().required('A job location is required'),
    industry: Yup.string().required('An industry for the job is required'),
    requiredExperience: Yup.mixed().test({
      name: 'Attributes',
      message: 'Minimum and maximum experience required',
      test: (value) => value[0] && value[1],
    }),
    attributes: Yup.mixed().test({
      name: 'Attributes',
      message: 'Please enter attributes',
      test: ({ optional, required }) => (required.length === 0 ? false : true),
    }),
  });

  const initialValues = {
    title: job && job.title,
    description: job && job.description,
    company: job && job.company && { company: job.company, isNewCompany: false },
    salary: job ? [job.salaryMin || 0, job.salaryMax || 0] : [0, 0],
    compensation_list: (job && job.compensation_list) || [],
    location: job && job.location ? (typeof job.location === 'string' ? JSON.parse(job.location) : job.location) : '',
    industry: job && job.industry ? job.industry : '',
    requiredExperience: job && job.requiredExperience ? (typeof job.requiredExperience === 'string' ? JSON.parse(job.requiredExperience) : job.requiredExperience) : ['5', '10'],
    attributes: job && job.attributes ? (typeof job.attributes === 'string' ? JSON.stringify(job.attributes) : job.attributes) : { required: [], optional: [] },
    notes: [],
    allowsRemote: job && job.allowsRemote,
    hiddenFromCandidates: job && job.hiddenFromCandidates,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit(values);
      }}
      validationSchema={jobSchema}
    >
      {({ values, setFieldValue }) => (
        <Form>
          {Object.entries(values).map(([label], index) => (

            <Field name={label} key={index}>
              {({ field: { value }, form: { errors, touched } }) => (
                <React.Fragment>
                  {['title', 'description'].includes(label) && <FieldTitle title={`${label}`} />}
                  {/* {placeholders[label] &
                    
                    
                    & <FieldTitle title={`${placeholders[label]}`} />} */}
                  <FormField
                    name={label}
                    value={value}
                    update={(value) => setFieldValue(label, value)}
                    error={errors && touched[label] && errors[label]}
                    placeholder={placeholders[label]}
                  />
                </React.Fragment>
              )}
            </Field>
          ))}
          <div className="flex relative justify-between" style={{ height: 55, marginBottom: 32, marginTop: 60 }}>
            <button
              onClick={() => navigate(isAdmin ? '/dashboard/jobs' : '/jobs')}
              type="button"
              className="rounded border font-medium text-sm bg-lightgray text-darkgray"
              style={{ width: '49%', fontSize: 14 }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="rounded font-medium text-sm bg-red text-white"
              style={{ width: '49%', fontSize: 14 }}
            >
              {loading ? <Loader className="flex justify-center" type="TailSpin" color="#FFFFFF" height={20} width={20} /> : 'Save & Continue'}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
