import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import * as Yup from 'yup';
import AttributeSelect from '../fields/AttributeSelect';
import FieldTitle from '../fields/FieldTitle';

export default function EditAttributeForm({ initialValues, saveAttribute, navigate, loading, isEdit }) {
  const AttributeSchema = Yup.object().shape({
    attribute_name: Yup.string().required('Please enter attribute name'),
    attribute_category: Yup.string().required('Please select attribute category'),
  });

  const [errors, setErrors] = useState({});

  return (
    <div className="EditAttributeForm-js py-lg w-full h-full relative container flex flex-col items-center">
      <div className="font-main text-darkblue" style={{ fontWeight: 500, fontSize: 21 }}>
        {isEdit ? 'Edit Attribute' : 'Create Attribute'}
      </div>
      <div className="flex p-md mb-md"></div>
      <div
        className="flex flex-1 w-full justify-center relative"
        style={{
          opacity: 1,
          transition: 'opacity 0.3s ease-in-out 0s',
          zIndex: 1000,
          minWidth: 320,
        }}
      >
        <div
          className={`EditAttributeForm-js w-full bg-white rounded shadow flex flex-col items-center`}
          style={{
            display: 'flex',
            padding: 30,
            transition: 'transform 0.3s ease-in-out',
            transform: `translateX(0%)`,
            height: 400,
          }}
        >
          <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              saveAttribute(values);
            }}
            validationSchema={AttributeSchema}
          >
            {({ setFieldValue }) => (
              <Form customErrors={errors} setCustomErrors={setErrors} style={{ width: '100%' }}>
                {Object.entries(initialValues).map(([label, value]) => {
                  switch (label) {
                    case 'attribute_category':
                      return (
                        <Field name="attribute_category">
                          {({ field, form }) => (
                            <>
                              <AttributeSelect
                                value={field.value}
                                update={(attribute_category) => {
                                  setFieldValue('attribute_category', attribute_category);
                                }}
                              />
                              {form.errors['attribute_category'] && form.touched['attribute_category'] && (
                                <div className="text-xs text-red font-medium mt-sm text-center">{form.errors['attribute_category']}</div>
                              )}
                            </>
                          )}
                        </Field>
                      );
                    case 'attribute_name':
                      return (
                        <Field name="attribute_name">
                          {({ field, form, meta }) => {
                            return (
                              <>
                                <FieldTitle title="Skill Name" />
                                <input
                                  type="text"
                                  className="text-sm  px-md rounded w-full border border-darkgray"
                                  style={{
                                    height: 55,
                                    marginTop: 16,
                                    borderColor: '#c4cad3',
                                  }}
                                  placeholder="Skill Name"
                                  {...field}
                                />
                                {form.errors['attribute_name'] && form.touched['attribute_name'] && (
                                  <div className="text-xs text-red font-medium mt-sm text-center">{form.errors['attribute_name']}</div>
                                )}
                              </>
                            );
                          }}
                        </Field>
                      );
                  }
                })}
                <div className="flex w-full mt-lg">
                  <button
                    onClick={() => navigate('/dashboard/attributes')}
                    type="button"
                    style={{ height: 55 }}
                    className="bg-lightgray flex items-center justify-center shadow text-xs font-medium flex-1 text-darkgray rounded mr-sm p-sm"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    style={{
                      height: 55,
                    }}
                    className="bg-red flex items-center justify-center  shadow ml-sm text-xs font-medium flex-1 text-white rounded p-sm"
                  >
                    {loading ? <Loader type="TailSpin" color="#FFFFFF" height={20} width={20} /> : 'Save & Continue'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
