import { Field } from 'formik';
import moment from 'moment';
import React, { useEffect } from 'react';
import CandidateSkills from './CandidateSkills';
import CompanyLogoUpload from './CompanyLogoUpload';
import RichTextArea from './RichTextArea';
import TenureField from './TenureField';
import TextInput from './TextInput';
import FieldTitle from './FieldTitle';

const EpisodeEntry = ({ init, value, update, error }) => {
    const errors = {
        link: 'Link is required',
        description: 'A job description is required',
    };

    const placeholders = {
        link: 'Link',
        // description: 'A job description is required',
        description: 'Link is Description',
    };

    const renderField = (name, value) => {
        switch (name) {
            case 'id':
                return null;
            case 'link':
                return <TextInput value={value} width="50%" error={!value && error} placeholder="Link" update={(value) => update({ name, data: value })} />;
            case 'description':
                return <TextInput value={value} width="50%" error={!value && error} placeholder="description" update={(value) => update({ name, data: value })} />;
            default:
                return <TextInput error={!value && error} value={value} placeholder={placeholders[name]} update={(value) => update({ name, data: value })} />;
        }
    };

    return (
        <div className="flex flex-wrap">
            {Object.entries(init).map(([label], index) => {
                return (
                    <React.Fragment key={index}>
                        {renderField(label, value[label], error && errors[label])}
                        {/* {error && errors[label] &&
                            <div className='w-full'>
                                <ErrorMessage error={errors[label]}/>
                            </div>} */}
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default function EpisodeField({ value, update, error, newResumeValue, setFieldValue }) {
    const blankEmploymentEntry = {
        id:
            Math.random()
                .toString(36)
                .substring(2, 15) +
            Math.random()
                .toString(36)
                .substring(2, 15),
        link: '',
        description: '',
    };

    const addEpisode = () => update([...value, blankEmploymentEntry]);
    const removeEpisode = (index) => update(value.filter((_, i) => index !== i));

    const updateEntry = ({ name, data }, index) => {
        const updatedEntry = Object.fromEntries(Object.entries(value[index]).map(([label, entry]) => (label === name ? [name, data] : [label, entry])));
        const updatedList = value.map((_, i) => (i === index ? updatedEntry : value[i]));
        update(updatedList);
    };

    useEffect(() => {
        if (newResumeValue) {
            update(newResumeValue);
        }
    }, [newResumeValue]);

    return (
        <div>
            <FieldTitle title='Links' />
            {error && <div className="text-red font-medium text-sm mt-sm text-center">{error}</div>}
            {value?.map((employmentEntry, index) => {
                return (
                    <Field key={index} name={`employment[${index}]`}>
                        {({ form: { errors, touched } }) => (
                            <React.Fragment key={index}>
                                <EpisodeEntry key={employmentEntry.id} init={blankEmploymentEntry} error={error} value={employmentEntry} update={(val) => updateEntry(val, index)} />
                                {value.length >= 1 && (
                                    <>
                                        <div className="text-red flex items-center justify-center font-medium" style={{ fontSize: 14, marginTop: 20 }}>
                                            <div onClick={() => removeEpisode(index)} className="flex items-center py-sm px-md hover:bg-red hover:text-white rounded cursor-pointer">
                                                <span style={{ fontSize: 24, marginRight: 7 }}>-</span>
                                                <span>Remove Links</span>
                                            </div>
                                        </div>
                                        <div className="border-b" style={{ marginTop: 20 }} />
                                    </>
                                )}
                            </React.Fragment>
                        )}
                    </Field>
                );
            })}
            <div className="text-green flex items-center justify-center font-medium" style={{ fontSize: 14, marginTop: 20 }}>
                <div onClick={addEpisode} className="flex items-center py-sm px-md hover:bg-green hover:text-white rounded cursor-pointer">
                    <span style={{ fontSize: 24, marginRight: 7 }}>+</span>
                    <span>Add new Links</span>
                </div>
            </div>
        </div>
    );
}