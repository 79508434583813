import React, { useState } from "react";
import './style.css';
import { TabTitle } from "../../components/common";
import LinkedinTable from "./tables/LinkedinTable";
import { generateRandomId, isValidLinkedInUrl } from "./util";
import { EXPRESS_SERVER_URL } from "../../config";
import axios from 'axios'
import { toast, ToastContainer } from 'material-react-toastify';
import firebase, { auth } from 'firebase/app';
import TalentFinderCheckboxes from "./TalentFinderCheckboxes";

function LinkedInUrl() {
  const [checkboxData, setCheckBoxData] = useState();
  const [linkedinData, setLinkedInData] = useState([]);
  const [linkedinURL, setLinkedinURL] = useState("");
  const [isLinkedinWarning, setIsLinkedinWarning] = useState(false);
  const [selectedLinkedinData, setSelectedLinkedinData] = useState({});
  const [openEditModal, setOpenEditModal] = useState(false);
  const [isEditLinkedInUrl, setIsEditLinkedinUrl] = useState(false);
  const [configData, setConfigData] = useState({
    contact_number: false,
    personal_email: false,
  })

  const onChangeCheckbox = (formData) => {
    setCheckBoxData((prevState) => {
      return { ...prevState, ...formData };
    });
  };

  const handleDeleteLinkedinURL = (id) => {
    const newLinkedinData = linkedinData?.filter((data) => id !== data?.id);
    setLinkedInData(newLinkedinData);
  };
  const handleEditSelectedLinkedinURL = (id) => {
    const selectedLinkedinData = linkedinData?.find((data) => id === data?.id);
    setSelectedLinkedinData(selectedLinkedinData);
    setLinkedinURL(selectedLinkedinData?.url);
    setIsEditLinkedinUrl(true);
  };

  const handleEditLinkedinURL = () => {
    const editedLinkedinURL = linkedinData?.map((elem) => {
      if (elem?.id === selectedLinkedinData?.id) {
        return { ...selectedLinkedinData, url: linkedinURL };
      }
      return elem;
    });
    setIsEditLinkedinUrl(false);
    setLinkedInData(editedLinkedinURL);
    setSelectedLinkedinData({})
    setLinkedinURL('')
  };

  const handleLinkedInURLChange = (e) => {
    setLinkedinURL(e);
  };

  const handleLinkedInAddClick = () => {
    if (!linkedinURL) {
      setIsLinkedinWarning(true);
      return;
    }
    const linkedinItems = linkedinURL.split(',');
    const validLinkedUrl = linkedinItems?.filter(elem => isValidLinkedInUrl(elem));
    const invalidLinkedinUrl = linkedinItems?.filter(elem => !isValidLinkedInUrl(elem))?.join(',');
    for (const url of validLinkedUrl) {
      setLinkedInData((prevState) => {
        return [{ id: generateRandomId(), url }, ...prevState];
      });
    }
    if(invalidLinkedinUrl?.length){
      toast.error(`Invalid linkedin url, ${invalidLinkedinUrl}`)
    }
    setLinkedinURL("");
  };

  const handleEnrichBtnClick = async () => {
    const recruiter_id = firebase.auth().currentUser && firebase.auth().currentUser.uid

    const { data: importerData } = await axios.post(`${EXPRESS_SERVER_URL}/checkImporter`, {
      recruiter_id,
      account_type: 'loxo',
    });

    try {
      const { data } = await axios.post(`${EXPRESS_SERVER_URL}/talent-finder`, {
        users: linkedinData,
        auth: importerData && importerData[0] && importerData[0].authorization ? importerData[0].authorization : null,
        config: configData
      });
      toast.success('Job added to the queue');
      setLinkedInData([]);
    } catch (error) {
      toast.error('something went wrong');
    }
  };

  return (
    <div className="mt-4">
      <div className="flex justify-between flex-row align-middle py-3">
        <TabTitle showBackButton={true}>{'Search By Linkedin Url'}</TabTitle>
        {linkedinData?.length ? (
          <span onClick={handleEnrichBtnClick} className="bg-red enrich-add-btn text-white hover:text-white font-medium rounded flex items-center justify-center cursor-pointer w-300 h-40">
            Search Selected Profile
          </span>
        ) : null}
      </div>
      <div>

        {/* <EnrichCheckboxes
          options={checkboxOptions}
          onChangeCheckbox={onChangeCheckbox}
        /> */}
      </div>
      <TalentFinderCheckboxes setConfigData={setConfigData}/>
      <div className="flex flex-row w-fit justify-center items-center">
        <div className="flex flex-col gap-1 w-full mb-3 mt-2">
          <input
            type='text'
            className="p-2 bg-white w-1/2 sm:w-full text-base shadow rounded relative placeholder-primary font-normal"
            placeholder={"Linkedin URL"}
            onChange={(e) => {
              handleLinkedInURLChange(e.target.value);
            }}
            value={linkedinURL}
          />
          <div className="text-sm">You may submit multiple LinkedIn URLs, each separated by a comma delimiter </div>
          <button
            onClick={!isEditLinkedInUrl ? handleLinkedInAddClick : handleEditLinkedinURL}
            className="bg-red mt-3 linkedin-add-btn text-white hover:text-white font-medium rounded flex items-center justify-center cursor-pointer talent-finder-linkedin-btn p-3"
          >
            {isEditLinkedInUrl ? 'Update' : 'Add'}
          </button>
        </div>
      </div>
      {linkedinData?.length ? (
        <>
          <LinkedinTable
            handleEditSelectedLinkedinURL={handleEditSelectedLinkedinURL}
            handleDeleteLinkedinURL={handleDeleteLinkedinURL}
            linkedinData={linkedinData}
          />
        </>
      ) : null}
      <ToastContainer style={{ width: 'fit-content' }} position="bottom-center" autoClose={3000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
    </div>
  );
}

export default LinkedInUrl;
