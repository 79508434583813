import React, { useState, useEffect, useContext } from "react";

const LocationCheckboxFilter = ({ value, update }) => {
  const [checked_current, toggleCurrent] = useState(value?.currentLocation);
  const [checked_relocate, toggleRelocate] = useState(value?.willingToRelocate);
  const [checked_remote, toggleRemote] = useState(value?.remote);

  const setCurrentLocation = (status, type) => {
    if (type == 'current') {
      toggleCurrent(status);
    }
    if (type == 'relocate') {
      toggleRelocate(status)
    }
    if (type == 'remote') {
      toggleRemote(status)
    }
    return status;
  }

  useEffect(() => {
    toggleRelocate(value?.willingToRelocate);
    toggleRemote(value?.remote);
  }, [value]);

  return (
    <div
      className="flex items-center loc_preference_main"
      style={{
        fontSize: 14,
        marginRight: name === "currentLocation" ? 45 : 0,
        paddingBottom: name === "willingToRelocate" ? 0 : 25,
        marginLeft: 0
      }}
    >

      {/* <div className="loc_preference">
        <div
          onClick={() => update({ ...value, willingToRelocate: setCurrentLocation(!checked_relocate, 'relocate') })}
          className={`${checked_relocate ? "bg-green" : ""
            } border border-green cursor-pointer`}
          style={{
            width: 14,
            height: 14,
            marginRight: 10,
          }}
        />
        <div className="cursor-pointer font-medium">
          Willing to Relocate
        </div>
      </div> */}

      <div className="loc_preference flex justify-start items-center" style={{ width: 'fit-content' }}>
        <div
          onClick={() => update({ ...value, remote: setCurrentLocation(!checked_remote, 'remote') })}
          className={`${checked_remote ? "bg-green" : ""
            } border border-green cursor-pointer`}
          style={{
            width: 14,
            height: 14,
            marginRight: 10,
          }}
        />
        <div className="cursor-pointer font-medium">
          Open to Remote
        </div>
      </div>
    </div>
  );
};

export default LocationCheckboxFilter;