import React, { useState } from 'react';
import CandidateDashboard from '../candidate-dashboard';
import { REQUEST_STATUSES as STATUSES } from '../../../utils/constants';

const SavedCandidates = () => {
  const [baseFilter, setBaseFilter] = useState(`where: {company_requests: {_and: {status: {_in: ["${STATUSES.approved}", "${STATUSES.hired}"]}, company: {adminID: {_eq: $admin_id}}}}}`)
  const onUnlockedDateChange = (baseFilter) => {
    setBaseFilter(baseFilter)
  }
  return <CandidateDashboard
  BASE_FILTER={baseFilter}
  searchPlaceholder="Name / Job title / Company"
  context="saved-candidates"
  showSavedCandidates={true}
  onUnlockedDateChange={onUnlockedDateChange}
/>
};

export default SavedCandidates;
// "${STATUSES.accepted}"