import React, { useRef, forwardRef, useImperativeHandle } from 'react';
import { CandidateList, NoCandidatesFound, NoFilterResults } from '.';
import Loader from 'react-loader-spinner';
import { isEmpty } from 'lodash';
import { USER_ROLES } from '../../../utils/constants';

/**
 * 
 * @param {Object} props Table Props
 * @param {String} props.ROLE User Role
 * @param {Array<Candidate>} props.selectedCandidates Selected Candidates
 * @param {Function} props.onCandidateSelect Updated selected candidate state
 * @param {Function} props.onChange Update candidate rows
 * @returns {React.FunctionComponent} Results
 */
const Results = forwardRef((props, ref) => {

  // Refs
  const listRef = useRef();

  useImperativeHandle(ref, () => ({
  }));

  if (props?.isLoading) {
    return (
      <tr>
        <td colSpan={[USER_ROLES.RECRUITER, USER_ROLES.AGENCY, USER_ROLES.ADMIN].includes(props.ROLE) ? 9 : 7}>
          <Loader style={{ display: 'flex', justifyContent: 'center' }} type="TailSpin" color="#e44027" height={60} width={60} />
        </td>
      </tr>
    );
  }

  const Candidates = () => (
    <CandidateList
      ref={listRef}
      candidates={props.candidates}
      selectedCandidates={props.selectedCandidates}
      ROLE={props.ROLE}
      isRequestedCandidates={props.isRequestedCandidates}
      isInterestedCandidates={props.isInterestedCandidates}
      isSavedCandidates={props.isSavedCandidates}
      isFavoriteCandidates={props.isFavoriteCandidates}
      onCandidateSelect={props?.onCandidateSelect}
      onChange={props.onChange}
      handleFavStatusChange={props.handleFavStatusChange}
      handleCandidateStatusChange={props.handleCandidateStatusChange}
      handleCandidateCancelStatus={props.handleCandidateCancelStatus}
      excludeApprovedCandidate={props.excludeApprovedCandidate}
    />
  );

  const NoResults = () => {
    setTimeout(() => {
    }, 100);
    return (
      <tr>
        <td colSpan={props.colSpan}>
          <NoCandidatesFound />
        </td>
      </tr>
    );
  }

  const NoResultsWithFilter = () => (
    <tr>
      <td colSpan={props.colSpan}>
        <NoFilterResults />
      </td>
    </tr>
  );

  if (props?.candidates?.length && !props?.showMessage) {
    return <Candidates />
  }

  if (props?.showMessage) {
    return <NoResultsWithFilter />
  }

  return <NoResults />
});

export default Results;