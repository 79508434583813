export function generateRandomId() {
    const timestamp = new Date().getTime() + Math.random();
    return `${timestamp}`;
}


export function isValidLinkedInUrl(url) {
    const linkedinPattern = /^https:\/\/www\.linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/;
    return linkedinPattern.test(url);
  }


export const validateFile = (file, setErrorMessage) => {
    const allowedExtensions = ["csv", "xls", "xlsx"];
    const fileSizeLimit = 5 * 1024 * 1024; // 5 MB

    const fileExtension = file.name.split(".").pop()?.toLowerCase();
    if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
        setErrorMessage && setErrorMessage("Invalid file format");
        return false;
    }

    if (file.size > fileSizeLimit) {
        setErrorMessage && setErrorMessage("File is too large");
        return false;
    }

    return true;
};