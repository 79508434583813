// libraries
import React from 'react'
import { useEffect, useContext, useState } from 'react';
// Context
import { UserRoleContext } from '../../../context';
// Constants
import { USER_ROLES } from '../../../utils/constants'
// Config
import { EXPRESS_SERVER_URL } from '../../../config'
// Components
import FilterModal from '../../../views/candidates/candidate-dashboard/filter-modal';
// Styles
import './admin-actions.css'
import { equals } from '../../../utils/candidate-helper';

/**
 * @param {Object} props React props
 * @param {String} props.userId User Id
 * @param {Boolean} props.loading Loading
 * @param {String} props.for Recuriter Subscriber
 * @returns {React.FunctionComponent}
 */
export default function AdminActions(props) {
  // context
  const [userRole] = useContext(UserRoleContext);
  // State
  const [isAdmin, setIsAdmin] = useState(false);
  const [isRecruiter, setIsRecruiter] = useState(false);
  const [isAgency, setIsAgency] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setIsAdmin(equals(userRole, USER_ROLES.ADMIN))
    setIsRecruiter(equals(userRole, USER_ROLES.RECRUITER))
    setIsAgency(equals(userRole, USER_ROLES.AGENCY))
  }, [userRole])

  useEffect(() => {
    if (props.userId && (isAdmin || isRecruiter || isAgency)) {
      getUser();
    }
  }, [props.userId, isAdmin, isRecruiter, isAgency])

  if (!props.userId || !(isAdmin || isRecruiter || isAgency)) {
    return null;
  }

  /**
   * Get user details from server
   * @returns {Promise}
   */
  const getUser = async () => {
    try {
      setIsLoading(true);
      const requestConfig = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
      const response = await fetch(`${EXPRESS_SERVER_URL}/users/${props.userId}`, requestConfig);
      const body = await response.json()
      setIsLoading(false);
      if (body?.data) {
        // Updated status
        setDisabled(!!body?.data?.disabled)
      } else {
        console.error('ERROR_UPDATE_USER_STATUS', response);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('ERROR_UPDATE_USER', error);
    }
  }

  /**
   * Update user disabled state from server
   * @param {Object} event HTML click event
   * @returns {Promise}
   */
  const updateUser = async (event) => {
    // event?.preventDefault();
    if (!event) {
      return;
    }
    try {
      setIsLoading(true);
      const payload = {
        disabled: !disabled,
        role: props.for,
      }
      const requestConfig = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload)
      }
      const response = await fetch(`${EXPRESS_SERVER_URL}/users/${props.userId}/account`, requestConfig);
      const body = await response.json()
      setIsLoading(false);
      if (body) {
        // Updated status
        await getUser();
      } else {
        console.error('ERROR_UPDATE_USER_STATUS', response);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('ERROR_UPDATE_USER', error);
    }
  }

  const handleChanges = (e) => {
    setOpenModal(false);
    updateUser(e)
  }

  return (
    <div className='admin-actions manage-users-actions'>
      <button onClick={() => setOpenModal(true)} disabled={isLoading} className={!disabled ? 'danger-button' : 'success-button'}>
        {
          disabled ? 'Enable ' : 'Disable '
        }
        {
          props.for || 'User'
        }
      </button>
      {/* Validation popup */}
      <FilterModal open={openModal} showCancel={true} onChange={(e) => handleChanges(e)}>
        {`Are you sure if you want to ${disabled ? 'enable ' : 'disable '} ${props.for || 'User'} ?`}
      </FilterModal>
    </div>
  )
}
