import { useMutation, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { navigate } from 'gatsby';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { GlobalFilterContext } from '../../components/layout/layout';
import TabNav from '../../components/layout/TabNav';
import { ApolloContext } from '../../context/Apollo';
import UserRoleContext from '../../context/UserRole';
import '../dashboard/dashboard.css';
import JobList from './job-list';
import JobSearch from './job-search';
import CherryPagination from '../../components/common/Pagination/Pagination';
import { DEFAULT_PER_PAGE } from '../../utils/constants';
import moment from 'moment';

const GET_COMPANY = gql`
  query getCompany($admin_id: String) {
    company(where: { adminID: { _eq: $admin_id } }) {
      id
      email
      name
      logoURL
      adminName
      totalCredit
      remainingCredit
      subscription_type
    }
  }
`;

const FILL_JOB = gql`
  mutation fillJob($id: uuid, $value: Boolean) {
    update_job(where: { id: { _eq: $id } }, _set: { filled: $value }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

const CLOSE_JOB = gql`
  mutation fillJob($id: uuid, $value: Boolean) {
    update_job(where: { id: { _eq: $id } }, _set: { closed: $value }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

const ALL_JOBS_QUERY = gql`
  query getJobs($whereCondition: job_bool_exp, $orderBy: [job_order_by!], $perPage: Int, $offset: Int) {
    job_aggregate(where: $whereCondition) {
      aggregate {
        count
      }
    }
    job(where: $whereCondition, order_by: $orderBy, limit: $perPage, offset: $offset) {
      id
      industry
      location
      notes
      company {
        id
        name
        logoURL
      }
      subscriber {
        id
        name
        logoURL
      }
      optionalAttributes
      requiredAttributes
      requiredExperience
      salaryMax
      salaryMin
      status
      title
      hiddenFromCandidates
      filled
      description
      compensation
      compensation_list
      recruiter_id
      recruiter {
        id
        availability
        email
        industries
        name
        phone
        profilePhoto
        title
        company {
          id
          name
        }
        position
        candidates_aggregate {
          aggregate {
            count
          }
        }
      }
      subscriber_id
      adminApproved
      created_at
      closed
    }
  }
`;

/*
job_aggregate(where: $whereCondition) {
      aggregate {
        count
      }
    }

*/
const ALL_FILTERED_JOBS = gql`
  query getJobs($whereCondition: job_bool_exp, $orderBy: [job_order_by!], $g_lat: float8, $g_long: float8, $g_radius: Int, $search_term: String, $perPage: Int, $offset: Int) {
    filter_jobs_aggregate(
      where: $whereCondition,
      args: {g_lat: $g_lat, g_long: $g_long, g_radius: $g_radius, search_term: $search_term},
      ) {
      aggregate {
        count
      }
    }
    filter_jobs(
      where: $whereCondition,
      args: {g_lat: $g_lat, g_long: $g_long, g_radius: $g_radius, search_term: $search_term},
      order_by: $orderBy, 
      limit: $perPage,
      offset: $offset
      ) {
      id
      industry
      location
      notes
      company {
        id
        name
        logoURL
      }
      subscriber {
        id
        name
        logoURL
      }
      optionalAttributes
      requiredAttributes
      requiredExperience
      salaryMax
      salaryMin
      status
      title
      hiddenFromCandidates
      filled
      description
      compensation
      compensation_list
      recruiter_id
      recruiter {
        id
        availability
        email
        industries
        name
        phone
        profilePhoto
        title
        company {
          id
          name
        }
        position
        candidates_aggregate {
          aggregate {
            count
          }
        }
      }
      subscriber_id
      adminApproved
      created_at
      closed
    }
  }
`;

export default function Jobs({ path, location, admin }) {
  const tabIndexes = {
    '/jobs/open-jobs': 0,
    '/jobs/all-jobs': 1,
    '/jobs/completed-jobs': 2,
    '/jobs/search': 3,
  };

  // Pagination
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [total, setTotal] = useState(0);

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage * perPage);
  };

  const [userRole] = React.useContext(UserRoleContext);
  const { globalFilter, setGlobalFilter } = useContext(GlobalFilterContext);

  const isAdmin = userRole === 'admin';
  const isSubscriber = userRole === 'company';
  const isAgency = userRole === 'agency';

  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [activeTab, setActiveTab] = useState('');
  const [filter, setFilter] = useState({});
  const [direction, setDirection] = useState('right');
  const [setJobFilled] = useMutation(FILL_JOB);
  const [setJobClosed] = useMutation(CLOSE_JOB);
  const [sortType, setSortType] = useState({ type: 'createdAt', direction: 'desc' });
  const [loading, setLoading] = useState(false);
  const { apolloClient } = useContext(ApolloContext);
  const [currCompany, setCurrCompany] = useState('');

  function getOrderBy() {
    let _orderBy;

    if (sortType.type === 'createdAt') {
      _orderBy = {
        created_at: sortType.direction,
      };
    } else if (sortType.type === 'title') {
      _orderBy = {
        title: sortType.direction,
      };
    } else if (sortType.type === 'industry') {
      _orderBy = {
        industry: sortType.direction,
      };
    } else if (sortType.type === 'salaryMin') {
      _orderBy = {
        salaryMin: sortType.direction,
      };
    } else if (sortType.type === 'recruiter') {
      _orderBy = {
        recruiter: {
          name: sortType.direction,
        },
      };
    } else if (sortType.type === 'status') {
      _orderBy = {
        status: sortType.direction,
      };
    }

    return _orderBy;
  }

  let queryVariables;
  let orderBy = getOrderBy();

  if (isAdmin) {
    queryVariables = {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
      variables: {
        whereCondition: { adminApproved: { _eq: true } },
        orderBy,
        perPage: perPage,
        offset: page * perPage,
      },
    };
  } else if (isSubscriber) {
    queryVariables = {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
      variables: {
        whereCondition: { subscriber_id: { _eq: currCompany.id } },
        orderBy,
        perPage: perPage,
        offset: page * perPage,
      },
    };
  } else if (isAgency) {
    queryVariables = {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
      variables: {
        whereCondition: { recruiter: { company_id: { _eq: currCompany.id } } },
        orderBy,
        perPage: perPage,
        offset: page * perPage,
      },
    };
  } else {
    queryVariables = {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
      variables: {
        whereCondition: {
          recruiter_id: { _eq: firebase.auth().currentUser && firebase.auth().currentUser.uid },
          subscriber_id: { _is_null: true },
        },
        orderBy,
        perPage: perPage,
        offset: page * perPage,
      },
    };
  }

  function toRad(Value) {
    return (Value * Math.PI) / 180;
  }

  function calcDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // km
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    lat1 = toRad(lat1);
    lat2 = toRad(lat2);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d * 0.621371;
  }

  /*
  async function _getJobs({ variables }) {
    try {
      setLoading(true);
      setJobs([]);

      if (filter.location && filter.location.city) {
        variables.perPage = null;
        variables.offset = null;
      }

      const response = await apolloClient.query({
        query: ALL_JOBS_QUERY,
        variables,
        fetchPolicy: 'network-only',
      });

      if (filter.location && filter.location.city) {
        const filteredJobs = response.data.job.filter((job) => {
          const radius = filter.location.radius ? filter.location.radius : 0;
          const distance = calcDistance(filter.location.lat, filter.location.lng, job.location.lat, job.location.lng);

          if (distance > radius) {
            return false;
          }

          return true;
        });

        setJobs(_.slice(filteredJobs, offset, offset + perPage));
        setTotal(filteredJobs.length || 0);
        // setPageCount(Math.ceil(filteredJobs.length / perPage));
      } else {
        setJobs(response.data.job);
        setTotal(response.data.job_aggregate.aggregate.count || 0);
        // setPageCount(Math.ceil(response.data.job_aggregate.aggregate.count / perPage));
      }

      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  }
  */

  useEffect(() => {
    if (!isSubscriber && isEmpty(filter)) {
      // _getJobs({ variables: queryVariables.variables });
      getAllFilteredJobs();
    }
  }, []);

  const { data: companyResponse } = useQuery(GET_COMPANY, {
    variables: { admin_id: firebase.auth().currentUser && firebase.auth().currentUser.uid },
  });

  useEffect(() => {
    if (companyResponse) {
      setCurrCompany(companyResponse.company[0]);
    }
  }, [companyResponse]);

  function fillPosition(jobID, value) {
    setFilteredJobs(
      // jobs
      filteredJobs.map((job) => {
        if (job.id === jobID) {
          return { ...job, filled: value };
        } else {
          return job;
        }
      }),
    );

    setJobFilled({
      variables: {
        id: jobID,
        value,
      },
      refetchQueries: ['getJobs'],
    });
  }

  function closePosition(jobID, value) {
    // setJobs
    setFilteredJobs(
      // jobs
      filteredJobs.map((job) => {
        if (job.id === jobID) {
          return { ...job, closed: value };
        } else {
          return job;
        }
      }),
    );

    setJobClosed({
      variables: {
        id: jobID,
        value,
      },
      refetchQueries: ['getJobs'],
    });
  }

  function isEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
  /*
  async function filterJobs({ currCompany }) {
    let variables;

    orderBy = getOrderBy();

    if (isAdmin) {
      variables = {
        whereCondition: { adminApproved: { _eq: true } },
        orderBy,
        perPage: perPage,
        offset: page * perPage,
      };
    } else if (isSubscriber) {
      if (!currCompany) {
        return;
      }

      variables = {
        whereCondition: { subscriber_id: { _eq: currCompany.id } },
        orderBy,
        perPage: perPage,
        offset: page * perPage,
      };
    } else {
      variables = {
        whereCondition: {
          recruiter_id: { _eq: firebase.auth().currentUser && firebase.auth().currentUser.uid },
          subscriber_id: { _is_null: true },
        },
        orderBy,
        perPage: perPage,
        offset: page * perPage,
      };
    }

    if (filter.date) {
      if (filter.date.startDate && filter.date.endDate) {
        variables.whereCondition.created_at = {
          _gte: filter.date.startDate,
          _lte: filter.date.endDate,
        };
      } else if (filter.date.startDate) {
        variables.whereCondition.created_at = {
          _gte: filter.date.startDate,
        };
      } else if (filter.date.endDate) {
        variables.whereCondition.created_at = {
          _lte: filter.date.endDate,
        };
      }
    }

    if (filter.title) {
      variables.whereCondition.title = {
        _ilike: `%${filter.title}%`,
      };
    }

    if (filter.recruiter && filter.recruiter.length) {
      variables.whereCondition.recruiter_id = {
        _in: filter.recruiter.map((a) => a.id),
      };
    }

    if (filter.salary) {
      if (filter.salary.min) {
        variables.whereCondition.salaryMin = {
          _gte: parseInt(filter.salary.min),
        };
      }

      if (filter.salary.max) {
        variables.whereCondition.salaryMax = {
          _lte: parseInt(filter.salary.max),
        };
      }
    }

    if (filter.attributes && filter.attributes.length) {
      variables.whereCondition._or = [{ optionalAttributes: { _has_keys_any: filter.attributes } }, { requiredAttributes: { _has_keys_any: filter.attributes } }];
    }

    _getJobs({ variables });
  }
  */

  function filterJobsQueryVar() {
    let variables;

    orderBy = getOrderBy();

    if (isAdmin) {
      variables = {
        whereCondition: { adminApproved: { _eq: true } },
        orderBy,
        perPage: perPage,
        offset: page * perPage,
      };
    } else if (isSubscriber) {
      if (!currCompany) {
        return;
      }

      variables = {
        whereCondition: { subscriber_id: { _eq: currCompany.id }, filled: { _eq: false }, closed: { _eq: false } },
        orderBy,
        perPage: perPage,
        offset: page * perPage,
      };
    } else if (isAgency) {
      if (!currCompany) {
        return;
      }
      variables = {
        whereCondition: { recruiter: { company_id: { _eq: currCompany.id } }, filled: { _eq: false }, closed: { _eq: false } },
        orderBy,
        perPage: perPage,
        offset: page * perPage,
      };
    } else {
      variables = {
        whereCondition: {
          recruiter_id: { _eq: firebase.auth().currentUser && firebase.auth().currentUser.uid },
          subscriber_id: { _is_null: true },
        },
        orderBy,
        perPage: perPage,
        offset: page * perPage,
      };
    }

    if (filter.date) {
      if (filter.date.startDate && filter.date.endDate) {
        variables.whereCondition.created_at = {
          _gte: moment(new Date(filter.date.startDate)).startOf('day').toISOString(),
          _lte: moment(new Date(filter.date.endDate)).endOf('day').toISOString(),
        };
      } else if (filter.date.startDate) {
        variables.whereCondition.created_at = {
          _gte: moment(new Date(filter.date.startDate)).startOf('day').toISOString(),
        };
      } else if (filter.date.endDate) {
        variables.whereCondition.created_at = {
          _lte: moment(new Date(filter.date.endDate)).endOf('day').toISOString(),
        };
      }
    }

    if (filter.title) {
      variables.whereCondition.title = {
        _ilike: `%${filter.title}%`,
      };
    }

    if (filter.recruiter && filter.recruiter.length) {
      variables.whereCondition.recruiter_id = {
        _in: filter.recruiter.map((a) => a.id),
      };
    }

    if (filter.salary) {
      if (filter.salary.min) {
        variables.whereCondition.salaryMin = {
          _gte: parseInt(filter.salary.min),
        };
      }

      if (filter.salary.max) {
        variables.whereCondition.salaryMax = {
          _lte: parseInt(filter.salary.max),
        };
      }
    }

    if (filter.attributes && filter.attributes.length) {
      variables.whereCondition._or = [{ optionalAttributes: { _has_keys_any: filter.attributes } }, { requiredAttributes: { _has_keys_any: filter.attributes } }];
    }

    if (path === '/jobs/open-jobs') {
      variables.whereCondition.filled = {
        _eq: false
      };
      variables.whereCondition.closed = {
        _eq: false
      };
    }

    if (path === '/jobs/completed-jobs') {
      if (!variables.whereCondition._or) {
        variables.whereCondition._or = [
          { filled: { _eq: true } },
          { closed: { _eq: true } }
        ]
      } else {
        variables.whereCondition._or = null;
        variables.whereCondition._and = [
          {
            _or: [
              { filled: { _eq: true } },
              { closed: { _eq: true } }
            ]
          },
          {
            _or: [
              { optionalAttributes: { _has_keys_any: filter.attributes } },
              { requiredAttributes: { _has_keys_any: filter.attributes } }
            ]
          }
        ]
      }
    }
    return variables
  }

  const getAllFilteredJobs = async () => {
    setLoading(true);

    if (filterJobsQueryVar()) {
      const { whereCondition, orderBy, perPage, offset } = { ...filterJobsQueryVar() };

      const response = await apolloClient.query({
        query: ALL_FILTERED_JOBS,
        variables: {
          g_lat: filter?.location?.lat ? filter?.location?.lat : null,
          g_long: filter?.location?.lng ? filter.location?.lng : null,
          g_radius: filter?.location?.radius ? filter.location.radius : process.env.GATSBY_DEFAULT_SEARCH_RADIUS || 50,
          search_term: filter?.title ? filter.title : null,
          whereCondition,
          orderBy,
          perPage,
          offset,
        },
        fetchPolicy: 'network-only',
      });
      setFilteredJobs(response?.data?.filter_jobs)
      setTotal(response?.data?.filter_jobs_aggregate?.aggregate?.count || 0);
      setLoading(false)
    } else {
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
      }, 2000);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllFilteredJobs()
  }, [filter, sortType, page, perPage, location, path, currCompany])

  useEffect(() => {
    if (path === '/jobs') {
      navigate('/jobs/open-jobs');
    }
  }, []);

  useEffect(() => {
    if (path === '/jobs/open-jobs' && JSON.stringify(globalFilter['open-jobs-filter']) !== JSON.stringify(filter)) {
      setGlobalFilter({ ...globalFilter, 'open-jobs-filter': filter });
    } else if (path === '/jobs/all-jobs' && JSON.stringify(globalFilter['all-jobs-filter']) !== JSON.stringify(filter)) {
      setGlobalFilter({ ...globalFilter, 'all-jobs-filter': filter });
    } else if (path === '/jobs/completed-jobs' && JSON.stringify(globalFilter['completed-jobs-filter']) !== JSON.stringify(filter)) {
      setGlobalFilter({ ...globalFilter, 'completed-jobs-filter': filter });
    } else if (location.pathname === '/dashboard/jobs' && JSON.stringify(globalFilter['admin-jobs-filter']) !== JSON.stringify(filter)) {
      setGlobalFilter({ ...globalFilter, 'admin-jobs-filter': filter });
    }

    // filterJobs({ currCompany });
    setPage(0);
  }, [filter]);

  useEffect(() => {
    // filterJobs({ currCompany });
  }, [sortType]);

  useEffect(() => {
    // filterJobs({ currCompany });
  }, [page, perPage]);

  useEffect(() => {
    if (location && location.pathname.split('/').length > 2) {
      setActiveTab(location.pathname);

      if (location.state) {
        if (location.state.companyName) {
          setFilter({
            search: [location.state.companyName],
          });
        } else if (location.state.previousTab) {
          setDirection(tabIndexes[location.state.previousTab] > tabIndexes[location.pathname] ? 'left' : 'right');
        }
      }
    } else if (!admin) {
      navigate('/jobs/open-jobs');
    }
  }, [location]);

  useEffect(() => {
    if (path === '/jobs/open-jobs' && globalFilter['open-jobs-filter']) {
      setFilter(globalFilter['open-jobs-filter']);
    } else if (path === '/jobs/all-jobs' && globalFilter['all-jobs-filter']) {
      setFilter(globalFilter['all-jobs-filter']);
    } else if (path === '/jobs/completed-jobs' && globalFilter['completed-jobs-filter']) {
      setFilter(globalFilter['completed-jobs-filter']);
    }
  }, [path]);

  useEffect(() => {
    if (location && location.pathname === '/dashboard/jobs' && JSON.stringify(globalFilter['admin-jobs-filter'])) {
      setFilter(globalFilter['admin-jobs-filter']);
    }
  }, []);

  const renderJobTab = () => {
    switch (activeTab) {
      case '/jobs/open-jobs':
        return (
          <JobList
            activeTab={activeTab}
            filterState={[filter, setFilter]}
            sortState={[sortType, setSortType]}
            loading={loading}
            fillPosition={fillPosition}
            closePosition={closePosition}
            // jobs={jobs.filter((job) => !job.filled && !job.closed)}
            jobs={filteredJobs}
          />
        );
      case '/jobs/all-jobs':
        return (
          <JobList
            activeTab={activeTab}
            filterState={[filter, setFilter]}
            sortState={[sortType, setSortType]}
            fillPosition={fillPosition}
            closePosition={closePosition}
            loading={loading}
            // jobs={jobs}
            jobs={filteredJobs}
          />
        );
      case '/jobs/completed-jobs':
        return (
          <JobList
            activeTab={activeTab}
            filterState={[filter, setFilter]}
            sortState={[sortType, setSortType]}
            fillPosition={fillPosition}
            closePosition={closePosition}
            loading={loading}
            // jobs={filteredJobs.filter((job) => job.filled || job.closed)}
            jobs={filteredJobs}
          />
        );
      // hidden menu item
      case '/jobs/search':
        return <JobSearch nav={location} />;
      default:
        break;
    }
  };

  function handlePaginationChange(event) {
    setPage(event.page);
    setPerPage(event.perPage);
  }

  return (
    <>
      {activeTab && (
        <div className="jobs-js flex flex-col container h-full">
          {!admin && (
            <TabNav
              route={activeTab}
              routeIndex={activeTab === '/jobs/open-jobs' ? 0 : activeTab === '/jobs/all-jobs' ? 1 : activeTab === '/jobs/completed-jobs' ? 2 : 3}
              type="jobs"
            />
          )}
          {!admin ? (
            <ReactCSSTransitionGroup
              className={`dash-slider relative`}
              transitionEnterTimeout={300}
              transitionLeaveTimeout={300}
              transitionName={{
                enter: `enter-${direction}`,
                enterActive: `enter-${direction}-active`,
                leave: `leave-${direction}`,
                leaveActive: `leave-${direction}-active`,
                appear: `appear-${direction}`,
                appearActive: `appear-${direction}-active`,
              }}
            >
              <div key={activeTab} className="dash-tab">
                <div className="absolute flex flex-col w-full" style={{ minHeight: 'calc(100vh - 156px)' }}>
                  {renderJobTab()}
                  {!isAdmin && (
                    <div className="job-pagination jobs-js-not-admin">
                      <CherryPagination page={page} perPage={perPage} total={total} onChange={handlePaginationChange} />
                    </div>
                  )}
                </div>
              </div>
            </ReactCSSTransitionGroup>
          ) : !isSubscriber ? (
            <JobList
              activeTab={activeTab}
              isAdmin={true}
              sortState={[sortType, setSortType]}
              filterState={[filter, setFilter]}
              closePosition={closePosition}
              fillPosition={fillPosition}
              loading={loading}
              // jobs={jobs}
              jobs={filteredJobs}
            />
          ) : (
            <JobList
              activeTab={activeTab}
              isSubscriber={true}
              sortState={[sortType, setSortType]}
              filterState={[filter, setFilter]}
              closePosition={closePosition}
              fillPosition={fillPosition}
              loading={loading}
              jobs={filteredJobs}
            // jobs={jobs.filter((job) => !job.filled && !job.closed)}
            />
          )}
          {(admin || isSubscriber) && <div className="job-pagination jobs-js-ReactPaginate-container">
            <CherryPagination page={page} perPage={perPage} total={total} onChange={handlePaginationChange} />
          </div>}
        </div>
      )}
    </>
  );
}
