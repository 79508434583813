/* eslint-disable jsx-a11y/anchor-is-valid */
import { useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Link } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { SearchBox, TabTitle } from '../../components/common';
import UserRoleContext from '../../context/UserRole';
import CaretDownGray from '../../images/caret-down-gray.png';
import CaretDown from '../../images/caret-down.png';
import CherryPagination from '../../components/common/Pagination/Pagination';
import { DEFAULT_PER_PAGE } from '../../utils/constants';
import CompanyEntry from '../companies/company-entry';
import AgencyEntry from './agencies-entry';

const ALL_FILTERED_AGENCIES = gql`
  subscription getFiltered_Agencies($search_term: String, $perPage: Int, $offset: Int, $order_by: order_by) {
    company(
      where: { is_agency: { _eq: true },  _or: [{ name: { _ilike: $search_term } }, { email: { _ilike: $search_term } }]},
      limit: $perPage,
      offset: $offset,
      order_by: { name: $order_by },
      ) {
        id
        name
    		email
        websiteURL
        size
        logoURL
        adminName
    		is_disabled
    }
  }
`;

const ALL_AGENCIES_TOTAL = gql`
subscription getFiltered_Companies($search_term: String, $offset: Int) {
  company_aggregate(
    where: { is_agency: { _eq: true },  _or: [ { name: { _ilike: $search_term } }, { email: { _ilike: $search_term } }]},
    offset: $offset,
    ) {
      aggregate {
        count
      }
  }
}
`;

// component for sorting companies alphabetically
function AlphabeticalSort({ sortState }) {
  const [sortType, setSortType] = sortState;
  const [direction, setDirection] = useState('asc');

  function setType() {
    if (direction === 'desc') {
      setDirection('asc');
      setSortType({ type: 'alphabetical', direction: 'asc' });
    } else {
      setDirection('desc');
      setSortType({ type: 'alphabetical', direction: 'desc' });
    }
  }
  return (
    <div className="flex mr-md">
      <div
        onClick={setType}
        className={`text-sm ${sortType.type === 'alphabetical' ? 'bg-darkblue text-white' : 'text-darkgray border border-darkgray'
          }   flex flex-row items-center font-medium cursor-pointer px-sm rounded`}
      >
        A - Z
        <img
          alt=""
          src={sortType.type === 'alphabetical' ? CaretDown : CaretDownGray}
          style={{ height: 5, margin: 0, transform: `rotate(${direction === 'desc' ? '0' : '180'}deg)`, marginLeft: 10 }}
        />
      </div>
    </div>
  );
}

export default function Companies() {
  // Pagination
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [total, setTotal] = useState(0);
  const [searchInput, setSearchInput] = useState('');

  function handlePaginationChange(event) {
    setPage(event.page);
    setPerPage(event.perPage);
  }

  const [companiesData, setCompaniesData] = useState([]);

  const [userRole] = useContext(UserRoleContext);
  const isAdmin = userRole === 'admin';

  const [sortType, setSortType] = useState({ type: 'alphabetical', direction: 'asc' });

  const [modal, setModal] = useState({ data: null, open: false });

  const { data: companiesTotal } = useSubscription(ALL_AGENCIES_TOTAL, {
    variables: {
      search_term: `%${searchInput}%`,
      offset: page * perPage ? page * perPage : 0
    },
  });

  const { data: filter_companies, loading } = useSubscription(
    ALL_FILTERED_AGENCIES,
    // flag ?
    {
      variables: {
        search_term: `%${searchInput}%`,
        perPage: perPage ? perPage : null,
        offset: page * perPage ? page * perPage : 0,
        order_by: sortType.direction,
      },
    },
  );

  useEffect(() => {
    if (filter_companies) {
      setCompaniesData(filter_companies.company)
      setTotal(companiesTotal?.company_aggregate?.aggregate?.count || 0);
    }
  }, [filter_companies]);

  useEffect(() => {
    if (filter_companies) {
      setTotal(companiesTotal?.company_aggregate?.aggregate?.count || 0);
    }
  }, [companiesTotal])

  useEffect(() => {
    reset()
  }, [searchInput])

  function reset() {
    setPage(0);
    setPerPage(DEFAULT_PER_PAGE);
  }

  useEffect(() => {
    if (sortType && filter_companies) {
      setCompaniesData(filter_companies.filter_company)
    }
  }, [sortType]);

  return (
    <div className="flex flex-col h-full pb-lg">
      <div className="relative flex items-center" style={{ marginTop: 30, marginBottom: 30, zIndex: 200 }}>
        <TabTitle>{'Agencies'}</TabTitle>
        <div className="import_csv flex justify-between lg:mt-0 mt-sm">
          <Link className={`bg-green text-white font-medium rounded flex items-center justify-center`} style={{ fontSize: 14, height: 40, width: 160 }} to="/agencies/new">
            <span style={{ fontSize: 22, marginRight: 8 }}>+</span> Add Agency
          </Link>
        </div>
      </div>
      <SearchBox value={searchInput} placeholder="Search by name or email" update={setSearchInput} />
      <div className="flex mb-sm mt-5">
        <div className="text-sm mr-sm">Sort:</div>
        <AlphabeticalSort sortState={[sortType, setSortType]} />
      </div>
      <div className="bg-white rounded shadow flex-1 w-full" style={{ maxWidth: 1340, overflowX: 'auto', height: '700px' }}>
        {loading ? (
          <div style={{ marginLeft: 600, marginTop: 100 }}>
            <Loader type="TailSpin" color="#E31C25" height={50} width={50} />
          </div>
        ) : companiesData && companiesData.length > 0 ? (
          companiesData?.map((company, index) => <AgencyEntry key={index} company={company} />)
        ) : (
          <div className="w-full h-full flex items-center justify-center text-darkgray">No Agencies available</div>
        )}
      </div>
      <div className="candidate-pagination">
        <CherryPagination page={page} perPage={perPage} total={total} onChange={handlePaginationChange} />
      </div>
    </div>
  );
}
