import React from 'react'
import { TabTitle } from '../../../components/common';
import EditIcon from '../icons/edit-icon';
import DeleteIcon from '../icons/delete-icon';
const ModalContext = React.createContext();

function LinkedinTable({ linkedinData, handleDeleteLinkedinURL, handleEditSelectedLinkedinURL }) {
    const activityData = linkedinData || [];
    return (
        <div className="w-full flex-1 candidates">
            <div className={`flex-1 bg-white rounded shadow candidates-container candidate-blank-dash`} style={{ overflowX: 'auto' }}>
                <table style={{ width: '100%' }}>
                    <thead style={{ fontSize: 14, fontWeight: 'bold' }}>
                        <tr style={{ position: 'sticky', top: 0, background: 'white' }}>
                            <th style={{ textAlign: 'center', paddingLeft: '15px' }}>Sl.No</th>
                            <th style={{ textAlign: 'center' }}>Linkedin URL</th>
                            <th style={{ textAlign: 'center' }}>Actions</th>
                        </tr>
                    </thead>

                    <tbody>
                        {(activityData || []).map((activity, i) => {
                            return (
                                <tr key={activity?.id}>
                                    <td>
                                        <div className="font-medium text-darkgray flex items-start" style={{ fontSize: 14, justifyContent: 'center', paddingLeft: '15px' }}>
                                            {i + 1}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="font-medium text-darkgray flex items-start" style={{ fontSize: 14, justifyContent: 'center' }}>
                                            {activity?.url}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="font-medium text-darkgray flex items-start" style={{ fontSize: 14, justifyContent: 'center' }}>
                                            <td>
                                                <div
                                                    className="font-medium flex justify-items-center gap-2"
                                                    style={{ fontSize: 10, justifyContent: "center" }}
                                                >
                                                    <button
                                                        onClick={() => handleEditSelectedLinkedinURL(activity?.id)}
                                                    >
                                                        <EditIcon />{" "}
                                                    </button>
                                                    <div>|</div>
                                                    <button onClick={() => handleDeleteLinkedinURL(activity?.id)}>
                                                        <DeleteIcon />
                                                    </button>
                                                </div>
                                            </td>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                {/* {loading && (
                        <div className={`flex justify-center items-center flex-1 candidate-blank${isDashboard ? '-dash' : ''}`} style={{ width: '100%' }}>
                            <div className="bg-lightgray text-darkgray py-sm px-md" style={{ borderRadius: 43 }}>
                                Loading
                            </div>
                        </div>
                    )} */}
            </div>
        </div>
    )
}

export default LinkedinTable