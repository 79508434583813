import React from 'react'
import { TabTitle } from '../../../components/common';
import EditIcon from '../icons/edit-icon';
import DeleteIcon from '../icons/delete-icon';
const ModalContext = React.createContext();

function CsvTable({ sheetData }) {
    const activityData = sheetData || [];
    return (
        <div className="w-full flex-1 candidates">
            <div className={`flex-1 bg-white rounded shadow candidates-container candidate-blank-dash`} style={{ overflowX: 'auto' }}>
                <table style={{ minWidth: '100%' }}>
                    <thead style={{ fontSize: 14, fontWeight: "bold" }}>
                        <tr style={{ position: "sticky", top: 0, background: "white" }}>
                            <th style={{ textAlign: "center", paddingLeft: "15px" }}>
                                SL.No
                            </th>
                            <th style={{ textAlign: "center" }}>First Name</th>
                            <th style={{ textAlign: "center" }}>Last Name</th>
                            <th style={{ paddingLeft: "center", textAlign: "center" }}>
                                Company Domain
                            </th>
                            <th style={{ paddingLeft: "center", textAlign: "center" }}>
                                Emails
                            </th>
                            <th style={{ paddingLeft: "center", textAlign: "center" }}>
                                Contact Numbers
                            </th>
                            <th style={{ paddingLeft: "center", textAlign: "center" }}>
                                Tags
                            </th>
                            <th style={{ paddingLeft: "center", textAlign: "center" }}>
                                Linkedin URL
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        {(activityData || []).map((activity, i) => {
                            return (
                                <tr key={Math.random()}>
                                    <td>
                                        <div className="font-medium text-darkgray flex items-start" style={{ fontSize: 14, justifyContent: 'center', paddingLeft: '15px' }}>
                                            {i + 1}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="font-medium text-darkgray flex items-start" style={{ fontSize: 14, width: '120px', justifyContent: 'center' }}>
                                            {activity?.first_name}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="font-medium text-darkgray flex items-start" style={{ fontSize: 14, width: '120px', justifyContent: 'center' }}>
                                            {activity?.last_name}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="font-medium text-darkgray flex items-start" style={{ fontSize: 14, justifyContent: 'center' }}>
                                            {activity?.company_domain}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="font-medium text-darkgray flex items-start" style={{ fontSize: 14, justifyContent: 'center' }}>
                                            {activity?.emails}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="font-medium text-darkgray flex text-center" style={{ fontSize: 14, justifyContent: 'center', width: '150px' }}>
                                            {activity?.contact_numbers}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="font-medium text-darkgray flex items-start" style={{ fontSize: 14, justifyContent: 'center' }}>
                                            {activity?.tags}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="font-medium text-darkgray flex items-start" style={{ fontSize: 14, justifyContent: 'center' }}>
                                            {activity?.linkedin_url}
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default CsvTable