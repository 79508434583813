import React, { useContext, useEffect, useState } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import CompanyTabNav from '../../components/layout/CompanyTabNav';
import { HasJobsOrCandidatesContext } from '../../components/layout/layout';
import TabNav from '../../components/layout/TabNav';
import { UserRoleContext } from '../../context';
import { Attributes } from '../attributes';
import { Candidates } from '../candidates';
import CandidateQueue from '../candidates/candidate-queue';
import { FindCandidates, RequestsSent } from '../candidates/company-candidates';
import InterestedCandidates from '../candidates/company-candidates/interested-candidates';
import Companies from '../companies/companies';
import { CompletedJobs } from '../jobs';
import JobQueue from '../jobs/job-queue';
import Jobs from '../jobs/jobs';
import Projects from '../projects/projects';
import Recruiters from '../recruiters/recruiters';
import CandidateIntroductionReport from '../reports/candidate-introduction-report';
import PlacementReport from '../reports/placement-report';
import CreditTransactionHistory from '../reports/credit-transaction-history';
import RecruiterActivity from '../reports/recruiter-activity';
import RecruiterCreditActivity from '../reports/recruiter-credit-activity';
import RecruiterJobOutput from '../reports/recruiter-job-output';
import Reports from '../reports/reports';
import SubscriberActivity from '../reports/subscriber-activity';
import SubscriberCreditReport from '../reports/subscriber-credit-report';
import Subscribers from '../subscribers/subscribers';
import DashboardCompany from './dashboard-company';
import DashboardWelcome from './dashboard-welcome';
import './dashboard.css';
import Interviews from './interviews';
import { Agencies } from '../agencies';
import AgencyCandidates from '../candidates/company-candidates/agency-candidates';
import firebase from 'firebase/app';
import TransactionDashboard from '../settings/transaction-dashboard.js/transaction-dashboard';
import OnboardingModal from '../../components/common/OnboardingModal';
import { TalentFinder } from '../talent-finder';
import LinkedInUrl from '../talent-finder/LinkedInUrl';
import CsvSheet from '../talent-finder/CsvSheet';

const TabWrapper = ({ children }) => {
  return children;
};

export default function Dashboard({ navigate, location }) {
  // const recruiterTabIndexes = {
  //   "candidates-in-process": 0,
  //   interviews: 1,
  //   candidates: 2,
  //   projects: 3,
  //   queue: 4,
  //   companies: 5,
  //   reports: 6
  // };
  const recruiterTabIndexes = {
    candidates: 0,
    interviews: 1,
    projects: 2,
    queue: 3,
    companies: 4,
    reports: 5,
    'placement-report': 5,
    'talent-finder': 6,
    'talent-finder-linkedin': 6,
    'talent-finder-csv': 6,
  };
  const adminTabIndexes = {
    candidates: 0,
    jobs: 1,
    'job-queue': 2,
    recruiters: 3,
    'agencies': 4,
    subscribers: 5,
    companies: 6,
    reports: 7,
    attributes: 8,
    'subscriber-credit-report': 7,
    'recruiter-activity': 7,
    'subscriber-activity': 7,
    'candidate-introduction-report': 7,
    'credit-transaction-history': 7,
    'placement-report': 7,
  };

  const agencyTabIndexes = {
    candidates: 0,
    jobs: 1,
    recruiters: 2,
    companies: 3,
    reports: 4,
    'placement-report': 4,
  };

  let [userRole, , recruiterAgencyId, recruiterAgency, , recruiterPermission, isOnBoarded, agencyDetails] = useContext(UserRoleContext);
  const [hasJobsOrCandidates] = useContext(HasJobsOrCandidatesContext);
  const isCompany = userRole === 'company';
  const isRecruiter = userRole === 'recruiter';
  const isAdmin = userRole === 'admin';
  const isAgency = userRole === 'agency';
  const [activeTab, setActiveTab] = useState();
  const [direction, setDirection] = useState('right');
  const [isAgencyRecruiter, setIsAgencyRecruiter] = useState(false);
  const adminID = firebase.auth().currentUser ? firebase.auth().currentUser.uid : 'not-logged-in'
  const [openModal, setOpenModal] = useState();

  useEffect(() => {
    setOpenModal(!isOnBoarded ? true : false)
  }, [isOnBoarded]);

  useEffect(() => {
    if (recruiterAgency) {
      setIsAgencyRecruiter(recruiterAgency)
    }
  }, [recruiterAgency, recruiterAgencyId, recruiterPermission])

  const renderDashboardPage = (active, location) => {
    switch (active) {
      case 'candidates-in-process':
        return (
          <TabWrapper key="candidates">
            <Candidates adminID={adminID} recruiterPermission={recruiterPermission} recruiterAgency={recruiterAgency} recruiterAgencyId={recruiterAgencyId} inProcess={true} isDashboard={true} location={location} />
          </TabWrapper>
        );

      case 'candidates':
        return (
          <TabWrapper key="candidates">
            <Candidates adminID={adminID} recruiterPermission={recruiterPermission} recruiterAgency={recruiterAgency} recruiterAgencyId={recruiterAgencyId} isDashboard={true} location={location} />
          </TabWrapper>
        );

      case 'projects':
        return (
          <TabWrapper key="projects">
            <Projects />
          </TabWrapper>
        );
      case 'interviews':
        return (
          <TabWrapper key="interviews">
            <Interviews />
          </TabWrapper>
        );
      case 'queue':
        return (
          <TabWrapper key="queue">
            <CandidateQueue />
          </TabWrapper>
        );
      case 'companies':
        return (
          <TabWrapper key="queue">
            <Companies />
          </TabWrapper>
        );
      case 'reports':
        return (
          <TabWrapper key="reports">
            <Reports />
          </TabWrapper>
        );
      case 'placement-report':
        return (
          <TabWrapper key="placeholder-report">
            <PlacementReport isRecruiter={isRecruiter} />
          </TabWrapper>
        );
      case 'talent-finder':
        return (
          <TabWrapper key="talent-finder">
            <TalentFinder />
          </TabWrapper>
        );
      case 'talent-finder-linkedin':
        return (
          <TabWrapper key="talent-finder-linkedin">
            <LinkedInUrl />
          </TabWrapper>
        );
      case 'talent-finder-csv':
        return (
          <TabWrapper key="talent-finder-csv">
            <CsvSheet />
          </TabWrapper>
        );
      case 'recruiter-credit-activity':
        return (
          <TabWrapper key="recruiter-credit-activity">
            <RecruiterCreditActivity isRecruiter={isRecruiter} />
          </TabWrapper>
        );
      case 'recruiter-job-output':
        return (
          <TabWrapper key="recruiter-job-output">
            <RecruiterJobOutput isRecruiter={isRecruiter} />
          </TabWrapper>
        );
      case 'index':
        return isAgencyRecruiter ? <TabWrapper key="candidates">
          <Candidates adminID={adminID} recruiterPermission={recruiterPermission} recruiterAgency={recruiterAgency} recruiterAgencyId={recruiterAgencyId} isDashboard={true} location={location} />
        </TabWrapper> :
          <TabWrapper key="welcome">{hasJobsOrCandidates ? <Candidates adminID={adminID} recruiterPermission={recruiterPermission} recruiterAgency={recruiterAgency} recruiterAgencyId={recruiterAgencyId} inProcess={true} isDashboard={true} location={location} /> : <DashboardWelcome />}</TabWrapper>;
      default:
        return (
          <TabWrapper key="welcome">
            <DashboardWelcome />
          </TabWrapper>
        );
    }
  };

  const renderCompanyDashboardPage = (active) => {
    switch (active) {
      case 'find-candidate':
        return <FindCandidates />;
      case 'dashboard':
        return <DashboardCompany />;
      case 'jobs':
        return (
          <TabWrapper key="jobs">
            <Jobs admin={true} location={{ pathname: '/dashboard/jobs' }} />
          </TabWrapper>
        );
      case 'completed-jobs':
        return (
          <TabWrapper key="completed-jobs">
            <CompletedJobs admin={true} location={{ pathname: '/dashboard/completed-jobs' }} />
          </TabWrapper>
        );
      case 'requests-sent':
        return <RequestsSent />;
      case 'interested-candidates':
        return <InterestedCandidates />;
      case 'reports':
        return (
          <TabWrapper key="reports">
            <Reports />
          </TabWrapper>
        );
      case 'placement-report':
        return (
          <TabWrapper key="placeholder-report">
            <PlacementReport isCompany={isCompany} />
          </TabWrapper>
        );
      case 'transaction-history':
        return (
          <TabWrapper key="placeholder-report">
            <TransactionDashboard adminID={adminID} />
          </TabWrapper>
        );
      default:
        return;
    }
  };

  const renderAgencyPage = (active) => {
    switch (active) {
      case 'candidates':
        return (
          <TabWrapper key="candidates">
            <AgencyCandidates ROLE='Agency' />
          </TabWrapper>
        );
      case 'jobs':
        return (
          <TabWrapper key="jobs">
            <Jobs admin={true} location={{ pathname: '/dashboard/jobs' }} />
          </TabWrapper>
        );
      case 'recruiters':
        return (
          <TabWrapper key="recruiters">
            <Recruiters />
          </TabWrapper>
        );
      case 'companies':
        return (
          <TabWrapper key="companies">
            <Companies />
          </TabWrapper>
        );
      case 'reports':
        return (
          <TabWrapper key="reports">
            <Reports />
          </TabWrapper>
        );
      case 'placement-report':
        return (
          <TabWrapper key="placeholder-report">
            <PlacementReport isAgency={isAgency} />
          </TabWrapper>
        );
      default:
        return;
    }
  };

  const renderAdminPage = (active) => {
    switch (active) {
      case 'candidates':
        return (
          <TabWrapper key="candidates">
            <Candidates isAdmin={true} location={location} />
          </TabWrapper>
        );
      case 'jobs':
        return (
          <TabWrapper key="jobs">
            <Jobs admin={true} location={{ pathname: '/dashboard/jobs' }} />
          </TabWrapper>
        );
      case 'job-queue':
        return (
          <TabWrapper key="job-queue">
            <JobQueue admin={true} location={{ pathname: '/dashboard/job-queue' }} />
          </TabWrapper>
        );
      case 'recruiters':
        return (
          <TabWrapper key="recruiters">
            <Recruiters />
          </TabWrapper>
        );
      case 'agencies':
        return (
          <TabWrapper key="agencies">
            <Agencies />
          </TabWrapper>
        );
      case 'subscribers':
        return (
          <TabWrapper key="subscribers">
            <Subscribers />
          </TabWrapper>
        );
      case 'companies':
        return (
          <TabWrapper key="companies">
            <Companies />
          </TabWrapper>
        );
      case 'reports':
        return (
          <TabWrapper key="reports">
            <Reports />
          </TabWrapper>
        );
      case 'attributes':
        return (
          <TabWrapper key="queue">
            <Attributes />
          </TabWrapper>
        );
      case 'recruiter-activity':
        return (
          <TabWrapper key="recruiter-activity">
            <RecruiterActivity isAdmin={isAdmin} />
          </TabWrapper>
        );
      case 'subscriber-activity':
        return (
          <TabWrapper key="subscriber-activity">
            <SubscriberActivity isAdmin={isAdmin} />
          </TabWrapper>
        );
      case 'subscriber-credit-report':
        return (
          <TabWrapper key="subscriber-credit-report">
            <SubscriberCreditReport isAdmin={isAdmin} />
          </TabWrapper>
        );
      case 'candidate-introduction-report':
        return (
          <TabWrapper key="candidate-introduction-report">
            <CandidateIntroductionReport isAdmin={isAdmin} />
          </TabWrapper>
        );
      case 'placement-report':
        return (
          <TabWrapper key="placeholder-report">
            <PlacementReport isAdmin={isAdmin} />
          </TabWrapper>
        );
      case 'agencies':
        return (
          <TabWrapper key="agencies-activity">
            <Agencies isAdmin={isAdmin} />
          </TabWrapper>
        );
      case 'credit-transaction-history':
        return (
          <TabWrapper key="credit-transaction-history">
            <CreditTransactionHistory />
          </TabWrapper>
        );

      default:
        return;
    }
  };

  useEffect(() => {
    if (location.pathname.split('/').length > 2 && location.pathname.split('/')[2] !== '') {
      const route = location.pathname.split('/')[2];

      setActiveTab(route);
      if (location.state && location.state.previousTab) {
        setDirection(tabIndexes[location.state.previousTab] > tabIndexes[route] ? 'left' : 'right');
      }
    } else {
      // navigate(
      //   `/dashboard/${isAdmin ? "candidates" : isCompany ? "find-candidate" : (!hasJobsOrCandidates ? "index" : "candidates-in-process")}`
      // );
      navigate(`/dashboard/${isAdmin ? 'candidates' : isCompany ? 'find-candidate' : !hasJobsOrCandidates ? 'index' : 'candidates'}`);
    }
  }, [location]);

  const tabIndexes = isAgency ? agencyTabIndexes : isAdmin ? adminTabIndexes : recruiterTabIndexes;

  return (
    // !hasJobsOrCandidates ?
    //   <DashboardWelcome /> :
    <div className="flex-1 flex justify-center w-full h-full relative">
      {isAgency ? <OnboardingModal isOnBoarded={isOnBoarded} agencyDetails={agencyDetails} OnboardingModal open={openModal} showCancel={true} onChange={(e) => setOpenModal(e)}>
      </OnboardingModal> : null}
      <div className="dashboard-js container flex flex-col">
        {(isRecruiter || isAdmin || isAgency) && activeTab && (
          <TabNav isAdmin={isAdmin} isAgency={isAgency} type={'dashboard'} route={activeTab} routeIndex={tabIndexes[activeTab]} hasJobsOrCandidates={activeTab} />
        )}
        {(isRecruiter || isAdmin || isAgency) && activeTab && (
          <ReactCSSTransitionGroup
            className={`dash-slider relative flex flex-col flex-1`}
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}
            transitionName={{
              enter: `enter-${direction}`,
              enterActive: `enter-${direction}-active`,
              leave: `leave-${direction}`,
              leaveActive: `leave-${direction}-active`,
              appear: `appear-${direction}`,
              appearActive: `appear-${direction}-active`,
            }}
          >
            <div key={activeTab} className="dash-tab flex-1 ">
              {isRecruiter ? renderDashboardPage(activeTab, location) : isAgency ? renderAgencyPage(activeTab, location) : renderAdminPage(activeTab, location)}
            </div>
          </ReactCSSTransitionGroup>
        )}
        {isCompany && <CompanyTabNav activeTab={activeTab} type="dashboard" />}
        {isCompany && renderCompanyDashboardPage(activeTab)}
      </div>
    </div>
  );
}
