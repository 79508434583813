import React, { useEffect, useState } from 'react';
import SelectDropdown from './SelectDropdown';
import { companySizeScale } from '../../utils/helpers';
import FieldTitle from './FieldTitle';

export default function CompanySize({ update, error, value }) {
  const [size, setSize] = useState(value);

  useEffect(() => {
    update(size);
  }, [size]);

  const company_sizes = companySizeScale;

  return (
    <>
      <FieldTitle title="Number of Employees" />
      <SelectDropdown value={size} error={error} update={setSize} list={company_sizes} placeholder={'Number of Employees'} dontClear={true} />
    </>
  );
}
