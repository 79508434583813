import React from 'react'
import { TabTitle } from '../../components/common'
import { Link } from '@reach/router'
import './style.css'
import LinkedInUrl from './LinkedInUrl'
function TalentFinder() {
    return (
        <div className="candidates-js w-full flex-1 candidates">
            <div className="relative flex items-center" style={{ marginTop: 30, marginBottom: 30, zIndex: 200 }}>
                <TabTitle>Talent Finder</TabTitle>
                <div className="import_csv flex justify-between lg:mt-0 mt-sm">
                    <Link to='/dashboard/talent-finder-linkedin' className={`bg-red text-white font-medium rounded flex items-center justify-center talent-finder-linkedin-btn`}>Add Linkedin Url + </Link>
                    <Link to='/dashboard/talent-finder-csv' className={`bg-red text-white font-medium rounded flex items-center justify-center talent-finder-linkedin-btn`}>Add Csv Sheet +</Link>
                </div>
            </div>

            <div>
                {/* <LinkedInUrl /> */}
            </div>
        </div>

    )
}

export default TalentFinder