// import DisabledProfileIcon from '../../../images/profile/profile.jpg';
import DisabledProfileIcon from '../../../images/profile/profile-lock-icon.jpg';
import React from 'react';
import './profile-image.css'

const ProfileImage = (props) => {

  if (props?.isDisabled) {
    return <img src={DisabledProfileIcon} width={props?.width || 80} height={props?.height || 80} alt={'Account Disabled'} className={'disabled-profile-image'} />;
  }

  return (
    <div
      className={props.className || ''}
      style={{
        margin: 0,
        height: props?.height || 80,
        width: props?.width || 80,
        borderRadius: 40,
        marginRight: 15,
        backgroundPosition: 'center',
        backgroundImage: `url('${!props?.approved ? DisabledProfileIcon : props.src}')`,
        backgroundSize: 'cover',
        // filter: !props?.approved && 'blur(6px)',
      }}
    />
  )
}

export default ProfileImage;