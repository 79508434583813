import React, { useState } from 'react'

function TalentFinderCheckboxes({ setConfigData }) {
    const checkboxes = [
        {
            id: 'sdgsdg454654',
            name: 'include Email',
            type: 'personal_email',
            value: false,
        },
        {
            id: 'sdgsdg454655',
            name: 'include Phone',
            type: 'contact_number',
            value: false,
        },
    ]
    const [checkboxData, setCheckBoxData] = useState(checkboxes);
    const handleCheckbox = (e, id) => {
        const value = e.target.value;
        const newCheckboxData = checkboxData?.map(elem => {
            if (elem?.id === id) {
                return { ...elem, value: value == 'false' ? true : false }
            }
            return elem;
        })
        setCheckBoxData(newCheckboxData);
        let obj = {};
        for (const elem of newCheckboxData) {
            obj[elem.type] = elem.value;
        }
        setConfigData(obj);
    };

    return (
        <div className='flex gap-2'>
            {
                checkboxData?.map(elem => (
                    <div key={elem?.id} className="flex gap-1 items-center">
                        <input onChange={(e) => handleCheckbox(e, elem?.id)} id={elem?.id} type="checkbox" value={elem?.value} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                        <label for={elem?.id} className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">{elem?.name}</label>
                    </div>
                ))
            }
        </div>
    )
}

export default TalentFinderCheckboxes