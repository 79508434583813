import moment from 'moment';

export default function getExperienceFromEmployment({ employment }) {
  let totalExperienceInMonths = 0;

  if (employment) {
    for (const emp of employment) {
      const start = moment()
        .set({ year: emp.year, month: (emp.month || 1) - 1 })
        .startOf('month');

      let end;

      if (emp.end_year && emp.end_month) {
        end = moment()
          .set({ year: emp.end_year, month: (emp.end_month || 1) - 1 })
          .endOf('month');
      } else {
        end = moment();
      }

      const difference = end.diff(start, 'months');

      totalExperienceInMonths += difference;
    }
  }
  return parseInt(totalExperienceInMonths / 12);
}

export function getExperienceFromLoxoEmployment({ employment }) {
  let totalExperienceInMonths = 0;

  if (employment) {
    for (const emp of employment) {
      const start = moment()
        .set({ year: emp.year, month: (emp.month || 1) - 1 })
        .startOf('month');

      let end;

      if (emp.end_year && emp.end_month) {
        end = moment()
          .set({ year: emp.end_year, month: (emp.end_month || 1) - 1 })
          .endOf('month');
      } else {
        end = moment();
      }

      const difference = end.diff(start, 'months');

      totalExperienceInMonths += difference;
    }
  }
  return parseInt(totalExperienceInMonths / 12);
}

