import { ApolloContext } from '../../context/Apollo';
import { gql } from 'apollo-boost';
import { get } from 'lodash';
import moment from 'moment';
import React, { useContext } from 'react';
import { formatSalary } from '../../utils/formatter';

export default function FilteredCandidates({ dashboardRef }) {
  const { apolloClient } = useContext(ApolloContext);

  const isJsonParsable = (string) => {
    try {
      JSON.parse(string);
    } catch (e) {
      return false;
    }
    return true;
  };

  function exportCSVFile(items, fileTitle) {
    // Convert Object to JSON
    const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
    const header = Object.keys(items[0])
    const csv = [
      header.join(','), // header row first
      ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
    ].join('\r\n')

    let exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      let link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
  // model: 'Phone Model'.replace(/,/g, ''), // remove commas to avoid errors

  async function downloadCSV(event) {
    event.preventDefault();
    const refs = dashboardRef?.current?.getQuery();
    if (refs?.query && refs?.variables) {
      const { query, variables } = refs;
      const response = await apolloClient.query({
        query: gql`${query}`,
        variables,
        fetchPolicy: 'network-only',
      });
      if (response?.data?.filter_candidates?.length) {
        const candidates = response?.data?.filter_candidates || [];
        const formatted_items = candidates?.map((candidate) => {
          const currentEmployment = candidate.employment && candidate.employment.filter(({ tenure }) => tenure.current);
          const previousEmployment = candidate.employment && candidate.employment.filter(({ tenure }) => !tenure.current);
          candidate.employment = currentEmployment;
          candidate.currEmployment =
            currentEmployment && currentEmployment[0]
              ? currentEmployment[0].title +
              ',' +
              currentEmployment[0].companyName +
              ',' +
              currentEmployment[0].companyLocation +
              ',' +
              moment(currentEmployment[0].tenure.startDate).format('MMM YYYY') +
              '-' +
              'Present'
              : '';

          candidate.prevEmployment = previousEmployment
            ? previousEmployment
              .map((emp) => {
                return (
                  emp.title +
                  ',' +
                  emp.companyName +
                  ',' +
                  emp.companyLocation +
                  ',' +
                  moment(emp.tenure.startDate).format('MMM YYYY') +
                  '-' +
                  moment(emp.tenure.endDate).format('MMM YYYY')
                );
              })
              .join(';')
            : '';

          candidate.interestedCities = isJsonParsable(candidate.interestedCity) && candidate.interestedCity && candidate.interestedCity.map((int) => int.city).join(';');
          return candidate;
        }).map((candidate) => {
          return {
            Name: candidate.name,
            Email: candidate.email,
            Phone: candidate.phone && candidate.phone.replace(/[- )(]/g, ''),
            'Linkedin URL': candidate.linkedin,
            'Referral Name': candidate.referralName,
            Location: candidate.location && candidate.location.city,
            Experience: candidate.experience,
            'Salary Min ($)': formatSalary({ salary: candidate.salaryMin }),
            'Facebook URL': candidate.facebook,
            'Instagram Handle': candidate.instagram,
            'Twitter Handle': candidate.twitter,
            'Short Bio': candidate.bio,
            'Current Employment': candidate.currEmployment,
            'Prev Employment': candidate.prevEmployment,
            'Current Company': candidate.employment && candidate.employment[0] && candidate.employment[0].title ? candidate.employment[0].companyName : '',
            'Current Job Title': candidate.employment && candidate.employment[0] && candidate.employment[0].title ? candidate.employment[0].title : '',
            'Job Preferences': candidate.preferredSkills && candidate.preferredSkills.map((attribute) => attribute),
            'Candidate Attributes': candidate.attributes && candidate.attributes.map((attribute) => attribute),
            'Remote (Y/N)': candidate.locationPreferences && candidate.locationPreferences.remote ? 'Y' : 'N',
            'Willing to Relocate (Y/N)': candidate.locationPreferences && candidate.locationPreferences.willingToRelocate ? 'Y' : 'N',
            'Interested Cities': candidate.interestedCities ? candidate.interestedCities : '',
            'Candidate Profile ID': get(candidate, 'id', '-'),
            'Candidate Invite Code': get(candidate, 'invite_code', '-'),
            'App Downloaded (Y/N)': get(candidate, 'is_login', '0') === '1' && (get(candidate, 'device_token', '') || '').length > 0 ? 'Y' : 'N',
          };
        });
        exportCSVFile(formatted_items, 'candidates'); // call the exportCSVFile() function to process the JSON and trigger the download
      }
    }
  }

  return (
    <>
      <button
        className={`bg-red text-white font-medium rounded flex items-center justify-center`}
        style={{ fontSize: 14, height: 40, width: 170, marginRight: 10 }}
        onClick={downloadCSV}
      >
        <span style={{ fontSize: 22, marginRight: 8 }}></span> Export Candidates
      </button>
    </>
  );
}
