import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import EditIcon from '../../../images/edit.svg';
import { Arrow } from '../../../components/common';
import AdminActions from '../../../components/admin/actions/admin-actions';
import ProfilePicture from './profilepicture';
import { CandidateEntry } from '../../jobs/job-details';
import { gql } from 'apollo-boost';
import { useMutation, useQuery, useSubscription } from '@apollo/react-hooks';
import DeleteUsersButton from '../../../components/common/DeleteUsersButton';
import { USER_ROLES } from '../../../utils/constants';
import { UserRoleContext } from '../../../context';
import { useContext } from 'react';
import firebase from 'firebase/app';
import { toast } from 'material-react-toastify';
import { useSnackbar } from 'react-simple-snackbar';

const ALL_AGENCY_RECRUITERS = gql`
  subscription getAllAgencyRecruiters(
    $adminID: String
  ) {
    recruiter(
      where: {company: {adminID: {_eq: $adminID}}}
    ) {
      availability
      email
      id
      industries
      permissions
    }
  }
`;


const RECRUITER_CANDIDATES = gql`
  query recruiterCandidates($id: String) {
    candidate(where: { recruiter_id: { _eq: $id } }) {
      id
      name
      employment
      profilePictureURL
      phone
      email
      location
      is_login
      device_token
      invite_code
    }
  }
`;

const UPDATE_RECRUITER_PERMISSIONS = gql`
  mutation updateRecruiterPermissions($recruiter_id: String, $permissions: jsonb) {
    update_recruiter(where: { id: { _eq: $recruiter_id } }, _set: { permissions: $permissions }) {
      affected_rows
      returning {
        permissions
      }
    }
  }
`;

export default function RecruiterDetails({ location, refetch = () => { } }) {
  const [recruiter, setRecruiter] = useState();
  const [permission, setPermission] = useState(false);
  const [userRole] = useContext(UserRoleContext);
  const adminID = firebase?.auth().currentUser ? firebase.auth().currentUser.uid : 'not-logged-in'
  const [openSnackbar] = useSnackbar();

  const { data: candidates } = useQuery(RECRUITER_CANDIDATES, {
    variables: { id: location?.state?.recruiter?.id },
  });

  const [updateRecruiterPermissions, { data }] = useMutation(UPDATE_RECRUITER_PERMISSIONS);
  const recruiterFields = ['email', 'phone', 'title'];

  useEffect(() => {
    if (location.state.recruiter) {
      const { availability, industries, permissions } = location.state.recruiter;
      setPermission(permissions?.allow_candidate_export)
      setRecruiter({
        ...location.state.recruiter,
        availability: typeof availability === 'string' ? JSON.parse(availability) : availability,
        industries: typeof industries === 'string' ? JSON.parse(industries) : industries,
      });
    }
  }, []);

  const handleCheckboxClick = () => {
    if (!recruiter?.id) return
    updateRecruiterPermissions({
      variables: {
        recruiter_id: recruiter?.id,
        permissions: { "allow_candidate_export": !permission },
      },
    }).then(per => {
      openSnackbar(`Recruiter candidates export ${!permission ? 'enabled' : 'disabled'}`);
      if (refetch) {
        refetch();
      }
      setPermission(!permission)
    }).catch(err => openSnackbar('Failed to update recruiter permission'));
  }

  return (
    <div className="w-full h-full pb-lg">
      <div className="flex pb-sm pt-md">
        <div
          className="cursor-pointer text-darkgray flex items-center capitalize hover:underline"
          style={{ fontSize: 12 }}
          onClick={() => navigate(location.state.from ? location.state.from : '/dashboard/recruiters')}
        >
          <Arrow color="darkgray" style={{ height: 15, marginRight: 10 }} />
          {location.state.from ? (location.state.from === '/dashboard/jobs' ? 'Jobs' : 'Candidates') : 'Recruiters'}
        </div>
      </div>
      <div className="flex flex-col lg:flex-row">
        <div className="flex flex-col w-full" style={{ maxWidth: 530 }}>
          <div className="flex py-sm mb-sm items-center flex-start">
            <div className="text-darkblue font-medium" style={{ fontSize: 21 }}>
              Recruiter Details
            </div>
            <div className="flex mr-md ml-5">
              <img
                alt=""
                onClick={() => navigate(`/recruiters/${recruiter && recruiter.id}/edit`, { state: { recruiter } })}
                className="cursor-pointer"
                src={EditIcon}
                style={{ width: 40, marginBottom: 0 }}
              />
              {!(userRole === 'agency') && < DeleteUsersButton selectedCandidates={[location.state.recruiter.id]} type="recruiter" componentType="trash-icon" />}
            </div>
          </div>
          {/* Admin Actions */}
          <div className='flex justify-between'>
            <AdminActions userId={recruiter?.id} for={USER_ROLES.RECRUITER} />
            <div className='flex justify-start items-center cursor-pointer mb-3' title='If checked Recruiter can download Agency all candidates data'>
              <input
                onClick={handleCheckboxClick}
                className='mr-1 w-4 h-4 rounded'
                type="checkbox"
                id='flexSwitchChecked'
                value={permission}
                checked={permission}
              />
              <label
                className="inline-block pl-[0.15rem] hover:cursor-pointer"
                htmlFor="flexSwitchChecked"
              >Allow candidates export</label>
            </div>
          </div>
          <div className="bg-white p-lg rounded  flex flex-col shadow" style={{ minHeight: 370 }}>
            {recruiter && (
              <React.Fragment>
                <div className="flex items-center">
                  <ProfilePicture source={recruiter.profilePhoto} />
                  <div className="flex flex-col">
                    <div className="text-darkblue font-medium">{recruiter.name}</div>
                    <div className="text-darkgray font-medium text-sm">{recruiter.company && recruiter.company.name}</div>
                  </div>
                </div>
                <div className="flex flex-wrap font-medium text-sm">
                  {recruiterFields.map((field, i) => (
                    <div className="flex flex-col mt-xl" style={{ width: '45%', paddingLeft: (i + 1) % 2 === 0 ? '9px' : '0px' }}>
                      <div className="capitalize">{field}</div>
                      <div className="text-darkgray">{recruiter[field]}</div>
                    </div>
                  ))}
                </div>
                <div className="flex flex-wrap mt-lg font-medium text-sm">
                  {recruiter.industries &&
                    recruiter.industries.map((industry) => (
                      <div className="bg-lightgray text-darkgray mr-md mt-md px-md flex items-center" style={{ height: 30, borderRadius: 30 }}>
                        {industry}
                      </div>
                    ))}
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="flex flex-col flex-1 lg:ml-lg w-full lg:w-auto">
          <div className="flex py-sm mb-sm items-center justify-between">
            <div className="text-darkblue font-medium" style={{ fontSize: 21 }}>
              Candidates
            </div>
            <div style={{ height: 40 }} />
          </div>
          <div className="bg-white rounded shadow flex-1" style={{ minHeight: 530, maxHeight: 'calc(100vh - 210px)', overflow: 'auto' }}>
            {candidates && candidates.candidate.length > 0 ? (
              candidates.candidate.map((candidate) => (
                <>
                  <CandidateEntry candidate={candidate} disableChat={true} />
                </>
              ))
            ) : (
              <div className="w-full h-full flex text-sm items-center py-xl lg:py-0 text-center justify-center text-darkgray font-medium">
                This recruiter isn't managing any candidates
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
