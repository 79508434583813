import parse from 'html-react-parser';
import moment from 'moment';
import React from 'react';
import { parseHTMLText } from '../../../utils/candidate-helper';

export default function EmploymentEntry({ entry, approved, isCompany }) {
  const startDate = (entry.tenure.startDate || '').length > 0 ? moment(entry.tenure.startDate).format('MMM YYYY') : 'Unknown';
  const endDate = (entry.tenure.endDate || '').length > 0 ? moment(entry.tenure.endDate).format('MMM YYYY') : "Present";

  return (
    <div className="pt-5 w-full">
      <div className="flex justify-between">
        {entry.companyLogo && entry.companyLogo.length !== '' && (
          <div className="w-full lg:w-auto flex justify-center" style={{ maxWidth: 115, paddingRight: 15 }}>
            <div style={{ filter: isCompany && !approved && 'blur(6px)' }}>
              <img alt="" src={entry.companyLogo} style={{ maxHeight: 40, maxWidth: '100%', marginBottom: 0 }} />
            </div>
          </div>
        )}
        <div className=" flex flex-1 flex-col font-medium w-1/2">
          <div style={{ filter: isCompany && !approved && 'blur(6px)' }}>{isCompany && !approved ? 'Company Name' : entry.companyName}</div>
          <div className="text-darkgray" style={{ fontSize: 14 }}>
            {entry.companyLocation}
          </div>
        </div>
        <div className=" flex flex-1 font-medium w-1/2">
          <div className="flex flex-col">
            <div>{entry.title}</div>
            <div className="text-darkgray" style={{ fontSize: 14 }}>
              {startDate} - {entry.tenure.current ? 'Present' : endDate}
            </div>
          </div>
        </div>
      </div>
      <div className="text-darkgray" style={{ fontSize: 14, lineHeight: '22px', paddingTop: 15 }}>
        {entry.description ? parse(parseHTMLText(entry.description)) : ''}
      </div>
      <div className="flex flex-wrap">
        {entry?.skills?.map((skill) => (
          <div
            className="bg-lightgray mt-md shadow px-md text-darkgray flex items-center mr-sm font-medium"
            style={{ fontSize: 12, height: 28, borderRadius: 28, color: '#9499a2' }}
          >
            {skill}
          </div>
        ))}
      </div>
    </div>
  );
}
