import React, { useEffect, useState } from 'react';
import PlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import useScript from '../../hooks/useScript';
import FilterModal from '../../views/candidates/candidate-dashboard/filter-modal';
export default function LocationFilter({ update, value, type, onEnter = () => { } }) {
  const { radius, city } = value || {};
  const [dropdownOpen, toggleDropdown] = useState(false);
  const [inputValue, setInputValue] = useState();
  const [loaded] = useScript(`https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GOOGLE_PLACES_KEY}&libraries=places`);
  const [openModal, setOpenModal] = useState(false);
  const [validationError, setValidationError] = useState('');

  useEffect(() => {
    if (!value.city) {
      setInputValue('');
    }
  }, [value]);

  useEffect(() => {
    if (value.city) {
      setInputValue(value.city.name);
    }
  }, []);

  return (
    <div className="LocationFilter-js flex">
      <div className="flex-1 relative mr-md">
        {!loaded ? (
          <input
            className="bg-white w-full shadow rounded relative p-sm text-xs placeholder-primary font-normal"
            placeholder="City, State"
            style={{ height: 40, zIndex: dropdownOpen ? 501 : 499 }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && onEnter) {
                onEnter();
              }
            }}
          />
        ) : (
          <PlacesAutocomplete value={inputValue} onChange={setInputValue} searchOptions={{ types: ['(regions)'] }}>
            {({ getInputProps, suggestions }) => (
              <React.Fragment>
                <input
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && onEnter) {
                      onEnter();
                    }
                  }}
                  {...getInputProps({
                    autoComplete: 'cksjfe',
                    onFocus: () => toggleDropdown(true),
                    onBlur: () => toggleDropdown(false),
                    onKeyDown: (ev) => {
                      if (ev.key === 'Enter' && onEnter) {
                        onEnter();
                      }
                    },
                    className: 'bg-white w-full shadow rounded relative p-sm text-xs placeholder-primary font-normal',
                    placeholder: 'City, State',
                    style: { height: 40, zIndex: dropdownOpen ? 501 : 499 },
                  })}
                />
                {dropdownOpen && suggestions.length > 0 && (
                  <div
                    className="absolute bg-white w-full border-r border-l overflow-auto shadow"
                    style={{ top: 'calc(100% - 10px)', zIndex: 500, paddingTop: 10, maxHeight: 350 }}
                  >
                    {suggestions.map((suggestion) => {
                      const city = suggestion.description;
                      return (
                        <div
                          key={Math.random()}
                          onMouseDown={async () => {
                            setInputValue(suggestion.description);
                            const [city] = await geocodeByPlaceId(suggestion.placeId);
                            const { lat, lng } = await getLatLng(city);
                            if (type === 'job-search') {
                              update({
                                ...value,
                                lat,
                                lng,
                                city: `${suggestion.terms[0].value}${suggestion.terms.length > 1 ? `, ${suggestion.terms[1].value}` : ''}`,
                              });
                              // update([...value, { lat, lng, name: suggestion.description }]);
                            } else {
                              update({ ...value, city: { lat, lng, name: suggestion.description } });
                              // update([...value, { lat, lng, name: suggestion.description }]);
                            }
                            if (e.key === 'Enter' && onEnter) {
                              onEnter();
                            }
                          }}
                          className="p-sm text-sm border-b cursor-pointer hover:bg-lightgray"
                        >
                          {city}
                        </div>
                      );
                    })}
                  </div>
                )}
              </React.Fragment>
            )}
          </PlacesAutocomplete>
        )}
      </div>
      <div title={!Object.keys({ ...city }).length ? 'To edit the Radius field, kindly specify the desired Location' : 'radius'} className="d-flex column flex-0 relative mr-md">
        <div className='text-darkgray flex items-center capitalize font-medium' style={{ position: 'absolute', top: '-35px', left: 0, fontSize: '12px', marginBottom: 10 }}>Radius (Miles)</div>
        <input
          type="number"
          value={radius || ''}
          disabled={!Object.keys({ ...city }).length ? true : false}
          // onKeyDown={e => {
          //   if (e.which === 13 && type === "job") {
          //     update([inputValue, ...value]);
          //     setInputValue("");
          //   }
          // }}
          // onChange={e => {
          //   update({ ...value, radius: e.target.value });
          //   if (type === "job-search") {
          //     update({ ...value, radius: e.target.value });
          //   }
          // }}
          onKeyDown={(e) => {
            if (e.which === 13 && type === 'job') {
              if (!Object.keys({ ...city }).length) {
                setValidationError(<span>To edit the Radius field, kindly specify the desired Location</span>);
                setOpenModal(true);
              } else {
                update([inputValue, ...value]);
                setInputValue('');
              }
            }
            if (e.key === 'Enter' && onEnter) {
              onEnter();
            }
          }}
          onChange={(e) => {
            if (!Object.keys({ ...city }).length) {
              setValidationError(<span>To edit the Radius field, kindly specify the desired Location</span>);
              setOpenModal(true);
            } else {
              update({ ...value, radius: e.target.value })
            }
          }}
          style={{ background: `${!Object.keys({ ...city }).length ? 'rgb(184 189 194)' : ''}`, height: 40, width: 100, cursor: `${!Object.keys({ ...city }).length ? 'not-allowed' : ''}` }}
          className="bg-white w-full shadow rounded p-sm text-xs placeholder-primary font-normal"
          placeholder="0+ Mi"
        />
      </div>
      {/* Filter Validator Modal */}
      <FilterModal open={openModal} onChange={(e) => setOpenModal(e)}>
        {validationError}
      </FilterModal>
    </div>
  );
}
