import React from 'react'
import { EXPRESS_SERVER_URL } from '../../../config';
import axios from 'axios';
import { useStripe } from '@stripe/react-stripe-js';

function Onboarding({ currentUser, isAgency, isOnBoarded }) {
    const stripe = useStripe();
    const handleGuestCheckout = async (e) => {
        e.preventDefault();
        const { data } = await axios.post(`${EXPRESS_SERVER_URL}/create-account-link`, {
            email: currentUser?.email,
            id: currentUser?.id,
        });
        window.open(data?.url, '_blank', 'noopener,noreferrer');
    }
    const handleInvoiceClick = async () => {
        const { data } = await axios.post(`${EXPRESS_SERVER_URL}/create-invoice`);
    };

    return (
        <div className="bg-white p-lg rounded flex flex-col" style={{ minHeight: 370 }}>
            <div className='mb-4 font-semibold text-xl'>Onboarding {!isOnBoarded ? 'Completed' : 'Pending'}</div>
            <div>
                {isOnBoarded ? <p>Your Onboarding process is not completed, Complete Onboarding process to receive Payments.</p> : <p> Your Onboarding is Completed </p>}
                {isOnBoarded ? <button onClick={handleGuestCheckout} className="danger-button" style={{ paddingTop: '4px', paddingBottom: '4px' }} >Start Onboarding </button>
                    : <button onClick={handleGuestCheckout} className="danger-button" style={{ paddingTop: '4px', paddingBottom: '4px' }} >Onboarding Completed </button>}
                {/* <button onClick={handlePaymentClick} className="danger-button" style={{ paddingTop: '4px', paddingBottom: '4px' }}>Pay</button> */}
                {/* <button onClick={handleInvoiceClick} className="danger-button" style={{ paddingTop: '4px', paddingBottom: '4px' }}>Create Invoice</button> */}
            </div>
        </div>
    )
}

export default Onboarding