import { useMutation, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import 'firebase/auth';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Loader from 'react-loader-spinner';
import UserAvatar from '../../images/user-avatar.png';
import { EXPRESS_SERVER_URL } from '../../config';
import { EMAIL_TEMPLATES } from '../../utils/constants';

// graphql query to update the totalCredit and remainingCredit into the company table
const UPDATE_CREDIT = gql`
  mutation updateCompany($id: uuid, $totalCredit: numeric, $remainingCredit: numeric) {
    update_company(where: { id: { _eq: $id } }, _set: { totalCredit: $totalCredit, remainingCredit: $remainingCredit }) {
      affected_rows
      returning {
        id
        name
        totalCredit
        remainingCredit
      }
    }
  }
`;

// graphql query to create payment history and update the total credits for the company
const CREATE_PAYMENT_HISTORY = gql`
  mutation CreatePayment($credits: numeric, $date: String, $type: smallint, $company_id: uuid, $totalCredit: numeric, $remainingCredit: numeric, $credit_amount: numeric) {
    insert_payment_history(objects: { credits: $credits, date: $date, type: $type, company_id: $company_id, credit_amount: $credit_amount }) {
      returning {
        id
        credits
        date
        type
        company_id
      }
    }
    update_company(where: { id: { _eq: $company_id } }, _set: { totalCredit: $totalCredit, remainingCredit: $remainingCredit }) {
      affected_rows
      returning {
        id
        name
        totalCredit
        remainingCredit
      }
    }
  }
`;

const SETTINGS = gql`
  query getSettings {
    settings {
      id
      credit_rate
    }
  }
`;

export default function CreditModal({ state }) {
  const [modal, setModal] = state;
  const [fadeDelay, setFadeDelay] = useState(false);
  const modalRef = useRef();

  const [totalCredit, setTotalCredit] = useState(''); // initially totalCredit is blank
  const [errors, setErrors] = useState({});

  const [btnType, setBtnType] = useState();

  // set current date
  const [date] = useState(new Date());

  // calling mutation when submit the modal having totalCredit
  const [updateCompanyCredit, { loading, data: submissionResponse }] = useMutation(UPDATE_CREDIT);

  // mutation to create new payment history and update the credits in company
  const [createPaymentHistory] = useMutation(CREATE_PAYMENT_HISTORY);

  useEffect(() => {
    if (modal.isFresh) {
      setErrors({});
    }
  }, [modal]);

  useEffect(() => {
    if (modal.open) {
      setFadeDelay(true);
    } else {
      setTimeout(() => {
        setFadeDelay(false);
      }, 300);
    }
  }, [modal]);

  const [settingData, setSettingData] = useState();
  const { data: tempSettingData } = useQuery(SETTINGS);
  useEffect(() => {
    if (tempSettingData) {
      setSettingData(tempSettingData.settings[0]);
    }
  }, [tempSettingData]);

  // update credit handler (form submit), called when click on the update from the modal
  const updateCredit = (evt) => {
    evt.preventDefault();

    // add more credit to the existing credits and update them
    if (totalCredit) {
      setErrors({});

      if (btnType === 'add') {
        updateCompanyCredit({
          variables: {
            id: modal.data.subscriber.id,
            remainingCredit: parseInt(totalCredit) + parseInt(modal.data.subscriber.remainingCredit),
            totalCredit: parseInt(totalCredit) + parseInt(modal.data.subscriber.totalCredit),
          },
        });

        // adding the record in payment history table
        const dateString = moment(date).format('YYYY-MM-DD');
        const paymentHistory = {
          credits: totalCredit,
          date: dateString,
          type: 3,
          company_id: modal.data.subscriber.id,
          remainingCredit: parseInt(totalCredit) + parseInt(modal.data.subscriber.remainingCredit),
          totalCredit: parseInt(totalCredit) + parseInt(modal.data.subscriber.totalCredit),
          credit_amount: parseInt(totalCredit) * (settingData ? settingData.credit_rate : 1000),
        };

        createPaymentHistory({ variables: paymentHistory });
        if (!modal.data.subscriber.email) {
          return;
        }
        fetch(`${EXPRESS_SERVER_URL}/send-email`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              recipient: modal.data.subscriber.email,
              template_id: EMAIL_TEMPLATES.TO_SUBSCRIBER_WHEN_ADMIN_ADD_CREDIT,
              attributes: {
                template_params: totalCredit,
              },
          }),
      });
      }
      if (btnType === 'remove') {
        if (modal.data.subscriber.remainingCredit < totalCredit) {
          setErrors({ error_str: 'Credits are greater than remaining credits' });
        } else {
          updateCompanyCredit({
            variables: {
              id: modal.data.subscriber.id,
              remainingCredit: parseInt(modal.data.subscriber.remainingCredit) - parseInt(totalCredit),
              totalCredit: parseInt(modal.data.subscriber.totalCredit) - parseInt(totalCredit),
            },
          });

          // removing the record in payment history table
          const dateString = moment(date).format('YYYY-MM-DD');
          const paymentHistory = {
            credits: totalCredit,
            date: dateString,
            type: 4,
            company_id: modal.data.subscriber.id,
            remainingCredit: parseInt(modal.data.subscriber.remainingCredit) - parseInt(totalCredit),
            totalCredit: parseInt(modal.data.subscriber.totalCredit) - parseInt(totalCredit),
            credit_amount: parseInt(totalCredit) * (settingData ? settingData.credit_rate : 1000),
          };

          createPaymentHistory({ variables: paymentHistory });
        }
      }
    } else {
      setErrors({ ...errors, error_str: 'Please enter some credits' });
    }
  };

  // when updateCredit handler submits successfully then closing the modal
  useEffect(() => {
    if (submissionResponse) {
      setModal({
        ...modal,
        open: false,
        newTotalCredit: submissionResponse.update_company.returning[0].totalCredit,
        newRemainingCredit: submissionResponse.update_company.returning[0].remainingCredit,
      });
    }
  }, [submissionResponse]);

  return (
    <React.Fragment>
      <form onSubmit={updateCredit}>
        <div
          onClick={() => setModal({ ...modal, open: !modal.open, reset: true })}
          className={`fixed inset-0 items-center justify-center`}
          style={{
            transition: 'opacity 0.3s ease-in-out',
            opacity: modal.open ? 1 : 0,
            zIndex: 3000000,
            display: fadeDelay ? 'flex' : 'none',
            backgroundColor: 'rgba(34, 46, 66, 0.9)',
          }}
        >
          <div ref={modalRef} onClick={(e) => e.stopPropagation()} className="bg-white px-xl py-md rounded shadow flex flex-col" style={{ minHeight: 300 }}>
            <div className="text-lg font-medium pb-sm border-b mb-lg">Update Subscriber Credit</div>
            {modal.data && (
              <div className="flex items-center justify-center mb-md">
                <div
                  style={{
                    marginRight: 10,
                    height: 36,
                    width: 36,
                    borderRadius: 40,
                    backgroundImage: `url(${modal.data.subscriber.logoURL ? modal.data.subscriber.logoURL : UserAvatar})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                  }}
                />
                <div className="font-medium" style={{ fontSize: 16 }}>
                  {modal.data.subscriber.name}
                </div>
              </div>
            )}
            <div className="flex justify-between">
              <input
                type="number"
                placeholder="Credits"
                className={`w-full rounded border placeholder-primary text-sm px-md`}
                style={{ height: 55, width: 340, marginTop: 20 }}
                min="0"
                onChange={(e) => setTotalCredit(e.target.value)}
              />
            </div>
            {errors ? (
              <div className="text-xs font-main text-red font-medium mt-sm text-center" style={{ minWidth: 320, maxWidth: 320 }}>
                {errors.error_str}
              </div>
            ) : (
              ''
            )}
            <div className="flex flex-1 justify-between items-end" style={{ marginTop: 40, marginBottom: 20 }}>
              <button
                className="rounded bg-green ml-md font-medium  text-sm text-white"
                style={{ width: 80, height: 55, fontSize: 14, boxShadow: '0 14px 10px 0 rgba(255, 0, 0, 0.18)' }}
                onClick={() => setBtnType('add')}
              >
                {btnType === 'add' && loading ? <Loader type="TailSpin" className="flex justify-center" color="#FFFFFF" height={20} width={20} /> : 'Add'}
              </button>
              <button
                className="rounded bg-red ml-md font-medium  text-sm text-white"
                style={{ width: 80, height: 55, fontSize: 14, boxShadow: '0 14px 10px 0 rgba(255, 0, 0, 0.18)' }}
                onClick={() => setBtnType('remove')}
              >
                {btnType === 'remove' && loading ? <Loader type="TailSpin" className="flex justify-center" color="#FFFFFF" height={20} width={20} /> : 'Remove'}
              </button>
              <button
                onClick={() => setModal({ ...modal, open: false, reset: true })}
                type="button"
                className="rounded bg-lightgray ml-md font-medium  text-sm text-black"
                style={{ width: 80, height: 55, fontSize: 14, boxShadow: '0 14px 10px 0 rgba(255, 0, 0, 0.18)' }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
}
