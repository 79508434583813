import { Field, Form, Formik } from 'formik';
import Loader from 'react-loader-spinner';
import * as Yup from 'yup';
import React from 'react';
import { BACKGROUND_SYNC_FREQUENCIES } from '../../utils/constants';
import FieldTitle from '../fields/FieldTitle';

export default function EditImporterForm({ initialValues, saveImporter, navigate, loading, errors, state, onClose }) {
  const [modal, setModal] = state;

  const AttributeSchema = Yup.object().shape({
    // username: Yup.string().required('Please enter username'),
    authorization: Yup.string().required('Please enter auth token'),
    sync_frequency: Yup.string().required('Please select frequency'),
  });

  return (
    <div className="py-lg w-full h-full relative container flex flex-col items-center">
      <div
        className={`bg-white rounded shadow flex flex-col items-center`}
        style={{ width: 400, display: 'flex', padding: 30, transition: 'transform 0.3s ease-in-out', transform: `translateX(0%)` }}
      >
        <div className="font-main text-darkblue" style={{ fontWeight: 500, fontSize: 21 }}>
          {modal.data ? 'Importer Config' : 'Create Importer'}
        </div>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={async (values, actions) => {
            await saveImporter(values);

            if (onClose) {
              onClose();
            }
          }}
          validationSchema={AttributeSchema}
        >
          {({ setFieldValue }) => (
            <Form customErrors={errors}>
              {Object.entries(initialValues).map(([label, value]) => {
                switch (label) {
                  // case 'username':
                  //   return (
                  //     <Field name="username">
                  //       {({ field, form, meta }) => {
                  //         return (
                  //           <>
                  //             <input
                  //               type="text"
                  //               className="text-sm  px-md rounded w-full border border-darkgray"
                  //               style={{ height: 55, marginTop: 16, borderColor: '#c4cad3' }}
                  //               placeholder="Username"
                  //               {...field}
                  //             />
                  //             {errors && errors.username && <div className="text-xs text-red font-medium mt-sm text-center">{errors.username}</div>}
                  //             {form.errors['username'] && form.touched['username'] && (
                  //               <div className="text-xs text-red font-medium mt-sm text-center">{form.errors['username']}</div>
                  //             )}
                  //           </>
                  //         );
                  //       }}
                  //     </Field>
                  //   );
                  case 'authorization':
                    return (
                      <Field name="authorization">
                        {({ field, form, meta }) => {
                          return (
                            <>
                              <FieldTitle title="Bearer Token Key" />
                              <input
                                type="text"
                                className="text-sm px-md rounded w-full border border-darkgray"
                                style={{ height: 55, marginTop: 16, borderColor: '#c4cad3' }}
                                placeholder="Auth Token"
                                {...field}
                              />
                              {errors && errors.v && <div className="text-xs text-red font-medium mt-sm text-center">{errors.password}</div>}
                              {form.errors['password'] && form.touched['password'] && (
                                <div className="text-xs text-red font-medium mt-sm text-center">{form.errors['password']}</div>
                              )}
                            </>
                          );
                        }}
                      </Field>
                    );
                  case 'sync_frequency':
                    return (
                      <Field name="sync_frequency">
                        {({ field, form, meta }) => {
                          return (
                            <>
                              <FieldTitle title="Sync Frequency" />
                              <select
                                className={`text-sm px-md rounded w-full border border-darkgray`}
                                style={{ height: 55, marginTop: 16, borderColor: '#c4cad3', width: '100%' }}
                                {...field}
                              >
                                {Object.keys(BACKGROUND_SYNC_FREQUENCIES).map((a) => {
                                  return <option value={a}>{BACKGROUND_SYNC_FREQUENCIES[a]}</option>;
                                })}
                              </select>
                            </>
                          );
                        }}
                      </Field>
                    );
                  default:
                    return (
                      <Field name={label}>
                        {({ field, form, meta }) => {
                          return (
                            <>
                              <input
                                className="text-sm  px-md rounded w-full border border-darkgray"
                                style={{ height: 55, marginTop: 16, borderColor: '#c4cad3' }}
                                placeholder={label}
                                {...field}
                              />
                              {form.errors[label] && form.touched[label] && <div className="text-xs text-red font-medium mt-sm text-center">{form.errors[label]}</div>}
                            </>
                          );
                        }}
                      </Field>
                    );
                }
              })}
              <div className="flex w-full mt-lg">
                <button
                  onClick={() => {
                    if (onClose) {
                      onClose();
                    }

                    setModal({ ...modal, open: false, reset: true, data: null });
                  }}
                  type="button"
                  style={{ height: 55 }}
                  className="bg-lightgray flex items-center justify-center shadow text-xs font-medium flex-1 text-darkgray rounded mr-sm p-sm"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  style={{ height: 55, boxShadow: '0 14px 10px 0 rgba(255, 0, 0, 0.18)' }}
                  className="bg-red flex items-center justify-center  shadow ml-sm text-xs font-medium flex-1 text-white rounded p-sm"
                >
                  {loading ? <Loader type="TailSpin" color="#FFFFFF" height={20} width={20} /> : modal.data ? 'Update' : 'Submit'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
