import { useLazyQuery, useQuery, useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import 'firebase/auth';
import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Arrow } from '../../../components/common';
import FieldTitle from '../../../components/common/FieldTitle';
import RequestInterviewButton from '../../../components/common/RequestInterviewButton';
import RescindButton from '../../../components/common/RescindButton';
import UnblockButton from '../../../components/common/UnblockButton';
import LinkIcon from '../../../images/link.png';
import ProfileIcon from '../../../images/profile/profile.jpg';
import UserAvatar from '../../../images/user-avatar.png';
import { formatSalary } from '../../../utils/formatter';
import { ViewResumeButton } from '../view-resume-button';
import EducationEntry from './education-entry';
import EmploymentEntry from './employment-entry';
import ProfileImage from '../profile/profile-image';
import ChatIcon from '../../../images/chat.svg';
import _ from 'lodash';
import { eventPropTypes } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/EditorPropTypes';
import { REQUEST_STATUSES } from '../../../utils/constants';

const APPROVED_COMPANY_REQUEST = gql`
  subscription approvedCompanyRequests($admin_id: String, $candidate_id: String) {
    company_request(where: { company: { adminID: { _eq: $admin_id } }, _and: { status: { _in: ["approved", "hired"] }, _and: { candidate_id: { _eq: $candidate_id } } } }) {
      status
      candidate_id
      candidate {
        name
        profilePictureURL
        bio
        phone
        email
        resumeFileName
        resumeURL
        linkedin
        location
        salaryMin
        salaryMax
        loxo_id
        interestedCity
        is_disabled
      }
    }
  }
`;

const COMPANY_REQUEST = gql`
  subscription companyRequest($admin_id: String, $candidate_id: String) {
    company_request(where: { company: { adminID: { _eq: $admin_id } }, _and: { candidate_id: { _eq: $candidate_id } } }) {
      status
      candidate_id
    }
  }
`;

const GET_CANDIDATE = gql`
  subscription getCompanyCandidate($candidate_id: String) {
    candidate(where: { id: { _eq: $candidate_id } }) {
      name
      experience
      attributes
      education
      salaryMax
      salaryMin
      location
      attributes
      employment
      id
      locationPreferences
      loxo_id
      is_login
      device_token
      invite_code
      recruiter_id
      resumeURL
      interestedCity
      is_disabled
    }
  }
`;

const GET_COMPANY_ID = gql`
  query getCompanyID($admin_id: String) {
    company(where: { adminID: { _eq: $admin_id } }) {
      id
      remainingCredit
      subscription_type
    }
  }
`;

const CandidateEmployment = ({ entries, approved }) => {
  const employment = entries.reduce(
    ({ current, past }, entry) => {
      if (entry.tenure.current) {
        return { current: [...current, entry], past };
      } else if ((entry.tenure?.startDate || '').length > 0) {
        return { current, past: [...past, entry] };
      } else {
        return { current, past };
      }
    },
    { current: [], past: [] },
  );

  return (
    <React.Fragment>
      {employment.current.length > 0 && <FieldTitle title="Current Employment" marginTop={1} />}
      {employment.current.map((entry, index) => (
        <EmploymentEntry key={index} entry={entry} approved={approved} isCompany={true} />
      ))}
      {employment.past.length > 0 && <FieldTitle title="Previous Employment" marginTop={employment.current.length === 0 && 1} />}
      {employment.past.map((entry, index) => (
        <EmploymentEntry key={index} entry={entry} approved={approved} isCompany={true} />
      ))}
    </React.Fragment>
  );
};

const Skills = ({ skills }) => {
  return (
    <div className="flex flex-wrap">
      {skills.map((skill, index) => (
        <div
          key={index}
          className="bg-lightgray mt-md shadow px-md text-darkgray flex items-center mr-sm font-medium"
          style={{
            fontSize: 12,
            height: 28,
            borderRadius: 28,
            color: '#9499a2',
          }}
        >
          {skill}
        </div>
      ))}
    </div>
  );
};

const LocationPreferences = ({ locationPreferences }) => {
  return (
    <div className="w-full relative">
      <div className="flex flex-wrap">
        {locationPreferences.remote && (
          <div
            className={`bg-lightgray text-darkgray mt-md shadow px-md flex items-center mr-sm font-medium`}
            style={{
              fontSize: 12,
              height: 28,
              borderRadius: 28,
            }}
          >
            Remote
          </div>
        )}
        {locationPreferences.willingToRelocate && (
          <div
            className={`bg-lightgray text-darkgray mt-md shadow px-md flex items-center mr-sm font-medium`}
            style={{
              fontSize: 12,
              height: 28,
              borderRadius: 28,
            }}
          >
            Wlling To Relocate
          </div>
        )}
        {locationPreferences.currentLocation && (
          <div
            className={`bg-lightgray text-darkgray mt-md shadow px-md flex items-center mr-sm font-medium`}
            style={{
              fontSize: 12,
              height: 28,
              borderRadius: 28,
            }}
          >
            Current Location
          </div>
        )}
      </div>
    </div>
  );
};

export default function CandidateDetailsCompany({ location, isRequestedCandidates, isInterestedCandidates }) {
  const [approved, setApproved] = useState(false);
  const locationStatus = `${['requested', 'pending', 'accepted', 'declined', 'request', 'interested', 'not-interested'].indexOf(location.state.candidate.status) > -1
    ? location.state.candidate.status
    : location.state.status
    }`;
  const [status, setStatus] = useState(locationStatus);
  const [candidateID, setCandidateID] = useState();

  const [companyID, setCompanyID] = useState();
  const [remainingCredit, setRemainingCredit] = useState();
  const [subscriptionType, setSubscriptionType] = useState();

  const [candidate, setCandidate] = useState();
  const { data: candidateData } = useSubscription(GET_CANDIDATE, {
    variables: { candidate_id: candidateID },
  });
  const currentUserId = firebase.auth().currentUser ? firebase.auth().currentUser.uid : 'not-logged-in';
  const { data: company } = useQuery(GET_COMPANY_ID, {
    variables: { admin_id: currentUserId },
  });
  const { data: approvedRequest } = useSubscription(APPROVED_COMPANY_REQUEST, {
    variables: {
      admin_id: currentUserId,
      candidate_id: candidateID ? candidateID : 'query-loading',
    },
    shouldResubscribe: true,
  });

  const { data: anyRequest } = useSubscription(COMPANY_REQUEST, {
    variables: {
      admin_id: currentUserId,
      candidate_id: candidateID ? candidateID : 'query-loading',
    },
    shouldResubscribe: true,
  });

  useEffect(() => {
    if (location) {
      const candidate_id = location.pathname.split('/')[2];
      setCandidateID(candidate_id);
    }
  }, []);

  useEffect(() => {
    if (candidateData) {
      if (candidateData.candidate.length > 0) {
        if (approvedRequest && approvedRequest.company_request.length > 0) {
          setCandidate({ ...candidateData.candidate[0], ...approvedRequest.company_request[0].candidate });
        } else {
          setCandidate(candidateData.candidate[0]);
        }
      } else {
        window.history.back();
      }
    }
  }, [candidateData]);

  useEffect(() => {
    if (company) {
      setCompanyID(company.company[0].id);
      setRemainingCredit(company.company[0].remainingCredit);
      setSubscriptionType(company.company[0].subscription_type);
    }
  }, [company]);

  useEffect(() => {
    if (approvedRequest && approvedRequest.company_request.length > 0) {
      setApproved(true);
      setCandidate({ ...candidate, ...approvedRequest.company_request[0].candidate });
    }
  }, [approvedRequest]);

  useEffect(() => {
    let computedStatus = '';

    if (anyRequest && anyRequest.company_request.length > 0) {
      for (const request of anyRequest.company_request.filter((request) => request.company_id === currentUserId)) {
        if (request.status === 'approved') {
          computedStatus = 'approved';
        }
      }
    }

    if (!computedStatus.length) {
      if (status === 'interested') {
        computedStatus = 'interested';
      }
    }

    if (!computedStatus.length) {
      if (anyRequest) {
        // There was an issue with setting the default status to interested here. Obviously this shouldnot be set if no company matches
        computedStatus = _.get(
          anyRequest.company_request.filter(({ company_id: companyId }) => companyId === companyID),
          '[0].status',
          locationStatus,
        );
      }
    }

    // console.log('Any Request:', anyRequest, 'Status:', status, computedStatus);

    setStatus(computedStatus);

    // if (status != 'accepted') {
    //   if (anyRequest && anyRequest.company_request.length > 0) {
    //     setStatus(anyRequest.company_request[0].status);
    //   } else {
    //     if (status != 'interested') {
    //       setStatus('request');
    //     }
    //   }
    // }
  }, [anyRequest]);

  const bioLorem =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.';

  const smallTitledFields = candidate
    ? {
      name: candidate.name,
      email: candidate.email,
      phone: candidate.phone,
      resume: candidate.resumeFileName,
      LinkedIn: candidate.linkedin,
      location: candidate.location,
      salary: candidate.salaryMin ? `$ ${formatSalary({ salary: candidate.salaryMin })} +` : `Negotiable`,
      experience: `${candidate.experience}`,
    }
    : {};

  const getLinkedInURL = (value) => {
    return value.includes('linkedin.com/in')
    ? (value.startsWith('https://') ? value : `https://${value}`)
    : `https://www.linkedin.com/in/${value}/`
  }
  // console.log(isInterestedCandidates, status, isInterestedCandidates || status == 'interested');
  return (
    <div className="w-full h-full py-lg">
      <div className="bg-white rounded shadow flex flex-col mb-lg pb-xl" style={{ minHeight: 'calc(100vh - 125px)' }}>
        <div className="w-full pl-lg pt-md flex">
          <a
            /*
            onClick={() =>
              isRequestedCandidates
                ? navigate('/dashboard/requests-sent')
                : isInterestedCandidates
                  ? navigate('/dashboard/interested-candidates')
                  : location.state.isSubscriber
                    ? navigate(`/dashboard/jobs/${location.state.jobID}`, {
                      state: {
                        jobID: location.state.jobID,
                      },
                    })
                    : navigate('/dashboard/find-candidate')
            }
            */
            onClick={() => window.history.back()}
            className="flex text-darkgray font-medium text-xs items-center cursor-pointer hover:underline"
          >
            <Arrow color="darkgray" style={{ height: 15, marginRight: 10 }} /> back
            {/* {isRequestedCandidates
              ? 'Back to Requests'
              : isInterestedCandidates
                ? 'Back to Interested Candidates'
                : location.state.isSubscriber
                  ? 'Back to Job detail'
                  : 'Back'} */}
          </a>
        </div>
        <div className="flex w-full justify-between items-center">
          <div className="text-darkblue font-medium px-xl py-md" style={{ fontSize: 21 }}>
            Candidate Details
            {
              approved ?
                (
                  <img
                    title='Chat'
                    alt="chat"
                    className="cursor-pointer"
                    onClick={() =>
                      navigate('/chat', { state: { candidate: { id: candidate.id, name: candidate.name, profilePicture: candidate.profilePictureURL, messages: [] } } })
                    }
                    src={ChatIcon}
                    style={{ width: 40, marginRight: 10, marginBottom: 8, display: 'inline-block', marginLeft: 10 }}
                  />
                ) : null
            }
          </div>
          <div className="px-xl flex justify-end">
            {isInterestedCandidates === true || status == 'interested' ? (
              <UnblockButton candidateID={candidate && candidate.id} candidate={candidate} status={`${approved ? 'approved' : status}`} />
            ) : isRequestedCandidates === true && status == 'requested' ? (
              <RescindButton candidateID={candidate && candidate.id} status={status} companyID={companyID} remainingCredit={remainingCredit} />
            ) : (
              <RequestInterviewButton
                status={`${approved ? 'approved' : status}`}
                companyID={companyID}
                candidateID={candidate && candidate.id}
                candidate={candidate}
                subscriptionType={subscriptionType}
                remainingCredit={remainingCredit}
              />
            )}
          </div>
        </div>

        {candidate && (
          <div className="flex w-full px-xl flex-wrap">
            <div className="flex flex-col w-full lg:w-1/2 pt-lg">
              {approved && (
                <div className="flex w-full items-center mb-xl">
                  <ProfileImage isDisabled={candidate?.is_disabled} approved={approved} src={approved ? candidate.profilePictureURL : UserAvatar} />
                  <div className="flex flex-col font-medium text-darkblue">
                    <div className="flex flex-col justify-center">
                      <div className="text-darkblue mr-md">{candidate.name}</div>

                      <ViewResumeButton resumeUrl={candidate.resumeURL} candidateLoxoId={candidate.loxo_id} recruiterId={candidate.recruiter_id} />

                      {/* {candidate.resumeURL && (
                        <div className="flex">
                          <div
                            onClick={() => window.open(candidate.resumeURL)}
                            className="bg-lightgray flex shadow cursor-pointer rounded hover:underline items-center text-darkgray"
                            style={{ paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10, marginTop: 5, fontSize: 12 }}
                          >
                            <img src={LinkIcon} style={{ height: 13, width: 13, marginBottom: 0, marginRight: 10 }} />
                            View Resume
                          </div>
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
              )}
              <div className="flex flex-wrap">
                {Object.entries(smallTitledFields).map(([title, value]) => (
                  <div className="flex flex-col w-1/2" key={title}>
                    <div className=" text-sm text-darkgray font-medium capitalize">{title}</div>
                    <div
                      className="text-sm text-darkblue font-medium pt-md pb-lg"
                      style={{
                        filter: `blur(${approved || title === 'location' || title === 'salary' || title === 'experience' ? '0' : '5'}px)`,
                      }}
                    >
                      {title === 'location' ? (
                        value?.city
                      ) : title === 'salary' ? (
                        value
                      ) : title === 'experience' ? (
                        (value === 'null' ? 0 : value) + ' years'
                      ) : !approved ? (
                        `Candidate ${title}`
                      ) : title === 'LinkedIn' ? (
                        <a href={`${getLinkedInURL(candidate?.linkedin)}`} target="_blank" className="hover:underline">
                          {getLinkedInURL(candidate?.linkedin)}
                        </a>
                      ) : (
                        value
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className="w-full pr-xl pb-xl">
                <FieldTitle title="Short Bio" />
                <div className="text-sm pt-md text-darkgray" style={{ filter: `blur(${approved ? '0' : '5'}px)` }}>
                  {approved ? candidate.bio : bioLorem}
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full lg:w-1/2">
              {candidate.employment && <CandidateEmployment entries={candidate.employment} approved={approved} />}
              <FieldTitle title="Education" />
              {candidate.education && candidate.education.map((entry, index) => <EducationEntry key={index} entry={entry} schoolHidden={!approved} />)}
              {candidate.attributes && candidate.attributes.length > 0 && (
                <>
                  <FieldTitle title="Candidate's Attributes" />
                  <Skills skills={candidate.attributes} />
                </>
              )}
              {candidate.interestedCity && Array.isArray(candidate.interestedCity) ? (
                <div className="w-full relative interested-city">
                  <FieldTitle title="Interested Cities" />
                  <div className="flex flex-wrap">
                    {candidate.interestedCity.map(({ city = '' }, idx) => (
                      <div
                        key={idx}
                        className={`bg-lightgray text-darkgray mt-md shadow px-md flex items-center mr-sm font-medium`}
                        style={{ fontSize: 12, height: 28, borderRadius: 28 }}
                      >
                        {city || ''}
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
              {candidate.locationPreferences && (
                <>
                  <FieldTitle title="Location Preferences" />
                  <LocationPreferences locationPreferences={candidate.locationPreferences} />
                </>
              )}
              {candidate.preferredSkills && candidate.preferredSkills.length > 0 && (
                <>
                  <FieldTitle title="Desired Job Attributes" />
                  <Skills skills={candidate.preferredSkills} />
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
