/* eslint-disable jsx-a11y/anchor-is-valid */
import { useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import { Link } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { TabTitle } from '../../components/common';
import CompanyFilter from '../../components/Filter/CompanyFilter';
import { GlobalFilterContext } from '../../components/layout/layout';
import UserRoleContext from '../../context/UserRole';
import CaretDownGray from '../../images/caret-down-gray.png';
import CaretDown from '../../images/caret-down.png';
import CompanyEntry from './company-entry';
import CompanyImportModal from './company-import-modal';
import './pagination.css';
import SampleCsvCompanies from './sample-csv-companies';
import CherryPagination from '../../components/common/Pagination/Pagination';
import { DEFAULT_PER_PAGE } from '../../utils/constants';

// graphql query to get all companies
const ALL_COMPANIES = gql`
  subscription getCompanies($adminID: String, $perPage: Int, $offset: Int, $order_by: order_by) {
    companies(order_by: { name: $order_by }, where: { adminID: { _eq: $adminID } }, limit: $perPage, offset: $offset) {
      id
      name
      websiteURL
      description
      size
      logoURL
      location
      industries
    }
  }
`;

// where: { adminID: { _eq: $adminID } },
const ALL_FILTERED_COMPANIES = gql`
  subscription getFiltered_Companies($adminID: String, $g_lat: float8, $g_long: float8, $g_radius: Int, $search_term: String, $perPage: Int, $offset: Int, $order_by: order_by) {
    filter_companies(
      args: {g_lat: $g_lat, g_long: $g_long, g_radius: $g_radius, search_term: $search_term},
      limit: $perPage,
      offset: $offset,
      order_by: { name: $order_by },
      ) {
        id
        name
        websiteURL
        description
        size
        logoURL
        location
        industries
    }
  }
`;

// where: { adminID: { _eq: $adminID } },
const ALL_COMPANIES_TOTAL = gql`
subscription getFiltered_Companies($adminID: String, $g_lat: float8, $g_long: float8, $g_radius: Int, $search_term: String, $offset: Int) {
  filter_companies_aggregate(
    args: {g_lat: $g_lat, g_long: $g_long, g_radius: $g_radius, search_term: $search_term},
    offset: $offset,
    ) {
      aggregate {
        count
      }
  }
}
`;

// component for sorting companies alphabetically
function AlphabeticalSort({ sortState }) {
  const [sortType, setSortType] = sortState;
  const [direction, setDirection] = useState('asc');

  function setType() {
    if (direction === 'desc') {
      setDirection('asc');
      setSortType({ type: 'alphabetical', direction: 'asc' });
    } else {
      setDirection('desc');
      setSortType({ type: 'alphabetical', direction: 'desc' });
    }
  }
  return (
    <div className="flex mr-md">
      <div
        onClick={setType}
        className={`text-sm ${sortType.type === 'alphabetical' ? 'bg-darkblue text-white' : 'text-darkgray border border-darkgray'
          }   flex flex-row items-center font-medium cursor-pointer px-sm rounded`}
      >
        A - Z
        <img
          alt=""
          src={sortType.type === 'alphabetical' ? CaretDown : CaretDownGray}
          style={{ height: 5, margin: 0, transform: `rotate(${direction === 'desc' ? '0' : '180'}deg)`, marginLeft: 10 }}
        />
      </div>
    </div>
  );
}

export default function Companies() {
  // Pagination
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [total, setTotal] = useState(0);
  const [flag, setFlag] = useState(true);

  function handlePaginationChange(event) {
    setPage(event.page);
    setPerPage(event.perPage);
  }

  const [companiesData, setCompaniesData] = useState([]);

  const [userRole] = useContext(UserRoleContext);
  const isAdmin = userRole === 'admin';

  const [sortType, setSortType] = useState({ type: 'alphabetical', direction: 'asc' });

  const { globalFilter, setGlobalFilter } = useContext(GlobalFilterContext);
  const [filter, setFilter] = useState(globalFilter[`companies-filter`] ? globalFilter[`companies-filter`] : {});

  const [modal, setModal] = useState({ data: null, open: false });

  useEffect(() => {
    if (!isEmpty(filter)) {
      setFlag(false);
    } else {
      setFlag(true);
    }
  }, [filter, flag]);

  // Aggregate of the companies that is use to show the page count
  // const { data: companiesTotal } = useSubscription(ALL_COMPANIES_TOTAL, {
  //   variables: {
  //     adminID: !isAdmin ? firebase.auth().currentUser && firebase.auth().currentUser.uid : null,
  //   },
  // });

  const { data: companiesTotal } = useSubscription(ALL_COMPANIES_TOTAL, {
    variables: {
      g_lat: filter?.location?.city?.lat ? filter.location.city.lat : null,
      g_long: filter?.location?.city?.lng ? filter.location.city.lng : null,
      g_radius: filter?.location?.radius ? filter.location.radius : process.env.GATSBY_DEFAULT_SEARCH_RADIUS || 50,
      search_term: filter?.search ? filter.search : null,
      offset: page * perPage ? page * perPage : 0,
      // adminID: !isAdmin ? firebase.auth().currentUser && firebase.auth().currentUser.uid : null,
    },
  });

  const { data: filter_companies, loading } = useSubscription(
    ALL_FILTERED_COMPANIES,
    // flag ?
    {
      variables: {
        g_lat: filter?.location?.city?.lat ? filter.location.city.lat : null,
        g_long: filter?.location?.city?.lng ? filter.location.city.lng : null,
        g_radius: filter?.location?.radius ? filter.location.radius : process.env.GATSBY_DEFAULT_SEARCH_RADIUS || 50,
        search_term: filter?.search ? filter.search : null,
        perPage: perPage ? perPage : null,
        offset: page * perPage ? page * perPage : 0,
        // adminID: !isAdmin ? firebase.auth().currentUser && firebase.auth().currentUser.uid : null,
        order_by: sortType.direction,
      },
    },
  );

  useEffect(() => {
    if (filter_companies) {
      console.log({filter_companies});
      setCompaniesData(filter_companies.filter_companies)
    }
  }, [filter_companies])

  useEffect(() => {
    if (companiesTotal) {
      setTotal(companiesTotal?.filter_companies_aggregate?.aggregate.count || 0);
    }
  }, [companiesTotal])

  function isEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  /*
  // function to get distance between two coordinates in miles
  function calcDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // km
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    lat1 = toRad(lat1);
    lat2 = toRad(lat2);

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d * 0.621371;
  }
*/
  // Converts numeric degrees to radians
  function toRad(Value) {
    return (Value * Math.PI) / 180;
  }

  useEffect(() => {
    if (filter_companies) {
      setCompaniesData(filter_companies.filter_companies)
    }
    setGlobalFilter({
      ...globalFilter,
      [`companies-filter`]: filter,
    });
    setPage(0);
    setPerPage(DEFAULT_PER_PAGE);

  }, [filter]);

  useEffect(() => {
    if (sortType && filter_companies) {
      setCompaniesData(filter_companies.filter_companies)
    }
  }, [sortType]);

  return (
    <div className="flex flex-col h-full pb-lg">
      <div className="relative flex items-center" style={{ marginTop: 30, marginBottom: 30, zIndex: 200 }}>
        <TabTitle>{'Companies'}</TabTitle>
        <div className="import_csv flex justify-between lg:mt-0 mt-sm">
          <SampleCsvCompanies />
          <a
            href="#"
            className={`bg-red text-white font-medium rounded flex items-center justify-center`}
            style={{ fontSize: 14, height: 40, width: 190, marginRight: 10 }}
            onClick={() => setModal({ data: null, open: true })}
          >
            <span style={{ fontSize: 22, marginRight: 8 }}>+</span>
            Import Companies
          </a>
          <Link className={`bg-green text-white font-medium rounded flex items-center justify-center`} style={{ fontSize: 14, height: 40, width: 160 }} to="/companies/new-company">
            <span style={{ fontSize: 22, marginRight: 8 }}>+</span> Add Company
          </Link>
        </div>
      </div>
      <CompanyFilter filterState={[filter, setFilter]} type="companies" />
      <div className="flex mb-sm">
        <div className="text-sm mr-sm">Sort:</div>
        <AlphabeticalSort sortState={[sortType, setSortType]} />
      </div>
      <div className="bg-white rounded shadow flex-1 w-full" style={{ maxWidth: 1340, overflowX: 'auto', height: '700px' }}>
        {loading ? (
          <div style={{ marginLeft: 600, marginTop: 100 }}>
            <Loader type="TailSpin" color="#E31C25" height={50} width={50} />
          </div>
        ) : companiesData && companiesData.length > 0 ? (
          companiesData?.map((company, index) => <CompanyEntry key={index} company={company} />)
        ) : (
          <div className="w-full h-full flex items-center justify-center text-darkgray">No companies available</div>
        )}
      </div>
      <div className="candidate-pagination">
        <CherryPagination page={page} perPage={perPage} total={total} onChange={handlePaginationChange} />
      </div>
      <CompanyImportModal state={[modal, setModal]} isAdmin={isAdmin} />
    </div>
  );
}
