import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import React, { useEffect } from 'react';
import firebase from 'firebase';
import { useState } from 'react';
import _ from 'lodash';
import RescindButton from '../../components/common/RescindButton';
import UnblockButton from '../../components/common/UnblockButton';
import { RequestInterviewButton } from '../../components/common';
import RemoveConfirmModal from '../jobs/remove-confirm-modal';
import Loader from 'react-loader-spinner';

const REMOVE_COMPANY_REQUEST = gql`
  mutation deleteCompanyRequest($candidate_id: String, $company_id: uuid) {
    delete_company_request(where: { candidate_id: { _eq: $candidate_id }, company_id: { _eq: $company_id } }) {
      affected_rows
    }
  }
`;

export default function CandidateAction({
    candidate: _candidate,
    status: _status,
    handleOnCandidateStatusChange,
    handleCandidateStatusChange,
    modal,
    companyID,
    isInterestedCandidates,
    isRequestedCandidates,
    remainingCredit,
    subscriptionType,
    handleCandidateCancelStatus,
    excludeApprovedCandidate
}) {

    const [candidate, setCandidateState] = useState(_candidate);
    const [status, setStatus] = useState(_status);
    const [candidateActionStatus, setCandidateActionStatus] = useState(_status);
    const { id, isDirectRequest, isFavorite } = candidate || {}

    const [loading, setLoading] = useState(false);
    const companyId = firebase.auth().currentUser && firebase.auth().currentUser.uid;
    const _candidateIsInterested = (c = candidate) => isInterestedCandidates !== undefined ? isInterestedCandidates : getCandidateIs(c, 'interested');
    const _candidateIsRequested = (c = candidate) => isRequestedCandidates !== undefined ? isRequestedCandidates : getCandidateIs(c, ['requested', 'request']);
    function getCandidateIs(candidate, status) {
        const candidateStatus = getCandidateOrRequestStatus(candidate);
        return status instanceof Array ? status.indexOf(candidateStatus) > -1 : candidateStatus === status;
    }

    useEffect(() => {
        setStatus(_status);
        setCandidateState(_candidate);
    }, [_status, _candidate])

    function getCandidateOrRequestStatus(candidate) {
        const request = (candidate?.company_requests || []).find((request) => request.company_id == companyId || request.company?.adminID == companyId);
        return request?.status || candidate?.status;
    }

    const [candidateIsInterested, setCandidateIsInterested] = useState(_candidateIsInterested());
    const [candidateIsRequested, setCandidateIsRequested] = useState(_candidateIsRequested());
    const [removeCompanyRequest] = useMutation(REMOVE_COMPANY_REQUEST);

    useEffect(() => {
        setCandidateIsInterested(_candidateIsInterested());
        setCandidateIsRequested(_candidateIsRequested());
    }, [candidate]);

    useEffect(() => {
        isInterestedCandidates = _candidateIsInterested();
        isRequestedCandidates = _candidateIsRequested();
        modal && handleOnCandidateStatusChange(status);
        setCandidateActionStatus(status);
    }, [candidate, status])

    function updateStatus(newStatus, updates) {
        const request = (candidate.company_requests || []).findIndex((request) => request.company_id == companyId || request.company?.adminID == companyId);
        const cand = { ...candidate, status: newStatus };
        if (request > -1 && cand?.company_requests?.length) {
            cand.company_requests[request] = updates?.status;
        }
        setCandidateState(cand);
        setStatus(newStatus);
        setCandidateIsInterested(_candidateIsInterested(cand));
        setCandidateIsRequested(_candidateIsRequested(cand));
        !modal && handleCandidateStatusChange(cand, newStatus);
        handleCandidateCancelStatus && handleCandidateCancelStatus(cand)
    }

    return <div className="w-40 pr-0">
        <div className="w-full flex justify-center items-center">
            {candidateIsRequested ? (
                <RescindButton candidateID={id} status={status} companyID={companyID} remainingCredit={remainingCredit} updateStatus={updateStatus} />
            ) : candidateIsInterested ? (
                    <UnblockButton
                     candidate={candidate} 
                     candidateID={id} 
                     status={status} 
                     excludeApprovedCandidate={excludeApprovedCandidate}
                     changeStatus={true}
                    />
            ) : (
                <RequestInterviewButton
                    handleOnCandidateStatusChange={handleOnCandidateStatusChange}
                    candidateID={id}
                    candidate={candidate}
                    status={status}
                    companyID={companyID}
                    remainingCredit={remainingCredit}
                    subscriptionType={subscriptionType}
                    actionModal={modal}
                    handleCandidateStatusChange={handleCandidateStatusChange}
                />
            )}
        </div>
        {isDirectRequest ? (
            <div className="w-full flex justify-center items-center">
                <button
                    onClick={handleClickOpen}
                    style={{
                        ...{ cursor: 'pointer', color: '#D8000C', borderColor: 'rgb(86, 208, 0)', backgroundColor: '#FFD2D2', border: '1px solid' },
                        ...{ borderRadius: 5, height: 40, width: '100%', minWidth: 200, marginBottom: 2, paddingLeft: 5, paddingRight: 5 },
                    }}
                    className={`text-xs flex justify-center items-center rounded`}
                >
                    {loading ? <Loader type="TailSpin" color="#000000" height={20} width={20} /> : `Remove Candidate`}
                </button>

                <RemoveConfirmModal
                    open={open}
                    loading={loading}
                    onFailure={handleClose}
                    onSuccess={async () => {
                        handleClose();

                        setLoading(true);

                        await removeCompanyRequest({
                            variables: { candidate_id: id, company_id: companyID },
                        });

                        setLoading(false);
                    }}
                />
            </div>
        ) : (
            ''
        )}
        {/* chat */}
        {/* {
      mutualInterest ?
        (
          <div>
            <button
              title='chat'
              onClick={() => navigate('/chat', { state: { candidate: { id: candidate.id, name, profilePicture: candidate?.profilePictureURL, messages: [] } } })}
              className='button-success'>
              Chat
            </button>
          </div>
        ) : null
    } */}
    </div>
}