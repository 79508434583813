import React, { useEffect, useState } from "react";
import CreateAccountForm from "../../forms/gateway/CreateAccountForm";
import firebase from "firebase/app";
import { navigate } from "gatsby";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";

const CREATE_COMPANY = gql`
  mutation createCompany($company: [company_insert_input!]!) {
    insert_company(objects: $company) {
      returning {
        id
      }
    }
  }
`;

const UPDATE_RECRUITER = gql`
  mutation updateRecruiter($id: String, $changedFields: recruiter_set_input) {
    update_recruiter(where: { id: { _eq: $id } }, _set: $changedFields) {
      affected_rows
      returning {
        availability
        email
        id
        industries
        name
        phone
        profilePhoto
        title
        company {
          id
          name
        }
        company_id
        position
        candidates_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  }
`;

export default function EditRecruiter({ location }) {
  const [updateRecruiter, { data, error }] = useMutation(UPDATE_RECRUITER);
  const [createCompany, { data: companyData }] = useMutation(CREATE_COMPANY)
  const [changes, setChanges] = useState()



  async function uploadImage(file, location) {
    const storageRef = firebase
      .storage()
      .ref()
      .child(`images/${location}/${file.name}`);
    const logoURL = await storageRef
      .put(file)
      .then(snapshot => snapshot.ref.getDownloadURL());
    return logoURL;
  }

  const saveRecruiterEdits = async recruiter => {
    const newRecruiter = {
      ...recruiter,
      industries: recruiter.industry,
      availability: { days: recruiter.days, schedule: recruiter.time },
    };
    delete newRecruiter.industry
    const oldRecruiter = location.state.recruiter;

    let changedFields = Object.fromEntries(
      Object.entries(newRecruiter).filter(([field, value]) => {
        if (
          field === "candidates_aggregate" ||
          // field === "industry" ||
          field === "days" ||
          field === "time" ||
          field === 'company'
        ) {
          return false;
        }
        if (typeof value === "string") {
          return oldRecruiter[field] !== value;
        } else {
          return JSON.stringify(oldRecruiter[field]) !== JSON.stringify(value);
        }
      })
    );

    let changedCompany = false
    /*
    // check company
    if (recruiter.company?.id) {
      changedFields.company_id = recruiter.company.id
    } else if (recruiter?.company !== oldRecruiter?.company) {
      changedCompany = true
      setChanges(changedFields)
      if (typeof recruiter.company.logoURL !== 'string') {
        const companyLogo = await uploadImage(recruiter.company.logoURL, 'companylogos')
        createCompany({
          variables: {
            company: { ...recruiter.company, logoURL: companyLogo }
          }
        })
      } else {
        createCompany({
          variables: {
            company: recruiter.company
          }
        })
      }
    }
    */
    if (!changedCompany) {
      if (Object.keys(changedFields).length > 0 && !changedCompany) {
        let profilePhoto;
        if (changedFields.profilePhoto) {
          profilePhoto = await uploadImage(
            changedFields.profilePhoto,
            "profilephotos"
          );
        }
        updateRecruiter({
          variables: {
            id: location.state.recruiter.id,
            changedFields: profilePhoto
              ? { ...changedFields, profilePhoto }
              : changedFields,
          },
        });
      } else {
        window.history.back();
      }
    }
  };

  useEffect(() => {
    if (data) {
      navigate(`/recruiters/${location.state.recruiter.id}`, {
        state: {
          recruiter: data.update_recruiter.returning[0],
        },
      });
    }
  }, [data]);

  async function updateRecruiterWithNewCompany(company_id) {
    let profilePhoto;
    if (changes.profilePhoto) {
      profilePhoto = await uploadImage(
        changes.profilePhoto,
        "profilephotos"
      );
    }
    updateRecruiter({
      variables: {
        id: location.state.recruiter.id,
        changedFields: profilePhoto
          ? { ...changes, company_id, profilePhoto }
          : { ...changes, company_id }
      },
    });
  }

  useEffect(() => {
    if (companyData) {
      updateRecruiterWithNewCompany(companyData.insert_company.returning[0].id)
    }
  }, [companyData])

  return (
    <CreateAccountForm
      onSubmit={saveRecruiterEdits}
      recruiter={location.state.recruiter}
    />
  );
}
