import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { navigate } from 'gatsby';
import React, { useContext, useEffect } from 'react';
import { ConfirmModalContext } from '../../context';
import LocationPin from '../../images/location.svg';
import UserAvatar from '../../images/user-avatar.png';
import TrashIcon from '../jobs/TrashIcon';

// graphql query to delete company on the basis of id
const DELETE_COMPANY = gql`
  mutation removeCompanies($id: uuid) {
    delete_companies(where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        id
        name
        websiteURL
        description
        size
        logoURL
        location
        industries
      }
    }
  }
`;

export default function CompanyEntry({ company }) {
  const [, setModal] = useContext(ConfirmModalContext);
  const [deleteCompany, { data }] = useMutation(DELETE_COMPANY);

  function removeCompany() {
    deleteCompany({ variables: { id: company.id }, refetchQueries: ['getCompanies'] });
  }

  const onClickTrashCan = () => {
    setModal({
      open: true,
      title: `Confirm Company Removal`,
      message: `Are you sure you want to remove the company ${company.name}?`,
      buttonText: 'Remove Company',
      action: removeCompany,
    });
  };

  useEffect(() => {
    if (data) {
      setModal({ loading: false, open: false });
      // navigate(`/dashboard/companies`)
    }
  }, [data]);

  return (
    <div className="px-md lg:px-xl">
      <div className="flex flex-col lg:flex-row items-center border-b py-md lg:py-lg">
        <div
          style={{
            margin: 0,
            height: 49,
            width: 49,
            marginRight: 15,
            borderRadius: 40,
            backgroundImage: `url(${company.logoURL ? `"${company.logoURL}"` : UserAvatar})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
          }}
        />
        <div className="flex flex-col">
          <div
            className="hover:underline lg:p-0 cursor-pointer lg:text-sm"
            style={{ fontWeight: 500 }}
            onClick={() => navigate(`/companies/${company.id}/`, { state: { company_id: company.id } })}
          >
            {company.name}
          </div>
          <div className="flex items-center font-medium" style={{ fontSize: 14, maxWidth: 402 }}>
            <div className="text-darkgray mr-sm flex items-center" style={{ whiteSpace: 'nowrap' }}>
              <img alt="" src={LocationPin} style={{ height: 14, margin: 0, marginRight: 10 }} />
              {company.location && company.location.city}
            </div>
            <div className="mr-sm text-darkgray">{'|'}</div>
            <div className="text-darkgray" style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
              <a
                href={company.websiteURL.includes('https') || company.websiteURL.includes('http') ? company.websiteURL : 'http://' + company.websiteURL}
                target="_blank"
                rel="noreferrer"
              >
                {company.websiteURL}
              </a>
            </div>
          </div>
        </div>
        <div className="hidden lg:flex justify-end mr-lg" style={{ flexGrow: 1 }}>
          {company.industries &&
            (company?.industries || [])?.map((industry, index) => (
              <div key={index} className="bg-mediumgray text-darkgray flex items-center rounded px-md font-medium mr-sm" style={{ height: 30, fontSize: 12, borderRadius: 15 }}>
                {industry}
              </div>
            ))}
        </div>
        <TrashIcon onClick={onClickTrashCan} />
      </div>
    </div>
  );
}
