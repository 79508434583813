import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { DateRangePicker } from 'react-date-range';
import './DateRange.css';

export default function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return { ref, isComponentVisible, setIsComponentVisible };
}

export const DateRange = (props) => {

  const now = {
    startDate: '',
    endDate: '',
    key: 'selection',
  };

  const [date, setDate] = useState(now);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  useEffect(() => {
    if (
      props?.dates?.startDate &&
      props?.dates?.endDate &&
      moment(props.dates.startDate).toISOString() !== moment(date.startDate).toISOString() &&
      moment(props?.dates?.endDate).toISOString() !== moment(date.endDate).toISOString()) {
      const changes = {
        startDate: moment(props.dates.startDate).toDate(),
        endDate: moment(props.dates.endDate).toDate(),
        key: 'selection',
      }
      setDate(changes);
    }
  }, [props.dates]);

  const handleSelect = (ranges) => {
    setDate(ranges?.selection);
    if (props.onChange) {
      props.onChange({ startDate: ranges?.selection.startDate, endDate: ranges?.selection.endDate });
    }
  }

  const reset = () => {
    const changes = {
      startDate: null,
      endDate: null,
      key: 'selection',
    };
    setDate(changes);
    if (props.onChange) {
      props.onChange({ startDate: null, endDate: null });
    }
  }

  const formattedDate = (_date) => _date ? moment(_date).format('MMM DD, YYYY') : null;
  const showClearButton = date?.startDate && date?.endDate;

  return (
    <div className="date-picker">
      <div className="date-picker-wrapper">
        <div className={'date-picker-display ' + (showClearButton ? 'display-margin' : '')}>
          <span className="date-picker-item" onClick={() => setIsComponentVisible(!isComponentVisible)}>
            <input readOnly={true} placeholder="Early" value={formattedDate(date?.startDate) || 'Start Date'} />
          </span>
          <span className="date-picker-item" onClick={() => setIsComponentVisible(!isComponentVisible)}>
            <input readOnly={true} placeholder="Continuous" value={formattedDate(date?.endDate) || 'End Date'} />
          </span>
        </div>
        {
          showClearButton ? (
            <div
              onClick={reset}
              className="absolute lowercase cursor-pointer bg-darkgray text-white font-medium px-sm date-picker-clear">
              clear
            </div>
          ) : null
        }
      </div>
      <div ref={ref}>
        {
          isComponentVisible ? (
            <div className="date-picker-selector-wrapper">
              <DateRangePicker
                color="#E31C25"
                rangeColors={["#E31C25"]}
                maxDate={moment().toDate()}
                className="date-picker-selector"
                ranges={[date]}
                onChange={handleSelect}
              />
            </div>
          ) : null
        }
      </div>
    </div>
  );
}