import React, { useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/storage";
import "firebase/firestore";
import FeeConfigForm from "./fee-config-form";
import { EXPRESS_SERVER_URL } from '../../../config';
import { gql } from "apollo-boost";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { toast } from "material-react-toastify";
import { useEffect } from "react";
import { useSnackbar } from 'react-simple-snackbar';

const UPDATE_SETTINGS = gql`
  mutation updateSettings($id: uuid, $changes: settings_set_input) {
    update_settings(where: { id: { _eq: $id } }, _set: $changes) {
      affected_rows
      returning {
        id
        credit_rate
        placement_fee_percentage
        guarantee_period
        recruiter_share
        cherrypicker_share
      }
    }
  }
`;

const SETTINGS = gql`
  query getSettings {
    settings {
      placement_fee_percentage
      guarantee_period
      recruiter_share
      cherrypicker_share
    }
  }
`;

export default function FeeConfig({
    navigate, refetch = () => { }
}) {
    const [errors, setErrors] = useState({});
    const [openSnackbar] = useSnackbar();

    const [initialFields, setInitialFields] = useState({
        placement_fee_percentage: 15,
        guarantee_period: 90,
        cherrypicker_share: 20,
        recruiter_share: 80
    })

    const [updateSettings, { data, loading }] = useMutation(UPDATE_SETTINGS);
    const { data: feeConfigSettingsData } = useQuery(SETTINGS);

    useEffect(() => {
        if (feeConfigSettingsData?.settings) {
            setInitialFields(feeConfigSettingsData?.settings[0])
        }
    }, [feeConfigSettingsData])

    const saveProfile = async (profile) => {
        const { placement_fee_percentage, guarantee_period, cherrypicker_share, recruiter_share } = profile;
        const changes = {
            guarantee_period: Number(guarantee_period),
            cherrypicker_share: Number(cherrypicker_share),
            recruiter_share: Number(recruiter_share),
            placement_fee_percentage: Number(placement_fee_percentage)
        };
        updateSettings({
            variables: { id: 'b8a33041-6c9d-4b5f-a219-70519b4d3dab', changes: changes },
        }).then(() => {
            openSnackbar('Fee Config Updated Successfully');
            if (refetch) {
                refetch();
            }
        }).catch(() => {
            openSnackbar('Failed to update Fee Config');
        });;
    };

    return (
        <div
            className="flex justify-center items-center pb-lg mx-md"
            style={{ top: 20, left: 0, right: 0 }}>
            <div
                className="w-full bg-white rounded flex flex-col"
                style={{ minHeight: 370, padding: 40 }}
                onClick={e => e.stopPropagation()}>
                <FeeConfigForm
                    initialValues={initialFields}
                    saveProfile={saveProfile}
                    navigate={navigate}
                    loading={loading}
                    errors={errors}
                />
            </div>
        </div>
    );
}
